import axios from '../globalAxios';
import { appConfig } from '../../config';

const baseUrl = appConfig.api.baseUrl;

export default class FileApi {
    static createOrderImageZip = async (orderId: number) => {
        const url = baseUrl + 'Order/CreateOrderImageZip/' + orderId;
        return await axios.post(url);
    }

    // https://dev2b.molo.com/media/productimage/DownloadZipFile/B2B13_210
    static downloadZipFile = async (location: string) => {
        // const url = baseUrl + 'Order/CreateOrderImageZip/' + orderId;
        return await axios.get(location);
    }

    // /api/Order/CreateCustomerDropZip
    static createCustomerDropZip = async (customerno: string, deliverycode: string, seasoncode: string) => {
        const url = baseUrl + `Order/CreateCustomerDropZip/${customerno}/${deliverycode}/${seasoncode}`;
        return await axios.post(url, );
    }

    static createCustomerUnshippedItemsZip = async (sonumber: string, deliverycode: string) => {
        const url = baseUrl + `Order/CreateNavOrderUnshippedItemsZip/${sonumber}?deliverycode=${deliverycode}`;
        return await axios.post(url, );
    }
    static createCustomerBSLItemsZip = async (bsl: string ) => {
        const url = baseUrl + `Order/CreateNavOrderBSLZip/${bsl}`;
        return await axios.post(url, );
    }

     // /api/Order/CreateCustomerMultipleDropZip
    static createCustomerMultipleDropZip = async (customerno: string, data: any[]) => {
        console.log(data, 'DATA')
        const url = baseUrl + 'Order/CreateCustomerMultipleDropZip';
        // const d = [{'deliverycode': "SS HOLIDAY", 'seasoncode': "S17"}, {'deliverycode': "AW20 HOL", 'seasoncode': "W20"}]
        return await axios.post(url, data, {params: {
            customerno
        }});
    }
}

