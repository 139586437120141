import React from 'react'

import { FaUserCircle } from 'react-icons/fa';

interface Props {
    shouldHide?: Function
    onClickCreateOrder: Function
    onClickDeleteOrder: Function
    onClickMyMolo: Function
    onClickProfile: Function
    onClickProductDownload: Function
    logoutUser: Function
    hideDelete?: boolean
    editMode?: boolean
    deleteOrderText?: string
    showProductDownload?: boolean
}

const ShopDropDown: React.FC<Props> = (props) => {
    const {
        onClickCreateOrder,
        onClickDeleteOrder,
        shouldHide,
        hideDelete,
        logoutUser,
        onClickMyMolo,
        onClickProfile,
        onClickProductDownload,
        showProductDownload,
    } = props;
    const node = React.useRef(null);
    const [open, setValue] = React.useState(false);

    const handleOnClickCreateOrder = () => {
        if (shouldHide) {
            shouldHide();
        }

        onClickCreateOrder();
    }

    React.useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClickOutside);
        // return function to be called when unmounted
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    const handleClickOutside = (e: any) => {
        // @ts-ignore
        if(node.current.contains(e.target)) {
            return;
        }
        
        // outside click 
        setValue(false)
    };

    const handleToggle = () => {
        setValue(!open)
    }

    return (
        <div className="dropdown" ref={node}>
            <div className="dropdown__trigger my-molo-dropdown-trigger" onClick={handleToggle}>
                
                <span className="desktop-only">
                    My Molo
                </span>
                <FaUserCircle className="my-molo-dropdown-icon" />
            </div>
            <div className={"dropdown__menu dropdown__menu--my-molo " + (open ? "--is-active" : "--is-hidden")}>
                <div
                    className={"dropdown__menu__item"}
                    onClick={() => {
                        setValue(false)
                        handleOnClickCreateOrder()}
                    }
                >
                    Create new order
                </div>
                {
                    !hideDelete &&
                    <div
                        className={"dropdown__menu__item"}
                        onClick={() => {
                            setValue(false)
                            onClickDeleteOrder()}
                        }
                    >
                        { props.deleteOrderText ? props.deleteOrderText : 'Delete current order'} 
                    </div>
                }
                <div
                    className={"dropdown__menu__item"}
                    onClick={() => {
                        setValue(false)
                        onClickMyMolo()}
                    }
                >
                    My orders
                </div>
                {showProductDownload && <div
                    className={"dropdown__menu__item"}
                    onClick={() => {
                        setValue(false)
                        onClickProductDownload()}
                    }
                >
                    Image download
                </div>}
                <div
                    className={"dropdown__menu__item"}
                    onClick={() => {
                        setValue(false)
                        onClickProfile()}
                    }
                >
                    My profile
                </div>
                <div
                    className={"dropdown__menu__item"}
                    onClick={() => {
                        setValue(false)
                        logoutUser()
                    }}
                >
                    Logout
                </div>
            </div>
        </div>
    )
}

export default ShopDropDown;