import React from 'react';

interface IProps {
    name: string
    title: string
    value: any,
    handleChange: any,
    checked: boolean,
}

export const CheckboxInput: React.FC<IProps> = ({
    name,
    title,
    value,
    handleChange,
    checked,
}) => {
	//console.log(props.value);
	return (  
        <div className="pretty p-default form-group">
            <input
                onChange={handleChange}
                value={value}
                className="a-form-control a-form-control--checkbox"
                type="checkbox"
                name={name}
                id={`${value}_id`}
                checked={checked} />
            <div className="state">
                <label htmlFor={`${value}_id`}>{title}</label>
            </div>
        </div>
    )
}