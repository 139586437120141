import { combineReducers } from 'redux'
import { ProductsActions } from './actions'

import Style, { DeliveryWindow } from 'molo-shared/lib/models/Style';

// States' definition
export interface IProductState {
    isFetching: boolean,
    isFetchingOrderTypes: boolean,
    orderTypes?: any[]
    moloItems?: Style[]
    deliveryWindows?: DeliveryWindow[]
}

export interface State {
    productStore: IProductState
}

const productReducer = (state: IProductState = {
    isFetching: false,
    isFetchingOrderTypes: false
}, action: ProductsActions): IProductState => {
    switch (action.type) {
        case 'FETCH_ORDER_TYPES_SUCCESS':
            return { ...state, orderTypes: action.orderTypes }

        case 'FETCH_MOLO_ITEM_SUCCESS':
            return { ...state, moloItems: action.moloItems }

        case 'SET_FETCHING_ORDER_TYPES':
            return { ...state, isFetchingOrderTypes: action.isFetchingOrderTypes }

        case 'SET_DELIVERY_WINDOWS_SUCCESS':
            return { ...state, deliveryWindows: action.deliveryWindows }
    }
    
    return state
}

export default combineReducers<State>({
    productStore: productReducer
})