import { combineReducers } from 'redux'
import { AlertActions, IAlertPayload } from './actions'

export interface IAlertState {
    alertState: IAlertPayload[]
}

const alertReducer = (state: IAlertPayload[] = [], action: AlertActions): IAlertPayload[] => {
    // console.log(action, 'action :: alertReducer')
    switch (action.type) {
        case 'ALERT_SUCCESS':
            return [...state, ...[action.payload]]
        case 'ALERT_CLEAR':
            return state.filter(alert => alert.id !== action.id)
    }
    
    return state
}

export default combineReducers<IAlertState>({
    alertState: alertReducer
})
