import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';

import session, { State as SessionState } from './session/reducers'
import { State as OrderState } from './order/orderStore';
import order from'./order/reducers';
import user, { State as UserSate } from './user/reducers';
import productStore, {State as ProductStoreState} from './products/reducers';
import collections, {State as CollectionsState} from './collections/reducers';
import alertStore, {IAlertState} from './alert/reducers';

export interface RootState {
  session: SessionState
  order: OrderState
  user: UserSate
  productStore: ProductStoreState,
  collections: CollectionsState,
  alertStore: IAlertState,
}

const allReducers = combineReducers<RootState>({
  session,
  order,
  user,
  productStore,
  collections,
  alertStore
});

const rootReducer = (state: any, action:any) => {
  if (action.type === 'LOGOUT_USER_SUCCESS') {
    state = undefined;
  }
  return allReducers(state, action);
}

export default createStore(rootReducer, applyMiddleware(thunk))