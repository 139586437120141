import React, { Component } from 'react'

import { appConfig } from '../../config';

import QuantityInput from './QuantityInput';
import { Basket } from '../../models/Basket';
import { IgroupedItems } from '../../models/MoloItem';
import { StyleBean } from '../../models/Beans';

import { clone } from 'lodash'
import { IOrderState } from '../../store/order/orderStore';

const baseUrl = appConfig.media.baseUrl;

interface IProps {
    sizes: any[],
    moloItems: IgroupedItems
    selectedProduct: StyleBean
    updateChanges: Function
    basket: Basket
    orderState:IOrderState 
}

interface State {
    currentCellIndex: number
    realCellIndex: number
    inputsRendered: boolean
    orderState: IOrderState
}

// const QuantityGrid: React.SFC<IProps> = (props) => {
export class QuantityGrid extends Component<IProps, State> {
    tableRef = React.createRef<HTMLTableElement>();
    currentRowIndex = 1;
    cellLength = 0;
    tableLength = 0;

    state: State = {
        currentCellIndex: 0,
        realCellIndex: 2,
        inputsRendered: false,
        orderState: this.props.orderState
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.moloItems !== this.props.moloItems
            || Object.keys(prevProps.moloItems).length !== Object.keys(this.props.moloItems).length
            || this.props.selectedProduct.colorCode !== prevProps.selectedProduct.colorCode) {
                this.setState({ inputsRendered: false })
        }

        if (this.tableRef.current !== null) {
            const rows: HTMLTableElement | null = document.querySelector('#quantity-grid-table');

            if (rows) {
                const inputs = rows.querySelectorAll('input')

                if (!this.state.inputsRendered) {
                    this.setState({ inputsRendered: true }, () => {
                        this.initialCheck()
                    })
                }
            }
        }
    }
    
    initialCheck = () => {
        if (this.tableRef.current !== null) {
            this.tableLength = this.tableRef.current.rows.length;
            this.cellLength = this.tableRef.current.rows[0].cells.length
            this.initialFocus()
        }
    }

    handleKeyUp = (e: any) => {
        // e.preventDefault()
        // console.log('handlekeyup');
    };

    handleKeyDown = (e: any) => {
        const isRight = e.key === 'ArrowRight' || e.key === 'Tab';
        const isLeft = e.key === 'ArrowLeft';
        const isUp = e.key === 'ArrowUp';
        const isDown = e.key === 'ArrowDown';

        if (isRight || isLeft || isUp || isDown) {
            e.preventDefault()
            this.handleNextInput(isRight, isLeft, isDown, isUp);
        }
    };

    handleNextInput(isRight: boolean = false, isLeft?: boolean, isDown?: boolean, isUp?: boolean, isClick?: boolean) {
        let currentCellIndex = clone(this.state.realCellIndex);

        if (this.tableRef.current !== null) {
            if ((currentCellIndex) === (this.cellLength - 1) && isRight && (!isUp && !isDown)) {
                currentCellIndex = 0;

                this.currentRowIndex++;
                
                if (this.currentRowIndex === this.tableLength) {
                    this.currentRowIndex = 1;
                }
            }
            
            else if (isRight && (!isUp && !isDown)) {
                    currentCellIndex++
            }
            
            else if (isLeft && (!isUp && !isDown)) {
                currentCellIndex--
                
                // Triggered when left arrow key is pressed
                // and cell is the first cell.
                if (currentCellIndex < 0) {
                    currentCellIndex = this.cellLength - 1;

                    // When this done in the first row.
                    if (this.currentRowIndex === 1) {
                        this.currentRowIndex = this.tableLength - 1;
                    } else {
                        this.currentRowIndex--;
                    }
                }
            }
            
            else if (isUp) {
                this.currentRowIndex--

                if (this.currentRowIndex <= 0) {
                    this.currentRowIndex = this.tableLength-1;
                }
            }

            else if (isDown) {
                this.currentRowIndex++

                if (this.currentRowIndex === this.tableLength) {
                    this.currentRowIndex = 1;
                }
            }

            const tds = this.tableRef.current.rows[this.currentRowIndex].getElementsByTagName('td');
            const input = tds[currentCellIndex].getElementsByTagName('input')
            let realCellIndex = 0;

            if (input[0]) {
                const closest: any = input[0].closest('td');
                
                if (closest) {
                    realCellIndex = closest.cellIndex;
                }
            } else {
                realCellIndex = currentCellIndex;
            }

            this.setState({ currentCellIndex, realCellIndex }, () => {
                if (input[0]) {
                    input[0].focus();

                } else {
                    this.handleNextInput(isRight, isLeft, isDown, isUp);
                }
            })
        }
    }

    initialFocus() {
        if (this.tableRef.current) {
            const rows = this.tableRef.current.rows;

            if (rows) {
                for (let i = 0; i < rows.length; i++) {
                    const row = rows[i].classList.contains(('active-row'));

                    if (row) {
                        const inputs = rows[i].getElementsByTagName('input')
                        this.currentRowIndex = i;

                        if (inputs.length) {
                            const el = inputs[0] as HTMLElement;
                            el.focus();
                            
                            const closest: any = el.closest('td');
                            if (closest) {
                                const realCellIndex = closest.cellIndex;


                                this.setState({ realCellIndex }, () => {
                                    closest.scrollIntoView(true);   
                                })
                            }
                        } else {
                            // If no inputs where found, scroll to row.
                            const cells = rows[i].getElementsByTagName('td')

                            const closest = cells[0] as HTMLElement;

                            closest.scrollIntoView(true);
                        }
                        
                        break;
                    }
                }
            }
        }
    }

    handleOnFocus = (e: any) => {
        e.preventDefault()
        const currentInput = e.target

        if (this.tableRef.current) {
            currentInput.select();

            if (currentInput) {
                const closest = currentInput.closest('td');

                if (closest) {
                    closest.classList.add('cell-is-active');
                }

                const allCells = this.tableRef.current.getElementsByTagName('td')

                for (const i in allCells) {
                    if (allCells[i] && allCells[i] !== closest) {
                        if (allCells[i].classList) {
                            allCells[i].classList.remove('cell-is-active');
                        }
                    }
                }
            }
        }
    }

    handleOnClick = (e: any) => {
        const field = e.target;
        this.currentRowIndex = field.closest('tr').rowIndex;

        if (this.tableRef.current) {
            const inputs = this.tableRef.current.rows[this.currentRowIndex].getElementsByTagName('input');

            for (const key in inputs) {
                const input = inputs[key];

                if (input === field) {
                    const closest: any = input.closest('td');
                    if (closest) {
                        this.setState({ realCellIndex: closest.cellIndex}, () => {
                            input.focus();
                        })
                    }
                }
            }
        }
    }

    render() {
        const { sizes, moloItems, selectedProduct } = this.props;
        let indexCounter = 0;

        return (
            <table className="quantity-grid" id="quantity-grid-table" ref={this.tableRef}>
                <thead>
                    <tr className="quantity-grid__header">
                        <th className="quantity-grid__cell">{ selectedProduct.description }</th>
                        <th className="quantity-grid__cell">Color name</th>
                        {
                            sizes.map((size, index) => {
                                return (
                                    <th key={( size.code )} className={'quantity-grid__cell' + ((index + 2) === this.state.realCellIndex ? ' cell-is-active' : '')}>
                                        <div>{size.name}</div>
                                    </th>
                                )
                            }
                        )}
                    </tr>
                </thead>
                
                <tbody className="quantity-grid__body">
                    {Object.keys(moloItems).map((itemKey, key) => {              
                        const myComponentClass = itemKey === (this.props.selectedProduct.masterId + this.props.selectedProduct.colorCode) ? 'active-row' : '';
                        const imgUrl = baseUrl + 'media/productimage/' + moloItems[itemKey][0].seasonCode + '/' + moloItems[itemKey][0].masterId + '/' + moloItems[itemKey][0].colorCode + '/small';
                        return (
                            <tr className={`quantity-grid__row ${myComponentClass}`} key={(moloItems[itemKey][0].itemId + myComponentClass + key)}>
                                <td className="quantity-grid__cell">
                                    <img src={imgUrl} alt={ moloItems[itemKey][0].colorName }/>
                                </td>
                                <td className="quantity-grid__cell">
                                    { moloItems[itemKey][0].colorName }
                                </td>
                                {    
                                    moloItems[itemKey].map((item, key) => {
                                        return (
                                            this.props.basket &&
                                                <QuantityInput
                                                    idx={indexCounter}
                                                    key={( item.itemId + item.sizeCode + item.sizeName )}
                                                    quantity={ +this.props.basket.basketLines[item.itemId] || '' }
                                                    available={(this.state.orderState.order.orderheader.orderType.toUpperCase() == 'REORDER')?item.available: (item.available + item.availablenos)}
                                                    itemId={item.itemId}
                                                    updateChanges={this.props.updateChanges}
                                                    handleKeyDown={this.handleKeyDown}
                                                    handleOnFocus={this.handleOnFocus}
                                                    handleKeyUp={this.handleKeyUp}
                                                    blocked={item.blocked === 1}
                                                    handeClick={this.handleOnClick}
                                                    soldOut={(!+this.props.basket.basketLines[item.itemId] || +this.props.basket.basketLines[item.itemId] < 1) && item.available < 1}
                                                />
                                        )
                                    })
                                }
                          </tr>
                        )
                    })}
                </tbody>
            </table>
        );    
    }

};

export default QuantityGrid