import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import axios from 'axios';
import { appConfig } from '../../config';

import { setAlert } from '../alert/actions';

const baseUrl = appConfig.api.baseUrl;

const clearLocalStorage = () => {
    const keysToRemove = [
        'previousNonDashboardUrl',
        'order',
        'recentClosedOrders',
        'token',
        'search:prevPage'
    ];

    for (const key of keysToRemove) {
        if (localStorage.getItem(key)) {
            localStorage.removeItem(key);
        }
    }
}

export interface ILoginUserToken {
    type: 'LOGIN_USER_SUCCESS',
    token: string
}

export interface ILoginUserError {
    type: 'LOGIN_USER_ERROR',
    error: string
}

export interface ILogoutUser {
    type: 'LOGOUT_USER_SUCCESS',
}

export interface IIsUserValid {
    type: 'USER_IS_VALID_SUCCESS',
    user: AuthUser,
}

export interface IIsUserValidError {
    type: 'USER_IS_VALID_ERROR',
    isValid: boolean,
}

export type UserActions = ILoginUserToken | IIsUserValid | IIsUserValidError | ILogoutUser | ILoginUserError

export interface IUser {
    email: string
    password: string
}

export interface AuthUser {
    currencyCode: string
    customerData: any
    email: string,
    disallowedTypes: string[]
}

// Action Creators
export const loginUserSuccess = (token: string): ILoginUserToken => {
    return { type: 'LOGIN_USER_SUCCESS', token }
}

export const loginUserError = (error: string): ILoginUserError => {
    return { type: 'LOGIN_USER_ERROR', error }
}

export const logoutUserSuccess = (): ILogoutUser => {
    return { type: 'LOGOUT_USER_SUCCESS' }
}

export const userIsvalidSucces = (user: AuthUser): IIsUserValid => {
    return { type: 'USER_IS_VALID_SUCCESS', user }
}

export const userIsvalidError = (): IIsUserValidError => {
    return { type: 'USER_IS_VALID_ERROR', isValid: false }
}

export const loginUser = (email: string, password: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        // dispatch(alertClear());
        try {
            const response = await UserApi.loginUser(email, password);
            
            if (process.env.NODE_ENV !== 'production') {
                localStorage.setItem('token',response.data.token);
            }
            dispatch(loginUserSuccess(response.data.token))
        } catch (error) {
            dispatch(setAlert('The user name or password provided is incorrect.', 'error'))
            // dispatch(setAlert(error.response.data.message, 'error'));
        }
    }
}

export const isUserValid = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    // Invoke API
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        let devToken = undefined;

        if (process.env.NODE_ENV !== 'production') {
            devToken = localStorage.getItem('token');

            if(!devToken) {
                dispatch(userIsvalidError());
                localStorage.removeItem('user');
                clearLocalStorage();
                return;
            }
        }
        
        try {
            const response = await UserApi.isUserValid(devToken);

            let disallowedTypes: string[] = [];

            if (response.data && response.data.disallowTypes) {
                const types = JSON.parse(response.data.disallowTypes);
                disallowedTypes = types.map((type: any) => type.OrdertypeType.toLowerCase())
            }

            const authUser: AuthUser = {
                currencyCode: response.data.currencyCode,
                customerData: JSON.parse(response.data.customer),
                email: response.data.login,
                disallowedTypes
            }
            dispatch(userIsvalidSucces(authUser))
            
        } catch(e) {
            console.log(e, 'error')
            if (process.env.NODE_ENV !== 'production') {
                localStorage.removeItem('user');
                clearLocalStorage();
            }
            dispatch(userIsvalidError());
        }
    }
  }

export const logoutUser = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {   
        try {
            console.log('logout called')
            await UserApi.logoutUser();
            clearLocalStorage();
            dispatch(logoutUserSuccess())
        } catch (e) {

        }
    }
}

export const updatePassword = (email: string, oldPassword: string, newPassword: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {   
        try {
            const update = await UserApi.updatePassword(email, oldPassword, newPassword);

            if (update) {
                dispatch(setAlert('Your password has been updated!', 'success'))
                dispatch(isUserValid())
            }            
        } catch (e) {}
    }
}

// API.
export class UserApi {
    static loginUser = async (email: string, password: string) => {
        const url = baseUrl + 'Users/authenticate';
        return await axios.post(url, {email, password});
    }

    static isUserValid = async (devToken?: string) => {
        const url = baseUrl + 'Users/Me';
        
        if (devToken) {
            axios.defaults.headers.common['Authorization'] = 'bearer ' + devToken;
        }
        
        return await axios.post(url);
    }

    static logoutUser = async () => {
        const url = baseUrl + 'Users/Logout';
        return await axios.post(url);
    }

    static resetPassword = async (email: string) => {
        const url = baseUrl + 'Users/ResetPassword';
        return await axios.post(url, null, {params: { Email: email }});
    }

    static updatePassword = async (email: string, oldPassword: string, newPassword: string) => {
        const url = baseUrl + 'Users/UpdatePassword';
        return await axios.post(url, null, {params: {
            email,
            oldPassword,
            newPassword,
        }})
    }
}