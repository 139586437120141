import React from 'react'

import Modal from '../layout/Modal/Modal';

import FileApi from '../../store/files/FileApi';

import PropagateLoader from 'react-spinners/PropagateLoader'

interface Props {
    show: boolean
    shouldHide: Function
    orderId: number
}

const DownloadOrderImagesModal: React.FC<Props> = (props) => {
    let [downloadURL, setDownloadURL] = React.useState<string | undefined>(undefined);
    let [downloading, setDownloading] = React.useState<boolean>(false)
    const { shouldHide, show } = props;

    React.useEffect(() => {
        if (show && !downloadURL && !downloading) {
            setDownloading(true)

            setTimeout(() => {
                getFile();
            }, 1000);
        }
        
        return () => {};
    });

    const getFile = async () => {
        const response = await FileApi.createOrderImageZip(props.orderId)

        setDownloadURL(response.data.location)
        window.location.href = response.data.location;
        setDownloading(false)

        setTimeout(() => {
            props.shouldHide();
        }, 1000);
    }

    const renderModal = () => {
        return (<div className="delete-order-modal" style={{minHeight: '200px', maxHeight: '100%'}}>
            <div className="loading-spinner-2" style={{paddingTop: '50px' }}>
                <p>Downloading your file</p>
                <PropagateLoader />
            </div>
        </div>)
    }

    return (
        <Modal
            children={renderModal()}
            size={'sm'}
            hide={shouldHide}
            isShowing={show}
            bodyClasses={'confirm-modal'}
            hideFooter={true}/>
    )
}

export default DownloadOrderImagesModal;