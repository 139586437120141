const baseUrl = 'https://dev2b.molo.com/';
// 
// const baseUrl = 'https://b2b.molo.com/';

export const appConfig = {
    api: {
        baseUrl: process.env.NODE_ENV !== 'production' ? baseUrl + 'api/' : '/api/'
    },
    media: {
        baseUrl: process.env.NODE_ENV !== 'production' ? baseUrl : '/'
    }
}