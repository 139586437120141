import { combineReducers } from 'redux'
import { UserActions } from './actions'

// States' definition
export interface IUserSate {
    isFetching: boolean
    user?: any
    isValidated: boolean
    token?: string
}

export interface State {
    user: IUserSate
}

const userReducer = (state: IUserSate = {
    isFetching: false,
    isValidated: false
}, action: UserActions): IUserSate => {
    switch (action.type) {
        case 'LOGIN_USER_SUCCESS':
                return { ...state, token: action.token }
        case 'USER_IS_VALID_SUCCESS':
            return { ...state, user: action.user, isValidated: true }
        case 'USER_IS_VALID_ERROR':
            return { ...state, isValidated: true, user: null }
        case 'LOGOUT_USER_SUCCESS':
            return { ...state , user: null}
    }
    
    return state
}

export default combineReducers<State>({
    user: userReducer
})