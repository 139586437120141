import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Order from '../../Models/Order';
import OrderApi from '../../store/order/OrderApi';
import ShippedOrdersCalendar from '../shared/Dashboard/ShippedOrdercalendar';
import RingLoader from 'react-spinners/RingLoader'
import TrackingModal from '../shared/TrackingModal'

interface IProps {
    order: any,
    onClose?: Function,
    onHandleClickBSL: Function
}

export const ShippedCalendar: React.FC<IProps> = (props) => {
    const { order } = props
    const [deliveryWindows, setDeliveryWindows] = useState([])
    const [isFetching, setIsFetching] = useState(false);
    const [showTrackingModal, setShowTrackingModal] = useState(false)
    const [trackingBsl, setTrackingBsl] = useState<string | undefined>(undefined)

    useEffect(() => {
        let navorderid = null;

        if (order.navorderid) {
            navorderid = order.navorderid;
        }

        if (order && navorderid && !isFetching) {
            if (order.anyunshipped || order.outstandingQuantity !== 0 ) {
                allShipments(navorderid)
            } else {
                getShipments(navorderid)
            }
        } else if (order && order.appOrderId){
            getDeliveryWindows(order.appOrderId)
        }
    }, [order])

    const allShipments = async (sonumber: string) => {
        setIsFetching(true)
        const requests: any[] = [
            OrderApi.peakNavOrderShipments(sonumber),
            OrderApi.peakNavOrderUnshippedShipments(sonumber)
        ]

        const responses = await axios.all(requests);
        const data: any = [ ...responses[0].data, ...responses[1].data ]

        setDeliveryWindows(data.map((window: any) => {
            return {
                appOrderId: order.navorderid,
                deliveryCode: window.bsl ? window.deliverywindows : window.deliverycode,
                deliveryPrintdescription: window.deliverycode,
                shipped: true,
                shippingDate: window.shipmentdate,
                shippingFrom: window.shipmentdate,
                bsl: window.bsl || '',
                items: window.items,
                nooftrackingcodes: window.nooftrackingcodes || 0
            }
        }))

        setIsFetching(false)
    }

    const getDeliveryWindows = async (orderId: string) => {
        setIsFetching(true);
        const response = await OrderApi.getOrderDeliveries(+orderId)
        if (response) {
            
            setDeliveryWindows(response.data.map((window: any) => {
                return {
                    appOrderId: window.appOrderId,
                    deliveryCode: window.bsl ? window.deliverywindows : window.deliverycode,
                    deliveryPrintdescription: window.deliveryPrintdescription,
                    shipped: window.deliveryPrintdescription,
                    shippingDate: window.shippingDate,
                    shippingFrom: window.shippingFrom,
                    bsl: '',
                    items: [],
                    nooftrackingcodes: window.nooftrackingcodes || 0
                    }
                }
            ))
        }
        setIsFetching(false)
    }

    const getShipments = async (sonumber: string) => {
        setIsFetching(true)
        const response = await OrderApi.peakNavOrderShipments(sonumber)
 
        if (response) {
            setDeliveryWindows(response.data.map((window: any) => {
                return {
                    appOrderId: order.navorderid,
                    deliveryCode: window.bsl ? window.deliverywindows : window.deliverycode,
                    deliveryPrintdescription: window.bsl || window.deliverycode,
                    shipped: true,
                    shippingDate: window.shipmentdate,
                    shippingFrom: window.shipmentdate,
                    bsl: window.bsl || '',
                    items: window.items,
                    nooftrackingcodes: window.nooftrackingcodes || 0
                    }
                }
            ))
        }

        setIsFetching(false)
    }

    const onClickDrop  = async (drop: any) => {
        props.onHandleClickBSL(order, drop)
    }

    const onClickViewTracking = async (drop: any) => {
        setTrackingBsl(drop.bsl)
        setShowTrackingModal(true)
    }

    if (isFetching) {
        return (
            <div className="flex flex-col items-center justify-center" style={{ minHeight: '400px' }}>
                <RingLoader />
            </div>
        )
    }

    if (!isFetching && !deliveryWindows.length) {
        return (
            <div className="flex flex-col items-center justify-center"  style={{ minHeight: '400px' }}>
                <h2 className="mb-6 text-xl">No shipments on this order!</h2>
            </div>
        )
    }

    return (
        <>
            <ShippedOrdersCalendar
                deliveryWindows={deliveryWindows}
                onClickFilterGroup={() => {}}
                onClickDrop={onClickDrop}
                userIsInformed={true}
                hideEditLink={true}
                setUserHasConfirmed={() => {}}
                onClickViewTracking={onClickViewTracking}
            />
            <TrackingModal
                show={ showTrackingModal }
                shouldHide={ () => setShowTrackingModal(false) }
                trackingBsl={ trackingBsl }
            />
        </>
    )

}