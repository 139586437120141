import { combineReducers } from 'redux'
import { CollectionsActions } from './actions'
import Style, { DeliveryWindow } from 'molo-shared/lib/models/Style';

// States' definition
export interface ICollectionState {
    isFetchingCollectionMasters: boolean
    collections?: any[],
    styles?: Style[],
    deliveryWindows?: DeliveryWindow[],
}

export interface State {
    collections: ICollectionState
}

const collectionReducer = (state: ICollectionState = {isFetchingCollectionMasters: false}, action: CollectionsActions): ICollectionState => {
    switch (action.type) {
        case 'FETCH_COLLECTIONS_SUCCESS':
            return { ...state, collections: action.collections }
        case 'FETCH_COLLECTION_MASTER_SUCCESS':
            return { ...state, styles: action.styles}
        case 'SET_FETCHING_COLLECTION_MASTERS':
            return { ...state, isFetchingCollectionMasters: action.isFetching}
        case 'SET_DELIVERY_WINDOWS_SUCCESS':
            return { ...state, deliveryWindows: action.deliveryWindows}
    }
    
    return state
}

export default combineReducers<State>({
    collections: collectionReducer
})