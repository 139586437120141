import React, { Component } from 'react'

// Third party.
import moment from 'moment';

// Components.
import Modal from '../../layout/Modal/Modal';
import { Calendar } from 'molo-shared';

// API.
import OrderApi from '../../../store/order/OrderApi';

// Models.
import Order from '../../../models/Order';

interface State {
    deliveryWindows: any[],
    showDeliveryModal: boolean
    currentDrop?: any,
}
  
interface OwnProps {
    currentDrop: any,
    order: Order,
    deliveryChanged?: Function,
    showDeliveryModal: boolean,
    hide: Function
}
  
interface DispatchProps {
}
  
interface StateProps {}
  
type Props = StateProps & OwnProps & DispatchProps;

export class MoveDropModal extends Component<Props, State> {
    state: State = {
        deliveryWindows: [],
        showDeliveryModal: false,
    }

    componentDidMount() {
        const { currentDrop } = this.props;
        this.setState({ currentDrop })
    }

    onSelectSlot = (slot: any) => {
        const event = { ...this.state.currentDrop };

        event.start = slot;
        event.end = slot;

        this.setState({ currentDrop: event })
    }

    moveDrop = async () => {
        const { order, deliveryChanged } = this.props;
        const { currentDrop } = this.state;

        if (currentDrop && order) {
            const orderId = order.orderheader.appOrderId;
            const deliveryCode = currentDrop.resource.deliveryCode;
            const newDate = moment(currentDrop.start).format('YYYY-MM-DD');

            await OrderApi.moveOrderDelivery(
                orderId,
                deliveryCode,
                newDate
            );

            if (deliveryChanged) {
                deliveryChanged()
            }
        }
    }

    renderBody = (currentDrop: any) => {
        return (
            <div style={{marginBottom: '30px'}}>
                <Calendar
                    defaultDate={currentDrop.start}
                    dateClick={ this.onSelectSlot }
                    selectedText={currentDrop.title}
                    startDate={currentDrop.resource.shippingFrom}
                />
            </div>
        )
    }

    render() {
        const { showDeliveryModal } = this.props;
        const { currentDrop } = this.state;

        if (!currentDrop) {
            return null;
        }

        return (
            <Modal
                header={<h4>Move drop</h4>}
                size={'lg'}
                isShowing={showDeliveryModal}
                hide={() => this.props.hide()}
                children={this.renderBody(currentDrop)}
                footer={(<button className="po__order-btn modal-footer__btn-center" onClick={this.moveDrop}>Move drop</button>)}
            />
        )
    }
}

export default MoveDropModal;