import React from 'react';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import StickyHeader from './StickyHeader';
import ProductListItem from './ProductListItem';
import { StyleBean } from '../../models/Beans';
import ClipLoader from 'react-spinners/ClipLoader';
import InfiniteScroll from 'react-infinite-scroller';
 
const InfinityList = (props: any) => {
    const {
        elements,
        onSticky,
        onUnSticky,
        handleOnProductClick,
        lineDiscount,
        isInBasket,
        lazyLoadMoloMaster,
        loadItems,
        hasMore,
        scrollPosition
    } = props;

    const loader = <div style={{display: 'flex',justifyContent: 'center'}} key={0}><ClipLoader size={30} /></div>

    let items: any = [];
    elements.map((element: any, i: number) => {
        let rendered = <div></div>

        if (element.type === 'iheader' && element.header) {
            rendered = <StickyHeader
                key={ element.header.description }
                title={ element.header.description }
                onSticky={onSticky}
                onUnSticky={onUnSticky}
            />              
        }

        if (element.type === 'style' && element.style) {
            const style = element.style;

            if (style) {
                rendered = <div
                    className="pl-item__wrapper ripple"
                    onClick={ () => handleOnProductClick(style) }
                    key={style.masterColor + style.colorCode + style.description}>
                    <ProductListItem
                        product={StyleBean.build(style, 'style')}
                        discount={lineDiscount}
                        inBasket={isInBasket(style)}
                        lazyLoadMoloMaster={lazyLoadMoloMaster}
                        quantityLabel={'Available'}
                        scrollPosition={scrollPosition}
                    />
                </div>
            }
        }

        return items.push(
            rendered
        );
    });
    
    return (
        <div className="product-list__list" id="sticky-container">
            <InfiniteScroll
                pageStart={0}
                threshold={800}
                loadMore={loadItems}
                hasMore={hasMore}
                loader={loader}
                useWindow={false}
            >
                <div className="pl">
                    { items }
                </div>
            </InfiniteScroll>
        </div>
      );
}
 
export default trackWindowScroll(InfinityList);