import React, { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../b2b/ShippedOrdersOverlay';
import OpenOrdersBody from './OpenOrdersBody';
import { ShippedOrdersOverlay } from '../b2b/ShippedOrdersOverlay';

// Components.
import { Overlay } from 'molo-shared';

interface Props {
    show: boolean
    hide: Function
}

const AllOrdersOverlay: React.FC<Props> = ({ show, hide }) => {
    const [tabIndex, setTabIndex] = useState(0)

    return (
        <Overlay
            children={
                <div className="pt-8 h-full to px-8 h-full">
                    <div className="mb-4">
                        <h1 className="text-2xl">Orders</h1>
                    </div>
                    <Tabs
                        selectedIndex={tabIndex}
                        onSelect={ti => setTabIndex(ti) }
                    >
                        <TabList>
                            <Tab>Not Completed</Tab>
                            <Tab>Completed</Tab>
                        </TabList>
                        <TabPanel>
                            <OpenOrdersBody
                                applyChanges={() => {}}
                                show={ tabIndex === 0 && show }
                            />
                        </TabPanel>
                        <TabPanel>
                            <ShippedOrdersOverlay
                                show={ tabIndex === 1 && show }
                            />
                        </TabPanel>
                    </Tabs>
                </div>
            }
            hide={() => {
                setTabIndex(0)
                hide()
            }}
            isShowing={show}
        />
    )
}

export default AllOrdersOverlay;