import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import {RouteComponentProps, withRouter} from "react-router-dom";

// Components.
import DeliveryWindows from './DeliveryWindows';
import StyleCarousel from './StyleCarousel';
import OrderSummary from './OrderSummary';
import MoveDropModal from './MoveDropModal';
import ChartSummaryHighcharts from './ChartSummaryHighcharts';
import Details from './Details';
import DashboardFilterComponent from '../DashboardFilterComponent';
import { DashboardFilterHelper } from '../../../utils/DashboardFilterHelper';
import MoloRingloader from '../../layout/MoloRingloader';

// Store.
import { RootState } from '../../../store';
import { IOrderState } from '../../../store/order/orderStore';
import { IUserSate } from '../../../store/user/reducers';
import {
    getOrder,
    applyChanges,
    applyChangesFromDashboard,
    getDashboardOrderState,
    setOrder,
    addClosedOrderToRecentAction,
    resetDashboard,
    setUserHasConfirmed
} from '../../../store/order/actions';
import { ICollectionState } from '../../../store/collections/reducers';
import { getCollectionMasters } from '../../../store/collections/actions'
import { IFilterState } from '../../../store/session/reducers';
import { resetFilter, applyFilter } from '../../../store/session/actions';
import { setAlert } from '../../../store/alert/actions';

// Models.
import { orderLine } from '../../../models/Order';
import { Filter } from '../../../models/Filter';
import { basketLine } from '../../../models/Basket';
import Style, { createStyle } from 'molo-shared/lib/models/Style';
 
// Utils.
import * as helper from './DashboardHelper';
import queryString from 'query-string'
import {
    tcategories,
    IDivider,
    IGrouping,
    DIVIDERS
} from './dashboard.d';
import { uniq, cloneDeep } from 'lodash';
import ProductApi from '../../../store/products/ProductApi';
import OrderApi from '../../../store/order/OrderApi';
import { ReactComponent as FilterIcon } from '../../../images/svg/filter_icon_black.svg';
import { appConfig } from '../../../config';
import { FaCaretLeft } from 'react-icons/fa';
import { mapMoloItems, IgroupedItems } from '../../../models/MoloItem';

const baseUrl = appConfig.media.baseUrl;

interface ISize {
    sizename: string,
    quantity: number
}

interface ISustainabilityMap {
    [id: string]: string | null | undefined
}

interface ISizes {
    [id: string]: ISize[],
}

interface IGroupedOrderLine {
    [id: string]: any,
}

interface IMasters {
    [id: string]: Style,
}

interface IRRPMap {
    [id: string]: number[]
}

interface State {
    productSortedByCategory: IGrouping,
    selectedCategory: tcategories,
    data: any[],
    salesOptionsTotal: number,
    slides: string[],
    deliveryWindows: any[],
    showDeliveryModal: boolean
    currentDrop?: any,
    basketIsEmpty: boolean,
    step: string,
    filteredOrderLines: orderLine[],
    filterInitialized: boolean
    dashboardLoading: boolean
    initialized: boolean,
    sizes: ISizes,
    groupedOrderlines: orderLine[],
    masters: IMasters,
    loadingMasters: boolean
    groupedMoloItems: {[id: string]: any[]}
    showFilter: boolean
    hideGoBack?: boolean
    rrpMap: IRRPMap
    allSizes: ISizes
}
  
interface OwnProps {
    location?: any,
    match?: {params: {orderId: number}},
    history: any

}
  
interface DispatchProps {
    getOrder: (orderId: number) => void
    resetFilter: (filter: Filter) => void
    applyFilter: (filter: Filter) => void
    applyChanges: (orderId: number, changes: basketLine) => void
    applyChangesFromDashboard: (orderId: number, changes: basketLine) => void
    getDashboardOrderState: (orderId: number) => void
    setOrder: (orderId: number) => void
    getCollectionMasters: (orderType: string, collectionName: string, currencyCode: string, pricegroup: string) => void
    setAlert: (message: string, type: string) => void
    addClosedOrderToRecent: (order: any) => void
    resetDashboard: () => void
    setUserHasConfirmed: (confirmed: boolean) => void
}
  
interface StateProps {
    orderState: IOrderState
    userState: IUserSate
    collectionState: ICollectionState
    filterState: IFilterState
}

interface chartData {
    name: string,
    y: number,
}
  
type Props = StateProps & OwnProps & DispatchProps & RouteComponentProps;

export class Dashboard extends Component<Props, State> {
    private data: chartData[] = [];

    state: State = {
        productSortedByCategory: {},
        selectedCategory: 'categories',
        data: [],
        salesOptionsTotal: 0,
        slides: [],
        deliveryWindows: [],
        showDeliveryModal: false,
        basketIsEmpty: false,
        step: 'dashboard',
        filteredOrderLines: [],
        filterInitialized: false,
        dashboardLoading: true,
        initialized: false,
        sizes: {},
        groupedOrderlines: [],
        masters: {},
        loadingMasters: false,
        groupedMoloItems: {},
        showFilter: false,
        rrpMap: {},
        allSizes: {},
    }   
    
    readonly dividers: IDivider[] =  DIVIDERS;

    componentDidMount() {
        const query = this.props.location && this.props.location.search ? queryString.parse(this.props.location.search) : null;

        if (query && query.step === 'details') {
            this.setState({ step: 'details'})
        }

        if (query && query.origin === 'shop') {
            // Reset filter.
            if (this.props.filterState.filter) {
                this.props.resetFilter(this.props.filterState.filter);
            }
            this.setState({ hideGoBack: true})
        }
        
        const paramOrderId = this.props.match && this.props.match.params.orderId ? this.props.match.params.orderId : null;

        if (this.props.orderState.dashboardOrder && !this.props.orderState.isDashboardFetching &&
            this.props.orderState.dashboardOrder.orderheader.appOrderId !== paramOrderId) {
                
            if (paramOrderId) {
                this.props.getDashboardOrderState(paramOrderId);
            }
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        let shouldUpdateOrderlines: boolean = false;
        let shouldInitDasboard: boolean = false;

        const filterHasChanged = prevProps.filterState.filter !== this.props.filterState.filter;

        if (!this.props.orderState.dashboardOrder && !this.props.orderState.isDashboardFetching) {
            const paramOrderId = this.props.match && this.props.match.params.orderId ? this.props.match.params.orderId : null;
            if (paramOrderId) {
                setTimeout(() => this.props.getDashboardOrderState(paramOrderId), 100)
            }
        }

        if (this.props.orderState.dashboardOrder && prevProps.orderState.dashboardOrder
            && this.props.orderState.dashboardOrder.orderLines !== prevProps.orderState.dashboardOrder.orderLines) {
            shouldUpdateOrderlines = true
        }

        if (!this.state.initialized && this.props.orderState.dashboardOrder && this.props.filterState.filter) {
            shouldUpdateOrderlines = true
        }

        if (prevProps.orderState.dashboardOrder !== this.props.orderState.dashboardOrder) {
            if (prevProps.orderState.dashboardOrder && this.props.orderState.dashboardOrder &&
                prevProps.orderState.dashboardOrder.orderheader.appOrderId !== this.props.orderState.dashboardOrder.orderheader.appOrderId) {
                    shouldInitDasboard = true;
                    this.setState({ loadingMasters: true }, () => {
                        this.getMoloItems()
                    })
            } else if (!prevProps.orderState.dashboardOrder && this.props.orderState.dashboardOrder) {
                this.setState({ loadingMasters: true }, () => {
                    this.getMoloItems()
                })
            }
        }

        if (filterHasChanged) {
            shouldUpdateOrderlines = true
        }

        if (shouldUpdateOrderlines && this.props.filterState.filter && this.props.orderState.dashboardOrder) {
            const filteredOrderLines = DashboardFilterHelper.resultByFilter(
                this.props.filterState.filter,
                this.props.orderState.dashboardOrder.orderLines,
            );
            this.setState({ filteredOrderLines, filterInitialized: true }, () => {
                this.initDashboard()
            });
        }

        const query = this.props.location && this.props.location.search ? queryString.parse(this.props.location.search) : null;
        let step = query && query.step as string;

        if (step && step === 'reset') {
            this.props.orderState.dashboardOrder && this.props.history.push('/dashboard/' + this.props.orderState.dashboardOrder.orderheader.appOrderId)
            this.setState({ step: 'dashboard'})
        }

        if (shouldInitDasboard) {
            this.initDashboard()
        }
    }

    componentWillUnmount = () => {
        this.props.resetDashboard();
    }

    initDashboard() {
        const order = this.props.orderState.dashboardOrder;
        if (order) {
            if (!order.orderLines.length) {
                this.setState({
                    basketIsEmpty: true,
                    initialized: true,
                    dashboardLoading: false
                })
            } else {
                const groupedOrderlines = this.groupSimilarColors(cloneDeep(this.state.filteredOrderLines));
                const productSortedByCategory = helper.getProductCategories(order.orderLines, this.dividers);
                const slides = uniq(order.orderLines.map(
                    line => baseUrl + 'media/productimage/' + line.seasoncode + '/' + line.masterid + '/' + line.colorcode + '/small')
                );

                this.setState({
                    productSortedByCategory,
                    slides,
                    basketIsEmpty: false,
                    initialized: true,
                    dashboardLoading: false,
                    groupedOrderlines,
                    
                });
                this.reMapAllsizes(groupedOrderlines)
            }
        }
    }

    getMoloItems = async () => {
        if (this.props.orderState.dashboardOrder) {
            const response = await OrderApi.GetMoloItemsOnOrder(this.props.orderState.dashboardOrder.orderheader.appOrderId)

            if (response) {
                const moloItems: {[id: string]: any[]} = {};

                for (const key in response.data) {
                    const item = response.data[key];
                    if (!moloItems[item.masterno + item.colorcode]) {
                        moloItems[item.masterno + item.colorcode] = [];
                    }

                    moloItems[item.masterno + item.colorcode].push(item)
                }

                this.setState({ loadingMasters: false, groupedMoloItems: moloItems }, () => {
                    this.mapAllMoloItems(moloItems)
                })
            }
        }
    }

    getMasters = async (orderLines: orderLine[]) => {
        let mastersRequest: {master: string, color: string, currencyCode: string}[] = [];

        for (const line of orderLines) {
            if (!this.state.masters[line.masterid + line.colorcode]) {
                mastersRequest.push({
                    master: line.masterid,
                    color: line.colorcode,
                    currencyCode: 'EUR'
                })
            }
        }

        const masterResponse = await ProductApi.getMasters(mastersRequest)
        let masters: IMasters = {};

        for (const response of masterResponse) {
            const style: Style = createStyle(response.data);
            masters[style.masterId + style.colorCode] = style;
        }
        
        this.setState({ masters, loadingMasters: false })        
    }

    groupSimilarColors(orderLines: orderLine[]) {
        const grouped: IGroupedOrderLine = {}
        const sizes: ISizes = {};
  
        for (const i in orderLines) {
            const orderline = orderLines[i];
            const masterid = orderline.masterid;
            const colorcode = orderline.colorcode;

            if (grouped[masterid + colorcode]) {
                const q = +grouped[masterid + colorcode].quantity + +orderline.quantity;
                grouped[masterid + colorcode].quantity = q;
                grouped[masterid + colorcode]['orderLines'].push({...orderline})
            } else {
                grouped[masterid + colorcode] = orderline
                grouped[masterid + colorcode]['orderLines'] = [{...orderline}]
            }
    
            if (!sizes[masterid + colorcode]) {
                sizes[masterid + colorcode] = [];
            }
    
            sizes[masterid + colorcode].push({ sizename: orderline.sizename, quantity: orderline.quantity });
        }

        this.setState({ sizes })

        let g: orderLine[] = []

        for (const key in grouped) {
            let line = grouped[key];
            let q = 0;

            for (const delta in line['orderLines']) {
                q = q + line['orderLines'][delta].quantity;
            }

            line.quantity = q;

            g.push(line);
        }

        const sorted = g.sort((a, b) => (a.masterid > b.masterid) ? 1 : -1)

        return sorted;
    }

    getSustainability = (item: any) => {
        if (item.gots !== '') return 'GOTS';

        if (item.oekoTex !== '') return 'OEKOTEX';

        if (item.recycled) return 'RECYCLED';
        
        if (item.organic) return 'ORGANIC';
        if (item.grsno) return 'GRS';
        if(item.ecovero) return 'ECOVERO';

        return null;
    }

    reMapAllsizes = (groupedOrderlines: IGroupedOrderLine) => {
        let allSizes = cloneDeep(this.state.allSizes);

        for (const key in allSizes){ 
            var theArr = allSizes[key];
            for(var zidex = 0;zidex < theArr.length;zidex++)  {
                theArr[zidex].quantity = 0;
            }
        }

        for (const i in groupedOrderlines) {
            const group = groupedOrderlines[i];
            const key = group.masterid + group.colorcode

            let found = allSizes[key]
            const orderLinesToUpdate = group.orderLines;

            if (found) {
                for (const delta in orderLinesToUpdate) {
                    // Set the new quantity.
                    let sizeToUpdate = found.find(item => item.sizename === orderLinesToUpdate[delta].sizename)
                    if (sizeToUpdate) {
                        sizeToUpdate.quantity = orderLinesToUpdate[delta].quantity;
                    }
                }
            }
        }
        this.setState({ allSizes })
    }

    mapAllMoloItems = (moloItems: IgroupedItems) => {
        let rrpMap: IRRPMap = {};
        let allSizes: ISizes = {};

        for (const key in moloItems) {
            const items = mapMoloItems(moloItems[key]);
            rrpMap[items[0].masterId + items[0].colorCode] = uniq(items.map((item: any) => item.msrp));
            
            if (!allSizes[items[0].masterId + items[0].colorCode]) {
                allSizes[items[0].masterId + items[0].colorCode] = [];
            }
            
            for (const i in items) {
                const item = items[i];

                let quantity = 0;

                if (this.state.sizes[items[0].masterId + items[0].colorCode]) {
                    const found = this.state.sizes[item.masterId + item.colorCode].find((i: ISize) => {
                        return i.sizename === item.sizeName;
                    })

                    if (!!found) {
                        quantity = found.quantity;
                    }
                }

                allSizes[item.masterId + item.colorCode].push({ sizename: item.sizeName, quantity });
            }
        }

        this.setState({ rrpMap, allSizes })
    }

    onChangeCategory = (selectedCategory: tcategories) => {
        this.setState({ selectedCategory })
    }

    onClickDrop = (drop: any) => {
        const order = this.props.orderState.dashboardOrder;



        if (order && order.orderheader.orderState === 0 && order.orderheader.orderState !== 1) {
            const event = {
                id: 0,
                title: drop.deliveryCode,
                start: drop.shippingDate,
                end: drop.shippingDate,
                allDay: true,
                resource: drop,
            }
    
            this.setState({ showDeliveryModal: true, currentDrop: event })
        }
    }

    dropMoved = async () => {
        const { order } = this.props.orderState;

        if (order) {
            if (this.props.orderState.dashboardOrder) {
                this.props.getDashboardOrderState(this.props.orderState.dashboardOrder.orderheader.appOrderId)
            }

            this.props.setAlert('Drop moved!', 'success')
            
            this.setState({ showDeliveryModal: false, currentDrop: null })
        }
    }

    onClickDelete = (line: orderLine) => {
        const order = this.props.orderState.dashboardOrder;

        if (!order) return;

        const sizesToRemove = order.orderLines.filter(function(l) {
            return line.masterid === l.masterid && line.colorcode === l.colorcode;
        })

        const changes: basketLine = {};

        for (const size of sizesToRemove) {
            changes[size.itemId] = 0;
        }

        this.props.applyChangesFromDashboard(order.orderheader.appOrderId, changes);
    }

    onClickFilterGroup = (selectedCategory: string, item: any) => {
        if (!this.props.filterState.filter) return;

        let type = '';

        switch(selectedCategory) {
        case 'deliverywindows':
            type = 'deliveryWindows';
            break;
        case 'groups':
            type = 'productGroups';
            break;
        case 'categories':
            type = 'productCategories';
            break;
        case 'collection':
            type = 'topCollections';
            break;
        default:
            type = selectedCategory;
            break;
        }

        const changedFilter = DashboardFilterHelper.changeActiveFilters({ ...this.props.filterState.filter }, type, item.value, true);
        this.props.applyFilter(changedFilter);
        
        this.setState({step: 'details'})
    }

    goBack = () => {
        if (this.props.filterState.filter) {
            this.props.resetFilter(this.props.filterState.filter)
        }
        this.props.orderState.dashboardOrder && this.props.history.push('/dashboard/' + this.props.orderState.dashboardOrder.orderheader.appOrderId)
        this.setState({step: 'dashboard'})
    }

    render() {
        const order = this.props.orderState.dashboardOrder;

        const { showDeliveryModal, currentDrop, slides, data, productSortedByCategory } = this.state;

        // if (this.props.orderState.isFetching) {
        //     return <div style={{height: '100vh', width: '100vw'}}><MoloRingloader /></div>
        // }

        if (!this.props.orderState.isFetching && this.props.orderState.orderNotFound) {
            return (
                <div className="loading-spinner-1">
                    <h2>Order is probably shipped!</h2>
                    <p style={{marginBottom: '70px'}} className="desktop-only">Click continue shopping to go back</p>
                </div>
            )
        }

        if (this.state.basketIsEmpty) {
            return (
                <div className="loading-spinner-1">
                    <h2>Basket is empty!</h2>
                    <p style={{marginBottom: '70px'}}>Click continue shopping to add items to basket.</p>
                </div>
            )
        }

        return (
            <>
                {
                    this.props.orderState.isFetching && !this.state.initialized ? <div style={{height: '100vh', width: '100vw'}}><MoloRingloader /></div> : null
                }
                <div className={'dashboard-container ' + (this.state.step !== 'dashboard' ? 'hide-container' : 'show-container')}>
                    <div className="dashboard">
                        <div className="dashboard__item">
                        {
                            order &&
                            <OrderSummary
                                order={order}
                                productSortedByCategory={productSortedByCategory}
                                categoryChanged={this.onChangeCategory}
                                onClickFilterGroup={this.onClickFilterGroup}
                            />}
                        </div>
                        <div className="dashboard__item chart">
                            {
                                order && Object.keys(productSortedByCategory).length &&
                                <ChartSummaryHighcharts
                                    data={data}
                                    orderId={order.orderheader.appOrderId}
                                    productSortedByCategory={this.state.productSortedByCategory}
                                    selectedCategory={this.state.selectedCategory}
                                    currencyCode={this.props.orderState.dashboardOrder ? this.props.orderState.dashboardOrder.orderheader.currencyCode : ''}
                                />
                            }
                        </div>
                        <div className="dashboard__item" onClick={
                                () => this.setState({step: 'details'})
                            }>
                            <div className="dashboard-carousel">
                                <StyleCarousel
                                    slides={slides}
                                />
                                <p style={{ marginTop: '30px' }}>VIEW YOUR BASKET</p> 
                            </div>
                        </div>
                        <div className="dashboard__item" style={{overflow: 'hidden'}}>
                            <div className="delivery-windows-header">
                                {this.props.orderState.dashboardOrder
                                && this.props.orderState.dashboardOrder.orderheader.navImportRef
                                && this.props.orderState.dashboardOrder.orderheader.orderState !== 0
                                ? 'Shipping dates' : 'Change shipping dates'}
                            </div>
                            {this.props.orderState.dashboardDeliveryWindows.length &&
                            <DeliveryWindows
                                deliveryWindows={this.props.orderState.dashboardDeliveryWindows}
                                onClickFilterGroup={this.onClickFilterGroup}
                                onClickDrop={this.onClickDrop}
                                userIsInformed={!!this.props.orderState.userIsInformed}
                                setUserHasConfirmed={this.props.setUserHasConfirmed}
                                hideEditLink={order
                                    && order.orderheader.navImportRef
                                    && order.orderheader.orderIsFinal
                                }
                            />}
                        </div>
                    </div>
                </div>
                {
                    this.state.currentDrop && order &&
                    <MoveDropModal
                        hide={() => this.setState({showDeliveryModal: false, currentDrop: null})}
                        order={order}
                        currentDrop={currentDrop}
                        showDeliveryModal={showDeliveryModal}
                        deliveryChanged={this.dropMoved}
                    />
                }
                {order &&
                <div className={'dashboard-container ' + (this.state.step !== 'details' ? 'hide-container' : 'show-container')}>
                    <div
                        className={"filter-dropdown__toggler" + (this.state.showFilter ? " open" : "")}
                        onClick={() => this.setState({showFilter: !this.state.showFilter})}
                    >
                        <span className="filter-dropdown__toggler__title">
                        </span>
                        <FilterIcon />
                    </div>
                    <div className="stuff mobile-only" onClick={() => {
                        this.props.orderState.dashboardOrder && this.props.history.push('/dashboard/' + this.props.orderState.dashboardOrder.orderheader.appOrderId)
                        this.setState({ step: 'dashboard' })
                    }}>
                        <FaCaretLeft />
                    </div>
                    <Details
                        groupedOrderlines={this.state.groupedOrderlines}
                        masters={this.state.masters}
                        productSortedByCategory={this.state.productSortedByCategory}
                        selectedCategory={this.state.selectedCategory}
                        orderLines={this.state.filteredOrderLines}
                        onClickDelete={this.onClickDelete}
                        order={order}
                        styles={this.props.collectionState.collections || []}
                        sizes={this.state.sizes}
                        groupedMoloItems= {this.state.groupedMoloItems}
                        allSizes={this.state.allSizes}
                        rrpMap={this.state.rrpMap}
                    />
                    <DashboardFilterComponent
                        styles={order.orderLines}
                        onClickGoBack={this.goBack}
                        showFilter={true}
                        onClickCloseFilter={() => this.setState({showFilter: false })}
                        hideGoBack={this.state.hideGoBack}
                    />
                </div>}
            </>
        )
    }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
    return {
        orderState: states.order.order,
        userState: states.user.user,
        collectionState: states.collections.collections,
        filterState: states.session.filter
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
    return {
        getOrder: async (orderId: number) => {
            await dispatch(getOrder(orderId));
        },
        resetFilter: async (filter: Filter) => {
            await dispatch(resetFilter(filter));
        },
        applyFilter: async (filter: Filter) => {
            await dispatch(applyFilter(filter));
        },
        applyChanges: async (orderId: number, changes: basketLine) => {
            await dispatch(applyChanges(orderId, changes))
        },
        applyChangesFromDashboard: async (orderId: number, changes: basketLine) => {
            await dispatch(applyChangesFromDashboard(orderId, changes))
        },
        getDashboardOrderState: async (orderId: number) => {
            await dispatch(getDashboardOrderState(orderId))
        },
        setOrder: async (orderId: number) => {
            await dispatch(setOrder(orderId))
        },
        getCollectionMasters: async (orderType: string, collectionName: string, currencyCode: string, pricegroup: string) => {
            await dispatch(getCollectionMasters(orderType, collectionName, currencyCode, pricegroup))
        },
        setAlert: async (message: string, type: string) => {
            await dispatch(setAlert(message, type))
        },
        addClosedOrderToRecent: async (order: any) => {
            await dispatch(addClosedOrderToRecentAction(order))
        },
        resetDashboard: async () => {
            await dispatch(resetDashboard())
        },
        setUserHasConfirmed: async (confirmed: boolean) => {
            await dispatch(setUserHasConfirmed(confirmed))
        },
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard))