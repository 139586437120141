import React, { Component } from 'react'

import { FaPen, FaThumbsUp } from 'react-icons/fa';

import { cloneDeep } from 'lodash';

import { checkMonthGaps, positionDrops, formatDate, addMinmumMonths } from '../../../utils/deliveryWindowsHelper';

import { ContextMenu, Icon, Menu, MenuItem } from "@blueprintjs/core";

import { TweenLite } from "gsap";
import OrderApi from '../../../store/order/OrderApi';
 
interface Props {
    deliveryWindows: any[]
    onClickDrop: Function
    hideEditLink?: boolean
    onClickFilterGroup: Function
    userIsInformed: boolean
    setUserHasConfirmed: Function
}

export class DeliveryWindows extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.box = null;
    this.t = null;
    this.myTween = null;
  }

  state = {
    months: [],
    data: {},
    drops: {},
    deliveryWindows: [],
    showLeftArrow: false,
    showRightArrow: true,
    showArrows: false,
    arrowsInitialized: false,
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    gotIt: false,
  };

  box: HTMLDivElement | null;
  t: ReturnType<typeof TweenLite.from> | null;
  myTween: GSAPTimeline | null;
  
  container = React.createRef<HTMLDivElement>();

  componentDidMount() {
    const gotIt = OrderApi.getGotIt();

    console.log(gotIt, 'gotit from localstorage')

    console.log(this.props.deliveryWindows, 'deliveryWindows')

    this.setState({ gotIt: gotIt})
    this.props.setUserHasConfirmed(gotIt);
    
    if (this.props.deliveryWindows) {
      this.setupMonths(this.props.deliveryWindows);
    }

    if (this.container.current) {
      if (+this.container.current.scrollWidth > +this.container.current.clientWidth) {
        this.setState({ showArrows: true, arrowsInitialized: true })
      }
    }

    window.addEventListener('resize', this.handleResize)
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.deliveryWindows !== this.props.deliveryWindows) {
      this.setupMonths(this.props.deliveryWindows);
    }

    if (!this.state.arrowsInitialized && this.container.current) {
      if (+this.container.current.scrollWidth > +this.container.current.clientWidth) {
        this.t = TweenLite.to(this.box, 1, {duration: 1, opacity: 1, y: 0, stagger: 0.25});
        this.setState({ showArrows: true, arrowsInitialized: true })
      }
    }
  }

  handleResize = () => {
    if (this.container.current) {
      if (+this.container.current.scrollWidth > +this.container.current.clientWidth && !this.state.showArrows) {
        this.setState({ showArrows: true })
      } else {
        if (this.state.showArrows &&
          +this.container.current.scrollWidth === +this.container.current.clientWidth) {
          this.setState({ showArrows: false })
        }
      }
    }
  }

  setupMonths(deliveryWindows: any) {
    deliveryWindows.sort(function compare(a:any, b:any) {
      const dateA:any = new Date(a.shippingDate);
      const dateB:any = new Date(b.shippingDate);
      return dateA - dateB;
    });

    // let months = this.state.months;
    let months = [];
    const data:any = {};

    for (const i in deliveryWindows) {
      const delivery = deliveryWindows[i];

      const date = new Date(delivery.shippingDate);
      const year = date.getFullYear();
      const month = this.state.monthNames[date.getMonth()];
      const label = month + ' ' + year;

      if (months.indexOf(label) === -1) {
        months.push(label);
        // @ts-ignore
        data[label] = [delivery];
      }
    };

    const newMonths = checkMonthGaps(months, data, this.state.monthNames, deliveryWindows);
    const newMonthsWithMinimumMonths = addMinmumMonths(cloneDeep(newMonths), data, this.state.monthNames, deliveryWindows);

    this.setState({
      months: newMonthsWithMinimumMonths,
      data,
    }, () => {
      this.setupDrops();
    });
  }

  setupDrops() {
    let drops = {};

    for (const i in this.props.deliveryWindows) {
      const delivery =  this.props.deliveryWindows[i];

      const date = new Date(delivery.shippingDate);
      const year = date.getFullYear();
      const month = this.state.monthNames[date.getMonth()];
      const label = month + ' ' + year;

      // @ts-ignore
      if (!drops[label]) {
        // @ts-ignore
        drops[label] = [];
      }

    // @ts-ignore
      drops[label].push({
        date: date,
        shippingDate: new Date(delivery.shippingDate),
        shippingFrom: new Date(delivery.shippingFrom),
        shippingdateVisual: formatDate(new Date(delivery.shippingDate)),
        deliveryCode: delivery.deliveryCode,
        delivreryShortdescription: delivery.deliveryPrintdescription,
        position: 0
      });
    }

    let postioned = positionDrops(drops, this.state.months);
    this.setState({drops: postioned});
  }

  onClickGotIt = () => {
    this.setState({ gotIt: true });
    this.props.setUserHasConfirmed(true)
  }

  onClickDrop(drop: any) {
    this.props.onClickDrop(drop);
  }

  onClickFilterGroup = (drop: any) => {
    this.props.onClickFilterGroup('deliverywindows', {
      value: drop.deliveryCode
    })
  }

  renderDrops(month: any) {
      // @ts-ignore
        if (!this.state.drops[month]) {
            return null;
        }

    // @ts-ignore
    const dropsForMonth = this.state.drops[month];

    return Object.keys(dropsForMonth).map((key: any, $index:number) => {
        const drops = dropsForMonth[key];
        if (!drops.length) {
          return null;
        }

        return <div className="delivery-windows__drop clickable" style={{gridRowStart: $index + 1}} key={$index}>
        {
          drops.length &&
          drops.map((drop: any, index: number) => {
            return <div
            className="delivery-windows__drop__item"
            onClick={(e: any) => this.showContextMenu(e, drop)}
            style={{gridRow: drop.position}} key={`${drop.deliveryCode}/${index}`}>
              <p className="delivery-windows__drop-header">
                {drop.deliveryCode === 'specdate' ? 'Shipping Date' : drop.deliveryCode}
              </p>
              <p className="delivery-windows__drop-shippingdate">
                {drop.shippingdateVisual}
              </p>
              {
                !this.props.hideEditLink &&
                <p className="delivery-windows__change-link">
                  <span>Change shipping date</span>
                  <span className="icon"><FaPen /></span>
                </p>
              }
          </div>
          })
        }
        </div>
    })
  }

  renderMonths() {
    return this.state.months.map((month, $index) => {
      return (
        <div className="delivery-windows__month" key={$index}>
          <div className="delivery-windows__month-header">
            <h2 className="delivery-windows__month-name">{month}</h2>
          </div>
          <div
            ref={e => this.box = e}
            className="delivery-windows__month-days"
            style={{gridTemplateRows: 'repeat(5, 1fr)'}}>
              {this.renderDrops(month)}
          </div>
        </div>
      );
    });
  }

  private showContextMenu = (e: React.MouseEvent<HTMLDivElement>, drop: any) => {
    // must prevent default to cancel parent's context menu
    e.preventDefault();
    // invoke static API, getting coordinates from mouse event
    ContextMenu.show(
        <Menu>
            <MenuItem icon="list-detail-view" text="View items" onClick={(e: any) => this.onClickFilterGroup(drop)  } />
            {!this.props.hideEditLink && <MenuItem className="hide-on-mobile" icon="calendar" text="Change shipping date" onClick={() => this.onClickDrop(drop)}/>}
        </Menu>,
        { left: e.clientX, top: e.clientY },
        () => this.setState({ isContextMenuOpen: false }),
    );
    // indicate that context menu is open so we can add a CSS class to this element
    this.setState({ isContextMenuOpen: true });
  };

  private scrollLeftRight = (direction: 'left' | 'right') => {
    if (this.container.current) {
      if (direction === 'left') {
        this.container.current.scrollLeft -= this.container.current.scrollWidth;
      }

      if (direction === 'right') {
        this.container.current.scrollLeft += this.container.current.scrollWidth;
      }
    }
  }

  handleScroll = (e: any) => {
    const scrollLeft = e.target.scrollLeft;

    if (scrollLeft > 0) {
      this.setState({ showLeftArrow: true })
    }

    if (scrollLeft === 0) {
      this.setState({ showLeftArrow: false })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="delivery-windows" ref={this.container} onScroll={this.handleScroll}>
          {this.renderMonths()}
          {
            !this.props.userIsInformed &&
            <React.Fragment>
              <div className="delivery-windows__explainer-backdrop"></div>
              <div className="delivery-windows__explainer">
                <h2>Change shipping dates</h2>
                <p>
                  Click on a drop on the timeline and change it to
                  your need.
                </p>
                <p className="got-it" onClick={this.onClickGotIt}>
                  <span>Got it</span>
                  <FaThumbsUp />
                </p>
              </div>
            </React.Fragment>
          }
        </div>
        {
          this.state.showLeftArrow && this.state.showArrows &&
          <div className="delivery-windows__scroll-arrow left" onClick={() => this.scrollLeftRight('left')}>
            <Icon icon="circle-arrow-left" iconSize={25} />
          </div>
        }
        {
          this.state.showRightArrow && this.state.showArrows &&
          <div className="delivery-windows__scroll-arrow right" onClick={() => this.scrollLeftRight('right')}>
            <Icon icon="circle-arrow-right" iconSize={25} />
          </div>
        }
      </React.Fragment>
    );
  }
}

export default DeliveryWindows;