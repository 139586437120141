export interface IgroupedItems {[id: string]: IMoloItem[]}

export interface IMoloItem {
    available: number
    availablenos : number
    blocked: number
    discontinued:boolean
    colorBlocked: number
    colorCode: string
    colorName: string
    deliveryWindow: string
    deliveryWindowLabel: string
    deliveryShipmentDate: Date
    description: string
    description2: string
    ean: string
    fabric: string
    gots: string | null
    itemId: string
    masterId: string
    msrp: number
    oekoTex: string | null
    ocsblended:string | null
    grsno:string | null
    priceType: string
    refill: string
    seasonSort: number
    seasonCode: string
    shelfNo: string
    sizeCode: string
    sizeName: string
    whp: number
}

export class MoloItem implements IMoloItem {
    public available: number = 0;
    public availablenos = 0;
    public blocked: number = 0;
    public discontinued: boolean = false;
    public colorBlocked: number = 0;
    public colorCode: string =  '';
    public colorName: string = '';
    public deliveryWindow: string = '';
    public description: string = '';
    public description2: string = '';
    public ean: string = '';
    public fabric: string = '';
    public gots: string | null = null;
    public ocsblended:string | null = null;
    public grsno:string | null = null;
    public itemId: string = '';
    public masterId: string = '';
    public msrp: number = 0;
    public oekoTex: string | null = null;
    public priceType: string =  '';
    public refill: string = '';
    public seasonSort: number = 0;
    public seasonCode: string = '';
    public shelfNo: string = '';
    public sizeCode: string = '';
    public sizeName: string = '';
    public whp: number = 0;
    public deliveryShipmentDate: Date = new Date()
    deliveryWindowLabel: string = '';
    
    public build(data: any) {
        console.log("Build the data here");
        this.available = data.available || 0;
        this.availablenos = data.availablenos || 0;
        this.blocked = data.blocked || 0;
        this.discontinued = data.discontinued || false;
        this.colorBlocked = data.colorblocked || 0;
        this.colorCode =  data.colorcode;
        this.colorName = data.colorname;
        this.deliveryWindow = data.deliveryWindow;
        this.description = data.description;
        this.description2 = data.description2;
        this.ocsblended = data.ocsblended || null;
        this.grsno  = data.grsno || null;
        this.discontinued = data.discontinued || false;
        this.ean = data.ean;
        this.fabric = data.fabric;
        this.gots = data.gots;
        this.itemId = data.itemid;
        this.masterId = data.masterno;
        this.msrp = data.msrp;
        this.oekoTex = data.oekoTex;
        this.ocsblended = data.ocsblended || '';
        this.priceType = data.pricetype;
        this.refill = data.refill;
        this.seasonSort = data.seasonSort;
        this.seasonCode = data.seasoncode;
        this.shelfNo = data.shelfNo;
        this.sizeCode = data.sizecode;
        this.sizeName = data.sizename;
        this.whp = data.whp;
        
        
        

        let shipmentDate = new Date(data.shipmentdate);
        const today = new Date();

        this.deliveryShipmentDate = new Date(shipmentDate);
        this.deliveryWindowLabel = shipmentDate > today ? data.deliveryWindow : 'available now';

        return this;
    }
}

export function mapMoloItems(data:any[]): MoloItem[] {
    let mapped: MoloItem[] = [];

    for (const item of data) {

        const newStyle = new MoloItem().build(item);
        mapped.push(newStyle);
    }

    return mapped;
}