import { combineReducers } from 'redux'
import { Action } from './actions'

import { Filter, initialFilter } from '../../models/Filter';

// States' definition
export interface IFilterState {
  isFetching: boolean
  filter?: Filter,
  data?: any
}

export interface State {
  filter: IFilterState
}

const filter = (state: IFilterState = { isFetching: false, filter: initialFilter}, action: Action): IFilterState => {
  switch (action.type) {
    case 'GET_FILTER':
      return { ...state }
    case 'SET_FETCHING':
      return { ...state, isFetching: action.isFetching } 
    case 'APPLY_FILTER':
      return { ...state, filter: action.filter }
    case 'RESET_FILTER_SELECTED':
      let newFilter = { ...action.filter }
      newFilter.activeFilters = { ...initialFilter.activeFilters }
      newFilter.sustainability = initialFilter.sustainability;
      return { ...state, filter: newFilter}
  }
  
  return state
}

export default combineReducers<State>({
  filter
})