import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import {RouteComponentProps, withRouter} from "react-router-dom";

import ProductList from '../shared/ProductList';
import MoloRingloader from '../layout/MoloRingloader';
import Style from 'molo-shared/lib/models/Style';

// Models.
import Order from '../../models/Order';

// Store.
import { RootState } from '../../store';
import { IUserSate } from '../../store/user/reducers';
import { getCollectionMasters, CollectionApi } from '../../store/collections/actions';
import { ICollectionState } from '../../store/collections/reducers';
import { IFilterState } from '../../store/session/reducers';
import { Filter } from '../../models/Filter';
import { resetFilter } from '../../store/session/actions';
import { IOrderState } from '../../store/order/orderStore';
import { IProductState } from '../../store/products/reducers';
import { getCollectionsSuccess } from '../../store/collections/actions';

interface State {
    products: Style[],
    loading: boolean,
    orderType: string
    collectionName: string
}
  
interface OwnProps {
    match: {params: {collection: string, orderType: string}},
}
  
interface DispatchProps {
    getCollectionMasters: (orderType: string, collectionName: string, currencyCode: string, pricegroup: string) => void;
    resetFilter: (filter: Filter) => void
    getCollectionsSuccess: (collections: any[]) => void
}
  
interface StateProps {
    userState: IUserSate,
    collectionState: ICollectionState,
    isFetchingCollectionMasters: boolean
    filterState: IFilterState
    orderState: IOrderState
    productState: IProductState
}
  
type Props = StateProps & OwnProps & DispatchProps & RouteComponentProps;

export class EditNavOrderContainer extends Component<Props, State> {

    state = { products: [], loading: true, collectionName: '', orderType: '' }

    componentDidMount() {
        const { order } = this.props.orderState;

        if (order) {
            const allowedTypes = this.props.productState.orderTypes ? this.props.productState.orderTypes.map((orderType: any) => orderType.orderType) : []
            const orderType =  allowedTypes.indexOf(order.orderheader.orderType) !== -1 ? order.orderheader.orderType : 'REORDER'
            this.init(this.state, order, orderType);
        }
    }

    async componentDidUpdate(prevProps: Props, prevState: State) {
        const { order } = this.props.orderState;
        let collectionName = this.props.match.params.collection;
        const currencyCode = this.props.userState.user.currencyCode;
        const pricegroup   = this.props.userState.user.customerData.pricegroup;

        if (!order) {
            this.props.history.push('/')
        }

        if (prevProps.orderState.order !== order && order) {
            this.init(this.state, order, this.state.orderType);
        }

        if (order) {
            let orderType = this.state.orderType

            if (orderType === '') {
                const allowedTypes = this.props.productState.orderTypes ? this.props.productState.orderTypes.map((orderType: any) => orderType.orderType) : []
                orderType =  allowedTypes.indexOf(order.orderheader.orderType) !== -1 ? order.orderheader.orderType : 'REORDER'
            }

            if (orderType && prevState.collectionName !== collectionName && !this.props.isFetchingCollectionMasters) {
                this.props.getCollectionMasters(orderType, collectionName, currencyCode, pricegroup);
                if (this.props.filterState.filter) {
                    this.props.resetFilter(this.props.filterState.filter)
                }

                setTimeout(() => {
                    this.setState({
                        collectionName,
                        orderType,
                    });
                })
            }
        }
    }

    public static getDerivedStateFromProps(props: Props, state: State) {
        
        if (props.collectionState.styles && props.collectionState.styles.length) {
          return {
              loading: false,
              products: props.collectionState.styles
          }
        }
        return null;
    }

    async init(state: State, order: Order, orderType: string) {
        const allowedTypes = this.props.productState.orderTypes ? this.props.productState.orderTypes.map((orderType: any) => orderType.orderType) : []
        const t =  allowedTypes.indexOf(order.orderheader.orderType) !== -1 ? order.orderheader.orderType : 'REORDER'
        let collectionName = this.props.match.params.collection;
        const response = await CollectionApi.getCollections(t)
        const collections = response.data.filter((col: any) => col.collectionName !== '');
        
        this.props.getCollectionsSuccess(collections)

        if (!collectionName) {
            if (response.data.length) {
                const hasMainCollection = collections.find((c: any) => c.collectionName.toLowerCase() === 'main')
                collectionName = hasMainCollection ? 'main' : collections[0].collectionName;
                this.props.history.push('/edit/' + collectionName)
            }
        }
    }

    render() {
        const { products, loading } = this.state;

        return (
            <div>
                {(loading || this.props.isFetchingCollectionMasters) && <div style={{height: '100vh', width: '100vw', position:'fixed'}}><MoloRingloader /></div>}
                {
                    (!loading&& !this.props.isFetchingCollectionMasters) &&
                    <ProductList
                        products={products}
                        lineDiscount={this.props.userState.user.customerData.linediscount}
                        useInfiniteScroll={true}
                        // collectionName={collectionName}
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
    return {
        userState: states.user.user,
        collectionState: states.collections.collections,
        isFetchingCollectionMasters: states.collections.collections.isFetchingCollectionMasters,
        filterState: states.session.filter,
        orderState: states.order.order,
        productState: states.productStore.productStore
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
    return {
        getCollectionMasters: async (orderType: string, collectionName: string, currencyCode: string, pricegroup: string) => {
            await dispatch(getCollectionMasters(orderType, collectionName, currencyCode, pricegroup))
            console.log('getCollectionMasters completed [UI]')
        },
        resetFilter: async (filter: Filter) => {
            await dispatch(resetFilter(filter))
        },
        getCollectionsSuccess: async (collections: any[]) => {
            await dispatch(getCollectionsSuccess(collections))
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditNavOrderContainer))