import React from 'react';

interface IProps {
    slides: string[],
}

const StyleCarousel: React.FC<IProps> = (props) => {
    const { slides } = props;
    const [currentIndex, setValue] = React.useState(0);

    React.useEffect(() => {
        const interval = setInterval(() => {
            let index = currentIndex;
            index++;
    
            if (index >= slides.length) {
                index = 0;
            }
    
            setValue(index);
        }, 4000);
        return () => clearInterval(interval);
    });
    
    return (
        <div className="carousel">
            {
                slides.map((slide, key) => {
                    let slideClassName = 'slide ' + (currentIndex === key ? 'active' : '');
                    
                    if (currentIndex + 1 === slides.length && key === 0) {
                        slideClassName = slideClassName + ' next';
                    } else if (currentIndex === 0 && key + 1 === slides.length ) {
                        slideClassName = slideClassName + ' previous';
                    } else if (currentIndex + 1 === key) {
                        slideClassName = slideClassName + (currentIndex + 1 === key ? ' next' : '');
                    } else if (currentIndex - 1 === key) {
                        slideClassName = slideClassName + (currentIndex - 1 === key ? ' previous' : '');
                    }

                    if (slides.length === 1) {
                        slideClassName = 'slide active'
                    }
                    
                    return (
                        <div
                            className={slideClassName}
                            key={key}
                        >
                            <img src={slide} alt={slide} />
                        </div>
                    )
                })
            }
        </div>
    );
}

export default StyleCarousel;