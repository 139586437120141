import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import {RouteComponentProps, withRouter} from "react-router-dom";

// Components.
import DeliveryWindows from '../shared/Dashboard/DeliveryWindows';
import StyleCarousel from '../shared/Dashboard/StyleCarousel';
import OrderSummary from '../shared/Dashboard/OrderSummary';
import ChartSummaryHighcharts from '../shared/Dashboard/ChartSummaryHighcharts';
import Details from '../shared/Dashboard/Details';
import DashboardFilterComponent from '../shared/DashboardFilterComponent';
import { DashboardFilterHelper } from '../../utils/DashboardFilterHelper';
import MoloRingloader from '../layout/MoloRingloader';

// Store.
import { RootState } from '../../store';
import { IOrderState } from '../../store/order/orderStore';
import { IUserSate } from '../../store/user/reducers';
import {
    getOrder,
    applyChanges,
    applyChangesFromDashboard,
    getDashboardOrderState,
    setOrder,
    addClosedOrderToRecentAction,
    resetDashboard,
    setUserHasConfirmed
} from '../../store/order/actions';
import { ICollectionState } from '../../store/collections/reducers';
import { getCollectionMasters } from '../../store/collections/actions'
import { IFilterState } from '../../store/session/reducers';
import { resetFilter, applyFilter } from '../../store/session/actions';
import { setAlert } from '../../store/alert/actions';

// Models.
import { orderLine } from '../../models/Order';
import { Filter } from '../../models/Filter';
import { basketLine } from '../../models/Basket';
import Style from 'molo-shared/lib/models/Style';
 
// Utils.
import * as helper from '../shared/Dashboard/DashboardHelper';
import queryString from 'query-string'
import {
    tcategories,
    IDivider,
    IGrouping,
    DIVIDERS
} from '../shared/Dashboard/dashboard.d';
import { uniq, cloneDeep } from 'lodash';
import ProductApi from '../../store/products/ProductApi';
import OrderApi from '../../store/order/OrderApi';
import { ReactComponent as FilterIcon } from '../../images/svg/filter_icon_black.svg';
import { appConfig } from '../../config';
import { FaCaretLeft } from 'react-icons/fa';
import { mapMoloItems, IgroupedItems } from '../../models/MoloItem';

const baseUrl = appConfig.media.baseUrl;

interface ISize {
    sizename: string,
    quantity: number
}

interface ISizes {
    [id: string]: ISize[],
}

interface IMasters {
    [id: string]: Style,
}

interface IRRPMap {
    [id: string]: number[]
}

interface State {
    productSortedByCategory: IGrouping,
    selectedCategory: tcategories,
    data: any[],
    salesOptionsTotal: number,
    slides: string[],
    deliveryWindows: any[],
    showDeliveryModal: boolean
    currentDrop?: any,
    basketIsEmpty: boolean,
    step: string,
    filteredOrderLines: orderLine[],
    filterInitialized: boolean
    dashboardLoading: boolean
    initialized: boolean,
    sizes: ISizes,
    groupedOrderlines: orderLine[],
    masters: IMasters,
    loadingMasters: boolean
    groupedMoloItems: {[id: string]: any[]}
    showFilter: boolean
    hideGoBack?: boolean
    rrpMap: IRRPMap
    allSizes: ISizes
}
  
interface OwnProps {
    order: any
    filteredOrderLines: orderLine[]
    groupedOrderlines: orderLine[]
    allSizes: ISizes
    rrpMap: IRRPMap
    sizes: ISizes
    groupedMoloItems: {[id: string]: any[]}
    deliveryWindows: any[]
    slides: string[]
    productSortedByCategory: IGrouping
}
  
interface DispatchProps {
    getOrder: (orderId: number) => void
    resetFilter: (filter: Filter) => void
    applyFilter: (filter: Filter) => void
    applyChanges: (orderId: number, changes: basketLine) => void
    applyChangesFromDashboard: (orderId: number, changes: basketLine) => void
    getDashboardOrderState: (orderId: number) => void
    setOrder: (orderId: number) => void
    getCollectionMasters: (orderType: string, collectionName: string, currencyCode: string, pricegroup: string) => void
    setAlert: (message: string, type: string) => void
    addClosedOrderToRecent: (order: any) => void
    resetDashboard: () => void
    setUserHasConfirmed: (confirmed: boolean) => void
}
  
interface StateProps {
    orderState: IOrderState
    userState: IUserSate
    collectionState: ICollectionState
    filterState: IFilterState
}

interface chartData {
    name: string,
    y: number,
}
  
type Props = StateProps & OwnProps & DispatchProps & RouteComponentProps;

export class Dashboard extends Component<Props, State> {
    private data: chartData[] = [];

    state: State = {
        productSortedByCategory: {},
        selectedCategory: 'categories',
        data: [],
        salesOptionsTotal: 0,
        slides: [],
        deliveryWindows: [],
        showDeliveryModal: false,
        basketIsEmpty: false,
        step: 'dashboard',
        filteredOrderLines: [],
        filterInitialized: false,
        dashboardLoading: true,
        initialized: false,
        sizes: {},
        groupedOrderlines: [],
        masters: {},
        loadingMasters: false,
        groupedMoloItems: {},
        showFilter: false,
        rrpMap: {},
        allSizes: {},
    }   
    
    readonly dividers: IDivider[] =  DIVIDERS;

    onChangeCategory = (selectedCategory: tcategories) => {
        this.setState({ selectedCategory })
    }

    onClickFilterGroup = (selectedCategory: string, item: any) => {
        if (!this.props.filterState.filter) return;

        let type = '';

        switch(selectedCategory) {
        case 'deliverywindows':
            type = 'deliveryWindows';
            break;
        case 'groups':
            type = 'productGroups';
            break;
        case 'categories':
            type = 'productCategories';
            break;
        case 'collection':
            type = 'topCollections';
            break;
        default:
            type = selectedCategory;
            break;
        }

        const changedFilter = DashboardFilterHelper.changeActiveFilters({ ...this.props.filterState.filter }, type, item.value, true);
        this.props.applyFilter(changedFilter);
        
        this.setState({step: 'details'})
    }

    goBack = () => {
        if (this.props.filterState.filter) {
            this.props.resetFilter(this.props.filterState.filter)
        }
        this.props.orderState.dashboardOrder && this.props.history.push('/dashboard/' + this.props.orderState.dashboardOrder.orderheader.appOrderId)
        this.setState({step: 'dashboard'})
    }

    render() {
        const { data } = this.state;

        const {
            order,
            productSortedByCategory,
            slides,
            deliveryWindows,
            groupedOrderlines,
            filteredOrderLines,
            sizes,
            groupedMoloItems,
            allSizes,
            rrpMap,
        } = this.props;

        return (
            <>
                {
                    this.props.orderState.isFetching && !this.state.initialized ? <div style={{height: '100vh', width: '100vw'}}><MoloRingloader /></div> : null
                }
                <div className={'dashboard-container ' + (this.state.step !== 'dashboard' ? 'hide-container' : 'show-container')}>
                    <div className="dashboard">
                        <div className="dashboard__item">
                        {
                            order &&
                            <OrderSummary
                                order={order}
                                productSortedByCategory={productSortedByCategory}
                                categoryChanged={this.onChangeCategory}
                                onClickFilterGroup={this.onClickFilterGroup}
                            />}
                        </div>
                        <div className="dashboard__item chart">
                            {
                                order && Object.keys(productSortedByCategory).length &&
                                <ChartSummaryHighcharts
                                    data={data}
                                    orderId={order.orderheader.appOrderId}
                                    productSortedByCategory={productSortedByCategory}
                                    selectedCategory={this.state.selectedCategory}
                                    currencyCode={order ? order.orderheader.currencyCode : ''}
                                />
                            }
                        </div>
                        <div className="dashboard__item" onClick={
                                () => this.setState({step: 'details'})
                            }>
                            <div className="dashboard-carousel">
                                <StyleCarousel
                                    slides={slides}
                                />
                                <p style={{ marginTop: '30px' }}>View basket</p>
                            </div>
                        </div>
                        <div className="dashboard__item" style={{overflow: 'hidden'}}>
                            <div className="delivery-windows-header">
                                {order
                                && order.orderheader.navImportRef
                                && order.orderheader.orderState !== 0
                                ? 'Shipping dates' : 'Change shipping dates'}
                            </div>
                            {deliveryWindows.length ?
                            <DeliveryWindows
                                deliveryWindows={deliveryWindows}
                                onClickFilterGroup={this.onClickFilterGroup}
                                onClickDrop={() => {}}
                                userIsInformed={true}
                                setUserHasConfirmed={() => {}}
                                hideEditLink={true}
                            /> : null }
                        </div>
                    </div>
                </div>
                {order &&
                <div className={'dashboard-container ' + (this.state.step !== 'details' ? 'hide-container' : 'show-container')}>
                    <div
                        className={"filter-dropdown__toggler" + (this.state.showFilter ? " open" : "")}
                        onClick={() => this.setState({showFilter: !this.state.showFilter})}
                    >
                        <span className="filter-dropdown__toggler__title">
                        </span>
                        <FilterIcon />
                    </div>
                    <div className="stuff mobile-only" onClick={() => {
                        order && this.props.history.push('/dashboard/' + order.orderheader.appOrderId)
                        this.setState({ step: 'dashboard' })
                    }}>
                        <FaCaretLeft />
                    </div>
                    <Details
                        groupedOrderlines={groupedOrderlines}
                        masters={this.state.masters}
                        productSortedByCategory={productSortedByCategory}
                        selectedCategory={this.state.selectedCategory}
                        orderLines={filteredOrderLines}
                        onClickDelete={() => {}}
                        order={order}
                        styles={this.props.collectionState.collections || []}
                        sizes={sizes}
                        groupedMoloItems={groupedMoloItems}
                        allSizes={allSizes}
                        rrpMap={rrpMap}
                        disableActions={true}
                    />
                    <DashboardFilterComponent
                        styles={order.orderLines}
                        onClickGoBack={this.goBack}
                        showFilter={true}
                        onClickCloseFilter={() => this.setState({showFilter: false })}
                        hideGoBack={this.state.hideGoBack}
                    />
                </div>}
            </>
        )
    }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
    return {
        orderState: states.order.order,
        userState: states.user.user,
        collectionState: states.collections.collections,
        filterState: states.session.filter
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
    return {
        getOrder: async (orderId: number) => {
            await dispatch(getOrder(orderId));
        },
        resetFilter: async (filter: Filter) => {
            await dispatch(resetFilter(filter));
        },
        applyFilter: async (filter: Filter) => {
            await dispatch(applyFilter(filter));
        },
        applyChanges: async (orderId: number, changes: basketLine) => {
            await dispatch(applyChanges(orderId, changes))
        },
        applyChangesFromDashboard: async (orderId: number, changes: basketLine) => {
            await dispatch(applyChangesFromDashboard(orderId, changes))
        },
        getDashboardOrderState: async (orderId: number) => {
            await dispatch(getDashboardOrderState(orderId))
        },
        setOrder: async (orderId: number) => {
            await dispatch(setOrder(orderId))
        },
        getCollectionMasters: async (orderType: string, collectionName: string, currencyCode: string, pricegroup: string) => {
            await dispatch(getCollectionMasters(orderType, collectionName, currencyCode, pricegroup))
        },
        setAlert: async (message: string, type: string) => {
            await dispatch(setAlert(message, type))
        },
        addClosedOrderToRecent: async (order: any) => {
            await dispatch(addClosedOrderToRecentAction(order))
        },
        resetDashboard: async () => {
            await dispatch(resetDashboard())
        },
        setUserHasConfirmed: async (confirmed: boolean) => {
            await dispatch(setUserHasConfirmed(confirmed))
        },
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard))