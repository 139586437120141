import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import {RouteComponentProps, withRouter} from "react-router-dom";

// Utils.
import { cloneDeep, uniqBy } from 'lodash';
import {
    Tooltip,
    Position,
} from "@blueprintjs/core";

// Icons.
import { FaSearch, FaCircle, FaTrash, FaTimes } from 'react-icons/fa';

// Components.
import SidebarMenu from './SidebarMenu';
import CreateOrderModal from '../b2b/CreateOrderModal'
import MyMoloOverlay from '../b2b/MyMoloOverlay';
import ProductDownloadModal from '../b2b/ProductDownloadModal';
import SearchBox from '../shared/SearchBox';
import ShopDropDown from '../b2b/ShopDropdown';
import Dropdown from './Dropdown';
import DeleteOrderModal from '../b2b/DeleteOrderModal';
import CountDown2 from '../shared/CountDown2';

// Models.
import { Filter } from '../../models/Filter';

// Store.
import { RootState } from '../../store';
import { ICollectionState  } from '../../store/collections/reducers'
import { IProductState  } from '../../store/products/reducers'
import { IFilterState } from '../../store/session/reducers'
import { IUserSate } from '../../store/user/reducers'
import { IOrderState } from '../../store/order/orderStore';
import { applyFilter, getFilter } from '../../store/session/actions'
import { logoutUser } from '../../store/user/actions';
import { resetFilter } from '../../store/session/actions'

import { DeliveryWindow } from '../../models/Style';
import { getDashboardOrderState } from '../../store/order/actions';
import AllOrdersOverlay from '../shared/AllOrdersOverlay';

interface State {
    showSidebar: boolean,
    showOrderModal: boolean,
    collectionName: string,
    orderTypeName: string,
    showNavOrderModal: boolean,
    selectedCollection: any,
    isCheckoutPage: boolean,
    showSearch: boolean,
    showShopOverlay: boolean,
    showProductDownload: boolean
    showDeleteOrderModal: boolean,
    orderIdToDelete?: number
    showMyMolo: boolean
    myMoloIsProfile: boolean
    showShippedOverlay: boolean
}
  
interface OwnProps {
    match?: {params: {collection: string, orderType: string}},
    children?: any
    history?: any
    location?: any
    staticContext?: any
    isDashboard?: boolean
    editMode?: boolean
}
  
interface DispatchProps {
    applyFilter: (filter: Filter) => void;
    getFilter: () => void;
    logoutUser: () => void;
    getDashboardOrderState: (orderId: number) => void;
    resetFilter: (filter: Filter) => void
}
  
interface StateProps {
    productState: IProductState
    collectionState: ICollectionState
    filterState: IFilterState
    userState: IUserState
    orderState: IOrderState
}
  
type Props = StateProps & OwnProps & DispatchProps & RouteComponentProps;

export class Header extends Component<Props, State> {
    state: State = {
        showSidebar: false,
        showOrderModal: false,
        collectionName: '',
        orderTypeName: '',
        showNavOrderModal: false,
        showProductDownload: false,
        selectedCollection: null,
        isCheckoutPage: false,
        showSearch: false,
        showShopOverlay: false,
        showDeleteOrderModal: false,
        showMyMolo: false,
        myMoloIsProfile: false,
        showShippedOverlay: false
    }

    childRef = React.createRef<SidebarMenu>();

    componentDidMount() {
        this.setMenuItems();

        if (this.props.location.pathname.includes('/checkout')) {
            this.setState({isCheckoutPage: true})
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.location !== prevProps.location) {
            this.setMenuItems();

            if (this.props.location && this.props.location.pathname && this.props.location.pathname.includes('/checkout')) {
                this.setState({isCheckoutPage: true})
            } else {
                this.setState({isCheckoutPage: false})
            }
        }
    }

    setMenuItems = () => {
        let collectionName = null;
        let orderTypeName = null;

        if (this.props.match) {
            collectionName = this.props.match.params.collection;
            orderTypeName = this.props.match.params.orderType;

            this.setState({
                collectionName,
                orderTypeName,
            }, () => {

            });
        }
    }

    toggleSidebar = (open: boolean) => {
        this.setState({ showSidebar: open })
    }

    hide = () => {}

    onClickSetCollectionType = (collection: any) => {
        // if (this.props.filterState.filter) {
        //     this.props.resetFilter(this.props.filterState.filter)
        // }
        if (this.props.editMode) {
            window.location.href = '/edit/' + collection.collectionName
            // this.props.history.push('/edit/' + collection.collectionName);
        } else {
            window.location.href = '/shop/' + this.state.orderTypeName + '/'  + collection.collectionName
            // this.props.history.push('/shop/' + this.state.orderTypeName + '/'  + collection.collectionName);
        }
    }

    onClickSetOrderType = (orderType: any) => {
        this.props.history.push('/shop/' + orderType.orderType);
    }

    onClickSetDeliveryType = (deliveryWindow: DeliveryWindow | string) => {
        const newFilter = cloneDeep(this.props.filterState.filter);
        if (!newFilter) return


        if (typeof deliveryWindow === 'string') {
            newFilter.activeFilters.deliveryWindows = [];
        } else {
            newFilter.activeFilters.deliveryWindows = [deliveryWindow.label];
        }

        this.props.applyFilter(newFilter);
    }

    onClickSetSustainability = (sustainability: string) => {
        const newFilter = cloneDeep(this.props.filterState.filter);
        
        if (!newFilter || !newFilter.activeFilters.sustainability) return

        const indexOf = newFilter.activeFilters.sustainability.indexOf(sustainability)

        if (sustainability === 'all') {
            newFilter.activeFilters.sustainability = [];
        } else {
            if (indexOf > -1) {
                newFilter.activeFilters.sustainability.splice(indexOf, 1);
            } else {
                newFilter.activeFilters.sustainability.push(sustainability);
            }
        }

        this.props.applyFilter(newFilter);   
    }

    onClickSearchIcon = () => {
        this.setState({showSearch: !this.state.showSearch})
    }

    onClickShopIcon = () => {
        if (this.state.showShopOverlay) {
            this.setState({showShopOverlay: false})
        }
        this.setState({showShopOverlay: true})
    }

    showCreateOrderModal = () => {
        this.setState({ showOrderModal: true, showShopOverlay: false })
    }

    openNavOrderModal = () => {
        if (this.childRef.current) {
            // this.childRef.current.toggle();
            this.setState({ showNavOrderModal: true })
        }
    }

    showDeleteOrderModal = () => {
        this.setState({ showDeleteOrderModal: true })
    }

    onClickLinkHandler = () => {
        if (this.childRef.current) {
            // this.childRef.current.toggle();
        }
        this.toggleSidebar(false)
    }

    onClickMyMolo = () => {
        this.setState({ showShippedOverlay: !this.state.showShippedOverlay })
    }

    onClickProfile = () => {
        this.setState({ showMyMolo: !this.state.showMyMolo })
    }

    onClickProductDownload = () => {
        this.props.history.push('/download')
    }

    logoutUser = () => {
        // this.props.history.push('/logout')
        // window.history.replaceState(null, '', "/");
        // this.props.history.replace(null, '', "/");
        this.props.history.push('/')
        this.props.logoutUser();
    }

    onCLickChangeDashboardOrder = (orderId: number) => {
        this.props.history.push('/dashboard/' + orderId);
        this.props.getDashboardOrderState(orderId);
    }

    upcomingLabel = (deliveryWindow: DeliveryWindow) => {
        if (deliveryWindow.label !== 'available now') {
            return <sub className="extra-label"> - upcoming</sub>
        }

        return null;
    }

    renderLinks = () => {
        return (
            <ul className="top-menu">
                {
                    this.props.productState.orderTypes &&
                    this.state.orderTypeName &&
                    this.props.productState.orderTypes.length ?
                    <li className="top-menu__item">
                        <Dropdown
                            items={this.props.productState.orderTypes || []}
                            value={this.props.productState.orderTypes.find(
                                type => type.orderType === this.state.orderTypeName)
                            }
                            identifier={'orderType'}
                            onSelect={this.onClickSetOrderType}
                        />
                    </li> : null
                }
                {
                    this.props.collectionState.collections && this.props.collectionState.collections.length &&
                    this.state.collectionName ?
                    <li className="top-menu__item">
                        <Dropdown
                            items={this.props.collectionState.collections || []}
                            value={this.props.collectionState.collections.find(
                                col => col.collectionName === this.state.collectionName)
                            }
                            identifier={'collectionName'}
                            onSelect={this.onClickSetCollectionType}
                        />
                    </li> : null
                }
                {
                    this.state.collectionName &&
                    this.props.filterState.filter && this.props.collectionState.deliveryWindows ?
                    <li className="top-menu__item desktop-only" >
                        <Dropdown
                            items={this.props.collectionState.deliveryWindows || []}
                            value={this.props.collectionState.deliveryWindows.find((del) => {
                                if (this.props.filterState.filter && this.props.filterState.filter.activeFilters.deliveryWindows[0]) {
                                    return del.label === this.props.filterState.filter.activeFilters.deliveryWindows[0]
                                }
                                
                                return null
                            })}
                            identifier={'label'}
                            onSelect={this.onClickSetDeliveryType}
                            allLabel='Show All'
                            allOption={true}
                            placeholder={'Deliveries All'}
                            extraLabel={this.state.orderTypeName && this.state.orderTypeName.toLowerCase() === 'reorder' && this.upcomingLabel}
                        />
                    </li> : null
                }
                {
                    this.state.collectionName &&
                    this.props.filterState.filter && this.props.filterState.filter.sustainability ?
                    <li className="top-menu__item desktop-only">
                        <Dropdown
                            items={this.props.filterState.filter.sustainability}
                            value={this.props.filterState.filter.activeFilters.sustainability}
                            identifier={'$index'}
                            onSelect={this.onClickSetSustainability}
                            allLabel='Show All'
                            allOption={true}
                            placeholder='SUSTAINABILITY'
                            multi={true}
                        />
                    </li> : null
                }
            </ul>
        )
    }

    renderOrderDropdownItem = (order: any, useLabel: boolean) => (
        <div className="order-dropdown flex">
            {useLabel && order.navImportRef && order.orderIsFinal && <span className="order-dropdown__label --completed">Approved order</span>}
            {useLabel && !order.orderIsFinal && order.navImportRef && <span className="order-dropdown__label --completed">Access to edit</span>}
            {useLabel && !order.navImportRef && <span className="order-dropdown__label">Open order</span>}
            <span>{ order.sellToCustomerName }</span>
            <span className="flex items-center pt-1"><FaCircle className="order-dropdown__divider" /></span>
            <span> 
                 b2b{order.appOrderId}</span>
            <span className="flex items-center pt-1"><FaCircle className="order-dropdown__divider" /></span>
            <span>
                 {order.orderType}</span>
            {order.navImportRef && <span className="flex items-center pt-1"><FaCircle className="order-dropdown__divider" /></span>}
            {
                order.navImportRef &&
                <span>
                     {order.navImportRef}
                </span>
            }
            {order.salespersonNote && <span className="flex items-center pt-1"><FaCircle className="order-dropdown__divider" /></span>}
            {
                order.salespersonNote &&
                <span>
                     { order.salespersonNote }
                </span>
            }

            {!useLabel && order.navImportRef && order.orderIsFinal && <span className="order-dropdown__label --small --completed">Approved order</span>}
            {!useLabel && order.navImportRef && !order.orderIsFinal && <span className="order-dropdown__label --small --completed">Access to edit</span>}
            {!useLabel && !order.navImportRef && <span className="order-dropdown__label --small">Open order</span>}
        </div>
    )

    render() {
        const { order, orders } = this.props.orderState;
        const dashboardOrder = order;
        let dropDownItems: any[] = []

        // console.log(this.props.orderState);

        let hoursLeft = 0;

        if (this.props.editMode && this.props.orderState.order) {
            const diff = new Date(new Date().toISOString()).getTime() - new Date(this.props.orderState.order.orderheader.createdUtc).getTime()
            hoursLeft = (3600000 * 24) - diff;
        }

        if (dashboardOrder) {
            dropDownItems = orders.filter(order => order.appOrderId !== dashboardOrder.orderheader.appOrderId) || [];
            const recent = this.props.orderState.recentClosedOrders.filter(order => order.navImportRef && order.appOrderId !== dashboardOrder.orderheader.appOrderId) || [];
            dropDownItems = [...dropDownItems, ...recent]
        }

        dropDownItems = uniqBy(dropDownItems, 'appOrderId');

        return (
            <div className={'header' + (this.props.editMode ? ' --edit-mode' : '')}>
                <div className="header__main">
                    <div className="molo-logo" onClick={() => {
                        if (window.innerWidth > 900) {
                            this.props.history.push('/shop')
                        }
                    }}>
                        {
                            this.props.userState.user &&
                            <span className="--user-name">{this.props.userState.user.customerData.Name}</span>
                        }
                    </div>
                    {
                        this.props.isDashboard && !this.state.isCheckoutPage && dashboardOrder ?
                        <ul className={'top-menu' + (this.props.isDashboard ? ' top-menu--dashboard' : '')}>
                            <li className="top-menu__item desktop-only">
                                <Dropdown
                                    items={dropDownItems}
                                    value={
                                        () => {
                                            if (dashboardOrder.orderheader.navImportRef &&  !dashboardOrder.orderheader.orderState) {
                                                return dashboardOrder
                                            }
                                            return orders.find(o => o.appOrderId === dashboardOrder.orderheader.appOrderId)
                                        }
                                    }
                                    trigger={() => this.renderOrderDropdownItem(dashboardOrder.orderheader, true)}
                                    valueRender={(item: any) => this.renderOrderDropdownItem(item, false)}
                                    identifier={'appOrderId'}
                                    onSelect={(item: any) => this.onCLickChangeDashboardOrder(item.appOrderId)}
                                />
                            </li>
                            <li className="top-menu__item desktop-only">
                                <div className="header-main__icons__icon clickable" style={{height: '100%'}}>
                                    
                                        <Tooltip
                                            content={<div>Delete order</div>}
                                            position={Position.RIGHT}
                                        >
                                            <span onClick={ (e) => this.setState({ showDeleteOrderModal: true }) }>
                                                {
                                                    dashboardOrder && dashboardOrder.orderheader.orderIsFinal ?
                                                    <FaTimes size="24px" /> : <FaTrash size="18px" />
                                                }
                                            </span>
                                        </Tooltip>
                                </div>
                            </li>
                            <li className="top-menu__item mobile-only">
                                <div className="dropdown">
                                    <div className="dropdown__trigger">
                                        <div className="order-dropwdown">
                                            <span>{ dashboardOrder.orderheader.sellToCustomerName }</span>
                                            <span> <FaCircle className="order-dropdown__divider" /> b2b{dashboardOrder.orderheader.appOrderId}</span>
                                            <span> <FaCircle className="order-dropdown__divider" /> {dashboardOrder.orderheader.orderType}</span>
                                            {
                                                dashboardOrder.orderheader.navImportRef &&
                                                <span>
                                                    <FaCircle className="order-dropdown__divider" /> {dashboardOrder.orderheader.navImportRef}
                                                </span>
                                            }
                                            {
                                                dashboardOrder.orderheader.salespersonNote &&
                                                <span>
                                                    <FaCircle className="order-dropdown__divider" /> { dashboardOrder.orderheader.salespersonNote }
                                                </span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        :
                        null
                    }
                    {
                        this.props.isDashboard && this.state.isCheckoutPage && this.props.orderState.order ?
                        <ul className={'top-menu' + (this.props.isDashboard ? ' top-menu--dashboard' : '')}>
                            <li className="top-menu__item">
                                <div className="dropdown">
                                    <div className="dropdown__trigger">
                                        {this.renderOrderDropdownItem(this.props.orderState.order.orderheader, true)}
                                    </div>
                                </div>
                            </li>
                        </ul>
                        :
                        null
                    }
                    { !this.state.isCheckoutPage && this.renderLinks() }
                    <div className="header-main__icons">
                        {
                            <div className="header-main__icons__icon clickable">
                                <ShopDropDown
                                    hideDelete={!this.props.orderState.order}
                                    onClickCreateOrder={this.showCreateOrderModal}
                                    onClickDeleteOrder={this.showDeleteOrderModal}
                                    logoutUser={this.logoutUser}
                                    onClickMyMolo={this.onClickMyMolo}
                                    onClickProductDownload={this.onClickProductDownload}
                                    editMode={this.props.editMode}
                                    onClickProfile={this.onClickProfile}
                                    // showProductDownload={this.props.userState.user &&
                                        // (this.props.userState.user.customerData.CustomerId === '12025' || this.props.userState.user.customerData.CustomerId === '11608')}
                                    showProductDownload={true}
                                    deleteOrderText={
                                        this.props.orderState.order && this.props.orderState.order.orderheader.navImportRef ?
                                    'Close order' : 'Delete current order'  }
                                />
                            </div>
                        }
                        {
                            this.props.editMode && this.props.orderState.order && 
                            <div>
                                <CountDown2
                                    from={hoursLeft}
                                    dateFrom={this.props.orderState.order.orderheader.createdUtc}
                                    limit={2}
                                />
                            </div>
                        }
                        <div className="header-main__icons__icon clickable search-trigger" onClick={this.onClickSearchIcon}>
                            <FaSearch className="search-trigger" />
                        </div>
                    </div>
                </div>
        
                <CreateOrderModal
                    isShowing={this.state.showOrderModal}
                    hide={() => this.setState({ showOrderModal: false })}
                    orderTypeName={this.state.orderTypeName}
                />
                <SearchBox
                    show={this.state.showSearch}
                    shouldHide={() => this.setState({ showSearch: false })}
                />
                
                <DeleteOrderModal
                    show={this.state.showDeleteOrderModal}
                    shouldHide={() => this.setState({ showDeleteOrderModal: false })}
                    orderId={this.props.orderState.order ? this.props.orderState.order.orderheader.appOrderId : 0}
                    order={this.props.orderState.order && this.props.orderState.order.orderheader}
                    onClickConfirmDelete={(deletedOrder: any) => {
                        if (this.props.orderState.order &&
                            deletedOrder.appOrderId === this.props.orderState.order.orderheader.appOrderId) {
                                this.props.history.push('/');
                        }
                    }}
                />
                <MyMoloOverlay
                    show={this.state.showMyMolo}
                    shouldHide={() => this.setState({ showMyMolo: false})}
                />

                <ProductDownloadModal
                    show={this.state.showProductDownload}
                    shouldHide={() => this.setState({ showProductDownload: false})}
                    isProfile={this.state.myMoloIsProfile}
                />
                <AllOrdersOverlay
                    show={this.state.showShippedOverlay}
                    hide={() => this.setState({ showShippedOverlay: false})}
                />
            </div>
        )
    }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
    return {
        productState: states.productStore.productStore,
        collectionState: states.collections.collections,
        filterState: states.session.filter,
        userState: states.user.user,
        orderState: states.order.order
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
    return {
        applyFilter: async (filter: Filter) => {
            await dispatch(applyFilter(filter))
            // console.log('apply filter completed [UI]')
        },
        getFilter: async () => {
            await dispatch(getFilter())
            // console.log('getFIlter completed [UI]')
        },
        logoutUser: async () => {
            await dispatch(logoutUser())
            // console.log('user is invalidated')
        },
        getDashboardOrderState: async (orderId: number) => {
            await dispatch(getDashboardOrderState(orderId));
        },
        resetFilter: async (filter: Filter) => {
            await dispatch(resetFilter(filter))
        },
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header)) 
