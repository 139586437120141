import React, { useState } from 'react';

interface Iprops {
    quantity: number | ''
    available: number
    itemId: string
    blocked: boolean
    idx: number
    handleKeyUp?: any
    handleKeyDown?: any
    handleOnFocus?: any
    handeClick?: any
    updateChanges: Function
    soldOut?: boolean
    regularDiv?: boolean
}

const QuantityInput: React.SFC<Iprops> = (props) => {

    const { quantity, available, itemId, blocked, idx, handleKeyDown, handleOnFocus, handeClick,soldOut } = props;
    const [value, setValue] = useState(quantity);

    const handleChange = (event: any) => {
        const quantity = event.target.value > available ? available : event.target.value;
        props.updateChanges(itemId, quantity);
        setValue(quantity);
    };

    const convertAvailable = (available: number, quantity: number | string) => {
        let availability = 0;
        const realAvailable: number = +available;

        if (realAvailable > 0) {
            availability = realAvailable - +quantity;
        }

        return availability;
    }

    const renderInput = () => {
        return <React.Fragment>
            {!blocked && !soldOut &&
                <input
                    className="quantity-input__input"
                    type="number"
                    id={'size_' + itemId}
                    name={'size_' + itemId}
                    value={value}
                    onChange={handleChange}
                    // onKeyUp={handleKeyUp}
                    onKeyDown={handleKeyDown}
                    onFocus={handleOnFocus}
                    onClick={handeClick}
                />
            }
            {/* {!blocked && !soldOut && convertAvailable(available, value) !== 9999 &&
                <span className="quantity-grid__availability">({convertAvailable(available, value)})</span>
            } */}
            {!blocked && !soldOut && convertAvailable(available, value) > -1 && convertAvailable(available, value) < 6 &&
                <>
                    <span className="quantity-grid__availability">
                        ({convertAvailable(available, value)})
                        <div className="lowAvailability"></div>
                    </span>
                </>
                
            }
            {!blocked && !soldOut && convertAvailable(available, value) > 5 && convertAvailable(available, value) < 16 &&
                <span className="quantity-grid__availability">
                    <div className="mediumAvailability"></div>
                </span>
            }
            {!blocked && !soldOut && convertAvailable(available, value) > 15 &&
                <span className="quantity-grid__availability">
                    <div className="infiniteAvailability"></div>
                </span>
            }
            {blocked &&
                <span className="quantity-input__label">Blocked</span>
            }

            {soldOut && !blocked &&
                <span className="quantity-input__label">Sold Out</span>
            }
        </React.Fragment>
    }

    if (props.regularDiv) {
        return (
            <div className="quantity-grid__cell quantity-grid__item">
                { renderInput() }
            </div>
        )
    }

    return (
        <td className="quantity-grid__cell quantity-grid__item" tabIndex={idx}>
            { renderInput() }
        </td>
    );
};

export default QuantityInput;