import React, { Component } from 'react'
import { ThunkDispatch } from 'redux-thunk'
import CSS from 'csstype';
import { RootState } from '../../store';
import { connect } from 'react-redux'
import {RouteComponentProps, withRouter} from "react-router-dom";

// Components.
import ProductOverlay from '../shared/ProductOverlay';
import ProductListItem from '../shared/ProductListItem';
import FilterComponent from './FilterComponent';
import StickyHeader from './StickyHeader';
import InfinityList from './InfinityList';

// Store.
import { IOrderState } from '../../store/order/orderStore';
import { IFilterState } from '../../store/session/reducers';
import { getOrder, applyChanges } from '../../store/order/actions';
import { setDeliveryWindows } from '../../store/collections/actions';
import { IProductState } from '../../store/products/reducers';
import ProductApi from '../../store/products/ProductApi';
import { IUserSate } from '../../store/user/reducers';

// Utils.
import { ReactComponent as FilterIcon } from '../../images/svg/filter_icon_black.svg';
import { FilterHelper } from '../../utils/FilterHelper';
import { uniq, clone, orderBy, sortBy } from 'lodash';
import { productListCategorySort } from '../../constants/productSort';

// import { TransitionGroup, CSSTransition } from 'react-transition-group';
// import InfiniteScroll from 'react-infinite-scroller';

// Models.
import Order from '../../models/Order';
// import Style, { createStyle } from '../../models/Style'
import Style, { createStyle } from 'molo-shared/lib/models/Style';
import { basketLine } from '../../models/Basket';
import { MoloItem } from '../../models/MoloItem';
import { StyleBean } from '../../models/Beans';

// Third party.
import { TweenLite } from "gsap";
import { Console } from 'console';
// import ClipLoader from 'react-spinners/ClipLoader';

interface IHeader {
    description: string
}

interface IElements {
    type: 'style' | 'iheader'
    style?: Style | undefined,
    header?: IHeader
}

interface ICategory { [id: string]: Style[]; }

interface State {
    order: Order | null,
    productOverlayComponent: JSX.Element | null,
    showModal: boolean,
    filteredStyles: Style[],
    productSortedByCategory: ICategory,
    productSortedByCategoryAsMap: Map<string, Style[]>,
    changes: basketLine,
    showFilter: boolean,
    selectedDeliveryWindow: string | undefined
    selectedSustainability: string[] | undefined
    selectedProduct?: StyleBean
    stickies: string[]
    listIndex: number,
    products: Style[],
    chunked: Style[][]
    infiniteLoaderCurrentIndex: number,
    currentCategory: string,
    elements: IElements[],
    hasMore: boolean,
    showInSeasonOnly: boolean,
}
  
interface OwnProps {
    products: Style[],
    lineDiscount?: number,
    ignoreFilter?: boolean
    showGoBack?: boolean
    lazyLoadMoloMaster?: boolean
    orderType?: string
    useProductOrderType?: boolean,
    useInfiniteScroll?: boolean,
    simple?: boolean
    match: {params: {orderType: string, collection: string}},
    hidePrice?: boolean
    checkOrderType?: boolean
}
  
interface DispatchProps {
    getOrder: (appOrderId: number) => void
    applyChanges: (orderId: number, changes: basketLine) => void
    setDeliveryWindows: (styles: Style[]) => void
}
  
interface StateProps {
    orderState: IOrderState
    filterState: IFilterState
    productState: IProductState
    userState: IUserSate
}
  
type Props = StateProps & OwnProps & DispatchProps & RouteComponentProps;

export const groupBy = (key: any) => (array: any[]) =>
array.reduce((objectsByKeyValue: { [x: string]: any; }, obj: { [x: string]: any; }) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
}, {});

export class ProductList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    
        this.box = null;
        this.t = null;
    }

    box: HTMLDivElement | null;
    t: ReturnType<typeof TweenLite.from> | null;

    private productSortedByCategory: ICategory = {};
    private initialChanges: basketLine = {};
    private tempProductListStyle: CSS.Properties = {
        position: 'relative',
        rowGap: '30px',
        cursor: 'pointer',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
        gridColumnGap: '30px',
        listStyle: 'none',
        margin: 0,
        padding: 0,
    }

    state: State = {
        order: null,
        productOverlayComponent: null,
        showModal: false,
        filteredStyles: [],
        productSortedByCategory: this.productSortedByCategory,
        changes: this.initialChanges,
        showFilter: false,
        selectedDeliveryWindow: undefined,
        selectedSustainability: [],
        stickies: [],
        listIndex: 0,
        products: [],
        infiniteLoaderCurrentIndex: 0,
        chunked: [[]],
        currentCategory: '',
        elements: [],
        hasMore: true,
        showInSeasonOnly: false,
        productSortedByCategoryAsMap: new Map(),
    }

    componentDidMount() {
        // console.log(this.props, 'PROPS :: mount')
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        let filterHasChanged = prevProps.filterState.filter !== this.props.filterState.filter;
        let { collection } = this.props.match.params;
        let prevCollection = prevProps.match.params.collection;

        if (prevState.showInSeasonOnly !== this.state.showInSeasonOnly) {
            filterHasChanged = true;
        }

        let products = [ ...this.props.products ];
        
        if ((filterHasChanged && this.props.filterState.filter)) {
            let withOutNOS = products.filter(style => style.seasonCode !== 'NOS');
            let seasons = uniq(withOutNOS.map(style => style.seasonCode));
            let years = uniq(seasons.map(seasonCode => seasonCode.substring(1))).sort().reverse();
            
            const latestYear = years[0];
            let inSeason = '';

            if (seasons.indexOf(`S${latestYear}`) !== -1) {
                inSeason = `S${latestYear}`;
            }

            if (seasons.indexOf(`W${latestYear}`) !== -1) {
                inSeason = `W${latestYear}`;
            }

            if (this.state.showInSeasonOnly) {
                products = products.filter(product => product.seasonCode === inSeason)
            }

            const filteredStyles = FilterHelper.resultByFilter(this.props.filterState.filter, products);

           
            // const productSortedByCategory = this.getProductCategories(filteredStyles);

            const productSortedByCategoryAsMap = this.getProductCategoriesAsMap(filteredStyles)

            // console.log(productSortedByCategoryAsMap, 'new map :: productSortedByCategoryAsMap')
            const iterator1 = productSortedByCategoryAsMap.keys();
            // console.log(iterator1.next().value, 'iterator1.next().value')

            // const currentCategory = this.state.currentCategory !== '' ? this.state.currentCategory : 
            //     Object.keys(productSortedByCategory).sort().reverse()[0]

            const currentCategory = iterator1.next().value;

            this.props.setDeliveryWindows(products);
            
            this.setState({
                filteredStyles,
                // productSortedByCategory,
                currentCategory,
                productSortedByCategoryAsMap,
            }, () => {
                this.updateItems()
            });
        }

        if (this.props.ignoreFilter && this.props.products !== prevProps.products) {
            const productSortedByCategory = this.getProductCategories(products);
            this.setState({ filteredStyles: { ...products }, productSortedByCategory });
        }
    }

    sortBySeasonMaster = (categories: Style[]) => {
        const groupBySeasonCode = groupBy(['seasonCode']);
        const gbs = groupBySeasonCode(categories);
        let sortKeys: string[] = [];
        let years = uniq(Object.keys(gbs).map(seasonCode => seasonCode.substring(1)));
        
        
        for (const year of years.sort().reverse()) {
            if (Object.keys(gbs).indexOf('W' + year) !== -1) {
                sortKeys.push('W' + year)    
            }

            if (Object.keys(gbs).indexOf('S' + year) !== -1) {
                sortKeys.push('S' + year)
            }
        }

        let mappedInSeasonOrder: Map<string, Style[]> = new Map();

        for (const delta of sortKeys) {
            mappedInSeasonOrder.set(delta, orderBy(gbs[delta], ['masterId'],['asc']));
        }

        let newSort: Style[] = [];

        mappedInSeasonOrder.forEach((value: Style[], key: string) => {
            for (const i in value) {
                newSort.push(value[i])
            }
        });

        if (gbs['NOS'] && gbs['NOS'].length) {
            newSort = [ ...newSort, ...gbs['NOS']]
        }

        return newSort;
    }

    getProductCategoriesAsMap(styles: Style[]): Map<string, Style[]> {
        let categories: ICategory = {};
        let { collection } = this.props.match.params;
        let collectionName = collection;

        if (!styles.length) return new Map();

        for (const style of styles) {
            if (!categories[style.productCategory] && style.productCategory.trim() !== '') {
                categories[style.productCategory] = [];
            }

            if (style.productCategory.trim() !== '') {
                categories[style.productCategory].push(style);
            }
        }

        for (const i in categories) {
            categories[i] = orderBy(categories[i], ['masterId'],['asc']);
        }

        let keysNotInSort: string[] = [];
        let keysInSort: string[] = [];
        let inCat: ICategory = {};
        let productMap = new Map();
        // let notInCat: ICategory = {};
        const allowedCollections = ['ow', 'main', 'sport', 'swim', 'outerwear']
        if (allowedCollections.indexOf(collectionName.toLowerCase()) === -1) {
            collectionName = 'no-collection';
        }

        if (collectionName === 'no-collection') {
            // return categories

            for (const categoryName in categories) {
                productMap.set(categoryName, categories[categoryName]);
            }

            return productMap;
        }

        if (productListCategorySort[collectionName.toLowerCase()]) {
            keysNotInSort = Object.keys(categories)
                .filter(key => productListCategorySort[collectionName.toLowerCase()].indexOf(key) === -1 && key.toLowerCase() !== 'marketing')

            keysInSort = Object.keys(categories)
                .filter(key => productListCategorySort[collectionName.toLowerCase()].indexOf(key) !== -1 && key.toLowerCase() !== 'marketing')

            keysInSort = sortBy(keysInSort, (item) => {
                return productListCategorySort[collectionName.toLowerCase()].indexOf(item)
            });
        }

        //  orderBy(categories[i], ['masterId'],['asc']);
        for (const categoryName of keysInSort) {
            inCat[categoryName] = categories[categoryName];
            let newSort = this.sortBySeasonMaster(categories[categoryName])

            productMap.set(categoryName, newSort);
        }

        for (const categoryName of keysNotInSort) {
            let newSort = this.sortBySeasonMaster(categories[categoryName])
            productMap.set(categoryName, newSort);
        }
    
        if (categories['Marketing']) {
            let newSort = this.sortBySeasonMaster(categories['Marketing'])
            productMap.set('Marketing', newSort);
        }

        return productMap;
    }

    getProductCategories(styles: Style[]): ICategory {
        let categories: ICategory = {};
        let masterIds = uniq(styles.map(style => style.masterId.substring(1)));
        masterIds = uniq(masterIds.map(masterId => masterId.substring(0, masterId.length - 4)));
        masterIds = masterIds.filter(masterId => masterId.startsWith('W') || masterId.startsWith('S'))
        let years = uniq(masterIds.map(masterId => masterId.substring(1)));
        let sortKeys: string[] = [];

        for (const year of years.sort().reverse()) {
            sortKeys.push('W' + year)
            sortKeys.push('S' + year)
        }

        for (const style of styles) {
            if (!categories[style.productCategory]) {
                categories[style.productCategory] = [];
            }

            categories[style.productCategory].push(style);
        }

        // for (const key in categories) {
        //     const category = clone(categories[key]);

        //     const sorted = category.sort((cat1, cat2) => {
        //         let m1 = cat1.masterId.substring(1);
        //         m1 = m1.substring(0, m1.length - 4);
        //         let m2 = cat2.masterId.substring(1);
        //         m2 = m2.substring(0, m2.length - 4);
                
        //         const index1 = sortKeys.indexOf(m1);
        //         const index2 = sortKeys.indexOf(m2);
                
        //         if (index1 === -1 && index2 === -1) {
        //             return -1;
        //         }

        //         if (index1 === -1 && index2 !== -1) {
        //             return 1
        //         }

        //         if (index2 === -1 && index1 !== -1) {
        //             return -1
        //         }

        //         if (index1 > index2) {
        //             return 1;
        //         }

        //         if (index1 < index2) {
        //             return -1;
        //         }

        //         return -1;
        //     })
            
        //     categories[key] = sorted;
        // }

        for (const i in categories) {
            categories[i] = orderBy(categories[i], ['masterId'],['asc']);
        }

        return categories;
    }

    public static getDerivedStateFromProps(props: Props, state: State) {
        if (props.orderState.order && props.orderState.order !== state.order) {
          return {
              order: props.orderState.order,
          }
        }

        return null;
    }

    handleOnChangeProduct = async (item: MoloItem) => {
        const currencyCode = this.props.userState.user.currencyCode || 'EUR';
        const pricegroup   = this.props.userState.user ? this.props.userState.user.customerData.pricegroup : 'ALL';
        const response = await ProductApi.getMaster(item.masterId, item.colorCode, currencyCode,pricegroup);
        const product = response.data;
        
        this.handleOnProductClick(createStyle(product));
    }

    handleOnProductClick = (product: Style) => {
        // this.t = TweenLite.to(this.box, 1, {duration: 1, opacity: 1, y: 0, stagger: 0.25});
        // this.t = TweenLite.to(this.box, .2, {y: -200, opacity: 0})

        setTimeout(() => {
            this.setState({
                
                selectedProduct: StyleBean.build(product, 'style'),
                showModal: true,
            });
        }, 200)
        
    }

    hide = () => {
        this.setState({showModal: false, productOverlayComponent: null})
    }

    applyBasketChanges = (changes: basketLine) => {
        const newChanges = { ...this.state.changes, ...changes}
        this.setState({changes: newChanges})
    }

    applyChanges = () => {
        if (this.props.orderState.order) {
            this.props.applyChanges(this.props.orderState.order.orderheader.appOrderId, this.state.changes);
            this.setState({changes: {}}, () => {
                this.hide();
            })
        }
    }

    goBack = () => {
        if (this.props.history) {
            this.props.history.goBack();
        }        
    }

    isInBasket = (product: Style) => {
        return this.props.orderState.order && !!this.props.orderState.order.orderLines.find((line) => {
            return line.masterid === product.masterId && line.colorcode === product.colorCode;
        })
    }

    onSticky = (categoryName: string) => {
        const stickies = this.state.stickies;
        stickies.push(categoryName)
        this.setState({ stickies })
    }

    onUnSticky = (categoryName: string) => {
        const stickies = this.state.stickies;
        const index = stickies.indexOf(categoryName);
        if (index > -1) {
            stickies.splice(index, 1);
        }

        this.setState({ stickies })
    }

    renderCategory(styles: Style[], categoryName: string) {
        if (!styles.length) {
            return null
        }

        return (
            <React.Fragment key={categoryName}>
                <StickyHeader
                    title={ categoryName }
                    onSticky={this.onSticky}
                    onUnSticky={this.onUnSticky}
                />
                <ul className="pl">
                    {
                        styles && styles.map((product:Style, index:number) =>
                            <li key={index} onClick={ () => this.handleOnProductClick(product) } className="pl-item__wrapper ripple">
                                <ProductListItem
                                    product={StyleBean.build(product, 'style')}
                                    discount={this.props.lineDiscount}
                                    inBasket={this.isInBasket(product)}
                                    lazyLoadMoloMaster={this.props.lazyLoadMoloMaster}
                                    quantityLabel={'Available'}
                                    hidePrice={this.props.hidePrice}
                                />
                            </li>
                        )
                    }
                </ul>
            </React.Fragment>
        )
    }

    renderOverlay() {

        if (!this.state.selectedProduct) {
            return null;
        }
        console.log('open product');

        console.dir(this.state.selectedProduct);
        return (
            <ProductOverlay
                product={StyleBean.build(this.state.selectedProduct, 'style')}
                hide={this.hide}
                order={this.props.orderState.order}
                applyBasketChanges={this.applyBasketChanges}
                applyChanges={this.applyChanges}
                changeProduct={this.handleOnChangeProduct}
                showModal={this.state.showModal}
                deliveryWindows={this.props.productState.deliveryWindows}
                orderType={this.props.useProductOrderType ? this.state.selectedProduct.orderType : undefined}
                checkOrderType={this.props.checkOrderType}
            />
        )
    }

    updateItems = () => {
        this.setState({
            hasMore: true,
            elements: [],
            currentCategory: this.state.currentCategory,
            infiniteLoaderCurrentIndex: 0
        }, () => {
            this.loadItems()
        })
    }

    loadItems = () => {

        if (this.state.currentCategory !== '') {
            let elements: IElements[] = []

            // let productSortedByCategory = this.getProductCategories(this.state.filteredStyles);
            let currentCategory = this.state.currentCategory;
            let infiniteLoaderCurrentIndex = this.state.infiniteLoaderCurrentIndex;
            let counter = 0;
            let hasMore = true;
            console.log('loadItems infinity list');            

            while (counter < 100) {
                if (!this.state.productSortedByCategoryAsMap.has(currentCategory)) {
                    counter = 100;
                    hasMore = false;
                    break;
                }

                const styles = this.state.productSortedByCategoryAsMap.get(currentCategory);
                const nextElement = styles && styles[infiniteLoaderCurrentIndex] ? styles[infiniteLoaderCurrentIndex] : undefined

                if (nextElement) {

                    if (infiniteLoaderCurrentIndex === 0) {
                        const header: IHeader = {description: currentCategory}
                        elements.push(
                            {
                                type: 'iheader',
                                header: header,
                            }
                        )
                    }

                    const product = nextElement;
                    elements.push(
                        {
                            type: "style",
                            style: product
                        }
                    )
                    counter++
                    infiniteLoaderCurrentIndex++;
                } else {
                    infiniteLoaderCurrentIndex = 0;
                    const keys = [ ...this.state.productSortedByCategoryAsMap.keys() ] 
                    currentCategory = keys[(keys.indexOf(currentCategory) + 1)]; 
                }
            }
            

            this.setState({
                hasMore,
                elements: [...this.state.elements, ...elements],
                currentCategory,
                infiniteLoaderCurrentIndex
            })
        }
    }

    // loadItems = () => {
    //     if (this.state.currentCategory !== '') {
    //         let elements: IElements[] = []

            

    //         // let productSortedByCategory = this.getProductCategories(this.state.filteredStyles);
    //         let currentCategory = this.state.currentCategory;
    //         let infiniteLoaderCurrentIndex = this.state.infiniteLoaderCurrentIndex;
    //         let counter = 0;
    //         let hasMore = true;
            

    //         while (counter < 100) {
    //             if (!this.state.productSortedByCategory[currentCategory]) {
    //                 counter = 100;
    //                 hasMore = false;
    //                 break;
    //             }

    //             if (this.state.productSortedByCategory[currentCategory][infiniteLoaderCurrentIndex]) {

    //                 if (infiniteLoaderCurrentIndex === 0) {
    //                     const header: IHeader = {description: currentCategory}
    //                     elements.push(
    //                         {
    //                             type: 'iheader',
    //                             header: header,
    //                         }
    //                     )
    //                 }

    //                 const product = this.state.productSortedByCategory[currentCategory][infiniteLoaderCurrentIndex];
    //                 elements.push(
    //                     {
    //                         type: "style",
    //                         style: product
    //                     }
    //                 )
    //                 counter++
    //                 infiniteLoaderCurrentIndex++;
    //             } else {
    //                 infiniteLoaderCurrentIndex = 0;
    //                 const keys = Object.keys(this.state.productSortedByCategory).sort().reverse();
    //                 currentCategory = keys[(keys.indexOf(currentCategory) + 1)]; 
    //             }
    //         }

    //         console.log(elements, 'elements :: on first load')
            

    //         this.setState({
    //             hasMore,
    //             elements: [...this.state.elements, ...elements],
    //             currentCategory,
    //             infiniteLoaderCurrentIndex
    //         })
    //     }
    // }

    render() {
        let { collection } = this.props.match.params;

        if (this.props.simple) {
            return (
                <div className={"product-list--simple"}>
                    { this.state.showModal && this.renderOverlay() }
                    <div className="product-list__content">
                        <div className="product-list__list">
                            <div className="product-list__list" id="sticky-container">
                                {
                                    <ul className="pl">
                                    {
                                        this.props.products && this.props.products.map((product:Style, index:number) =>
                                            <li key={index} onClick={ () => this.handleOnProductClick(product) } className="pl-item__wrapper ripple">
                                                <ProductListItem
                                                    product={StyleBean.build(product, 'style')}
                                                    discount={this.props.lineDiscount}
                                                    inBasket={this.isInBasket(product)}
                                                    lazyLoadMoloMaster={this.props.lazyLoadMoloMaster}
                                                    quantityLabel={'Available'}
                                                    hidePrice={this.props.hidePrice}
                                                />
                                            </li>
                                        )
                                    }
                                </ul>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className={"product-list" + (this.state.showFilter ? " filter-is-active" : "")} ref={e => this.box = e}>
                <div className="product-list__header">
                    <span
                        className="product-list__header__text">
                        {this.state.stickies[this.state.stickies.length - 1]}
                    </span>
                </div>

                <div className="product-list__content">
                    {
                        !this.props.ignoreFilter &&
                        <FilterComponent
                            styles={ this.props.products }
                            collectionName={ collection }
                            showFilter={ this.state.showFilter } />
                    }
                    {
                        !this.props.useInfiniteScroll &&
                        <div className="product-list__list" id="sticky-container">
                            {
                                Object.keys(this.state.productSortedByCategory).sort().reverse().map((key, index) => {
                                    return (
                                        this.renderCategory(this.state.productSortedByCategory[key], key)
                                    )
                                })
                            }
                        </div>
                    }
                    {
                        this.props.useInfiniteScroll &&
                        <InfinityList 
                            elements={this.state.elements}
                            onSticky={this.onSticky}
                            onUnSticky={this.onUnSticky}
                            handleOnProductClick={this.handleOnProductClick}
                            lineDiscount={this.props.lineDiscount}
                            isInBasket={this.isInBasket}
                            lazyLoadMoloMaster={this.props.lazyLoadMoloMaster}
                            loadItems={this.loadItems}
                            hasMore={this.state.hasMore}
                        />
                    }
                </div>

                {!this.props.ignoreFilter && <div className="filter-dropdown__toggler__wrapper">
                    <div
                        className={"filter-dropdown__toggler" + (this.state.showFilter ? " open" : "")}
                        onClick={() => this.setState({showFilter: !this.state.showFilter})}
                    >
                        <span className="filter-dropdown__toggler__title desktop-only">
                        {!this.state.showFilter ? 'Show filter' : 'Hide filter'}
                    </span>
                        <FilterIcon />
                    </div>
                </div>}
                {this.props.match && this.props.match.params && this.props.match.params.orderType && this.props.match.params.orderType === 'REORDER' &&
                <div className="filter-dropdown__toggler__wrapper --in-season">
                    <div className="dashboard-details__header__item dashboard-details__header__show-sizes">
                        <div className="pretty p-default form-group">
                            <input
                                className="a-form-control a-form-control--checkbox"
                                checked={this.state.showInSeasonOnly}
                                onChange={() => {this.setState({ showInSeasonOnly: !this.state.showInSeasonOnly })}}
                                type="checkbox"/>
                            <div className="state">
                                <label>Show in season only</label>
                            </div>
                        </div>
                    </div>
                </div>}
                { this.state.showModal && this.renderOverlay() }
            </div>
        )
    }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
    return {
      orderState: states.order.order,
      filterState: states.session.filter,
      productState: states.productStore.productStore,
      userState: states.user.user,
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
    return {
        getOrder: async (orderId: number) => {
            await dispatch(getOrder(orderId))
            // console.log('getOrder completed [UI]')
        },
        applyChanges: async (orderId: number, changes: basketLine) => {
            await dispatch(applyChanges(orderId, changes))
            // console.log('applyChanges completed [UI]')
        },
        setDeliveryWindows: async (styles: Style[]) => {
            await dispatch(setDeliveryWindows(styles))
            // console.log('setDeliveryWindows completed [UI]')
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductList))
