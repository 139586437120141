import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {uuid} from 'uuidv4'

export interface Isuccess {
    type: 'ALERT_SUCCESS',
    message: string
}

export interface Ierror {
    type: 'ALERT_ERROR',
    message: string
}

export interface ICritical {
    type: 'ALERT_CRITICAL',
    message: string
}

export interface IClearAlert {
    type: 'ALERT_CLEAR',
    id: string
}

export interface IAlertPayload {
    message: string,
    type: string
    id: string
}

export interface IAlert {
    type: 'ALERT_ERROR' | 'ALERT_SUCCESS' | 'ALERT_CRITICAL'
    payload: IAlertPayload
}

export type AlertActions = IClearAlert | IAlert | ICritical

export const setAlertAction = (message: string, type: string, id: string): IAlert => {
    return { type: 'ALERT_SUCCESS', payload: {
        message, id, type
    }}
}

export const removeAlertAction = (id: string): IClearAlert => {
    return { type: 'ALERT_CLEAR', id }
}

export const setAlert = (message: string, type: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        const id = uuid()
        
        dispatch(setAlertAction(message, type, id))

        setTimeout(() => dispatch(removeAlertAction(id)))
    }
}