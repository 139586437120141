import React from 'react';

interface IProps {
    name: string
    title: string
    inputType: string
    value: any,
    handleChange: any,
    placeholder: string
    required?: boolean
}

export const Input: React.FC<IProps> = ({
    name,
    title,
    inputType,
    value,
    handleChange,
    placeholder,
    required
}) => {
	//console.log(props.value);
	return (  
  <div className="form-group">
    <label htmlFor={name} className="form-label">{title}</label>
    <input
      className="form-control"
      id={name}
      name={name}
      type={inputType}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      required={required}
    />
  </div>
)
}