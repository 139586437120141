export interface IproductListCategorySort {
    [id: string]: string[]
}

export const productListCategorySort: IproductListCategorySort = {
    'main': [
        'T-shirts & Tops',
        'Shirts',
        'Sweaters',
        'Jumpers & Cardigans',
        'Collection Jackets',
        'Dresses',
        'Skirts',
        'Shorts',
        'Pants',
        'Leggings',
        'Tights & Socks',
        'Underwear',
        'Nightwear',
        'Babysuits',
        'Acc. Collection',
        'Bags & Containers',
        'Marketing',
    ],
    'outerwear': [
        'Jackets & Coats',
        'Pants',
        'Snowsuits',
        'Rainwear',
        'Fleece & Soft Shells',
        'Collection Jackets',
        'Acc .Outerwear',
        'Acc. Outerwear',
        'Acc Collection',
        'Acc. Collection',
        'Marketing',
    ],
    'ow': [
        'Jackets & Coats',
        'Pants',
        'Snowsuits',
        'Rainwear',
        'Fleece & Soft Shells',
        'Collection Jackets',
        'Acc .Outerwear',
        'Acc. Outerwear',
        'Acc Collection',
        'Acc. Collection',
        'Marketing',
    ],
    'sport': [
        'T-shirts & Tops',
        'Sweaters',
        'Collection Jackets',
        'Shorts',
        'Skirts',
        'Pants',
        'Leggings',
        'Bodysuits',
        'Bags & Containers',
        'Acc Collection',
        'Acc. Collection',
    ],
    'swim': [
        'Swimwear',
        'Acc Swimwear',
        'Acc. Swimwear',
        'Acc. Collection',
        'Acc Collection',
        'Footwear',
        'T-shirts & Tops',
        'Dresses',
        'Shorts',
        'Pants',
        'Marketing',
    ],
}
