import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import {RouteComponentProps, withRouter} from "react-router-dom";

// Third part.
import { CSSTransition } from 'react-transition-group';

// Store.
import { RootState } from '../../store';
import { IOrderState } from '../../store/order/orderStore';
import { IUserSate } from '../../store/user/reducers';
import {
    setOrder,
    getCustomerShippingAddresses,
    clearShop,
    getOpenOrders,
    getDashboardOrderState,
    applyOrder,
} from '../../store/order/actions';
import OrderApi from '../../store/order/OrderApi';

// Icons.
import { FaCheck, FaTruck, FaChevronLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';

// Components.
import MoloRingloader from '../../components/layout/MoloRingloader';
import PropagateLoader from 'react-spinners/PropagateLoader'
import DownloadOrderImagesModal from '../../components/b2b/DownloadOrderImagesModal';

interface countryCode {
    code: string,
    name: string,
}

interface IshipToAddress {
    [id: string]: string
}

interface State {
    selectedAddress: any | null
    salespersonNote: string
    externalDocumentNo: string
    shipToAddress?: IshipToAddress
    showNewShipTo: boolean
    countryCodes: countryCode[]
    defaultCountryCode: string
    orderIsProcessed: boolean,
    orderIsBeingProcessed: boolean,
    initialized: boolean
    editMode: boolean
    processedOrderId?: number
    showDownloadModal: boolean
    showDownload: boolean
}
  
interface OwnProps {
    match?: {params: {orderId: number}},
    history?: any
}
  
interface DispatchProps {
    getCustomerShippingAddresses: (customerId: number) => void
    setOrder: (orderId: number) => void
    clearShop: () => void
    getOpenOrders: () => void
    getDashboardOrderState: (orderId: number, should?: boolean) => void
    applyOrder: (orderId: number) => void
}
  
interface StateProps {
    orderState: IOrderState
    userState: IUserSate
}
  
type Props = StateProps & OwnProps & DispatchProps & RouteComponentProps

export class CheckoutContainer extends Component<Props, State> {
    state: State = {
        selectedAddress: null,
        salespersonNote: '',
        externalDocumentNo: '',
        showNewShipTo: false,
        countryCodes: [],
        defaultCountryCode: '',
        orderIsProcessed: false,
        initialized: false,
        orderIsBeingProcessed: false,
        editMode: false,
        showDownloadModal: false,
        showDownload: false,
    }

    async componentDidMount() {
        const paramOrderId = this.props.match && this.props.match.params.orderId ? this.props.match.params.orderId : null;
        let editMode = false;

        if (paramOrderId) {
            this.props.setOrder(+paramOrderId);
        }

        this.props.getOpenOrders();

        const response = await OrderApi.getCountryRegionCodes()

        this.setState({countryCodes: response.data})

        const { order } = this.props.orderState;
        let showDownload = false;

        if (order) {
            if (order.orderheader.navImportRef && !order.orderheader.orderIsFinal && !this.state.editMode) {
                editMode= true;
            }

            showDownload = this.showDownload();
        }
        
        this.setState({ editMode, showDownload }, () => {})
    }

    componentDidUpdate(prevProps: Props) {
        const { shippingAddresses } = this.props.orderState;
        const { order } = this.props.orderState;

        if (order && order !== prevProps.orderState.order) {
            const customerId = order.orderheader.sellToCustomerNo;
            this.props.getCustomerShippingAddresses(customerId);

            const salespersonNote =  order.orderheader.salespersonNote;
            const externalDocumentNo = order.orderheader.externalDocumentNo;

            this.setState({ salespersonNote, externalDocumentNo })
        }

        if (order && shippingAddresses && shippingAddresses.length && shippingAddresses !== prevProps.orderState.shippingAddresses) {
            let selectedAddress = shippingAddresses[0];
            const orderSelectedAddressId = order.orderheader.shipToCode;

            if (orderSelectedAddressId) {
                const orderSelectedAddress = shippingAddresses.find(address => address.addressid === orderSelectedAddressId);

                if (orderSelectedAddress) {
                    selectedAddress = orderSelectedAddress;
                }
            }
            this.setState({ selectedAddress, initialized: true })
        }

        if (this.props.userState.user && this.state.defaultCountryCode === '') {
            if (this.props.userState.user.customerData.CountryCode && this.props.userState.user.customerData.CountryCode !== '') {
                this.setState({ defaultCountryCode: this.props.userState.user.customerData.CountryCode})
            }
        }

        if (order) {
            if (order.orderheader.navImportRef && !order.orderheader.orderIsFinal && !this.state.editMode) {
                this.setState({ editMode: true }, () => {})
            }
        }

        if (prevProps.orderState.orderIsPending && !this.props.orderState.orderIsPending && !this.props.orderState.order) {
            this.setState({orderIsProcessed: true, orderIsBeingProcessed: false})
        }

        
    }

    onSelectAddress = (address: any) => {
        this.setState({ selectedAddress: address, showNewShipTo: false })
    }

    onSelectNewAddressOption = () => {
        this.setState({
            showNewShipTo: true,
            selectedAddress: null,
        })
    }

    showDownload = () => {
        let showDownload = this.props.userState.user &&
        (this.props.userState.user.customerData.CustomerId === '12025' || this.props.userState.user.customerData.CustomerId === '11608');

        if (this.props.orderState.order && this.props.orderState.order.isCurrentOrderType) {
             return this.props.orderState.order.orderheader.orderType === 'REORDER';
        }

        return showDownload;
    }

    renderShipping = () => {
        if (!this.props.orderState.shippingAddresses || !this.props.orderState.order) {
            return null;
        }

        return (
            <div>
                {
                    this.props.orderState.shippingAddresses.map((address, key) => {
                        return (
                            <div className="pretty p-default p-round" key={key}>
                                <input
                                    type="radio"
                                    name="multiAddress"
                                    value={key}
                                    checked={(this.state.selectedAddress && this.state.selectedAddress.addressid === address.addressid )|| false}
                                    onChange={() => this.onSelectAddress(address)}
                                />
                                <div className="state">
                                    <label>{address.name}, {address.address}, {address.postcode} {address.city}</label>
                                </div>
                            </div>
                        )
                    })
                }
                 <div className="pretty p-default p-round">
                    <input
                        type="radio"
                        name="multiAddress"
                        onChange={this.onSelectNewAddressOption}
                        value={9999}
                    />
                    <div className="state">
                        <label style={{fontStyle: 'italic', textDecoration: 'underline'}}>Use alternative address?</label>
                    </div>
                </div>
            </div>
        )
    }

    handleOnSubmit = (event: any) => {
        event.preventDefault();

        const { order } = this.props.orderState;

        this.setState({
            orderIsProcessed: false,
            orderIsBeingProcessed: false,
            processedOrderId: order ? order.orderheader.appOrderId : 0,
        })

        const formData = new FormData(event.target);
        const multiAddress = formData.get('multiAddress')
        let selectedAddress = this.state.selectedAddress;

        if (multiAddress === '9999') {
            selectedAddress = this.getNewAddressValues(formData);
            selectedAddress['addressid'] = null;
        }

        this.setState({
            selectedAddress,
            orderIsBeingProcessed: true
        }, async () => {
            if (order) {
                let checkoutData = {};

                if (multiAddress === '9999' || order.orderheader.shipToCode !== this.state.selectedAddress.addressid) {
                    checkoutData = { ...checkoutData, 'shipToAddress': selectedAddress};
                }

                if (order.orderheader.salespersonNote !== formData.get('salespersonNote')) {
                    checkoutData = { ...checkoutData, 'salespersonNote': formData.get('salespersonNote')};
                }

                if (order.orderheader.externalDocumentNo !== formData.get('externalDocumentNo')) {
                    let externalDocumentNo = formData.get('externalDocumentNo') as string;
                    checkoutData = { ...checkoutData, 'externalDocumentNo': externalDocumentNo.substring(0, 20)};
                }

                checkoutData = { ...checkoutData, 'appOrderId': order.orderheader.appOrderId}

                localStorage.removeItem('order')

                window.addEventListener("popstate", e => {
                    // Nope, go back to start.
                    this.props.history.push('/');
                });

                const response = await OrderApi.updateOrder(checkoutData);

                if (response) {
                    // const applied = await OrderApi.applyOrder(order.orderheader.appOrderId);
                    this.props.applyOrder(order.orderheader.appOrderId)
                }
            }
        })
        
    }

    getNewAddressValues = (formData: FormData): IshipToAddress => {
        const data: IshipToAddress = {};

        for (const entry of Array.from(formData.entries())) {
            const id: string = entry[0];
            const value: string = !(entry[1] instanceof File) ? entry[1]: '';

            if (id.includes('newAddress.')) {
                // var str1 = id;
                var pattern = "newAddress.";
                var newId = id.substr(pattern.length);
                data[newId] = value;
            }
        }

        return data;

    }

    onChangeValue = (event: any) => {
        const {name, value } = event.target;

        this.setState({
            ...this.state,
            [name]: value
        } as Pick<State, keyof State>)
    }

    toggleShowShipTo = () => {
        this.setState({showNewShipTo: !this.state.showNewShipTo})
    }

    renderShipToGroup = (preFill?: boolean) => {
        return (
            <div>
                <h2>New shipping address</h2>
                <h3 className="title-throughline">
                    <span className="title-throughline__text">
                        Required fields
                    </span>
                </h3>
                <div className="form-control">
                    <input
                        type="text"
                        name="newAddress.name"
                        defaultValue={this.props.userState.user.customerData.Name}
                        placeholder='Name'
                        required
                        maxLength={50}
                    />
                </div>
                <div className="form-control">
                    <input
                        type="text"
                        name="newAddress.address"
                        placeholder='Address'
                        required
                        maxLength={50}
                    />
                </div>
                <div className="form-control">
                    <input
                        type="text"
                        name="newAddress.postcode"
                        placeholder='Postcode'
                        required
                        maxLength={20}
                    />
                </div>
                <div className="form-control">
                    <input
                        type="text"
                        name="newAddress.city"
                        placeholder='City'
                        required
                        maxLength={30}
                    />
                </div>
                <div className="form-control">
                    <select name="newAddress.countrycode" defaultValue={this.props.userState.user.customerData.CountryCode}>
                        {
                            this.state.countryCodes.length &&
                            this.state.countryCodes.map((countryCode, key) => {
                                return (
                                    <option key={key} value={countryCode.code}>
                                        { countryCode.code } | { countryCode.name }
                                    </option>
                                )  
                            })
                        }
                    </select>
                </div>
                <h3 className="title-throughline">
                    <span className="title-throughline__text">
                        Optional fields
                    </span>
                </h3>
                <div className="form-control">
                    <input
                        type="text"
                        name="newAddress.email"
                        placeholder='Email'
                        maxLength={80}
                    />
                </div>
                <div className="form-control">
                    <input
                        type="text"
                        name="newAddress.name2"
                        placeholder='Name 2'
                        maxLength={50}
                    />
                </div>
                <div className="form-control">
                    <input
                        type="text"
                        name="newAddress.address2"
                        placeholder='Address 2'
                        maxLength={50}
                    />
                </div>
                
                <div className="form-control">
                    <input
                        type="text"
                        name="newAddress.phone"
                        placeholder='Phone'
                        maxLength={80}
                    />
                </div>
            </div>
        )
    }

    handleOrderClick = (orderId: number) => {
        this.props.history.push('/dashboard/' + orderId);
        this.props.getDashboardOrderState(orderId)
    }

    renderMoreOrders = () => {
        return (
            <React.Fragment>
                <p>Please note, below orders are still open and has not been finalised!</p>
                <div>
                    <table className="striped-table table-hover">
                        <thead>
                            <tr>
                                <th>Order#</th>
                                <th>Customer</th>
                                <th>Type</th>
                                <th>Order Comment</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                
                                this.props.orderState.orders.map((order, key) => {
                                    return (
                                        <tr key={key} onClick={() => this.handleOrderClick(order.appOrderId)}>
                                            <td>B2B{order.appOrderId}</td>
                                            <td>
                                                <span>{order.sellToCustomerNo}</span><br/>
                                                <span>{order.sellToCustomerName}</span>
                                                <span>{order.sellToAddress}, {order.sellToCity}, {order.sellToCountry_RegionCode}</span>
                                            </td>
                                            <td>
                                                {order.orderType}
                                                {
                                                order.navImportRef && !order.orderIsFinal ?
                                                    <span className="item-blocked">
                                                        Access to edit
                                                    </span> : null
                                                }
                                            </td>
                                            <td>{order.salespersonnote}</td>
                                            <td style={{textAlign: 'right'}}><button className="co-confirm__button">Checkout</button></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        )
    }

    handleOnClickDownloadImages = () => {
        this.setState({ showDownloadModal: true })
    }

    renderConfirm = () => (
        <div className="co-confirm">
            <div className="basket-bottom after-checkout">
                <div style={{textAlign: 'left'}}>
                    <Link className="basket-bottom__link" to={'/'} >
                        <span className="basket-bottom__icon-left"><FaChevronLeft /></span>
                        Back to shop
                    </Link>
                </div>
            </div>
            <div className="co-confirm__check">
                <FaCheck />
            </div>
            <div className="co-confirm__content mt-10">
                <h2 className="co-confirm__title">Order { this.state.processedOrderId && this.state.processedOrderId} completed successfully!</h2>
                <p>Check your email for details.</p>
                {
                    this.state.showDownload &&
                    <button onClick={() => {
                            this.setState({ showDownloadModal: true })
                        }}
                        style={{marginBottom: '30px'}}
                    >
                        Download images
                    </button>
                }
                {
                    this.props.orderState.orders.length ? this.renderMoreOrders() : null
                }
            </div>
            {this.state.processedOrderId && <DownloadOrderImagesModal
                orderId={this.state.processedOrderId}
                shouldHide={() => { this.setState({ showDownloadModal: false }) }}
                show={this.state.showDownloadModal}
            />}
        </div>
    )

    renderCheckoutForm = () => {
        if (!this.state.initialized) {
            return <div style={{height: '100vh', width: '100vw', position: 'fixed'}}><MoloRingloader /></div>
        }

        if (this.state.orderIsBeingProcessed) {
            return (
                <div className="loading-spinner-1">
                    <h2>Applying your order</h2>
                    <p style={{marginBottom: '70px'}}>Please wait - it won't be long :-)</p>
                    <PropagateLoader />
                </div>
            )
        }

        return (
            <>
                <div className={"basket-bottom" + (this.state.editMode ? ' --edit-mode' : '')}>
                {
                    this.props.orderState.order ?
                    (
                        <div style={{textAlign: 'left'}}>
                            <Link className="basket-bottom__link" to={`/dashboard/` + this.props.orderState.order.orderheader.appOrderId} >
                                <span className="basket-bottom__icon-left"><FaChevronLeft /></span>
                                Back to dashboard
                            </Link>
                        </div>
                    ) : null
                }
                </div>
                <div className="checkout-form__header" style={{
                    textAlign: 'center',
                    padding: '1px 10px 0',
                    borderBottom: '1px solid #e5e5e5',
                    position: 'relative'
                }}>
                    <h2 style={{fontSize: '30px', marginBottom: '5px'}}>
                        <FaTruck style={{position: 'relative', top: '5px', right: '3px'}} /> Last step
                    </h2>
                    <p>We're almost there!</p>
                </div>
                <form onSubmit={this.handleOnSubmit} style={{maxWidth: '900px', margin: '0 auto'}} className="checkout-form">
                    {
                        this.props.orderState.shippingAddresses &&
                        this.props.orderState.shippingAddresses.length ?
                        <>
                            <div className="form-section">
                                <h2>Shipping address</h2>
                                <div className="form-control">
                                    { this.props.orderState.shippingAddresses && this.renderShipping() }
                                </div>
                            </div>
                            
                                <CSSTransition in={this.state.showNewShipTo} timeout={500} classNames="transition-opacity">
                                    {state => (
                                        this.state.showNewShipTo &&
                                        <div className="form-section">
                                            { this.renderShipToGroup() }
                                        </div>
                                    )}
                                </CSSTransition>
                        </> : null
                    }

                    <div className="form-section">
                        <h2>Order information</h2>
                        <div className="form-control">
                            <input
                                type="text"
                                name="salespersonNote"
                                value={this.state.salespersonNote || ''}
                                onChange={this.onChangeValue}
                                placeholder='Order Comment'
                                maxLength={30}
                            />
                        </div>
                        <div className="form-control">
                            <input
                                type="text"
                                name="externalDocumentNo"
                                maxLength={20}
                                value={this.state.externalDocumentNo || ''}
                                onChange={this.onChangeValue}
                                placeholder='External document no.'
                            />
                        </div>
                    </div>
                    <div className="form-section form-section--footer">
                        <div className="form-control">
                            <input type="submit" value="Place order" className="co-submit"/>
                        </div>
                    </div>                    
                </form>
            </>
        )
    }

    render() {
        if (this.state.orderIsProcessed) {
            return (
                this.renderConfirm()
            )
        }

        return (
            <div className="checkout-page">
                {this.renderCheckoutForm()}
            </div>
        )
    }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
    return {
        orderState: states.order.order,
        userState: states.user.user,
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
    return {
        getCustomerShippingAddresses: async (customerId: number) => {
            await dispatch(getCustomerShippingAddresses(customerId))
            console.log('getCustomerShippingAddresses completed [UI]')
        },
        setOrder: async (orderId: number) => {
            await dispatch(setOrder(orderId))
            console.log('setOrder completed [UI]')
        },
        clearShop: async () => {
            await dispatch(clearShop())
        },
        getOpenOrders: async () => {
            await dispatch(getOpenOrders())
        },
        getDashboardOrderState: async (orderId: number, should?: boolean) => {
            await dispatch(getDashboardOrderState(orderId, should))
        },
        applyOrder: async (orderId: number) => {
            await dispatch(applyOrder(orderId))
        }
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckoutContainer)) 