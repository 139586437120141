// Models.
import { orderLine as OrderLine, orderLine } from '../../../models/Order';

import {
    IKeyValue,
    IDivider,
    IGrouping,
} from './dashboard.d';

interface ISize {
    sizename: string,
    quantity: number
}

interface ISizes {
    [id: string]: ISize[],
}

interface IGroupedOrderLine {
    [id: string]: any,
}

// import DeliveryWindows from './DeliveryWindows';

interface IWindows {
    [id: string]: any
}

export function getProductCategories(orderlines: OrderLine[], dividers: IDivider[]): IGrouping {
    let groupings: IGrouping = {};

    if (!orderlines.length) {
        return {};
    }
  
    for (const i in dividers) {
        const divider = dividers[i];
  
        for (const key in orderlines) {
            const orderline = orderlines[key];
  
            if (!groupings[divider.id]) {
                groupings[divider.id] = {};
            }

            if (divider.id !== 'sustainability') {
                const linesWith = orderlines.filter(function(line) {
                    return line[divider.valueKey] === orderline[divider.valueKey];
                });
                
                if (linesWith.length) {
                    groupings[divider.id][orderline[divider.valueKey]] = {
                        orderLines: linesWith,
                        title: divider.id === 'collection' ? orderline[divider.labelKey].toUpperCase() : orderline[divider.labelKey],
                        value: orderline[divider.valueKey],
                        colorBlocked: orderline.colorBlocked || false,
                        quantity: ((groupings[divider.id][orderline[divider.valueKey]] && groupings[divider.id][orderline[divider.valueKey]].quantity) || 0) + orderline.quantity,
                        revenue: ((groupings[divider.id][orderline[divider.valueKey]] && groupings[divider.id][orderline[divider.valueKey]].revenue) || 0) + orderline.amount,
                        salesOptions: 0,
                    };
                }
          } else {
                let valueKey: string | null = null;
                if (orderline.organic) valueKey = 'organic';
                if( orderline.ecovero) valueKey = 'ecovero';
                if (orderline.recycled) valueKey = 'recycled';
                if (orderline.oekoTex !== '') valueKey = 'oekoTex';
                if (orderline.gots !== '') valueKey = 'gots';
                if (orderline.ocsno !== '') valueKey = 'ocs_100';
                if (orderline.ocsblended !== '') valueKey = 'ocs_blended';
                console.log('b2b?!?');
if(valueKey == null){
    console.log('bolarsen novalue ', orderline);
} else  {
console.log('bolarsen hasvalue ' + valueKey , orderline);
}
                if (valueKey) {
                    const linesWith = orderlines.filter(function(line) {
                        if (valueKey === 'gots') {
                            if (line.gots !== '') return true;
                        }

                        if (valueKey === 'oekoTex') {
                            if (line.oekoTex !== '') return true;
                        }

                        if (valueKey === 'recycled') {
                            if (line.recycled) return true;
                        }

                        if (valueKey === 'organic') {
                            if (line.organic) return true;
                        }
                        if (valueKey === 'ecovero') {
                            if (line.ecovero) return true;
                        }
                        if (valueKey === 'ocs_100') {
                            if (line.ocsno) return true;
                        }
                        if (valueKey === 'ocs_blended') {
                            if (line.ocsblended) return true;
                        }

                        return false;
                    });

                    if (linesWith.length) {
                        groupings[divider.id][valueKey] = {
                            orderLines: linesWith,
                            title: valueKey.toUpperCase(),
                            value: valueKey,
                            colorBlocked: orderline.colorBlocked || false,
                            quantity: ((groupings[divider.id][valueKey] && groupings[divider.id][valueKey].quantity) || 0) + orderline.quantity,
                            revenue: ((groupings[divider.id][valueKey] && groupings[divider.id][valueKey].revenue) || 0) + orderline.amount,
                            salesOptions: 0,
                        };
                    }
                }
          }
        }
    }

    for (var groupKey in groupings) {
        for (var key in groupings[groupKey]) {
            let grouped: IKeyValue = {};
            var orderLines = groupings[groupKey][key].orderLines;
  
            for (var orderLine of orderLines) {
                const key: string = String(orderLine.masterid +  orderLine.colorcode);

                if (!grouped[key]) {
                    grouped[key] = [];
                }

                grouped[key].push(orderLine);
                
            }
          
            groupings[groupKey][key]['salesOptions'] = Object.keys(grouped).length;
        }
    }

    return groupings;
}

export function getDeliveryWindows(orderLines: orderLine[]) {
    
    const deliveryWindows = [];
    const windows: IWindows = {}

    for (const i in orderLines) {
        windows[orderLines[i].deliveryCode] = {
            shippingDate: orderLines[i].deliveryshiptmentdate,
            deliveryCode: orderLines[i].deliveryCode,
            delivreryShortdescription: orderLines[i].delivreryshortdescription,
        };
    } 

    for (const i in windows) {
        deliveryWindows.push(windows[i]);
    }

    return deliveryWindows;
}

export function getHest() {
    return [];
}

export function groupSimilarColors  (orderLines: any[]) {
    const grouped: IGroupedOrderLine = {}
    const sizes: ISizes = {};

    for (const i in orderLines) {
        const orderline = orderLines[i];
        const masterid = orderline.masterid;
        const colorcode = orderline.colorcode;

        if (grouped[masterid + colorcode]) {
            const q = +grouped[masterid + colorcode].quantity + +orderline.quantity;
            grouped[masterid + colorcode].quantity = q;
            grouped[masterid + colorcode]['orderLines'].push({...orderline})
        } else {
            grouped[masterid + colorcode] = orderline
            grouped[masterid + colorcode]['orderLines'] = [{...orderline}]
        }

        if (!sizes[masterid + colorcode]) {
            sizes[masterid + colorcode] = [];
        }

        sizes[masterid + colorcode].push({ sizename: orderline.sizename, quantity: orderline.quantity });
    }

    // this.setState({ sizes })

    let g: orderLine[] = []

    for (const key in grouped) {
        let line = grouped[key];
        let q = 0;

        for (const delta in line['orderLines']) {
            q = q + line['orderLines'][delta].quantity;
        }

        line.quantity = q;

        g.push(line);
    }

    const sorted = g.sort((a, b) => (a.masterid > b.masterid) ? 1 : -1)

    return sorted;
}