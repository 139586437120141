import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'

import MoloRingloader from '../layout/MoloRingloader';

// Store.
import { RootState } from '../../store';
import { IProductState } from '../../store/products/reducers';
import { getOrderTypes } from '../../store/products/actions';

// Models.
import { OrderType } from '../../models/OrderType';

// Thirs party components.
import { TweenLite } from "gsap";



interface State {
    imagesIsLoded: boolean,
    imagesLoaded: number,
    initialized: boolean,
}
  
interface OwnProps {
    orderTypes: OrderType[],
}
  
interface DispatchProps {
    getOrderTypes: () => void;
}
  
interface StateProps {
    productStore: IProductState
}
  
type Props = StateProps & OwnProps & DispatchProps;

export class PreOrderTypeChooser extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    
        this.box = null;
        this.t = null;
        this.myTween = null;
    }
      
    box: HTMLLIElement | null;
    t: ReturnType<typeof TweenLite.from> | null;
    myTween: GSAPTimeline | null;
    // tl = React.useRef<GSAPStatic.Timeline>();

    state: State = {
        imagesIsLoded: false,
        imagesLoaded: 1,
        initialized: false,
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.props.productStore.orderTypes && this.props.productStore.orderTypes.length && !this.state.initialized) {
            this.setState({ initialized: true }, () => {
            })
        }
    }
     
    basepath = () => { 
        if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1"){
            return "https://dev2b.molo.com";
        }
        return '';
    };

    imageOnErrorHandler = (
        event: React.SyntheticEvent<HTMLImageElement, Event>
      ) => {
        event.currentTarget.src = this.basepath() + "/media/static/404.gif";
      };

    getImage = (type: string) => {

        const banner =  this.basepath() + "/media/static/banner/" + type.replace(' ','_') + ".jpg";
        return <img
            src={banner}
            alt={type}
            className="card-list__item__image" 
            onLoad={this.imageDoneLoading}
            onError={this.imageOnErrorHandler}
    />
    }

    imageDoneLoading = () => {
        if (!this.props.productStore.orderTypes) return
        let imagesLoaded = this.state.imagesLoaded;
        imagesLoaded++;

        this.setState({
            imagesIsLoded: this.props.productStore.orderTypes.length <= imagesLoaded,
            imagesLoaded
        });
    }

    render() {
        let preOrders: OrderType[] = [];
        
        if (this.props.productStore.orderTypes && this.props.productStore.orderTypes.length) {
            preOrders = this.props.productStore.orderTypes.filter(type => type.orderType.toLowerCase() !== 'reorder')
                .sort()
                .reverse()
        }
        
        return (
            <>
                {(!this.state.initialized || !this.state.imagesIsLoded) && <div style={{height: '100vh', width: '100vw', position: 'fixed'}}><MoloRingloader /></div>}
                <div
                    className={"card-list__wrapper " + ((!this.state.imagesIsLoded) ? "" : "fade-in")}
                    style={{visibility: (!this.state.initialized || !this.state.imagesIsLoded) ? 'hidden' : 'visible'}}
                >
                    <div className="card-list__wrapper">
                        <ul className={"card-list" + (preOrders && preOrders.length === 1 ? ' single-type' : '')}>
                            {
                                preOrders.map((orderType: OrderType, key) => {
                                    return <li className="card-list__item stagger-box" key={orderType.orderType} ref={e => this.box = e}>
                                        <Link
                                            to={`/shop/${orderType.orderType}`}
                                            className="card-list__item__link">
                                            {this.getImage(orderType.orderType)}
                                            <div className="card-list__item__title">
                                                {orderType.orderType}
                                            </div>
                                        </Link>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
    return {
        productStore: states.productStore.productStore
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
    return {
        getOrderTypes: async () => {
            await dispatch(getOrderTypes())
            console.log('getOrderTypes completed [UI]')
          },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreOrderTypeChooser)