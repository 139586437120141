import React, { useEffect, useState } from 'react'

interface Props {
    from: number,
    dateFrom: Date
    limit: number
}

const CountDown2: React.FC<Props> = (props) => {
    const [intervalId, setIntervalId] = useState<any>(null)
    const [counter, setCounter] = useState(0);
    const [dateFromCurrent, setDateFromCurrent] = useState<any>(null);

    React.useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1000), 1000);
    }, [counter]);

    useEffect(() => {
        const { dateFrom } = props;

        if (dateFromCurrent !== dateFrom) {
            setDateFromCurrent(dateFrom)
            const iid = setInterval(() => {
                const diff = new Date(new Date().toISOString()).getTime() - new Date(dateFrom).getTime()
                const timeLeft = (3600000 * 24) - diff;

                setCounter(timeLeft)

                if (timeLeft <= 0) {
                    clearInterval(iid)
                }
            }, 1000)

            setIntervalId(intervalId)

            
        }
    }, [props, dateFromCurrent, intervalId]);

    const convertMillisToTime = (ms: number, delim = " : ") => {
        const showWith0 = (value: any) => (value < 10 ? `0${value}` : value);
        const hours = showWith0(Math.floor((ms / (1000 * 60 * 60)) % 60));
        const minutes = showWith0(Math.floor((ms / (1000 * 60)) % 60));
        // const seconds = showWith0(Math.floor((ms / 1000) % 60));
        return `${parseInt(hours) ? `${hours}${delim}` : ""}${minutes}`;
    }
    
    return <div className="countdown">
        <span>Time left:</span>
        <span>{counter > 0 ? convertMillisToTime(counter) : '00 : 00'}</span>
        </div>
    
}

export default CountDown2;