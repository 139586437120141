import axios from '../globalAxios';
import { chunk } from 'lodash';
import { appConfig } from '../../config';

const baseUrl = appConfig.api.baseUrl;
const mediaBaseUrl = appConfig.media.baseUrl;

export default class ProductApi {
    static search = async (query: string, currencycode: string) => {
        const url = baseUrl + 'Items/Search/' + query + '/' + currencycode + '/';
        const response =  await axios.post(url);
        return response.data;
    }

    // /api/Items/AutoComplete/{query}
    static autoComplete = async (query: string) => {
        const url = baseUrl + 'Items/AutoComplete/' + query;
        const response = await axios.post(url);

        return response.data;
    }

    static autoComplete2 = async (query: string) => {
        const url = baseUrl + 'Items/AutoComplete2/' + query;
        const response = await axios.post(url);

        return response.data;
    }

    static getOrderTypes = async () => {
        const url = baseUrl + 'OrderTypes/GetOrderTypes'
        return await axios.post(url);
    }

    static registerMediaToken = async () => {
        const url = baseUrl + 'Authenticate/RegisterToken';
        const jwtToken = localStorage.getItem('token');
        return await axios.post(url, null, {params: { jwtToken }});
    }

    static getImageMap = async (seasonCode: string, master: string, color: string) => {
        const url = mediaBaseUrl + 'media/productimage/GetMasterImageMap/' + seasonCode + '/'+ master + '/' + color;
        const imageMapResponse = await axios.post(url);
        return imageMapResponse.data;
    }

    static getMaster = async (master: string, color: string, currencyCode: string, pricegroup: string) => {
        // /Items/LookupMaster/{master}/{colorcode}
        const url = baseUrl + 'Items/LookupMaster/' + master + '/' + color + '/' + currencyCode + '/' + pricegroup;

        return await axios.post(url);
    }

    static getMoloItem = async (master: string, color: string, currencycode: string, pricegroup: string) => {
        const url = baseUrl + 'Items/GetMoloItem/' + master + '/' + color + '/' + currencycode + '/' + pricegroup;
        console.log('debug GetMOloItem');
        return await axios.post(url);
    }

    static getMasters = async (moloItems: {master: string, color: string, currencyCode: string}[]) => {
        const limit = 5;
        let finalResponse:any = [];
        const chunky = chunk(moloItems, limit)

        for (const key in chunky) {
            const requests: any[] = [];
            for (const moloItem of chunky[key]) {
                const url = baseUrl + 'Items/LookupMaster/' + moloItem.master + '/' + moloItem.color + '/' + moloItem.currencyCode;
                const request = axios.post(url)
                requests.push(request);
            }
            
            const responses = await axios.all(requests);
            finalResponse = [...finalResponse, ...responses]
        }

        return finalResponse
    }

    // getProductGroupConfig
    static getProductGroupConfig = async () => {
      const url = baseUrl + 'Items/GetGroupDisplayOptions'
      return await axios.post(url);
    }
}
