import React from 'react'
import ReactDOM from 'react-dom';

import {RouteComponentProps, withRouter} from "react-router-dom";

// Components.
import ProductDownloadModalContent from './ProductDownloadModalContent';

// Icons.
import { FaTimes } from 'react-icons/fa';

interface Props {
    show: boolean
    shouldHide?: Function
    isProfile?: boolean
}

type SomeComponentProps = Props & RouteComponentProps;

const ProductDownloadModal: React.FC<SomeComponentProps> = (props) => {
    const {
        show,
        shouldHide,
        history,
        isProfile,
    } = props;
    const node = React.useRef(null);

    React.useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener('keydown', onKeyPressed);
        // return function to be called when unmounted
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
          document.removeEventListener('keydown', onKeyPressed);
        };
    });

    const handleClickOutside = (e: any) => {

        // @ts-ignore
        if(node.current.contains(e.target)) {
            return;
        }
        
        // outside click 
        shouldHide && shouldHide();
    };

    const onKeyPressed = (e: any) => {
        if (e.key === 'Escape' && shouldHide) {
            shouldHide()
        }
    }

    return ReactDOM.createPortal(
        <React.Fragment>
            <div
                className={"overlay-wrapper overlay-wrapper--my-molo" + (show ? ' show' : '')}
                onClick={(event: any) => {
                    if (event.target === node.current) {
                        shouldHide && shouldHide();
                    }
                }}
                ref={node}
            >
                <div className="modal-header__close" onClick={() => shouldHide && shouldHide()}>
                    <FaTimes />
                </div>
                {!!show && <ProductDownloadModalContent shouldHide={() => shouldHide && shouldHide()} history={history} isProfile={isProfile} />}
            </div>
        </React.Fragment>, document.getElementById('root') as HTMLElement
    )
}

export default withRouter(ProductDownloadModal);