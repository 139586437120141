import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import {RouteComponentProps, withRouter} from "react-router-dom";

// Store.
import { RootState } from '../../store';
import { IOrderState } from '../../store/order/orderStore';
import OrderApi from '../../store/order/OrderApi';
import {
    setOrder,
    getOpenOrders,
    deleteOrder,
    applyOrder,
} from '../../store/order/actions';

// Components.
import Modal from '../layout/Modal/Modal';

interface State {
    showOverlay: boolean,
    items: any[]
    initialized: boolean
    continueEdit: boolean
    closeOrder: boolean
    submitOrder: boolean
    editableOrder?: any
    intervalId?: any
}
  
interface OwnProps {
}
  
interface DispatchProps {
    setOrder: (orderId: number) => void
    getOpenOrders: () => void
    deleteOrder: (orderId: number) => void
    applyOrder: (orderId: number) => void
}
  
interface StateProps {
    orderState: IOrderState
}
  
type Props = StateProps & OwnProps & DispatchProps & RouteComponentProps;

export class EditOrderHandlerOverlay extends Component<Props, State> {

    state: State = {
        showOverlay: false,
        items: [],
        initialized: false,
        continueEdit: true,
        closeOrder: false,
        submitOrder: false,
    }

    componentDidUpdate = (prevProps: Props) => {
        const editableOrder = this.props.orderState.orders.find((order: any) => (!order.orderIsFinal && order.navImportRef))

        if (!this.state.editableOrder && editableOrder) {
            if (this.state.intervalId) {
                clearInterval(this.state.intervalId);
            }

            this.setState({ editableOrder, showOverlay: false }, () => {
                this.initializeInterval()
            })
        }

        if (this.state.editableOrder && editableOrder && this.state.editableOrder.appOrderId !== editableOrder.appOrderId) {
            clearInterval(this.state.intervalId);

            this.setState({ editableOrder, showOverlay: false }, () => {
                this.initializeInterval()
            })
        }

        if (this.state.editableOrder && !editableOrder) {
            clearInterval(this.state.intervalId);
            this.setState({
                showOverlay: false,
                editableOrder: null,
            })
        }
    }

    componentWillUnmount = () => {
        // use intervalId from the state to clear the interval
        clearInterval(this.state.intervalId);
    }

    initializeInterval = () => {
        let intervalId = setInterval(this.validate, 1000);
        this.setState({intervalId: intervalId});
    }

    validate = () => {
        let editableOrder:any = null;
        let showOverlay:boolean = false;

        editableOrder = this.props.orderState.orders.find((order: any) => (!order.orderIsFinal && order.navImportRef))

        if (editableOrder) {
            const presentDate = new Date();
            const orderCreated = new Date(editableOrder.createdUtc)
            let diff =(presentDate.getTime() - orderCreated.getTime()) / 1000;
            diff /= (60 * 60);

            if (diff > 2) {
                showOverlay = true;
            }
        }

        this.setState({
            showOverlay,
            editableOrder,
        })
    }

    confirmChanges = async () => {
        if (this.state.continueEdit) {
            const updateResponse = await OrderApi.updateOrder({
                appOrderId: this.state.editableOrder.appOrderId,
                createdUtc: new Date()
            })

            if (updateResponse) {
                this.props.setOrder(this.state.editableOrder.appOrderId)
                this.props.getOpenOrders()
                this.setState({ showOverlay: false, editableOrder: null })
            }
        }

        if (this.state.closeOrder) {
            this.props.deleteOrder(this.state.editableOrder.appOrderId)
            this.setState({ showOverlay: false, editableOrder: null })
        }

        if (this.state.submitOrder) {
            this.props.applyOrder(this.state.editableOrder.appOrderId)
            this.setState({ showOverlay: false, editableOrder: null })
        }
    }

    onChangeOption = (option: string) => {
        let continueEdit = false;
        let closeOrder = false;
        let submitOrder = false;

        switch(option) {
            case 'continueEdit':
                continueEdit = true;
                break;
            case 'closeOrder':
                closeOrder = true;
                break;
            case 'submitOrder':
                submitOrder = true;
                break;
        }

        this.setState({ submitOrder, closeOrder, continueEdit })
    }

    renderContent = () => {
        const order = this.state.editableOrder;
        return (
            <div>
                <div className="">
                    <div className="edit-navorder-confirm">
                        <h3 className="modal-title text-center" id="modal-title">
                            { order.sellToCustomerName } B2B{order.appOrderId} {order.orderType} {order.navImportRef} {order.salespersonNote}
                        </h3>

                        <h5 className="text-center" style={{marginBottom: '0px'}}>
                            You did not finish editing this order you were granted access to edit.
                        </h5>
                        <h5 className="text-center" style={{marginBottom: '40px', marginTop: '10px'}}>
                            Please choose how you would like to proceed:
                        </h5>
                        <div className="form-control">
                            <div className="pretty p-default p-round">
                                <input
                                    type="radio"
                                    name="editNavOrderOptions"
                                    checked={this.state.continueEdit}
                                    onChange={() => this.onChangeOption('continueEdit')}
                                />
                                <div className="state">
                                    <label>
                                        <strong>Continue edit:</strong> Extend your time limit and continue editing this order.
                                    </label>
                                </div>
                            </div>

                            <div className="pretty p-default p-round">
                                <input
                                    type="radio"
                                    name="editNavOrderOptions"
                                    checked={this.state.closeOrder}
                                    onChange={() => this.onChangeOption('closeOrder')}
                                />
                                <div className="state">
                                    <label>
                                        <strong>Close order:</strong> WARNING - The order will be closed and all your latest changes will be lost.
                                    </label>
                                </div>
                            </div>

                            {/* <div className="pretty p-default p-round">
                                <input
                                    type="radio"
                                    name="editNavOrderOptions"
                                    checked={this.state.submitOrder}
                                    onChange={() => this.onChangeOption('submitOrder')}
                                />
                                <div className="state">
                                    <label>
                                        <strong>Approve changes</strong> - The order will be closed and all your changes will be submitted.
                                    </label>
                                </div>
                            </div> */}
                        
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    }

    render() {
        if (!this.state.showOverlay) {
            return null;
        }
        return (
            <Modal
                children={this.renderContent()}
                size={'sm'}
                hideClose={true}
                hide={() => console.log('hide')}
                footer={
                    <button className="po__order-btn modal-footer__btn-center" onClick={this.confirmChanges}>Confirm</button>
                }
                isShowing={this.state.showOverlay}
            />
        )
    }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
    return {
        orderState: states.order.order,
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
    return {
        setOrder: async (orderId: number) => {
            await dispatch(setOrder(orderId))
        },
        getOpenOrders: async () => {
            await dispatch(getOpenOrders())
        },
        deleteOrder: async (orderId: number) => {
            await dispatch(deleteOrder(orderId))
        },
        applyOrder: async (orderId: number) => {
            await dispatch(applyOrder(orderId))
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditOrderHandlerOverlay))