import axios from '../globalAxios';
import { basketLine } from '../../models/Basket';
import { appConfig } from '../../config';

import { uniqBy } from 'lodash'

const baseUrl = appConfig.api.baseUrl;

export default class OrderApi {
    static getOrder = async (orderId: number) => {
        const url = baseUrl + 'Order/GetOrder';
        return await axios.post(url, null, {params: { apporderid: orderId }});
    }

    static getOrderFromLocalStorage = () => {
        const orderId = localStorage.getItem('order');

        if (orderId) {
            return +orderId;
        }

        return orderId;
    }

    static getRecentOrdersFromLocalStorage = () => {
        const recentClosedOrdersLocalStorage = localStorage.getItem('recentClosedOrders')
        let localStorageClosedOrders = [];
        let recentClosedOrders = []

        if (recentClosedOrdersLocalStorage) {
            localStorageClosedOrders = JSON.parse(recentClosedOrdersLocalStorage);
        }

        recentClosedOrders = uniqBy(localStorageClosedOrders, 'appOrderId')

        return recentClosedOrders;
    }

    static removeRecentOrdersFromLocalStorage = (appOrderId: number) => {
        const recentClosedOrdersLocalStorage = localStorage.getItem('recentClosedOrders')
        let localStorageClosedOrders = [];
        let recentClosedOrders = []

        if (recentClosedOrdersLocalStorage) {
            localStorageClosedOrders = JSON.parse(recentClosedOrdersLocalStorage).filter((order: any) => order.appOrderId !== appOrderId)
        }

        recentClosedOrders = uniqBy(localStorageClosedOrders, 'appOrderId');

        localStorage.setItem('recentClosedOrders', JSON.stringify(recentClosedOrders))

        return recentClosedOrders;
    }

    static getOpenOrders = async () => {
        const url = baseUrl + 'Order/GetMyOpenOrders';
        return await axios.post(url);
    }

    static getClosedOrders = async () => {
        const url = baseUrl + 'Order/GetMyClosedOrders';
        return await axios.post(url);
    }

    static getShops = async () => {
        const url = baseUrl + 'Order/MyShops';
        return await axios.post(url);
    }

    static createOrder = async (orderType: string, customerId: number) => {
        const url = baseUrl + 'Order/CreateNewOrder';
        return await axios.post(url, null, {params: { orderType, customerid: customerId }});
    };

    static applyChanges = async (orderId: number, changes: basketLine) => {
        const requests: any[] = [];

        for (const itemId in changes) {
            const request = axios.post(baseUrl + 'Order/AddItemToOrder', null, {params: {
                apporderid: orderId,
                itemno: itemId,
                quantity: changes[itemId],
            }})
            requests.push(request);
        }
        
        return axios.all(requests);
    }
    
    static updateOrder = async (data: any) => {
        const url = baseUrl + 'Order/UpdateOrder';
        return axios.patch(url, data);
    }

    static applyOrder = async (orderId: number) => {
        const url = baseUrl + 'Order/ApplyOrder';
        return axios.post(url, null, {params: { apporderid: orderId }});
    }

    static deleteOrder = async (orderId: number) => {
        const url = baseUrl + 'Order/DeleteOpenOrder';
        return axios.post(url, null, {params: { apporderid: orderId }});
    }

    static peakNavOrders = async (customerId: number) => {
        const url = baseUrl + 'Order/PeakNavOrders';
        return axios.post(url, null, {params: { customerid: customerId }});
    }

    static peakNavOrdersNew = async (customerId: number, final: boolean, allshops: boolean = false) => {
        const url = baseUrl + 'Order/PeakNavOrders';

        interface IParams {
            customerid: number
            final?: boolean
            allshops: boolean
        }

        let params: IParams = {
            customerid: customerId,
            allshops: allshops,
        }

        if (final !== undefined) {
            params['final'] = final;
        }

        return axios.post(url, null, { params });
    }

    static peakNavCustomerShipments = async (customerId: number, allShops: boolean = false) => {
        const url = baseUrl + 'Order/PeakNavCustomerShipments';
        return axios.post(url, null, {params: { customerid: customerId, allShops: allShops}});
    }

    // /api/Order/PeakNavOrderUnshippedShipments
    static peakNavOrderUnshippedShipments = async (sonumber: string) => {
        const url = baseUrl + 'Order/PeakNavOrderUnshippedShipments';
        return axios.post(url, null, {params: { sonumber: sonumber }});
    }

    // /api/Order/PeakNavShipmentItems
    static peakNavShipmentItems = async (bsl: string) => {
        const url = baseUrl + 'Order/PeakNavShipmentItems';
        return axios.post(url, null, {params: { bsl: bsl }});
    }

    // PeakNavOrderShipments
    static peakNavOrderShipments = async (sonumber: string) => {
        const url = baseUrl + 'Order/PeakNavOrderShipments';
        return axios.post(url, null, {params: { sonumber: sonumber }});
    }

    // /api/Order/PeakNavOrderUnshippedItems
    static peakNavOrderUnshippedItems = async (sonumber: string, deliverycode: string) => {
        const url = baseUrl + 'Order/PeakNavOrderUnshippedItems';
        return axios.post(url, null, {params: { SO: sonumber, deliverycode: deliverycode }});
    }

    static lookupOrderInNav = async (apporderid: number) => {
        const url = baseUrl + 'Order/LookupOrderInNav';
        return axios.post(url, null, {params: { apporderid }});
    }

    static openNavOrder = async (navOrderId: number) => {
        const url = baseUrl + 'Order/ImportNavOrder';
        return axios.post(url, null, {params: { navorderid: navOrderId }});
    }

    // /api/OrderTypes/GetSeasonOrderTypes/{seasoncode}
    static getSeasonOrderTypes = async (seasoncode: string) => {
        const url = baseUrl + `OrderTypes/GetSeasonOrderTypes/${seasoncode}`;
        return axios.post(url);
    }

    // GetItemOrderTypes
    static getItemOrderTypes = async (master: string, color: string) => {
        const url = baseUrl + `OrderTypes/GetItemOrderTypes/${master}/${color}`;
        return axios.post(url);
    }

    static getCustomerShippingAddresses = async (customerId: number) => {
        const url = baseUrl + 'Order/GetCustomerShippingAddresses';
        return axios.post(url, null, {params: { customerid: customerId }});
    }

    static moveOrderDelivery = async (orderId: number, deliveryCode: string, newDate: string) => {
        const url = baseUrl + 'Order/MoveOrderDelivery';
        
        return axios.post(url, null, {params: {
            apporderid: orderId,
            srcDeliveryCode: deliveryCode,
            newDate
        }})
    }

    static getCountryRegionCodes = async () => {
        const url = baseUrl + 'Order/AllCountryRegionCodes';
        return axios.post(url);
    }

    static ensureOrderStockChanges = async (orderId: number, confirmChange: boolean = false)  => {
        const url = baseUrl + 'Order/EnsureOrderStockChanges';
        return axios.post(url, null, {params: {
            apporderid: orderId,
            changeOrderQuantity: confirmChange
        }});
    }

    // /api/Order/GetMoloItemsOnOrder
    static GetMoloItemsOnOrder = async (apporderid: number) => {
        const url = baseUrl + 'Order/GetMoloItemsOnOrder';
        return axios.post(url, null, {params: { apporderid }});
    }

    static getCustomerDeliveryDownloadInfo = async (customerno: string) => {
        const url = baseUrl + 'Order/GetCustomerDeliveryDownloadInfo/' + customerno;
        return await axios.post(url);
    }

    // /api/Order/GetOrderDeliveries
    static getOrderDeliveries = async (appOrderId: number) => {
        const url = baseUrl + 'Order/GetOrderDeliveries';
        return axios.post(url, null, {params: { appOrderId }});
    }

    static getGotIt = () => {
        const storageVal = localStorage.getItem('gotIt') || 'false';

        return localStorage.getItem('gotIt') === null ? false : JSON.parse(storageVal)
    }

    // GetFilteredOrderLines
    static getFilteredOrderLines = async (appOrderId: number, deliverycode: string, seasoncode?: string) => {
        const url = baseUrl + 'Order/GetFilteredOrderLines';
        // return axios.post(url, null, {params: { appOrderId, deliverycode, seasoncode}});
        return axios.post(url, { appOrderId, deliverycode})
    }

    static getAllClosedOrders = async (customerId: string | number) => {
        const requests: any[] = [
            OrderApi.peakNavOrdersNew(+customerId, true),
            OrderApi.peakNavCustomerShipments(+customerId, true)
        ]
        const responses = await axios.all(requests);
        
        const peakNavordersResponse = responses[0]
        const shipmentsResponse = responses[1]

        const allOrders: any[] = [ ...peakNavordersResponse.data , ...shipmentsResponse.data.map((item: any) => {
            item.isFullyShipped = true
            return item
        }) ]

        return allOrders;
    }

    static getAllOpenOrders = async (customerId: string | number) => {
        const requests: any[] = [
            OrderApi.peakNavOrdersNew(+customerId, false, true),
            OrderApi.getOpenOrders()
        ]

        const responses = await axios.all(requests);
        const peakNavordersResponse = responses[0].data.map((res: any) => {
            let sellToCustomerName = res.shipttoname || '';

            return {
                appOrderId: null,
                sellToCustomerNo: res.selltocustomerno,
                sellToCustomerName: sellToCustomerName,
                sellToAddress: null,
                sellToCity: null,
                sellToCountry_RegionCode: null,
                shipttoname: '',
                orderType: res.ordertype,
                salespersonnote: res.salespersonnote,
                navorderid: res.navorderid,
                status: 'Submitted',
            }
        })
        const openOrdersResponse = responses[1]

        const allOrders: any[] = [
            ...peakNavordersResponse ,
            ...openOrdersResponse.data.map((item: any) => {
                item.status = 'Open'
                return item
            })
        ];

        return allOrders;
    }

    // PeakShipmentTracking
    static PeakShipmentTracking = (bsl: string) => {
        const url = baseUrl + 'Order/PeakShipmentTracking'
        return axios.post(url, null, {
            params: {
                bsl
            }
        })
    }
}
