import { combineReducers } from 'redux'

import { initialBasket as basket } from '../../models/Basket';
import {
    IOrderState,
    State,
    OrderActions,
} from './orderStore';

import { uniqBy } from 'lodash'

const order = (state: IOrderState = {
    isFetching: false,
    orders: [],
    closedOrders: [],
    basket,
    isDashboardFetching: false,
    dashboardDeliveryWindows: [],
    recentClosedOrders: [],
    orderIsPending: false,
    orderNotFound: false,
    allClosedOrders: [],
    allOpenOrders: [],
    allClosedOrdersPending: false,
    allOpenOrdersPending: false,
}, action: OrderActions): IOrderState => {
    switch (action.type) {
        case 'GET_ORDER_SUCCESS':
        case 'SET_ORDER_SUCCESS':
            return { ...state, order: action.order }

        case 'GET_BASKET_SUCCESS':
            return { ...state, basket: action.basket }

        case 'GET_OPEN_ORDERS_SUCCESS':
            return { ...state, orders: action.orders }
        
        case 'APPLY_BASKET_CHANGES_SUCCESS':
        case 'RESET_BASKET_CHANGES_SUCCESS':
            const basketChanges = {...state.basket.changes, ...action.changes};
            const newBasket = state.basket;
            newBasket.changes = basketChanges;
            return { ...state, basket: newBasket}

        case 'ORDER_COMPLETED_SUCCESS':
            return { ...state }

        case 'ORDER_IS_PENDING':
            return { ...state, orderIsPending: action.payload }

        case 'GET_SHOPS_SUCCESS':
            return { ...state, shops: action.shops }

        case 'PEAK_NAV_ORDER_SUCCESS':
            return { ...state, navOrders: action.navOrders }

        case 'NAV_ORDER_CLOSED_SUCCESS':
            return { ...state, allClosedOrders: action.orders }
        
        case 'NAV_ORDER_OPEN_SUCCESS':
            return { ...state, allOpenOrders: action.orders }
        
        case 'GET_CUSTOMER_SHIPPING_ADDRESSES_SUCCESS':
            return { ...state, shippingAddresses: action.shippingAddresses }

        case 'SET_FETCHING_SHIPPING':
            return { ...state, isfetchingShippingAdresses: action.isFetching }
        
        case 'SET_FETCHING_ORDER':
            return { ...state, isFetching: action.isFetching }
        
        case 'SET_ORDER_NOT_FOUND':
            return { ...state, orderNotFound: action.payload }

        case 'GET_CLOSED_ORDERS_SUCCESS':
            return { ...state, closedOrders: action.closedOrders }
        
        case 'REMOVE_CLOSED_ORDER_TO_RECENT':
            return { ...state, recentClosedOrders: action.closedOrders }

        case 'DASHBOARD_IS_FETCHING':
            return { ...state, isDashboardFetching: action.isDashboardFetching }
        
        case 'GET_DASHBOARD_DATA_SUCCESS':
            let recentClosedOrdersLocalStorage = localStorage.getItem('recentClosedOrders')
            let localStorageClosedOrders: any[] = [];

            if (recentClosedOrdersLocalStorage) {
                localStorageClosedOrders = JSON.parse(recentClosedOrdersLocalStorage);
            }

            let recentClosedOrders = uniqBy([...state.recentClosedOrders, ...localStorageClosedOrders], 'appOrderId')

            return { ...state,
                dashboardOrder: action.dashboardData.dashboardOrder,
                dashboardDeliveryWindows: [],
                recentClosedOrders
            }

        case 'SET_DASHBOARD_DELIVERY_WINDOWS_SUCCESS':
            return { ...state, dashboardDeliveryWindows: action.dashboardDeliveryWindows}

        case 'CLEAR_ACTIVE_ORDER':
            delete state.order;
            delete state.basket;
            delete state.dashboardOrder;
            return { ...state }

        case 'ADD_CLOSED_ORDER_TO_RECENT':
            recentClosedOrdersLocalStorage = localStorage.getItem('recentClosedOrders')
            localStorageClosedOrders = [];

            if (recentClosedOrdersLocalStorage) {
                localStorageClosedOrders = JSON.parse(recentClosedOrdersLocalStorage);
            }

            recentClosedOrders = uniqBy([...state.recentClosedOrders, ...[action.order], ...localStorageClosedOrders], 'appOrderId')
            return { ...state, recentClosedOrders  }
        
        case 'CLEAR_DASHBOARD':
            delete state.dashboardOrder;
            return { ...state }
        
        case 'ORDER_USER_IS_INFORMED':
            return { ...state, userIsInformed: action.payload }
    }
    
    return state
}

export default combineReducers<State>({
    order
})