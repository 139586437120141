import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'

import ProductList from '../shared/ProductList';
import MoloRingloader from '../layout/MoloRingloader';
// import Style from '../../models/Style';
import Style, { createStyle } from 'molo-shared/lib/models/Style';

// Store.
import { RootState } from '../../store';
import { IUserSate } from '../../store/user/reducers';
import { getCollectionMasters } from '../../store/collections/actions';
import { ICollectionState } from '../../store/collections/reducers';
import { IFilterState } from '../../store/session/reducers';
import { Filter } from '../../models/Filter';
import { resetFilter } from '../../store/session/actions';

interface State {
    products: Style[],
    loading: boolean,
    orderType: string
    collectionName: string
}
  
interface OwnProps {
    match: {params: {collection: string, orderType: string}},
}
  
interface DispatchProps {
    getCollectionMasters: (orderType: string, collectionName: string, currencyCode: string, pricegroup: string) => void;
    resetFilter: (filter: Filter) => void
}
  
interface StateProps {
    userState: IUserSate,
    collectionState: ICollectionState,
    isFetchingCollectionMasters: boolean
    filterState: IFilterState
}
  
type Props = StateProps & OwnProps & DispatchProps;

export class ProductListContainer extends Component<Props, State> {

    state = { products: [], loading: true, collectionName: '', orderType: '' }

    componentDidMount() {
        this.init(this.state);
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        this.init(prevState);
    }

    componentWillUnmount = () => {
        console.log('unmount :: ProductListContainer')
    }

    public static getDerivedStateFromProps(props: Props, state: State) {
        
        if (props.collectionState.styles && props.collectionState.styles.length) {
          return {
              loading: false,
              products: props.collectionState.styles
          }
        }
        return null;
    }

    init(state: State) {
        const collectionName = this.props.match.params.collection;
        const orderType =  this.props.match.params.orderType;
        const currencyCode = this.props.userState.user.currencyCode;
        const pricegroup   = this.props.userState.user.customerData.pricegroup;
        
        if (state.collectionName !== collectionName && !this.props.isFetchingCollectionMasters) {
            this.props.getCollectionMasters(orderType, collectionName, currencyCode, pricegroup);
            if (this.props.filterState.filter) {
                this.props.resetFilter(this.props.filterState.filter)
            }

            setTimeout(() => {
                this.setState({
                    collectionName,
                    orderType,
                });
            })
        }
    }

    render() {
        const { products, loading } = this.state;

        return (
            <div>
                {(loading || this.props.isFetchingCollectionMasters) && <div style={{height: '100vh', width: '100vw', position:'fixed'}}><MoloRingloader /></div>}
                {
                    (!loading && !this.props.isFetchingCollectionMasters) &&
                    <ProductList
                        products={products}
                        lineDiscount={this.props.userState.user.customerData.linediscount}
                        useInfiniteScroll={true}
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
    return {
        userState: states.user.user,
        collectionState: states.collections.collections,
        isFetchingCollectionMasters: states.collections.collections.isFetchingCollectionMasters,
        filterState: states.session.filter,
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
    return {
        getCollectionMasters: async (orderType: string, collectionName: string, currencyCode: string, pricegroup: string) => {
            await dispatch(getCollectionMasters(orderType, collectionName, currencyCode, pricegroup))
            console.log('getCollectionMasters completed [UI]')
        },
        resetFilter: async (filter: Filter) => {
            await dispatch(resetFilter(filter))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ProductListContainer)