import React from 'react';
import Order from '../../models/Order';
import { useSelector } from "react-redux";

import { RootState } from '../../store/';

import { FaAngleUp, FaTrash, FaTimes } from 'react-icons/fa';
import PropagateLoader from 'react-spinners/PropagateLoader'

import BasketDropUpItem from '../b2b/BasketDropUpItem';
import DeleteOrderModal from '../b2b/DeleteOrderModal';

interface IProps {
    activeOrder: Order | null,
    setOrderHandler: Function,
    orders: any[],
    onClickCreateModal: Function
}

const BasketDropUp: React.FC<IProps> = (props) => {
    const node = React.useRef(null);
    const [showDropUp, setValue] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const { activeOrder, setOrderHandler, orders, onClickCreateModal } = props;
    const isFetchingOrder = useSelector((state: RootState) => state.order.order.isFetching);

    React.useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClickOutside);
        // return function to be called when unmounted
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (e: any) => {

        // @ts-ignore
        if(node.current.contains(e.target)) {
            return;
        }
        
        // outside click 
        setValue(false)
    };

    return (
        <div className="dropup" ref={node}>
            {
                !isFetchingOrder && activeOrder &&
                <React.Fragment>
                    <div className="dropup__actions">
                        {activeOrder &&
                            <span onClick={ () => setShowDeleteModal(true) } className="dropup__actions__item clickable">
                                {
                                    activeOrder && activeOrder.orderheader.orderIsFinal ?
                                    <FaTimes size="24px" /> : <FaTrash size="18px" />
                                }
                            </span>
                        }
                    </div>
                    <div className="dropup-trigger" onClick={() => setValue(!showDropUp)}>
                        { activeOrder && activeOrder.orderheader.orderState === 0 && activeOrder.orderheader.navImportRef
                        && <span className="order-dropdown__label --completed" style={{marginLeft: '26px', top: '0'}}>Access to edit</span>}
                        { activeOrder && activeOrder.orderheader.orderState === 1 && activeOrder.orderheader.navImportRef
                        && <span className="order-dropdown__label --completed" style={{marginLeft: '26px', top: '0'}}>Approved order</span>}
                        { activeOrder && activeOrder.orderheader.appOrderId && 'B2B' + activeOrder.orderheader.appOrderId}
                        { activeOrder && activeOrder.orderheader.sellToCustomerName && ' ' + activeOrder.orderheader.sellToCustomerName}
                        { activeOrder && activeOrder.orderheader.orderType && ' ' + activeOrder.orderheader.orderType}
                        { activeOrder && activeOrder.orderheader.navImportRef && ' ' +  activeOrder.orderheader.navImportRef}
                        { activeOrder && activeOrder.orderheader.salespersonNote && ' ' + activeOrder.orderheader.salespersonNote }
                        {
                            orders.length > 0 ?
                            <span className="dropup-trigger__icon">
                                <FaAngleUp />
                            </span> : null
                        }
                    </div>
                </React.Fragment>
            }
            {
                !isFetchingOrder && !activeOrder && orders.length ?
                <div className="dropup-trigger" onClick={() => setValue(!showDropUp)}>
                    <span style={{ paddingLeft: '30px' }}>Select order</span>
                    <span className="dropup-trigger__icon">
                        <FaAngleUp />
                    </span>
                </div> : null
            }
            {activeOrder && <DeleteOrderModal
                show={showDeleteModal}
                shouldHide={() => {
                    setShowDeleteModal(false)
                }}
                orderId={activeOrder.orderheader.appOrderId}
                order={activeOrder.orderheader}
            />}

            {
                isFetchingOrder &&
                    <div className="dropup-trigger">
                    <div className="spinner">
                        <PropagateLoader
                            color="white"
                        />
                    </div>
                </div>
            }

            <div className={"dropup-menu " + (showDropUp ? "--is-active" : "--is-hidden")}>
                {
                    <div className="dropup-menu__items">
                        {
                            orders.map((order, key) => {
                                if (activeOrder && order.appOrderId === activeOrder.orderheader.appOrderId) {
                                    return null;
                                }

                                return <BasketDropUpItem
                                            key={key}
                                            order={order}
                                            hide={() => setValue(false)}
                                            onClickHandler={(order: any) => {
                                                setValue(false)
                                                setOrderHandler(order)
                                            }} />
                            })
                        }
                    </div>
                }
                <div className="dropup-menu__item --fixed">
                    <span onClick={() => onClickCreateModal()}>
                        Create new order
                    </span>
                </div>
            </div>
        </div>
    )
}

export default BasketDropUp;