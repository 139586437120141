import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import MoloRingloader from '../../components/layout/MoloRingloader';
import { ICollectionState  } from '../../store/collections/reducers'

interface State {
    imagesLoaded: number
    imagesIsLoded: boolean
    initialized: boolean
    sortedCollections?: any[]
}

interface Props {
    collections: any[],
    orderType: string,
    isFetching: boolean
};

export class CollectionChooser extends Component<Props, State> {

    state: State = {
        imagesIsLoded: false,
        imagesLoaded: 1,
        initialized: false,
        sortedCollections: []
    }

    imageDoneLoading = () => {
        if (!this.props.collections) return

        let imagesLoaded = this.state.imagesLoaded;
        imagesLoaded++;

        this.setState({
            imagesIsLoded: this.props.collections.length <= imagesLoaded,
            imagesLoaded
        });
    }

    isOdd(num: number) { return num % 2;}

    render() {
        const { orderType } = this.props;
        const basepath = () => { 
            if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1"){
                return "https://dev2b.molo.com";
            }
            return '';
        };
    
        const imageOnErrorHandler = (
            event: React.SyntheticEvent<HTMLImageElement, Event>
          ) => {
            event.currentTarget.src = basepath() + "/media/static/404.gif";
          };

        const getImage = (type: string, name: string) => {
            const banner = basepath() + "/media/static/banner/" + type.replaceAll(' ','_') + "@" + name.replaceAll(' ','_') + ".jpg";
            return (
                <img
                    src={banner}
                    alt={name}
                    className="card-list__item__image"
                    onLoad={this.imageDoneLoading}
                    onError={imageOnErrorHandler}
                />
            )
        }

        if ((this.props.isFetching)) {
            return <div style={{height: '100vh', width: '100vw', position: 'fixed'}}>
                <MoloRingloader />
            </div>
        }

        return (
            <>
                <div
                    className={"card-list__wrapper " + ((this.props.isFetching || !this.state.imagesIsLoded) ? "" : "fade-in")}
                    style={{visibility: (this.props.isFetching || !this.state.imagesIsLoded) ? 'hidden' : 'visible'}}
                >
                    <ul className={"card-list" + ((this.props.collections && this.props.collections.length > 2) ? ' card-list--small' : '')}>
                        {
                            this.props.collections && this.props.collections.map((collection, key) => {
                                return collection.collectionName && <li key={collection.collectionName} className="card-list__item">
                                    <Link
                                        to={`/shop/${orderType}/${collection.collectionName}`}
                                        className="card-list__item__link"
                                    >
                                        
                                        { getImage(orderType, collection.collectionName) }
                                        
                                        <div className="card-list__item__title">
                                            {collection.collectionName || '?!?'}
                                        </div>
                                    </Link>
                                </li>
                                }
                            )
                        }
                        {/* {
                            this.props.collections && this.isOdd(this.props.collections.length) ?
                            <li className="card-list__item">
                                <div className="card-list__item__link">
                                    <img
                                        src={getImage()}
                                        alt={'missing placeholder'}
                                        className="card-list__item__image"
                                    />
                                </div>
                            </li>
                            :
                            null
                        } */}
                    </ul>
                </div>
            </>
        )
    }
}

export default CollectionChooser
