import { clone } from 'lodash';

export function checkMonthGaps(months:any[], data: any, monthNames:any[], deliveryWindows:any[]) {
    const newMonths = [];

    deliveryWindows.sort(function compare(a, b) {
      const dateA: any = new Date(a.shippingDate);
      const dateB: any = new Date(b.shippingDate);
      return dateA - dateB;
    });

    for (const i in months) {
      const month = months[i];
      const currentMonth = data[month];
      const nextMonth = data[months[+i + 1]];
      let diff = 0;

      if (typeof nextMonth !== 'undefined') {
        diff = monthDiff(new Date(currentMonth.shippingDate), new Date(nextMonth.shippingDate));
      }

      newMonths.push(month);

      const newMonthDate = new Date(currentMonth.shippingDate);
      let newMonthYear = newMonthDate.getFullYear();
      for (let y = 0; y < diff; y++) {
        const monthNumber = newMonthDate.getMonth() + y + 1;
        const newMonth = monthNames[monthNumber % 12];

        if (monthNumber % 12 === 0) {
          newMonthYear = newMonthYear + 1;
        }

        const label = newMonth + ' ' + newMonthYear;

        newMonths.push(label);
      }
    }

    return newMonths;
}

export function addMinmumMonths(months: any[], data: any[], monthNames: any[], deliveryWindows:any) {

  if (!deliveryWindows.length) return []
  
  const minMonths = 6;

  deliveryWindows.sort(function compare(a:any, b:any) {
    const dateA:any = new Date(a.shippingDate);
    const dateB:any = new Date(b.shippingDate);
    return dateA - dateB;
  });

  const firstMonth = deliveryWindows[0].shippingDate;
  const lastMonth = deliveryWindows[deliveryWindows.length - 1].shippingDate;
  let reneDiff = monthDiff(new Date(firstMonth), new Date(lastMonth))

  if (months.length === 1) {
    // Add at least the minimum amount of months
  // to the calendar.
  if (months.length < minMonths) {
    const monthsToAdd = minMonths - months.length;
    const newMonthDate = new Date(deliveryWindows[deliveryWindows.length - 1].shippingDate);
    // @ts-ignore
    let nmy = newMonthDate.getFullYear();

    for (let y = 0; y < monthsToAdd; y++) {
      const monthNumber = newMonthDate.getMonth() + y + 1;
      const newMonth = monthNames[monthNumber % 12];

      if (monthNumber % 12 === 0) {
        nmy = nmy + 1;
      }

      const label = newMonth + ' ' + nmy;

      if (months.indexOf(label) === -1) {
          months.push(label);
      }
    }
  }
  } else {
    let newMonths: any[] = [];
    const start = new Date(deliveryWindows[0].shippingDate);
    let nextMonth: Date = start;

    if (reneDiff < minMonths) {
      reneDiff = minMonths;
    }

    for (let y = 0; y < reneDiff; y++) {
      
      const otherClone = clone(nextMonth)
      let nmy = otherClone.getFullYear();
      const monthNumber = clone(otherClone).getMonth();
      const newMonth = monthNames[monthNumber % 12];

      const label = newMonth + ' ' + nmy;

      if (newMonths.indexOf(label) === -1) {
        newMonths.push(label);
      }

      // Add it.
      nextMonth = new Date(otherClone.setMonth(clone(otherClone).getMonth() + 1, 1));
    }

    months = newMonths;
  }

  return months;
}

export function monthDiff(d1:any, d2:any) {
    let months = null;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth() + 1;
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}

export function positionDrops(orderDrops:any, months:any) {
    const drops = { ...orderDrops };
    interface IDropByPostition {
      [id: string]: any[],
    };

    let dropsSorted: {[id: string]: IDropByPostition} = {};

    for (const i in months) {
      const month = months[i];
      let dropsSortedByPosition: IDropByPostition = {1: [], 2: [], 3: [], 4: [], 5: []};
      dropsSorted[month] = dropsSortedByPosition;

      if (drops[month]) {
        const dropsForMonth = drops[month];
        const y = new Date(dropsForMonth[0].shippingDate).getFullYear().toString();
        const m = new Date(dropsForMonth[0].shippingDate).getMonth();
        const weekcount = getWeekCount(y, m);
        const weeksInMonth = getWeeksInMonth(y, m);
        // const numberOfWeeks = weekcount[1] - weekcount[0];

        for (const key in dropsForMonth) {
          
          const drop = dropsForMonth[key];
          let weekNumber = getWeekNumber(new Date(drop.shippingDate));

          
        
          // Total edge case.
          if (weekNumber === 1 && weekcount[1] === 53) {
            weekNumber = 53;
          }

          const position = weeksInMonth - (weekcount[1] - weekNumber);

          drop.position = position;

          if (dropsSortedByPosition && !dropsSortedByPosition[drop.position]) {
            dropsSortedByPosition[drop.position] = [];
          }
          dropsSortedByPosition[drop.position].push(drop);
        }

        dropsSorted[month] = dropsSortedByPosition;
      }
    }

    return dropsSorted;
}

export function getWeekCount(year:any, month:any) {
    // // month_number is in the range 1..
    year = year || new Date().getFullYear();
    const yearStart:any = new Date(year,0,1); // 1st Jan of the Year

    const first_day_of_month:any = new Date(year, month, 1);
    const first_week_number = Math.ceil(( ( (first_day_of_month - yearStart) / 86400000) +yearStart.getDay()+ 1)/7);

    const last_day_of_month:any = new Date(year, month+1, 0); // Last date of the Month
    const last_week_number = Math.ceil(( ( (last_day_of_month - yearStart) / 86400000) +yearStart.getDay()+ 1)/7);

    return  [first_week_number, last_week_number];
}


function getWeeksInMonth(year: any, month_number: any) {

  // month_number is in the range 1..12

  var firstOfMonth = new Date(year, month_number-1, 1);
  var lastOfMonth = new Date(year, month_number, 0);

  var used = firstOfMonth.getDay() + 6 + lastOfMonth.getDate();

  return Math.ceil( used / 7);
}

export function getWeekNumber(d:any) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    // Get first day of year
    const yearStart:any = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    const weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);

    return weekNo;
}

export function formatDate(dateObj:any, format?:string) {
    if (!isValidDate(dateObj)) { return 'Invalid Date'; }

    const day = pad(dateObj.getDate().toString());
    const month = pad((dateObj.getMonth() + 1).toString());
    const year = dateObj.getFullYear().toString();

    switch (format) {
        case 'YYYY-MM-DD':
            return year + '-' + month + '-' + day;
        case 'DD.MM.YYYY':
            return day + '.' + month + '.' + year;
        default:
            return day + '/' + month + ' - ' + year;
    }
}

export function isValidDate(dateObj: any) {
    return dateObj instanceof Date && !isNaN(dateObj.getTime()) && new Date().getFullYear().toString().length === 4;
}

export function pad(n: any) {
    return n < 10 ? '0' + n : n;
}