import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import OrderApi from '../../store/order/OrderApi';
import { RootState } from '../../store/';
import { ShippedCalendar } from './ShippedCalendar';
import SidebarMenu from '../layout/SidebarMenu';
import { appConfig } from '../../config';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import { ShippedItemsOverview } from './ShippedItemsOverview';
import { filterByValue } from 'molo-shared/lib/utils/UtilHelper';
import Dropdown from '../layout/Dropdown';
import { FaTimes } from 'react-icons/fa';
import DashboardOverlay from '../b2b/DashboardOverlay';
import { Spinner } from "@blueprintjs/core";

interface ISize {
    sizename: string,
    quantity: number
}

interface IProps {
    show: boolean
}

interface IOrderTableRowProps {
    order: any
    expanded: boolean
    onClickOrder?: Function
    onHandleClickBSL: Function
    onClickViewOrder?: Function
}

const OrderTableRow : React.FC<IOrderTableRowProps> = (props) => {
    const { order, expanded, onClickOrder, onHandleClickBSL, onClickViewOrder } = props;
    const openOrders = useSelector((state: RootState) => state.order.order.orders);
    const status = order.status;

    let statusClass = 'status-badge--not-shipped';

    if (status !== 'Not Shipped') {
        statusClass = status === 'Fully Shipped' ? 'status-badge--fully' : 'status-badge--partial';
    }

    const onClickOrderHandler = () => {
        onClickOrder && onClickOrder(order)
    }

    const isAlreadyOpen = (order: any) => {
        var existing = openOrders.find(function(item: any) {
            return item.navorderid === order.navorderid;
        });

        return existing;
    }
   
    return (
        <>
            <tr
                onClick={() => {
                    onClickOrderHandler()
                }}
                className={`${expanded ? 'active' : ''}`}
            >
                <td style={{ paddingLeft: '20px' }}>
                    <div
                        className={`table-expander ${expanded ? 'active' : ''}`}>
                            <FaTimes/>
                    </div>
                    
                    {new Date(order.orderdate).toLocaleDateString()}
                </td>
                <td>{order.orderidcreated}</td>
                <td>{order.navorderid}</td>
                <td>
                    <span>{order.shipttoname}</span>
                </td>
                <td>{order.ordertype}</td>
                <td>
                    {order.externaldocumentno}
                </td>
                <td>{order.salespersonnote}</td>
                <td>
                    <div className={`status-badge ${statusClass}`}>
                        { status }
                        {order.nooftrackingcodes && order.nooftrackingcodes > 0 ? <div className="has-tracking">tracking</div> : null}
                    </div>
                </td>
                <td>
                    {
                        order.inscanner && !isAlreadyOpen(order) &&
                        <span
                            className="item-list__item__blocked">
                            In scanner
                        </span>
                    }
                    {
                        '' !== order.beingmodifiedby && !isAlreadyOpen(order) &&
                        <span
                        className="item-list__item__blocked">
                            Being modified by : { order.beingmodifiedby }
                        </span>
                    }
                    {
                        isAlreadyOpen(order) &&
                        <span
                        className="item-list__item__blocked">
                            Already open!
                        </span>
                    }
                    { 
                        !order.isFullyShipped 
                        &&
                        <span className="to__button" onClick={(e) => { 
                            e.stopPropagation();
                            onClickViewOrder && onClickViewOrder(order)
                        }}>
                            View order
                        </span>
                    }
                </td>
            </tr>
            {expanded && <tr className="hover-white">
                <td colSpan={9} className="no-padding expanded">
                    {/* <div>
                        <h2 className="text-xl p-2">Shipments</h2>
                    </div> */}
                    <ShippedCalendar
                        order={ order }
                        onHandleClickBSL={onHandleClickBSL}
                    />
                </td>
            </tr>}
        </>
    )
}

export const ShippedOrdersOverlay: React.FC<IProps> = (props) => {
    const user = useSelector((state: RootState) => state.user.user);
    const [orders, setOrders] = useState<any[]>([])
    const [activeOrder, setActiveOrder] = useState<any | undefined>(undefined)
    const [selectedBSLItems, setSelectedBSLItems] = useState<any | undefined>(undefined)
    const [showSidebar, setShowSidebar] = useState(false);
    const [isFetching, setIsFetching] = useState(false)
    const [selectedShop, setSelectedShop] = useState<any | undefined>(undefined)
    const [isFetchingItems, setIsFetchingItems] = useState(false)
    let [searchString, setSearchString] = React.useState<string>('');
    const [initialized, setinitialized] = useState(false)
    const [orderTypes, setOrderTypes] = useState<any[]>(['REORDER', 'PRE-ORDER'])
    let [selectedOrderType, setSelectedOrderType] = useState<null | string>(null)
    const [selectedStatus, setSelectedStatus] = useState<null | string>(null)
    const [allStatus, setAllStatus] = useState(['Fully Shipped', 'Partially Shipped', 'Not Shipped'])
    const [filteredItems, setFilteredItems] = useState<any[]>([])
    const [activeOrderIds, setActiveOrderIds] = useState<any[]>([])
    const [showDashOverlay, setShowDashOverlay] = useState(false)
    const [activeViewOrder, setActiveViewOrder] = useState<any | undefined>(undefined)

    const { show } = props;

    useEffect( () => {
        if (!isFetching && !initialized && show) {
            setinitialized(true)
            getInitialShop()
        }
    }, [])

    useEffect( () => {
        filterItems()
    }, [selectedOrderType, selectedStatus])

    const init = async (customerid: number, allShops: boolean = false) => {
        setActiveOrder(undefined)
        setIsFetching(true)
        setOrders([])
        const requests: any[] = [
            OrderApi.peakNavOrdersNew(+customerid, true, allShops),
            OrderApi.peakNavCustomerShipments(+customerid, allShops)
        ]

        const responses = await axios.all(requests);

        const peakNavordersResponse = responses[0].data.map((item: any) => {

            item.status = 'Not Shipped'

            if (item.orderQuantity === item.outstandingQuantity) {
                item.status = 'Not Shipped'
            }

            if (item.outstandingQuantity > 0 && item.orderQuantity > item.outstandingQuantity) {
                item.status = 'Partially Shipped'
            }

            if (item.outstandingQuantity === 0) {
                item.status = 'Fully Shipped'
            }

            return item
        }) 
        const shipmentsResponse = responses[1]

        const allOrders: any[] = [ ...peakNavordersResponse , ...shipmentsResponse.data.map((item: any) => {
            item.status = 'Fully Shipped'
            item.isFullyShipped = true;
            return item
        }) ]

        setIsFetching(false)
        setOrders(allOrders)
        setFilteredItems( [ ...allOrders ])
    }

    const getInitialShop = async () => {

        setSelectedShop({
            customerId: user.user.customerData.CustomerId,
            name: user.user.customerData.Name,
        })

        init(+user.user.customerData.CustomerId, user.user.customerData.InvoiceCustomer)
    }

    const filterItems = () => {
        let items = [ ...orders ];

        if (selectedOrderType) {
            if (selectedOrderType === 'REORDER') {
                items = items.filter(order => order.ordertype === 'REORDER')
            } else {
                items = items.filter(order => order.ordertype !== 'REORDER')
            }
            
        }

        if (selectedStatus) {
            if (selectedStatus === 'Fully Shipped') {
                items = items.filter(order => order.status === 'Fully Shipped')
            } else if (selectedStatus === 'Partially Shipped') {
                items = items.filter(order => order.status === 'Partially Shipped')
            } else if (selectedStatus === 'Not Shipped') {
                items = items.filter(order => order.status === 'Not Shipped')
            }
            
        }

        setFilteredItems(items)
    }

    const handleOrderClick = (order: any) => {
        if (activeOrderIds.indexOf(order.navorderid) === -1) {
            setActiveOrder(activeOrderIds.push(order.navorderid))
        } else {
            let orders = activeOrderIds;
            let indexOf = activeOrderIds.indexOf(order.navorderid);
            var spliced = orders.splice(indexOf, 1);
            setActiveOrder(spliced)
        }
    }

    const onClickViewOrder = async (order: any) => {
        setIsFetching(true)

        if (!order.appOrderId && order.navorderid) {
            const navOrderResponse = await OrderApi.openNavOrder(order.navorderid)
            let createdapporderid = navOrderResponse.data.createdapporderid
            navOrderResponse.data.appOrderId = navOrderResponse.data.createdapporderid
            let response = await OrderApi.getOrder(createdapporderid)
            setActiveViewOrder(response.data)
            setShowDashOverlay(true)
            setIsFetching(false)
        }
    }
    
    const onHandleClickBSL = async (order: any, drop: any) => {

        setIsFetchingItems(true)

        setShowSidebar(true)

        if (drop.bsl) {
            const response = await OrderApi.peakNavShipmentItems(drop.bsl)

            setSelectedBSLItems(cloneDeep(response.data))
        } else {
            const response = await OrderApi.peakNavOrderUnshippedItems(order.navorderid, drop.deliveryCode)

            setSelectedBSLItems(cloneDeep(response.data))
        }

        setIsFetchingItems(false)
    }

    const filterByOrderType = (type: string) => {
        setSelectedOrderType(type === 'all' ? null : type)
    }

    const filterByOrderStatus = (status: string) => {
        setSelectedStatus(status === 'all' ? null : status)
    }

    const renderNavOrderTable = (orders: any) => {
        return (
            <table className="striped-table table-fixed table-hover completed-orders-table text-xs mb-32">
                <thead>
                    <tr>
                        <th style={{ width: '100px' }}>Order date</th>
                        <th>Order number</th>
                        <th>Molo Ref. Number</th>
                        <th>Shop</th>
                        <th>Type</th>
                        <th>Ext. Doc No</th>
                        <th>Order Comment</th>
                        <th style={{ width: '160px' }}>Status</th>
                        <th style={{ width: '100px' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        
                        filterByValue(filteredItems, searchString, [
                            'ordertype',
                            'navorderid',
                            'shipttoname',
                            'externaldocumentno',
                            'salespersonnote',
                            'orderidcreated',
                        ]).sort((a: any, b: any) => new Date(b.orderdate).valueOf()  - new Date(a.orderdate).valueOf())
                        .map((order: any, key: any) => {
                            return (
                                <OrderTableRow
                                    key={key}
                                    order={ order }
                                    expanded={ activeOrderIds.indexOf(order.navorderid) !== -1 }
                                    onClickOrder={ handleOrderClick }
                                    onHandleClickBSL={onHandleClickBSL}
                                    onClickViewOrder={onClickViewOrder}
                                />
                            )
                        })
                    }
                </tbody>
            </table>
        )
    }

    const renderSidebarMenuContent = () => {
        if (!selectedBSLItems) return null
        const total = selectedBSLItems.reduce((sum: number, li: any) => sum + li.quantity, 0)

        const orderlines = selectedBSLItems.map((item: any) => {
            item.quantity = item.qty
            item.deliveryCode = item.deliverycode
            return item
        })

        if (!isFetchingItems && !orderlines.length) {
            return (
                <div className="h-full flex justify-center pt-16">
                    <h1 className="text-2xl">No Items on this shipment!</h1>
                </div>
            )
        }

        return (
            <>
                <ShippedItemsOverview
                    orderLines={ selectedBSLItems }
                    order={ {
                        orderheader: activeOrder,
                        orderLines: orderlines,
                        orderTotals: {
                            // Should be modified.
                            noOfItems: +total,

                            // Should be modified / hide.
                            amount: 199,
                        }
                    } }
                />
            </>
        )
    }

    const onCloseSidebar = () => {
        setShowSidebar(false)
        setSelectedBSLItems([])
    }

    const renderContent = () => {
        return (
            <>
                <div className='h-full flex flex-col'>
                    {
                        isFetching ?
                            <div className="big-spinner"><Spinner /></div>
                            : null
                    }
                    <div className="grid grid-cols-3 gap-10">
                        <div className="form-control">
                            <input
                                type="text"
                                name="search"
                                placeholder='Search'
                                onChange={(event: any) => {
                                    const value = event.target.value

                                    setSearchString(value || '')
                                }}
                            />
                        </div>
                        <div className="form-control">
                            <Dropdown
                                items={ orderTypes }
                                value={selectedOrderType}
                                identifier={'$index'}
                                placeholder='Filter by type'
                                allLabel='Show all'
                                onSelect={filterByOrderType}
                                allOption={true}
                            /> 
                        </div>
                        <div className="form-control">
                            <Dropdown
                                items={ allStatus }
                                value={selectedStatus}
                                identifier={'$index'}
                                placeholder='Filter by status'
                                allLabel='Show all'
                                onSelect={filterByOrderStatus}
                                allOption={true}
                            /> 
                        </div>
                    </div>
                    <div className="overflow-auto">
                        { renderNavOrderTable(orders) }
                    </div>
                    {
                        selectedShop && !orders.length && !isFetching &&
                        <div className=""><h1 className="text-5xl">No orders for this customers</h1></div>
                    }
                </div>
                <SidebarMenu
                    isShowing={ showSidebar }
                    onClose={ onCloseSidebar }
                >
                    { renderSidebarMenuContent() }
                </SidebarMenu>
                <DashboardOverlay
                    isShowing={ showDashOverlay }
                    onHide={() => setShowDashOverlay(false)}
                    order={activeViewOrder}
                />
            </>
        )
    }

    return (
        <>
            { renderContent() }
        </>
    )
}