import React, { Component } from 'react'

import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import {
    IGrouping,
} from './dashboard.d';

interface chartData {
    name: string,
    y: number,
}

interface State {
    chartQuantifier: 'quantity' | 'revenue',
    chartOptions: any,
    chartOptionsDesktop: any,
    initialized: boolean
}
  
interface OwnProps {
    data: any[],
    orderId: number,
    selectedCategory: string,
    productSortedByCategory: IGrouping,
    currencyCode: string
}
  
interface DispatchProps {}
  
interface StateProps {
}

type Props = StateProps & OwnProps & DispatchProps;

export class ChartSummaryHighcharts extends Component<Props, State> {
    state: State = {
        chartQuantifier: 'quantity',
        chartOptions: {},
        chartOptionsDesktop: {},
        initialized: false,
    }

    componentDidMount() {
        if ((!this.state.initialized && this.props.selectedCategory) && Object.keys(this.props.productSortedByCategory).length) {
            setTimeout(() => this.drawChart(), 200)
        }
    }

    componentDidUpdate(prevProps: Props) {
        if ((this.props.selectedCategory !== prevProps.selectedCategory)) {
            setTimeout(() => this.drawChart(), 200)
        } else if (this.props.orderId !== prevProps.orderId) {
            setTimeout(() => this.drawChart(), 200)
        }
    }

    drawChart = () => {
        let data: chartData[] = [];
        
        for (const key in this.props.productSortedByCategory[this.props.selectedCategory]) {
            const subGroup = this.props.productSortedByCategory[this.props.selectedCategory][key];
            data.push({name: subGroup.title, y: +subGroup[this.state.chartQuantifier].toFixed(2)});
        }

        const ref = this;

        const chartOptions = {
            chart: {
                type: 'pie',
                backgroundColor: '#000',
                className: 'highchart-container',
                style: {
                    fontColor: '#fff'
                }
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    animation: {
                        duration: 750,
                        easing: 'easeOutQuad'
                    },
                    shadow: false,
                    borderWidth: 0,
                    center: ['50%', '50%'],
                    cursor: 'pointer',
                    showInLegend: true,
                    dataLabels: {
                        enabled: true,
                        // inside: true,
                        align: 'center',
                        distance: -15,
                        crop: false,
                        borderWidth: 0,
                        style: {
                            color: 'white',
                            fontSize: '10px',
                        },
                        formatter: function() {
                            var self: any = this;
                            if (ref.state.chartQuantifier === 'revenue') {
                                return self.point.y + ' ' + ref.props.currencyCode;
                            }
                            return self.point.y;
                        }
                    },
                    colors: [
                        '#FF1529',
                        '#FF961B',
                        '#F4CE00',
                        '#7CB43C',
                        '#708739',
                        '#5B3716',
                        '#FF961B',
                        '#8A29EA',
                        '#18D43F',
                        '#FDFD15',
                        '#DDA300',
                        '#1A82DE',
                    ]
                }
            },
            legend: {
                align: 'left', 
                layout: 'vertical',
                verticalAlign: 'top',
                x: 0,
                y: 0,
                margin:0,
                itemStyle: {
                    color: '#fff',
                    fontSize: '11px',
                },
                itemMarginBottom: 5,
            },
            title: {
                text: '',
                style: {
                    color: '#fff'
                }
            },
            series: [{
                animation: {
                    duration: 750,
                    easing: 'easeOutQuad'
                },
                name: '',
                size: '100%',
                // innerSize: '55%',
                data
            }]
        };

        const chartOptionsDesktop = {
            chart: {
                type: 'pie',
                backgroundColor: '#000',
                className: 'highchart-container',
                style: {
                    fontColor: '#fff'
                }
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    animation: {
                        duration: 750,
                        easing: 'easeOutQuad'
                    },
                    shadow: false,
                    borderWidth: 0,
                    center: ['50%', '50%'],
                    cursor: 'pointer',
                    showInLegend: true,
                    dataLabels: {
                        enabled: true,
                        inside: true,
                        softConnector: false,
                        borderWidth: 0,
                        shadow: false,
                        style: {
                            color: '#fff'
                        },
                        formatter: function() {
                            var self: any = this;
                            if (ref.state.chartQuantifier === 'revenue') {
                                return self.point.y + ' ' + ref.props.currencyCode;
                            }
                            return self.point.y;
                        }
                    },
                    colors: [
                        '#FF1529',
                        '#FF961B',
                        '#F4CE00',
                        '#7CB43C',
                        '#708739',
                        '#5B3716',
                        '#FF961B',
                        '#8A29EA',
                        '#18D43F',
                        '#FDFD15',
                        '#DDA300',
                        '#1A82DE',
                    ]
                }
            },
            legend: {
                align: 'left',
                layout: 'vertical',
                verticalAlign: 'top',
                x: 0,
                y: 0,
                itemStyle: {
                    color: '#fff',
                    fontSize: '14px'
                }
            },
            title: {
                text: '',
                style: {
                    color: '#fff'
                }
            },
            series: [{
                animation: {
                    duration: 750,
                    easing: 'easeOutQuad'
                },
                name: '',
                size: '85%',
                innerSize: '55%',
                data
            }]
        };

        this.setState({
            chartOptions,
            chartOptionsDesktop,
            initialized: true
        })
    }

    toggleQuantitfier() {
        const chartQuantifier = this.state.chartQuantifier === 'quantity' ? 'revenue' : 'quantity';

        this.setState({chartQuantifier}, () => {
            this.drawChart();
        });
    }

    render() {
        return (
            <>
                <div className="mobile-only" style={{width: '100%', height: '100%'}}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={this.state.chartOptions}
                        className='dkjsdekjs'
                    />
                </div>
                <div className="desktop-only" style={{width: '100%', height: '100%'}}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={this.state.chartOptionsDesktop}
                        className='dkjsdekjs'
                    />
                </div>
                <div className="dashboard-chart__toggler">
                    <div className="a-form-control a-form-control-toggle">
                        <div className="a-form-control-toggle__option a-form-control-toggle__option--left">
                            Quantity
                        </div>
                        <div className="a-form-control-toggle__control">
                            <input
                                type="checkbox"
                                checked={this.state.chartQuantifier === 'revenue'}
                                className="a-form-control-toggle__checkbox"
                                onChange={() => this.toggleQuantitfier()}
                            />
                            <label className="a-form-control-toggle__knob"></label>
                        </div>
                        <div className="a-form-control-toggle__option a-form-control-toggle__option--right">
                            Amount
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ChartSummaryHighcharts;