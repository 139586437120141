import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import OrderApi from './OrderApi';
import Order from '../../models/Order';
import { Basket, compileBasket, basketLine } from '../../models/Basket';
import { setAlert } from '../alert/actions'


import {
    IGetOrder,
    ISetOrder,
    ICreateOrder,
    IGetOpenOrders,
    IGetClosedOrders,
    IGetBasket,
    IApplyChanges,
    IApplyBasketChanges,
    IResetBasketChanges,
    IGetShops,
    IPeakNavOrders,
    IOpenNavOrder,
    IGetCustomerShippingAdresses,
    IIsfetchingShippingAdresses,
    ISetFetching,
    IDashboardIsFetching,
    IGetDashboardData,
    IDashboardState,
    ISetDashboardDeliveryWindows,
    ISetOrderDeleted,
    IClearOrder,
    IAddRecentClosedOrder,
    IRemoveClosedOrderFromRecent,
    IApplyOrder,
    IOrderIsPending,
    ISetOrderNotFound,
    IClearDashboard,
    IUserIsInformed,
    INavOrdersOpen,
    INavOrdersClosed,
    IOrdersFullyShipped,
    IAllClosedOrdersIsPending,
    IAllOpenOrdersIsPending,
} from './orderStore';

import * as helper from '../../components/shared/Dashboard/DashboardHelper';

// import DeliveryWindows from '../../components/shared/Dashboard/DeliveryWindows';

// Action Creators
export const getOrderSucces = (order: Order): IGetOrder => {
    return { type: 'GET_ORDER_SUCCESS', order }
}

export const getDashboardOrderStateSuccess = (dashboardData: IDashboardState): IGetDashboardData => {
    return { type: 'GET_DASHBOARD_DATA_SUCCESS', dashboardData}
}

export const clearDashBoard = (): IClearDashboard => {
    return { type: 'CLEAR_DASHBOARD'}
}

export const getShopsSuccess = (shops: any[]): IGetShops => {
    return { type: 'GET_SHOPS_SUCCESS', shops }
}

export const setOrderSucces = (order: Order): ISetOrder => {
    return { type: 'SET_ORDER_SUCCESS', order }
}

export const addClosedOrderToRecent = (order: any): IAddRecentClosedOrder => {
    return { type: 'ADD_CLOSED_ORDER_TO_RECENT', order }
}

export const removeClosedOrderFromRecentSuccess = (closedOrders: any[]): IRemoveClosedOrderFromRecent => {
    return { type: 'REMOVE_CLOSED_ORDER_TO_RECENT', closedOrders }
}

export const setDeliveryWindowsSuccess = (dashboardDeliveryWindows: any[]): ISetDashboardDeliveryWindows => {
    return { type: 'SET_DASHBOARD_DELIVERY_WINDOWS_SUCCESS', dashboardDeliveryWindows }
}

export const getOpenOrdersSuccess = (orders: any[]): IGetOpenOrders => {
    return { type: 'GET_OPEN_ORDERS_SUCCESS', orders }
}

export const getClosedOrdersSuccess = (closedOrders: any[]): IGetClosedOrders => {
    return { type: 'GET_CLOSED_ORDERS_SUCCESS', closedOrders }
}

export const peakNavOrdersSuccess = (navOrders: any[]): IPeakNavOrders => {
    return { type: 'PEAK_NAV_ORDER_SUCCESS', navOrders }
}

export const navOrdersClosedSuccess = (orders: any[]): INavOrdersClosed => {
    return { type: 'NAV_ORDER_CLOSED_SUCCESS', orders }
}

export const navOrdersOpenSuccess = (orders: any[]): INavOrdersOpen => {
    return { type: 'NAV_ORDER_OPEN_SUCCESS', orders }
}

export const fullyShippedOrdersSuccess = (orders: any[]): IOrdersFullyShipped => {
    return { type: 'ORDERS_FULLY_SHIPPED_SUCCESS', orders }
}

export const openNavOrderSuccess = (navOrder: any): IOpenNavOrder => {
    return { type: 'OPEN_NAV_ORDER_SUCCESS', navOrder }
}

export const createOrderSucces = (order: Order): ICreateOrder => {
    return { type: 'CREATE_ORDER_SUCCESS', order }
}

export const getBasketSuccess = (basket: Basket): IGetBasket => {
    return { type: 'GET_BASKET_SUCCESS', basket }
}

export const applyChangesSuccess = (): IApplyChanges => {
    return { type: 'APPLY_CHANGES_SUCCESS' }
}

export const applyBasketChangesSuccess = (changes: basketLine): IApplyBasketChanges => {
    return { type: 'APPLY_BASKET_CHANGES_SUCCESS', changes }
}

export const resetBasketChangesSuccess = (): IResetBasketChanges => {
    return { type: 'RESET_BASKET_CHANGES_SUCCESS', changes: {} }
}

export const getCustomerShippingAddressesSuccess = (addresses: any[]): IGetCustomerShippingAdresses => {
    return { type: 'GET_CUSTOMER_SHIPPING_ADDRESSES_SUCCESS', shippingAddresses:  addresses }
}

export const setIsFetchingShippingAddresses = (isFetching: boolean): IIsfetchingShippingAdresses => {
    return { type: 'SET_FETCHING_SHIPPING', isFetching }
}

export const setIsDashboardFetching = (isDashboardFetching: boolean): IDashboardIsFetching => {
    return { type: 'DASHBOARD_IS_FETCHING', isDashboardFetching }
}

export const setFetching = (isFetching: boolean): ISetFetching => {
    return { type: 'SET_FETCHING_ORDER', isFetching }
}

//ISetOrderDeleted
export const orderDeletedSuccess = (orderId: number): ISetOrderDeleted => {
    return { type: 'ORDER_DELETED_SUCCESS', orderId }
}

//IClearOrder
export const clearActiveOrder = (): IClearOrder => {
    return { type: 'CLEAR_ACTIVE_ORDER' }
}

export const applyOrderSuccess = (): IApplyOrder => {
    return { type: 'ORDER_COMPLETED_SUCCESS' }
}

export const setOrderIsPending = (isPending: boolean): IOrderIsPending => {
    return { type: 'ORDER_IS_PENDING', payload: isPending }
}

export const setAllOpenOrderIsPending = (isPending: boolean): IAllOpenOrdersIsPending => {
    return { type: 'ALL_OPEN_ORDERS_IS_PENDING', payload: isPending }
}

export const setAllOpenClosedIsPending = (isPending: boolean): IAllClosedOrdersIsPending => {
    return { type: 'ALL_CLOSED_ORDERS_IS_PENDING', payload: isPending }
}

export const setOrderNotFound = (notFound: boolean): ISetOrderNotFound => {
    return { type: 'SET_ORDER_NOT_FOUND', payload: notFound }
}

export const setUserIsInformed = (informed: boolean): IUserIsInformed => {
    return { type: 'ORDER_USER_IS_INFORMED', payload: informed }
}

export const getOpenOrders = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        try {
            const response = await OrderApi.getOpenOrders();
            dispatch(getOpenOrdersSuccess(response.data))
        } catch (e) {}
    }
}

export const applyOrder = (orderId: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        try {
            dispatch(setOrderIsPending(true))
            await OrderApi.applyOrder(orderId);
            localStorage.removeItem('order')
            const response = await OrderApi.getOpenOrders();
            dispatch(clearActiveOrder())
            dispatch(getOpenOrdersSuccess(response.data))
            dispatch(setOrderIsPending(false))
        } catch (e) {}
    }
}

export const clearShop = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        try {
            localStorage.removeItem('order')
            const response = await OrderApi.getOpenOrders();
            dispatch(clearActiveOrder())
            dispatch(getOpenOrdersSuccess(response.data))
        } catch (e) {}
    }
}

export const getClosedOrders = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        try {
            const response = await OrderApi.getClosedOrders();
            dispatch(getClosedOrdersSuccess(response.data))
        } catch (e) {}
    }
}

export const addRecentClosedOrder = (customerId: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        try {
            const response = await OrderApi.peakNavOrders(customerId);
            dispatch(peakNavOrdersSuccess(response.data))
        } catch (e) {}
    }
}

export const peakNavOrders = (customerId: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        try {
            const response = await OrderApi.peakNavOrders(customerId);
            dispatch(peakNavOrdersSuccess(response.data))
        } catch (e) {}
    }
}

export const getAllClosedNavOrders = (customerId: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        try {
            dispatch(setAllOpenClosedIsPending(true))
            const response = await OrderApi.getAllClosedOrders(customerId);
            dispatch(navOrdersClosedSuccess(response))
            dispatch(setAllOpenClosedIsPending(false))
        } catch (e) {}
    }
}

export const getAllOpenNavOrders = (customerId: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        try {
            dispatch(setAllOpenOrderIsPending(true))
            const response = await OrderApi.getAllOpenOrders(customerId);
            dispatch(navOrdersOpenSuccess(response))
            dispatch(setAllOpenOrderIsPending(false))
        } catch (e) {}
    }
}

export const getShops = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        try {
            const response = await OrderApi.getShops();
            dispatch(getShopsSuccess(response.data))
        } catch (e) {}
    }
}

export const getOrder = (orderId: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        try {
            
            dispatch(setFetching(true));
            dispatch(setOrderNotFound(false))
            const response = await OrderApi.getOrder(orderId);

            if (response.data.orderheader.orderState === 1 && !response.data.orderheader.navImportRef) {
                clearShop();
            } else {
                dispatch(getOrderSucces(response.data))
                dispatch(getBasket(response.data));
                dispatch(setFetching(false));
            }
        } catch (e) {
            dispatch(setAlert('Order is probably shipped', 'error'))
            localStorage.removeItem('order')
            clearShop();
            dispatch(setOrderNotFound(true));
        }
    }
}

export const setOrder = (orderId: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        try {
            dispatch(setFetching(true));
            dispatch(setOrderNotFound(false))
            const response = await OrderApi.getOrder(orderId);

            if (response.data.orderheader.orderState === 1 && !response.data.orderheader.navImportRef) {
                clearShop();
            } else {
                localStorage.setItem('order', String(orderId))           
            
                dispatch(setOrderSucces(response.data))
                dispatch(getBasket(response.data));
                dispatch(setFetching(false));
            }
            
        } catch (e) {
            dispatch(setFetching(false));
            dispatch(setAlert('Order is probably shipped', 'error'))
            localStorage.removeItem('order')
            clearShop();
            dispatch(setOrderNotFound(true));
        }
        dispatch(setFetching(false));
    }
}

export const setDashboardDeliveryWindows = (appOrderId: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        try {

            const response = await OrderApi.getOrderDeliveries(appOrderId);

            // const dashboardDeliveryWindows = helper.getDeliveryWindows(orderlines);
            dispatch(setDeliveryWindowsSuccess(response.data));
            
        } catch (e) {}
    }
}

// addClosedOrderToRecent
export const addClosedOrderToRecentAction = (order: any): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        
        try {
            const recentClosedOrders = localStorage.getItem('recentClosedOrders')
            let found = [];
            let orders = []

            if (recentClosedOrders) {
                orders = JSON.parse(recentClosedOrders);
                
                found = orders.map((item: any) => {
                    return item.appOrderId === order.orderheader.appOrderId
                })[0];
            }

            if (!found && order.orderheader.navImportRef && order.orderheader.orderState !== 0 && order.orderheader.orderIsFinal) {
                orders.push(order.orderheader)
            }

            localStorage.setItem('recentClosedOrders', JSON.stringify(orders))

            dispatch(addClosedOrderToRecent(order.orderheader));
        } catch (e) {}
    }
}

export const removeClosedOrderToRecentAction = (appOrderId: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        
        try {
            const recentClosedOrders = OrderApi.removeRecentOrdersFromLocalStorage(appOrderId)
            const activeOrderId = localStorage.getItem('order')

            dispatch(removeClosedOrderFromRecentSuccess(recentClosedOrders));

            if (activeOrderId && appOrderId === +activeOrderId) {
                localStorage.removeItem('order')
                dispatch(clearActiveOrder())

                dispatch(orderDeletedSuccess(appOrderId))
                dispatch(getOpenOrders())
            }

            dispatch(setAlert('Order has been closed', 'success'))
            
        } catch (e) {}
    }
}

export const createOrder = (orderType: string, customerId: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        try {
            const response = await OrderApi.createOrder(orderType, customerId);
            dispatch(createOrderSucces(response.data));
        } catch (e) {}
    }
}

export const openNavOrderAndSetOrder = (navOrderId: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        try {
            const response = await OrderApi.openNavOrder(navOrderId);
            dispatch(openNavOrderSuccess(response.data));
            dispatch(setOrder(response.data.createdapporderid));
        } catch (e) {}
    }
}

export const createAndSetOrder = (orderType: string, customerId: number, salespersonNote?: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        try {
            const createdOrder = await OrderApi.createOrder(orderType, customerId);

            if (salespersonNote && salespersonNote.trim() !== '') {
                await OrderApi.updateOrder({
                    appOrderId: createdOrder.data.appOrderId,
                    salespersonNote
                });
            }

            dispatch(setOrder(createdOrder.data.appOrderId));
            dispatch(getOpenOrders());
        } catch (e) {}
    }
}

export const deleteOrder = (orderId: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        try {
            const activeOrderId = localStorage.getItem('order')
            await OrderApi.deleteOrder(orderId);

            if (activeOrderId && +activeOrderId === orderId) {
                localStorage.removeItem('order')
                dispatch(clearActiveOrder())
            }

            dispatch(setAlert('Order has been deleted', 'success'))

            dispatch(orderDeletedSuccess(orderId))
            dispatch(getOpenOrders())
            
        } catch (e) {}
    }
}

export const getBasket = (order: Order): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        const basket: Basket = compileBasket(order);
        dispatch(getBasketSuccess(basket));
    }
}

export const applyChanges = (orderId: number, changes: basketLine): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        try {
            await OrderApi.applyChanges(orderId, changes);
            dispatch(setAlert('New items added to basket', 'success'))
            
            dispatch(getOrder(orderId));
        }
        catch (e) {
            console.log(e, 'ERROR');
        }
        
    }
}

export const applyChangesFromDashboard = (orderId: number, changes: basketLine): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        await OrderApi.applyChanges(orderId, changes);
        dispatch(setAlert('New items added to basket', 'success'))
        dispatch(getDashboardOrderState(orderId));
    }
}

export const applyBasketChanges = (changes: basketLine): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        dispatch(applyBasketChangesSuccess(changes));
    }
}

export const resetBasketChanges = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        dispatch(resetBasketChangesSuccess());
    }
}

export const resetDashboard = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        dispatch(clearDashBoard());
    }
}

export const getCustomerShippingAddresses = (customerId: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        dispatch(setIsFetchingShippingAddresses(true));
        const response = await OrderApi.getCustomerShippingAddresses(customerId);
        dispatch(getCustomerShippingAddressesSuccess(response.data));
        dispatch(setIsFetchingShippingAddresses(false));
    }
}

export const getDashboardOrderState = (orderId: number, shouldAddToRecent?: boolean): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {

    try {
        dispatch(setIsDashboardFetching(true));
        dispatch(setFetching(true));
        dispatch(setOrderNotFound(false))
        
        const response = await OrderApi.getOrder(orderId);
        
        localStorage.setItem('order', String(orderId)) 

        if (response.data.orderheader.orderState === 1 && !response.data.orderheader.navImportRef) {
            clearShop();
        } else {
            localStorage.setItem('order', String(orderId)) 

            const dashboardOrder = response.data;
            
            dispatch(getDashboardOrderStateSuccess({
                dashboardOrder,
                dashboardDeliveryWindows: [],
            }))

            dispatch(addClosedOrderToRecentAction(dashboardOrder))
            dispatch(setDashboardDeliveryWindows(dashboardOrder.orderheader.appOrderId))
            dispatch(setIsDashboardFetching(false));
            
            dispatch(setOrderSucces(response.data))
            dispatch(getBasket(response.data));
            dispatch(setFetching(false));
        }
        
    } catch (e) {
        clearShop();
        dispatch(setOrderNotFound(true));
        dispatch(setFetching(false));
    }
        dispatch(setFetching(false));
    }
}

export const setUserHasConfirmed = (informed: boolean): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        localStorage.setItem('gotIt', JSON.stringify(informed));
        dispatch(setUserIsInformed(informed));
    }
}