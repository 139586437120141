import React, { useState, useRef, useEffect } from 'react'
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';

// Utils.
import { FaSearch } from 'react-icons/fa';
import ProductApi from '../../store/products/ProductApi';
import { useDebounce } from '../../utils/use-debounce';
import PulseLoader from 'react-spinners/PulseLoader';
import { uniq } from 'lodash';

interface Props {
    show: boolean
    shouldHide?: Function
}

const SearchBox: React.FC<Props> = (props) => {
    let { show, shouldHide } = props;
    let [query, setValue] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    let [currentIndex, setCurrentIndex] = React.useState(-1);
    let [filteredSuggestions, setFilteredSuggestions] = useState<any[]>([]);
    // let [showSuggestions, setShowSuggestions] = React.useState(false);

    const debouncedSearchTerm = useDebounce(query, 500);

    let history = useHistory();
    const node = useRef(null);
    let inputField = useRef<HTMLInputElement>(null);

    // Here's where the API call happens
    // We use useEffect since this is an asynchronous action
    useEffect(
        () => {
        // Make sure we have a value (user has entered something in input)
        if (debouncedSearchTerm && debouncedSearchTerm.length > 1) {
            // Set isSearching state
            setIsSearching(true);
            // // Fire off our API call
            ProductApi.autoComplete2(debouncedSearchTerm).then((response) => {
                setIsSearching(false);
                setCurrentIndex(-1);

                // Sort by relevance.
                const sorted = response.sort((a: any, b: any) => {
                    // Sort results by matching name with keyword position in name
                    if(a.complete.toLowerCase().indexOf(debouncedSearchTerm.toLowerCase()) > b.complete.toLowerCase().indexOf(debouncedSearchTerm.toLowerCase())) {
                        return 1;
                    } else if (a.complete.toLowerCase().indexOf(debouncedSearchTerm.toLowerCase()) < b.complete.toLowerCase().indexOf(debouncedSearchTerm.toLowerCase())) {
                        return -1;
                    } else {
                        if(a.complete > b.complete)
                            return 1;
                        else
                            return -1;
                    }
                });

                const simpliFied = uniq(sorted.map((s: any) => s.complete))

                setFilteredSuggestions(simpliFied);
            })
        } else {
            setIsSearching(false);
            setFilteredSuggestions([]);
        }
        },
        // This is the useEffect input array
        // Our useEffect function will only execute if this value changes ...
        // ... and thanks to our hook it will only change if the original ...
        // value (searchTerm) hasn't changed for more than 500ms.
        [debouncedSearchTerm]
    );

    useEffect(() => {
        if (!props.show) {
            setValue('')
            setCurrentIndex(-1);
            setFilteredSuggestions([]);
        }
    }, [props.show]);

    useEffect(() => {
        // add when mounted
        document.addEventListener("keydown", handlekeyDown);

        // return function to be called when unmounted
        return () => {
            document.removeEventListener("keydown", handlekeyDown);
        };
    });

    const handlekeyDown = (e: any) => {
        const direction = e.key;
        if (!filteredSuggestions.length || (direction !== 'ArrowDown' && direction !== 'ArrowUp')) return

        const isDown = e.key === 'ArrowDown';
        const items = document.querySelectorAll('.js-autocomplete-item');
        let index = currentIndex;

        isDown ? index++ : index--;
        
        if (items.length <= index) {
            index = items.length -1;
        }

        if (index < 0) {
            index = -1;
            if (inputField && inputField.current !== null && !!show) {
                inputField.current.focus();
            }
        }

        setCurrentIndex(index);
    };

    const onChangeHandler = (event: any) => {
        /* signal to React not to nullify the event object */
        event.persist();

        const query = event.target.value;

        setValue(query);
    }

    const handleQuery = async () => {
        const clonedQuery = query;
        setValue('');

        history.push("/search/" + clonedQuery);

        close()
    }

    const close = () => {
        if (shouldHide) {
            shouldHide();
        }
        setValue('')
        setCurrentIndex(-1);
        setFilteredSuggestions([]);
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter' && query !== '') {

            if (currentIndex === filteredSuggestions.length) {
                handleQuery();
            } else 

            if (currentIndex > -1) {
                if (filteredSuggestions[currentIndex]) {
                    // @ts-ignore
                    handleAutoCompleteClick(filteredSuggestions[currentIndex])
                }
            } else {
                handleQuery();
            }
        }
    }

    const onKeyPressed = (e: any) => {
        if (e.key === 'Escape') {
            close()
        }
    }

    const handleAutoCompleteClick = (suggestion: string) => {
        close()
        setValue('');

        history.push({
            pathname: '/search/' + query,
            search: '?suggestion=' + suggestion,
        })
    }

    useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener('keydown', onKeyPressed);
        
        setTimeout(() => {
            if (inputField && inputField.current !== null && !!show) {
                inputField.current.focus();
            }
        })
        // return function to be called when unmounted
         return () => {
           document.removeEventListener("mousedown", handleClickOutside);
           document.removeEventListener('keydown', onKeyPressed);
         };
    });

    const formatStringByRelevance = (name: string) => {
        const highligted = name.replace(new RegExp(query, "gi"), (match) => `<span class="highlighted">${match}</span>`);
        return <span dangerouslySetInnerHTML={{__html: highligted}} />
    }

    const handleClickOutside = (e: any) => {
        // // @ts-ignore
        // if(node.current.contains(e.target) ||
        // (e.target.parentElement && e.target.parentElement.classList.contains("search-trigger"))
        // || (e.target.parentElement && e.target.classList.contains("search-trigger"))) {
        //     return;
        // }
        
        // // outside click
        // setValue('')
        // shouldHide && shouldHide();
    };

    return ReactDOM.createPortal(
        <React.Fragment>
            <div
                className={"searchbox-wrapper" + (show ? ' show' : '')}
                onClick={(event: any) => {
                    if (event.target === node.current) {
                        // shouldHide && shouldHide();
                    }
                }}
                ref={node}
            >
                <div className="searchbox" onKeyDown={handleKeyDown}>
                    <input
                        type="text"
                        className="searchbox__input"
                        onChange={(event) => onChangeHandler(event)}
                        placeholder="Search"
                        value={query}
                        ref={inputField}
                    />
                    <button className="searchbox__button" onClick={handleQuery}>
                        { !isSearching && <FaSearch /> }
                        { isSearching && <PulseLoader size={10} color={'#fff'} /> }
                    </button>
                    {
                        filteredSuggestions && filteredSuggestions.length ?
                        <div className="searchbox__autocomplete">
                            <ul className="searchbox__autocomplete__list">  
                                {
                                    filteredSuggestions.map((suggestion: any, key: any) => {
                                        // complete: "Hopla Fur - Polar Bear"
                                        return (
                                            <li
                                                className={
                                                    "searchbox__autocomplete__list__item js-autocomplete-item" +
                                                    (currentIndex === (key) ? ' active' : ' not-active')
                                                }
                                                key={key}
                                                onClick={() => handleAutoCompleteClick(suggestion)}
                                            >
                                                <span className="title">{formatStringByRelevance(suggestion)}</span>
                                            </li>
                                        )   
                                    })
                                }
                                <li className={
                                                    "searchbox__autocomplete__list__item seach-placeholder-element js-autocomplete-item" +
                                                    (currentIndex === filteredSuggestions.length ? ' active' : ' not-active')
                                                }>
                                    <span onClick={handleQuery}>
                                        See all results for <strong>"{ query }"</strong>
                                    </span>
                                </li>
                            </ul>
                        </div> : null
                    }
                </div>
            </div>
        </React.Fragment>, document.getElementById('root') as HTMLElement
    )
}

export default SearchBox;
