import React, { useEffect, useState } from 'react'

import { Modal } from 'molo-shared';
import OrderApi from '../../store/order/OrderApi';
import { Spinner } from "@blueprintjs/core";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

interface ITrackingLink {
    bsl: string
    links: any[]
}

// Store.

interface Props {
    show: boolean
    shouldHide: Function
    trackingBsl?: string
}

const style = {
    width: '100%',
    maxWidth: '100%',
    bgcolor: 'background.paper',
};

const TrackingModal: React.FC<Props> = (props) => {
    const { shouldHide, show, trackingBsl } = props;
    const [trackingLinks, setTrackingLinks ] = useState<ITrackingLink>({bsl: '', links: []});
    const [isFecthing, setIsFetching] = useState(true)

    useEffect(() => {
        if (show && trackingBsl && trackingBsl !== trackingLinks.bsl) {
            getTrackingLink(trackingBsl)
        }
        return () => {}
    }, [show, trackingBsl])

    const getTrackingLink = async (trackingBsl: string) => {
        setIsFetching(true)

        const response = await OrderApi.PeakShipmentTracking(trackingBsl)

        setTrackingLinks({
            bsl: trackingBsl,
            links: response.data
        })

        setIsFetching(false)
    }

    const renderModal = () => (
        <div className="">
            <div className="p-12">
                <h2 className="text-2xl mb-2">Tracking Links</h2>
                <h3 className="text-lg mb-2">Click on one the links below to follow your tracking</h3>
                {
                    trackingLinks.bsl && trackingLinks.links.length ?
                    <List sx={style} component="nav" aria-label="mailbox folders">
                        {trackingLinks.links.map((link: any, key: number) => {
                            return (
                                <>
                                    <ListItem button key={key}>
                                        <a href={link.trackinglink} target={'_blank'}>
                                            Parcel {(key + 1)}: {link.parcelno}
                                        </a>
                                        {/* <a href={link.trackinglink} target={'_blannk'}>{ link.trackinglink }</a> */}
                                    </ListItem>
                                    <Divider />
                                </>
                            )
                        })}
                    </List>
                    : null
                }
                
            </div>
            <div className="">
            </div>
        </div>
    );

    const renderSpinner = () => <div className="big-spinner"><Spinner /></div>

    return (
        <Modal
            children={isFecthing ? renderSpinner() : renderModal()}
            size={'md'}
            hide={shouldHide}
            isShowing={show}
            bodyClasses={'confirm-modal'}
            hideFooter={true}/>
    )
}

export default TrackingModal