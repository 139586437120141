import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import { ThunkDispatch } from 'redux-thunk'
import { connect } from 'react-redux'
import { Link } from "react-router-dom";
import {RouteComponentProps, withRouter} from "react-router-dom";

// Thris party.
import DetectableOverflow from 'react-detectable-overflow';

// Components.
import OrderFlow from '../../components/shared/OrderFlow';
import { filter } from 'lodash';
import Modal from '../layout/Modal/Modal';
import QuantityGrid from '../layout/QuantityGrid';
// import MobileQuantity from '../layout/MobileQuantity';

// Models.
import { StyleBean } from '../../models/Beans';
import Order from '../../models/Order';
import { basketLine } from '../../models/Basket';
import { DeliveryWindow } from '../../models/Style'

import { createStyle } from 'molo-shared/lib/models/Style';

// Utils.
import { mapMoloItems, MoloItem, IgroupedItems, IMoloItem } from '../../models/MoloItem';
import { sortBy, uniqBy } from 'lodash';
import { FaExclamation, FaExternalLinkAlt } from 'react-icons/fa';
import queryString from 'query-string'
import { roundAmount } from '../../utils/priceHelper';
import { getWHPWithDiscount } from '../../utils/priceHelper';
import PropagateLoader from 'react-spinners/PropagateLoader'
import { appConfig } from '../../config';

// Images.
import OekotexImage from '../../images/sustainability/oekotex.png'
import GotsImage from '../../images/sustainability/gots.png'
import RecycleImage from '../../images/sustainability/recycled.png';
import OrganicImage from '../../images/sustainability/organic.png';
import EcoveroImage from '../../images/sustainability/ecovero.png';
import OcsBlendedImage from '../../images/sustainability/ocsblended.png';

import OCSnoImage from 'molo-shared/lib/images/sustainability/ocs.png';
import GRSnoImage from 'molo-shared/lib/images/sustainability/grs.png';
import GRSMixedImage from 'molo-shared/lib/images/sustainability/grsmixed.png';
import GRSNylonImage from 'molo-shared/lib/images/sustainability/grsnylon.png';

// Store.
import { IProductState } from '../../store/products/reducers';
import { IOrderState } from '../../store/order/orderStore';
import { RootState } from '../../store';
import { getMoloItem } from '../../store/products/actions';
import ProductApi from '../../store/products/ProductApi';
import { IUserSate } from '../../store/user/reducers';

import {
    Tooltip,
    Position
} from "@blueprintjs/core";
import OrderApi from '../../store/order/OrderApi';
import { register } from '../../serviceWorker';

const baseUrl = appConfig.media.baseUrl;

interface ISize {
    code: string,
    name: string,
}

interface imageMapInterface {
    imageurl: string
}
interface IPrice {
    whp: number
    msrp: number
}

interface IPrices {
    [id: string] : IPrice[]
}

interface State {
    moloItems: IgroupedItems,
    sizes: ISize[],
    changes: basketLine,
    itemsLoading: boolean
    imageDoneLoading: boolean
    currentStep: string
    infoOpen: boolean,
    imageMap: imageMapInterface[],
    imageMapCurrentIndex: number,
    currencyCode: string,
    isOverflowed: boolean,
    imageIsExpanded: boolean,
    moloMaster: StyleBean | null,
    orderType?: string | undefined
    prices: IPrices
    orderTypes: any[]
    initialLoadDone: boolean
}
  
interface OwnProps {
    product: StyleBean
    hide: Function
    showModal: boolean
    order?: Order
    deliveryWindows?: DeliveryWindow[]
    applyBasketChanges: Function
    applyChanges: Function
    changeProduct: Function
    lazyLoadMaster?: boolean
    match?: {params: {collection: string, orderType: string}}
    orderType?: string
    checkOrderType?: boolean
}
  
interface DispatchProps {
    getMoloItem: (masterId: string, currencyCode: string, pricegroup: string) => void
}
  
interface StateProps {
    productState: IProductState
    orderState: IOrderState
    userState: IUserSate
}
  
type Props = StateProps & OwnProps & DispatchProps & RouteComponentProps;

export class ProductOverlay extends Component<Props, State> {

    initialChanges: basketLine = {};
    initialMoloItems: IgroupedItems = {};
    initialSizes: ISize[] = [];
    
    state: State = {
        moloItems: this.initialMoloItems,
        sizes: this.initialSizes,
        changes: this.initialChanges,
        itemsLoading: false,
        imageDoneLoading: false,
        currentStep: 'product',
        infoOpen: false,
        imageMap: [],
        imageMapCurrentIndex: 0,
        currencyCode: 'EUR',
        isOverflowed: false,
        imageIsExpanded: false,
        moloMaster: null,
        prices: {},
        orderTypes: [],
        initialLoadDone: false
    };

    async componentDidMount() {
        const { product } = this.props;
        const currencyCode = this.props.userState.user ? this.props.userState.user.currencyCode : 'EUR';
        const pricegroup   = this.props.userState.user ? this.props.userState.user.customerData.pricegroup : 'ALL';

        let { orderType } = product;

        this.props.getMoloItem(product.masterId, currencyCode, pricegroup);
        this.getImageMap()
        this.setState({ currencyCode, orderType })
        
        if (this.props.lazyLoadMaster) {
            const moloMaster = await ProductApi.getMaster(product.masterId, product.colorCode, currencyCode, pricegroup)
            console.log('loadstuff');
            orderType = moloMaster.data.orderType;
            this.setState({ moloMaster: StyleBean.build(createStyle(moloMaster.data), 'style'), orderType })
        }

        if (this.props.checkOrderType) {
            // Check the order type.
            const response = await OrderApi.getItemOrderTypes(product.masterId, product.colorCode)
            this.setState({ orderTypes: response.data })
        }

        this.setState({ initialLoadDone: true })
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.productState.moloItems !== this.props.productState.moloItems && this.props.productState.moloItems && this.props.productState.moloItems.length) {
            const items: MoloItem[] = filter(mapMoloItems(this.props.productState.moloItems), (item) => {
                return item.colorBlocked !== 1;
            });

            let grouped: IgroupedItems = {};
            const prices: IPrices = {};

            for (const item of items) {
                const key: string = String(item.masterId +  item.colorCode);

                if (!grouped[key]) {
                    grouped[key] = [];
                }

                grouped[key].push(item);
            }

            let groupedSorted: IgroupedItems = {};

            for (const groupKey in grouped) {
                if (!groupedSorted[groupKey]) {
                    groupedSorted[groupKey] = grouped[groupKey];// sortBy(grouped[groupKey], ['sizeCode']);
                }
            }

            const sizes: ISize[] = [];

            for (const item of groupedSorted[Object.keys(groupedSorted)[0]]) {
                sizes.push({
                    code: item.sizeCode,
                    name: item.sizeName,
                });
            }

            

            for (const key in groupedSorted) {
                const items = groupedSorted[key];
                const diffPrices: IPrice[] = uniqBy(items.map((item: any) => {
                    return {
                        whp: item.whp,
                        msrp: item.msrp
                    }
                }), 'whp')

                prices[key] = diffPrices;
            }
            
            this.setState({
                moloItems: groupedSorted,
                sizes: sizes,
                itemsLoading: false,
                prices
            });
        }

        

        if (prevProps.product.colorCode !== this.props.product.colorCode) {
            this.getImageMap()
        }
    }

    getImageMap = async () => {
        let imageMap = []
        imageMap = await ProductApi.getImageMap(this.props.product.seasonCode, this.props.product.masterId, this.props.product.colorCode);
        this.setState({ imageMap, itemsLoading: false })
    }

    renderOrderGrid = () => (
        <React.Fragment>
            <div className="po__grid desktop-only">
                <QuantityGrid
                    basket={ this.props.orderState.basket }
                    sizes={ this.state.sizes }
                    orderState={this.props.orderState}
                    moloItems={ this.state.moloItems }
                    selectedProduct={ this.props.lazyLoadMaster && this.state.moloMaster ? this.state.moloMaster : this.props.product }
                    updateChanges={ this.updateChanges }
                />
            </div>
            <div className="po__grid__explainer">
                <div className="po__grid__explainer__item">
                    <div className="infiniteAvailability"></div>
                    <div>Full stock</div>
                </div>
                <div className="po__grid__explainer__item">
                    <div className="mediumAvailability"></div>
                    <div>Limited stock</div>
                </div>
                <div className="po__grid__explainer__item">
                    <div className="lowAvailability"></div>
                    <div>Low stock</div>
                </div>
            </div>
            {/* <div className="mobile-only">
                <MobileQuantity
                    basket={ this.props.orderState.basket }
                    sizes={ this.state.sizes }
                    moloItems={ this.state.moloItems }
                    selectedProduct={ this.props.lazyLoadMaster && this.state.moloMaster ? this.state.moloMaster : this.props.product }
                    updateChanges={ this.updateChanges }
                
                />
            </div> */}
        </React.Fragment>
    )

    updateChanges = (itemId: number, quantity: number) => {
        // const changes = this.props.orderState.basket.changes;
        const changes = {[itemId]: +quantity};
        this.props.applyBasketChanges(changes);
    }

    handleOverFlow = (isOverflowed: boolean) => {
        this.setState({ isOverflowed })
    }

    renderColors = (product: StyleBean) => (
        
        <div className={'po__styles-list__wrapper' + (this.state.isOverflowed ? ' overflowed' : '')} >
            <DetectableOverflow onChange={this.handleOverFlow} className="detector">
                <div className="po__styles-list">
                    {
                        Object.keys(this.state.moloItems).map((itemKey, key) => {
                            const item = this.state.moloItems[itemKey][0];
                            const isSoldOut = !this.state.moloItems[itemKey].filter(item => item.available > 0).length
                            if (itemKey === (product.masterId + product.colorCode)) {
                                return null;
                            }

                            const imgUrl = baseUrl + 'media/productimage/' + item.seasonCode + '/' + item.masterId + '/' + item.colorCode + '/small';
                            
                            return (
                                <div key={key} className="po__styles-list__item" onClick={() => {
                                    this.setState({
                                        itemsLoading: true
                                    }, () => {
                                        this.props.changeProduct(item)
                                    })
                                }}>
                                    {
                                        isSoldOut ?
                                        <div className="po__styles-list__item__sold-out">Sold Out</div> : null
                                    }
                                    <div className="po__styles-list__item__color">
                                        <div>{ item.colorName }</div>
                                        <div style={{textTransform: 'uppercase'}}>{ item.deliveryWindowLabel }</div>
                                    </div>
                                    <img src={encodeURI(imgUrl)} alt={ item.colorName } style={{maxHeight: '100px'}}/>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    this.state.itemsLoading ?
                    <div
                        className="loading-spinner-2"
                        style={{
                            width: '100%',
                            position: 'absolute',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            fontSize: '20px',
                            bottom: '18px',
                        }}>
                        <PropagateLoader size={8}/>
                    </div> : null
                }
            </DetectableOverflow>
        </div>
        
    );

    renderSizes = (selectedMoloItem: IMoloItem[]) => (
        <div className="po__sizes">
            {
                this.state.sizes.map((size, key) => (
                    <div key={key} className="po__sizes__size">{ size.name }</div>
                ))
            }
        </div>
    );

    renderHeader = (product: StyleBean) => (
        <div className="po__header">
            {
                product.deliveryWindow &&
                <p style={{textTransform: 'uppercase', color: 'gray'}}>
                    {product.orderType && product.orderType.toLowerCase() !== 'reorder' ? product.seasonCode + ' / ' : ''}{ product.deliveryWindow.label }
                </p>
            }
            <p className="po__header__heading">
                <span className="--highlighted">{ product.description } </span>
                <Tooltip
                    content={<div>Click for products in same color</div>}
                    position={Position.RIGHT}
                >
                    <Link
                        className="po__search-link"
                        to={{
                            pathname: '/search',
                            search:  queryString.stringify({
                                collection: product.collectionName,
                                color: product.colorCode,
                                orderType: product.orderType,
                            })
                        }}
                    >
                        { product.colorName } <span className="po__search-click"><FaExternalLinkAlt /></span>
                    </Link>
                </Tooltip>
            </p>

            <p>{ product.masterId } { product.colorCode }</p>
            
            {(product.b2BColorComment || product.b2BMasterComment) &&
                <div className="po__header__note">
                    <span className="--icon"><FaExclamation /></span>
                    <p>{ product.b2BMasterComment }</p>
                    <p>{ product.b2BColorComment }</p>
                </div>
            }
            {(product.discontinued) &&
                <div className="po__header__note">
                    <span className="--icon"><FaExclamation /></span>
                    <p><b>This product is discontinued</b></p>
                </div>
            }
            <div className="po__header__info --b2b">
                <div className="">
                    {product.fabricComposition &&
                        <p className="po__header__info__fabric">
                            {  product.fabricComposition }
                        </p>
                    }

                    {product.styleComment &&
                        <p className="po__header__info__fabric"
                        dangerouslySetInnerHTML={{
                        __html: product.styleComment
                        }}></p>
                    }

                    {product.colorComment &&
                        <p className="po__header__info__fabric"
                        dangerouslySetInnerHTML={{
                        __html: product.colorComment
                        }}></p>
                    }
                </div>
            </div>
        </div>
    );

    onKeyPressed = (e: any) => {
        if (e.key === 'Enter') {
            this.props.applyChanges();
        }
    }

    imageDoneLoading = () => {
        this.setState({imageDoneLoading: true})
    }

    onHandleOpenOrderFlow = () => {
        this.setState({ currentStep: 'orderFlow' })
    }

    renderOrderFlow = () => {
        return (
            <OrderFlow
                orderFlowDone={() => this.setState({ currentStep: 'product' })}
                orderType={this.props.orderType}
                multiOrderTypes={this.state.orderTypes}
            />
        )
    }

    hasOrderType = () => {
        if (this.state.orderTypes && this.state.orderTypes.length && this.props.orderState.order) {            
            const types = this.state.orderTypes.map(t => t.orderType)

            if (types.indexOf(this.props.orderState.order.orderheader.orderType) !== -1) {
                return true
            }
        }

        return this.props.orderState.order && this.props.orderState.order.isCurrentOrderType &&
            this.props.orderState.order.orderheader.orderType === this.state.orderType
    }

    getOrderType = () => {

        if (this.state.orderTypes && this.state.orderTypes.length && this.props.orderState.order) {
            const types = this.state.orderTypes.map(t => t.orderType)

            if (types.indexOf(this.props.orderState.order.orderheader.orderType) !== -1) {
                return this.props.orderState.order.orderheader.orderType
            }
            

        }

        return this.props.orderState.order && this.props.orderState.order.isCurrentOrderType ?
            this.props.orderState.order.orderheader.orderType : 'REORDER'
    }

    renderModalContent = () => {
        if (!this.state.initialLoadDone) {
            return (
                <div className="loading-spinner-1">
                    <PropagateLoader />
                </div>
            )
        }

        const product = this.props.lazyLoadMaster && this.state.moloMaster ? this.state.moloMaster : this.props.product;
        const selectedMoloItem = this.state.moloItems[product.masterId + product.colorCode];

        let imgUrl = baseUrl + 'media/productimage/' + product.seasonCode + '/' + product.masterId + '/' + product.colorCode + '/large';
        // let imgUrl = 'https://picsum.photos/200/300'

        if (this.state.imageMap.length > 0 && selectedMoloItem && selectedMoloItem.length) {
            const imgStr = this.state.imageMap[this.state.imageMapCurrentIndex].imageurl;
            const item = selectedMoloItem[0];
            imgUrl = baseUrl + 'media/productimage/' + item.seasonCode + '/' + imgStr + '/large';
            // imgUrl = 'https://picsum.photos/200/300'
        }

        if (this.state.currentStep === 'orderFlow') {
            return this.renderOrderFlow()
        }

        return (
            <div className="po" onKeyDown={this.onKeyPressed} style={{position: 'relative'}}>
                {this.state.imageIsExpanded && this.imageOverlay(imgUrl)}
                <div className="po__content">
                    <div className="po__left">
                        <img
                            src={imgUrl}
                            alt="large product"
                            className="po__left__image"
                            // className={"po__left__image " + (this.props.order ? "order-is-open" : "") + (this.state.imageIsExpanded ? " expanded" : "")}
                            onClick={() => this.setState({ imageIsExpanded: !this.state.imageIsExpanded })}
                            onLoad={this.imageDoneLoading}
                        />
                        {
                            product.oekotex && product.oekotex !== '' &&
                            <div className="po__left__sustainablity --oekotex">
                                <img src={OekotexImage} alt="OekotexImage" />
                                <svg viewBox="0 0 240 80" xmlns="http://www.w3.org/2000/svg" className="sustainability__code">
                                    <text x="20" y="35" className="small">{product.oekotex}</text>
                                </svg>
                            </div>
                        }
                        { console.dir("look at sustainability",  product ) }
                        {
                            product.gots && product.gots !== '' &&
                            <div className="po__left__sustainablity --gots">
                                <img src={GotsImage} alt="GotsImage" />
                            </div>
                        }
                        {
                            product.ocsno && product.ocsno !== '' &&
                            <div className="po__left__sustainablity --OCSno">
                                <img src={OCSnoImage} alt="GotsImage" />
                            </div>
                        }
                        {
                            product.ocsblended && product.ocsblended  !== '' &&
                            <div className="po__left__sustainablity --OcsBlended">
                                <img src={OcsBlendedImage} alt="ocsblended image" />
                            </div>
                        }
                        {
                            product.grsno && product.grsno !== '' && product.grsno.startsWith('N:') &&
                            <div className="po__left__sustainablity --grsno">
                                <img src={GRSNylonImage} alt="grs image" />
                            </div>
                        }
                        {
                            product.grsno && product.grsno !== '' && product.grsno.startsWith('M:') &&
                            <div className="po__left__sustainablity --grsno">
                                <img src={GRSMixedImage} alt="grs image" />
                            </div>
                        }
                        {
                            product.grsno && product.grsno !== '' && !product.grsno.startsWith('N:') && !product.grsno.startsWith('M:')  &&
                            <div className="po__left__sustainablity --grsno">
                                <img src={GRSnoImage} alt="grs image" />
                            </div>
                        }
                        {
                            !!product.organic &&
                            <div className="po__left__sustainablity --organic">
                                <img src={OrganicImage} alt="OrganicImage" />
                            </div>
                        }
                        {
                            !!product.ecovero &&
                            <div className="po__left__sustainablity --ecovery">
                                <img src={EcoveroImage} alt="OrganicImage" />
                            </div>
                        }
                        {
                            product.recycled &&
                            <div className="po__left__sustainablity --recycled">
                                <img src={RecycleImage} alt="Recycled" />
                            </div>
                        }
                        {
                            this.state.imageMap.length > 1 &&
                            <div className="po__left__thumbnails">
                                {
                                    this.state.imageMap.map((map, index) => {
                                        let imgUrl = baseUrl + 'media/productimage/' + this.props.product.seasonCode + '/' + map.imageurl + '/small';
                                        // imgUrl = 'https://picsum.photos/200/300'
                                        return (
                                            <img
                                                className="po__left__thumbnails__img"
                                                src={imgUrl} alt={'thumbnail:' + index}
                                                key={index}
                                                onClick={() => this.setState({ imageMapCurrentIndex: index })}
                                            />
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                    <div className="po__right">
                        { this.renderHeader(product) }
                        <div className="po__right__bottom">
                            { Object.keys(this.state.moloItems).length > 1 && this.renderColors(product) }
                        </div>
                        {
                            this.state.prices[product.masterId + product.colorCode]
                            &&
                            <p className="price">
                                {
                                    this.state.prices[product.masterId + product.colorCode].map((price: IPrice, key: number) => {
                                        return (
                                            <React.Fragment key={key}>
                                                { roundAmount(getWHPWithDiscount(price.whp, +this.props.userState.user.customerData.linediscount)) } / { roundAmount(price.msrp) }
                                                {
                                                    key + 1 !== this.state.prices[product.masterId + product.colorCode].length && <span> - </span>
                                                }
                                            </React.Fragment>
                                        )
                                    })
                                }
                                {' ' + product.currencyCode}
                            </p>
                            
                        }
                        { !this.props.orderState.order && this.renderSizes(selectedMoloItem)}
                        {
                            (!this.props.orderState.order || (!this.hasOrderType() && !this.props.orderState.order.orderheader.orderIsFinal))
                            &&
                            <button className="button" onClick={this.onHandleOpenOrderFlow} style={{marginTop: '20px'}}>Add to order</button>
                        }

                        {
                            (this.props.orderState.order && this.props.orderState.order.orderheader.orderIsFinal)
                            &&
                            <div className="text-center" style={{maxWidth: '500px', margin: '0 auto'}}>
                                <h5>
                                    It is not possible to add products to an already approved order.<br/>Please create a new order.
                                </h5>
                                <button className="button" onClick={this.onHandleOpenOrderFlow} style={{marginTop: '20px'}}>Create new order</button>
                            </div>
                        }
                    </div>
                </div>
                {  (this.props.orderState.order && this.hasOrderType()) && !this.props.orderState.order.orderheader.orderIsFinal && <h5 className="po__add_to_order">Add to order:</h5>}
                { (this.props.orderState.order && this.hasOrderType()) && !this.props.orderState.order.orderheader.orderIsFinal && this.imageDoneLoading && this.renderOrderGrid() }
                {/* { (this.props.orderState.order && this.state.orderTypes && this.state.orderTypes.length && this.hasOrderType()) && !this.props.orderState.order.orderheader.orderIsFinal && this.imageDoneLoading && this.renderOrderGrid() } */}
                {/* <div style={{height: '100px', display: 'block'}}>

                </div> */}
            </div> 
        )
    }

    renderFooter = () => {
        if (!this.props.orderState.order ||
            (this.props.orderState.order && this.props.orderState.order.orderheader.orderIsFinal)
            ||
            (this.props.orderState.order && !this.hasOrderType())) {
            return null
        }
        
        return (
            <button
                className="po__order-btn modal-footer__btn-center"
                onClick={() => this.props.applyChanges()}>
                    Add to order
            </button>
        );
    }

    imageOverlay = (image: string) => {
        return ReactDOM.createPortal(
            <React.Fragment>
                <div className="image-overlay ignore-clickoutside" onClick={(e: any) => {
                    this.setState({ imageIsExpanded: false })
                } }>
                    <img src={image} alt="zoom" className="ignore-clickoutside hest" />
                </div>
            </React.Fragment>, document.getElementById('root') as HTMLElement
        )
    }

    onHideModal = () => {
        this.setState({
            moloItems: this.initialMoloItems,
            sizes: this.initialSizes,
            changes: this.initialChanges,
            itemsLoading: false,
            imageDoneLoading: false,
            currentStep: 'product',
            infoOpen: false,
            imageMap: [],
            imageMapCurrentIndex: 0,
            currencyCode: 'EUR',
            isOverflowed: false,
            imageIsExpanded: false,
        }, () => {
            this.props.hide()
        })
    }

    render() {

        return (
            <Modal
                children={this.renderModalContent()}
                hide={this.onHideModal}
                size={'special'}
                hideHeader={true}
                hideFooter={!this.props.orderState.order}
                footer={this.renderFooter()}
                isShowing={this.props.showModal} />
        )
    }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
    return {
      productState: states.productStore.productStore,
      orderState: states.order.order,
      userState: states.user.user,
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
    return {
        getMoloItem: async (masterId: string, currencyCode: string, pricegroup: string) => {
            await dispatch(getMoloItem(masterId, currencyCode,pricegroup))
            console.log('getMoloItem completed [UI]')
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductOverlay))