import React, { useEffect, useState } from 'react'
import OrderSummary from '../shared/Dashboard/OrderSummary'

import * as helper from '../shared/Dashboard/DashboardHelper';
import { FaTimes, FaArrowLeft } from 'react-icons/fa'

import { appConfig } from '../../config';

import { cloneDeep } from 'lodash';

import {
    tcategories,
    IDivider,
    IGrouping,
    DIVIDERS
} from '../shared/Dashboard/dashboard.d';

interface ISize {
    sizename: string,
    quantity: number
}

interface ISustainabilityMap {
    [id: string]: string | null | undefined
}

interface ISizes {
    [id: string]: ISize[],
}

const baseUrl = appConfig.media.baseUrl;

interface IProps {
    orderLines: any[],
    order: any
}

export const ShippedItemsOverview: React.FC<IProps> = (props) => {
    const { orderLines, order } = props

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [activeItems, setActiveItems] = useState<any[]>([])
    const [productSortedByCategory, setproductSortedByCategory] = useState<{[id: string]: {}}>({})

    const onChangeCategory = () => {}

    const onClickFilterGroup = (group: string, item: any) => {
        console.log(group, item, 'group clicked')

        if (productSortedByCategory[group]) {
            const groupedOrderlines = helper.groupSimilarColors(cloneDeep(item.orderLines));
            console.log(groupedOrderlines, 'groupedOrderlines')
            setActiveItems(groupedOrderlines)
            setSidebarOpen(true)
        }
    }

    const getSizes = (item: any) => {
        const sizes: {[id: string] : ISize} = {}

        for (const i in item.orderLines) {
            const orderline = item.orderLines[i];

            if (!sizes[orderline.sizename]) {
                sizes[orderline.sizename] = {
                    sizename: orderline.sizename,
                    quantity: orderline.quantity,
                }
            } else {
                sizes[orderline.sizename].quantity = sizes[orderline.sizename].quantity + orderline.quantity
            }
        }

        return (
            <ul>
                {
                    Object.keys(sizes).map((key, index: number) => {
                        const size = sizes[key]

                        return (
                            <li key={ index }>
                                <strong>{ size.sizename }</strong> : { size.quantity }
                            </li>
                        )
                    })
                }
            </ul>
        )
    }

    const renderItemsTable = () => {
        return (
            <table className="striped-table table-fixed table-hover completed-orders-table text-xs">
                <thead>
                    <tr>
                        <th></th>
                        <th>Quantity</th>
                        <th>Sizes / quantity</th>
                    </tr>
                </thead>
                <tbody>
                {activeItems.map((item: any, key: number) => {
                    const imageUrl = baseUrl + 'media/productimage/' + item.seasoncode + '/' + item.masterid + '/' + item.color + '/small';
                    return (
                        <tr key={ key }>
                            <td>
                                <div className="details__info">
                                    <img src={imageUrl} alt={item.description} className="details__image" />
                                    <div className="details__info__data">
                                        <span className="item-list__item__name__id">{item.masterid} {item.description}</span>
                                        <span className="item-list__item__color">{item.color} {item.colorname}</span>
                                    </div>
                                </div>
                            </td>
                            <td>{ item.quantity }</td>
                            <td>{ getSizes(item) }</td>
                        </tr>
                    )
                })}
                </tbody>
                
            </table>
        )
    }

    useEffect(() => {
        let productSortedByCategory = helper.getProductCategories(orderLines, DIVIDERS);

        setproductSortedByCategory(productSortedByCategory)

    }, [orderLines])

    return (
        <>
            <OrderSummary
                order={order}
                productSortedByCategory={productSortedByCategory}
                categoryChanged={onChangeCategory}
                onClickFilterGroup={onClickFilterGroup}
                hidePrices={true}
                showDownloadLink={true}
            />
            <div className={`sidebar-menu__inner ${ sidebarOpen ? '--is-active' : '' }`}>
                <div className="modal-header__close --left" onClick={() => { setSidebarOpen(false) } }>
                    <FaArrowLeft />
                </div>
                <div className="overflow-auto h-full">
                    { sidebarOpen && renderItemsTable() }
                </div>
            </div>
        </>
    )
}