import React, { Component } from 'react'
import { RootState } from '../../store';
import { connect } from 'react-redux'
import { getFilter, applyFilter } from '../../store/session/actions'
import { IFilterState } from '../../store/session/reducers'
import { ThunkDispatch } from 'redux-thunk'

import { cloneDeep, uniq } from 'lodash';

import Style from 'molo-shared/lib/models/Style';
import { Filter } from '../../models/Filter';
import { FilterHelper } from '../../utils/FilterHelper';
import { CheckboxInput } from '../form/CheckboxInput';
import { AccordionPanel } from '../layout/AccordionPanel';

import ProductApi from '../../store/products/ProductApi';

interface State {
  initialized?: boolean;
  styles?: Style[],
  searchTerm: string,
  productGroupConfig?: any,
}

interface OwnProps {
  styles: Style[];
  showFilter: boolean;
  collectionName?: string
}

interface DispatchProps {
  getFilter: () => void;
  applyFilter: (filter: Filter) => void;
}

interface StateProps {
  filterState: IFilterState
}

type Props = StateProps & OwnProps & DispatchProps

class FilterComponent extends Component<Props, State> {

  state: State = {initialized: false, searchTerm: '', styles: []}

  async componentDidMount () {
    this.props.getFilter();
    const productGroupConfig = await ProductApi.getProductGroupConfig();

    this.setState({ productGroupConfig })
  }
  componentDidUpdate(prevProps: Props, prevState: State) {
    if (!this.state.initialized && this.props.filterState.filter) {
      this.initFilters(this.props.filterState.filter, this.props.styles);
    }
  }

  componentWillUnmount() {
    this.setState({initialized: false})
  }

  public static getDerivedStateFromProps(props: Props, state: State) {
    if (props.styles !== state.styles) {
      return {
          styles: props.styles,
      }
    }
    
    return null;
  }

  async initFilters (filter:Filter, styles: Style[]) {
    const newFilter = cloneDeep(filter);

    let productGroupConfig = this.state.productGroupConfig;

    const response = await ProductApi.getProductGroupConfig();
      productGroupConfig = response.data;
      this.setState({ productGroupConfig }, () => {
        if (productGroupConfig) {
          for (const groupName in productGroupConfig) {
            if (!productGroupConfig[groupName]) {
              continue;
            }
    
            for (const rule of productGroupConfig[groupName]) {
              let includeStyles = styles.filter(style => style.productGroup.toLowerCase() === rule.include.group.toLowerCase());
    
              for (const delta in rule.include.ORproperties) {
                const ORpropety = rule.include.ORproperties[delta];
                // @ts-ignore
                const local = includeStyles.filter(style => style[Object.keys(ORpropety)[0]] === true);
    
                for (const i in local) {
                  let index = styles.findIndex(style => style.masterId === local[i].masterId && style.colorCode === local[i].colorCode)
    
                  if (styles[index]) {
                    let cloned = styles[index].subCategories;
                    cloned.push(groupName)
                    styles[index].subCategories = uniq(cloned)
                  }
                }
              }
            }
          }
        }
        
        newFilter.seasons = FilterHelper.hydrateSeasons(styles);
        newFilter.productGroups = FilterHelper.hydrateProductGroups(styles);
        newFilter.deliveryWindows = FilterHelper.hydrateDeliveryWindows(styles);
        // newFilter.productCategories = FilterHelper.hydrateProductCategories(styles);
        newFilter.sustainability = FilterHelper.hydrateSustainability(styles);

        if (!this.props.collectionName) {
          newFilter.productCategories = FilterHelper.hydrateProductCategories(styles);
        } else {
          newFilter.productCategories = FilterHelper.hydrateProductCategoriesByCollectionSort(styles, this.props.collectionName);
        }
    
        this.props.applyFilter(newFilter);
    
        this.setState({
          initialized: true
        });
      })

    
  }

  onFilterSelectionSeason (event: any) {        
    const selectedSeason = event.currentTarget.value;
    const selectedSeasonIsActive = event.currentTarget.checked;
    this.changeActiveFilters('seasons', selectedSeason, selectedSeasonIsActive);
  }

  onFilterSelectionProductGroup (event: any) {
    const selectedProductGroup = event.currentTarget.value;
    const selectedProductGroupIsActive = event.currentTarget.checked;
    this.changeActiveFilters('productGroups', selectedProductGroup, selectedProductGroupIsActive);
  }

  onFilterSelectionSustainability (event: any) {
    const selected = event.currentTarget.value;
    const selectedIsActive = event.currentTarget.checked;
    this.changeActiveFilters('sustainability', selected, selectedIsActive);
  }

  onFilterSelectionDeliveryWindow (event: any) {
    const selectedDeliveryWindow = event.currentTarget.value;
    const selectedDeliveryWindowIsActive = event.currentTarget.checked;
    this.changeActiveFilters('deliveryWindows', selectedDeliveryWindow, selectedDeliveryWindowIsActive);
  }

  onFilterSelectionProductCategory (event: any) {
    const selectedProdoctCategory = event.currentTarget.value;
    const selectedProdoctCategoryWindowIsActive = event.currentTarget.checked;
    this.changeActiveFilters('productCategories', selectedProdoctCategory, selectedProdoctCategoryWindowIsActive);
  }

  changeActiveFilters (filterType: any, filterValue: string, isActive: boolean) {
    const newFilter = Object.assign({}, this.props.filterState.filter);

    if (!newFilter) return

    let tempResult;

    switch (filterType) {
      case 'seasons':
         if (isActive) {
          newFilter.activeFilters.seasons.push(filterValue);
        } else {
          const filterTypeIndex = newFilter.activeFilters.seasons.indexOf(filterValue);
          newFilter.activeFilters.seasons.splice(filterTypeIndex, 1);
        }
        break;
      case 'deliveryWindows':
        if (isActive) { 
          newFilter.activeFilters.deliveryWindows.push(filterValue);
        } else {
          const filterTypeIndex = newFilter.activeFilters.deliveryWindows.indexOf(filterValue);
          newFilter.activeFilters.deliveryWindows.splice(filterTypeIndex, 1);
        }
        break;
      case 'sustainability':
          if (isActive && newFilter.activeFilters.sustainability) {
            newFilter.activeFilters.sustainability.push(filterValue);
          } else {
            if (newFilter.activeFilters.sustainability) {
              const filterTypeIndex = newFilter.activeFilters.sustainability.indexOf(filterValue);
              newFilter.activeFilters.sustainability.splice(filterTypeIndex, 1);  
            }
          }
          break;
      case 'productGroups':
        if (isActive) {
          newFilter.activeFilters.productGroups.push(filterValue);
        } else {
          const filterTypeIndex = newFilter.activeFilters.productGroups.indexOf(filterValue);
          newFilter.activeFilters.productGroups.splice(filterTypeIndex, 1);
        }

        let cloned = cloneDeep(newFilter);
        cloned.activeFilters.productCategories = [];
        
        tempResult = FilterHelper.resultByFilter(cloned, this.props.styles);

        if (!this.props.collectionName) {
          newFilter.productCategories = FilterHelper.hydrateProductCategories(tempResult);
        } else {
          newFilter.productCategories = FilterHelper.hydrateProductCategoriesByCollectionSort(tempResult, this.props.collectionName);
        }

        break;
      case 'productCategories':
        if (isActive) {
          newFilter.activeFilters.productCategories.push(filterValue);
        } else {
          const filterTypeIndex = newFilter.activeFilters.productCategories.indexOf(filterValue);
          newFilter.activeFilters.productCategories.splice(filterTypeIndex, 1);
        }
        
        break;
    }

    this.props.applyFilter(newFilter);
  }

  

  render() {
    return (
      <div className={"filter-sidebar --product-list " + (this.props.showFilter ? "--is-active" : "--is-hidden")}>
        <div className="mobile-only">
          <AccordionPanel heading="Delivery Windows">
            <div className="a-filter-facet-group">
              {(this.props.filterState.filter && this.props.filterState.filter.deliveryWindows
              && this.props.filterState.filter.deliveryWindows.map(name => 
                this.props.filterState.filter && !!name && (
                    <div className="a-filter-facet" key={name}>
                        <CheckboxInput 
                            name={name} 
                            title={name} 
                            handleChange={this.onFilterSelectionDeliveryWindow.bind(this)}
                            value={name} 
                            checked={this.props.filterState.filter.activeFilters.deliveryWindows.indexOf(name) !== -1} />
                    </div>
                )
              ))}
            </div>
          </AccordionPanel>
        </div>
        <div className="mobile-only">
          <AccordionPanel heading="Sustainability">
            <div className="a-filter-facet-group">
              {(this.props.filterState.filter && this.props.filterState.filter.sustainability
              && this.props.filterState.filter.sustainability.map(sustainabilityName => 
                this.props.filterState.filter && !!sustainabilityName && (
                    <div className="a-filter-facet" key={sustainabilityName}>
                        <CheckboxInput 
                            name={sustainabilityName} 
                            title={sustainabilityName} 
                            handleChange={this.onFilterSelectionSustainability.bind(this)}
                            value={sustainabilityName} 
                            checked={!!this.props.filterState.filter.activeFilters.sustainability
                              && this.props.filterState.filter.activeFilters.sustainability.indexOf(sustainabilityName) !== -1} />
                    </div>
                )
              ))}
            </div>
          </AccordionPanel>
        </div>
        <AccordionPanel heading="Product Groups">
          <div className="a-filter-facet-group">
            {(this.props.filterState.filter && this.props.filterState.filter.productGroups
            && this.props.filterState.filter.productGroups.map(productGroupName => 
              this.props.filterState.filter && !!productGroupName && (
                  <div className="a-filter-facet" key={productGroupName}>
                      <CheckboxInput 
                          name={productGroupName} 
                          title={productGroupName} 
                          handleChange={this.onFilterSelectionProductGroup.bind(this)}
                          value={productGroupName} 
                          checked={this.props.filterState.filter.activeFilters.productGroups.indexOf(productGroupName) !== -1} />
                  </div>
              )
            ))}
          </div>
        </AccordionPanel>
        <AccordionPanel heading="Product Categories">
          <div className="a-filter-facet-group">
            {(this.props.filterState.filter && this.props.filterState.filter.productCategories
            && this.props.filterState.filter.productCategories.map(productCategoryName => 
              this.props.filterState.filter && !!productCategoryName && (
                  <div className="a-filter-facet" key={productCategoryName}>
                      <CheckboxInput 
                          name={productCategoryName} 
                          title={productCategoryName} 
                          handleChange={this.onFilterSelectionProductCategory.bind(this)}
                          value={productCategoryName} 
                          checked={this.props.filterState.filter.activeFilters.productCategories.indexOf(productCategoryName) !== -1} />
                  </div>
              )
            ))}
          </div>
        </AccordionPanel>
      </div>
    )
  }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
  return {
    filterState: states.session.filter
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
  return {
    getFilter: async () => {
  
      await dispatch(getFilter())
      console.log('getFIlter completed [UI]')
    },
    applyFilter: async (filter: Filter) => {
      await dispatch(applyFilter(filter))
      console.log('apply filter completed [UI]')
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterComponent)