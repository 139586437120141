import React from 'react'
import { useParams} from "react-router-dom";
import { useSelector } from "react-redux";

// Models.
import { OrderType } from '../../models/OrderType';

// Store.
import { IUserSate } from '../../store/user/reducers';

interface Props {
    shops: any[]
    orderTypes: any[]
    applyChanges: Function
    orderType?: string
}

interface stepLabel {
    [id: string]: string
}

interface ParamTypes {
    orderType: string
}

const CreateOrderBody: React.FC<Props> = (props) => {
    const { shops, orderTypes, applyChanges } = props;
    let { orderType } = useParams<ParamTypes>();
    const userState = useSelector((state: IUserSate) => state.user);
    let [currentStep, setCurrentStep] = React.useState('');
    let [initialized, setInitialized] = React.useState(false);
    let [selected, setSelected] = React.useState<OrderType>({
        orderType: '',
        delivery: '',
        description: '',
        type: '',
    })
    let [steps, setSteps] = React.useState<string[]>([])
    let [selectedShop, setShop] = React.useState<any>(null);
    let [salespersonNote, setComment] = React.useState<string>('');
    const stepLabels: stepLabel = {
        orderType: 'Order type',
        shop: 'Shop',
        final: 'Create'
    }

    React.useEffect(() => {
        if (!initialized && shops && orderTypes.length) initializeSteps();
        return () => {};
    });

    const onSelectOrderType = (type: OrderType) => {
        setSelected(type);
        nextStep()
    }
    
    const onSelectShop = (shop: any) => {
        setShop(shop)
        nextStep()
    }

    const nextStep = () => {
        const nextStep = steps[steps.indexOf(currentStep) + 1];
        setCurrentStep(nextStep);
    }
    
    const onApplyChanges = (event: any) => {
        event.preventDefault();

        if (selected && selected.orderType !== '') {
            applyChanges(selected.orderType, selectedShop.customerId, salespersonNote || undefined);
        }
    }
    
    const handleCommentChange = (event: any) => {
        const { value } = event.currentTarget;
        setComment(value);
    }

    const renderHeader = () => {
        return (
            <div className="stepwizard">
                <div className="stepwizard-row setup-panel">
                    {
                        steps.map((step, index) => {
                            return (
                                <div className="stepwizard-step col-xs-3" key={index}> 
                                    <button
                                        onClick={() => {
                                            if (currentStep !== step &&
                                                index < steps.indexOf(currentStep)) {
                                                    setCurrentStep(step);
                                            }
                                        }}
                                        className={
                                            "button button-circle" + (currentStep === step ? ' button-success' : ' button-default')
                                            + (steps.length === (index + 1) ? ' last' : '')
                                        }
                                    >{index+1
                                            }
                                    </button>
                                    <p><small>{stepLabels[step]}</small></p>
                                </div>        
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    const initializeSteps = () => {
        if (props.orderType && orderTypes.length) {
            const type = orderTypes.find((t: OrderType) => t.orderType === props.orderType)

            setSelected(type)
        }

        if (orderTypes.length === 1) {
            setSelected(orderTypes[0]);
        }

        if (orderTypes.length > 1 && !orderType && !props.orderType) {
            steps.push('orderType')
        }

        if (orderTypes.length > 1 && orderType) {
            selected = orderTypes.find((type: OrderType) => {
                return type.orderType === orderType;
            })
            setSelected(selected);
        }
        
        if (shops.length > 1) {
            steps.push('shop')
        } else if (shops.length === 1) {
            setShop(shops[0])
        } else if (shops.length === 0) {
            setShop({
                name: userState.user.user.customerData.Name,
                customerId: userState.user.user.customerData.CustomerId,
                address: '',
                city: '',
            })
        }

        steps.push('final')

        setSteps(steps);
        setCurrentStep(steps[0]);
        setInitialized(true)
    }

    return (
        <div>
            {steps.length > 1 && renderHeader()}
            {
                currentStep === 'orderType' &&
                <table className="striped-table">
                    <tbody>
                        {
                            orderTypes && 
                            orderTypes.map((type, key) => {
                                return (
                                    <tr onClick={() => onSelectOrderType(type)} key={key} >
                                        <td>
                                            { type.orderType }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            }
            {
                currentStep === 'shop' && 
                <table className="striped-table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Customer ID</th>
                            <th>Address</th>
                            <th>City</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            shops.map((shop, key) => {
                                return (
                                    <tr onClick={() => onSelectShop(shop)} key={key} >
                                        <td>{ shop.name }</td>
                                        <td>{ shop.customerId }</td>
                                        <td>{ shop.address }</td>
                                        <td>{ shop.city }</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            }
            {
                currentStep === 'final' &&
                <form onSubmit={onApplyChanges}>
                    <div className="form-section form-section__order-modal-last-step">
                        <h2>Order information</h2>
                        <div className="form-control">
                            <span>
                                Order type: { selected && selected.orderType }
                            </span><br/>
                            <span>
                                Shop: { selectedShop.name }
                            </span><br/>
                        </div>
                        <div className="form-section">
                            <h2>Order comment</h2>
                            <div className="form-control">
                                <input
                                    name='salespersonNote'
                                    type="text"
                                    placeholder=''
                                    onChange={handleCommentChange}
                                    value={salespersonNote || ''}
                                    maxLength={30}
                                />
                            </div>
                            <div className="form-control">
                                <button className="po__order-btn">Create order</button>
                            </div>
                        </div>
                    </div>
                </form>
            }
        </div>
    )
}

export default CreateOrderBody;