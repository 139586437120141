import React, { Component } from 'react'
import Burger from './Burger';
import { FaTimes } from 'react-icons/fa'

interface IProps {
    isShowing?: boolean,
    onClose?: Function
}

// const SidebarMenu: React.FC<IProps> = (props) => {
export class SidebarMenu extends Component<IProps> {
    state = {
        showSidebar: false,
    }
    // const { isShowing } = props;
    childRef = React.createRef<Burger>();
    node = React.createRef<HTMLDivElement>();
    // const [showSidebar, setValue] = React.useState(false);

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = (e: any) => {
        if(this.node && this.node.current && this.node.current.contains(e.target)) {
            return;
        }

        if(this.node && this.node.current && e.target.classList.contains('ignore-clickoutside')) {
            return;
        }
        
        // outside click 
        if (this.props.isShowing) {
            console.log('close the sidebar')
            this.props.onClose && this.props.onClose()
            this.setState({showSidebar: !this.state.showSidebar})
        }
    };

    // toggle = () => {
    //     this.setState({showSidebar: !this.state.showSidebar}, () => {
    //         if (this.childRef.current) {
    //             this.childRef.current.toggle();
    //         }
    //     })
    // }

    render() {
        return (
            <div className={"sidebar-menu__wrapper " + (this.props.isShowing ? "--is-active" : "--is-hidden")} ref={this.node}>
                <div className="modal-header__close" onClick={() => this.props.onClose && this.props.onClose() }>
                    <FaTimes />
                </div>
                <div>
                    { this.props.children }
                </div>
            </div>
        )
    }
    
    
}

export default SidebarMenu;