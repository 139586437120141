import React from 'react'
import { useDispatch } from "react-redux";

import Modal from '../layout/Modal/Modal';

// Store.
import { deleteOrder, removeClosedOrderToRecentAction } from '../../store/order/actions';

interface Props {
    show: boolean
    shouldHide: Function
    orderId: number
    order: any
    onClickConfirmDelete?: Function
}

const ShopOverlay: React.FC<Props> = (props) => {
    const { shouldHide, show, order, onClickConfirmDelete } = props;
    const dispatch = useDispatch();

    const onClickDeleteOrderConfirm = () => {
        if (order.orderState === 1) {
            dispatch(removeClosedOrderToRecentAction(order.appOrderId))
            shouldHide();
        } else {
            dispatch(deleteOrder(order.appOrderId))
            shouldHide();
        }

        if (onClickConfirmDelete) {
            onClickConfirmDelete(order);
        }
    }

    const renderModal = () => (
        <div className="delete-order-modal">
            <div style={{padding: '20px'}}>
                <h2>{order && order.navImportRef  ? 'Close order' : 'Delete order'}</h2>
                {
                    order && order.orderState === 1 &&
                    <p>
                        Dont worry, you're able to import this order again at anytime.    
                    </p>
                }
                {
                    order && order.orderState === 0 && !order.navImportRef &&
                    <p>Are you sure you want to delete this order?</p>
                }
                {
                    order && order.orderState === 0 && order.navImportRef &&
                    <p>The order will be closed and your latest changes will be lost. If you want to submit your latest changes please push cancel and check out the order.</p>
                }
            </div>
            <div className="delete-order-modal__footer">
                <button className="btn" onClick={() => onClickDeleteOrderConfirm()}>Yes</button>
                <button className="btn" onClick={() => shouldHide()}>Cancel</button>
            </div>
        </div>
    );

    return (
        <Modal
            children={renderModal()}
            size={'sm'}
            hide={shouldHide}
            isShowing={show}
            bodyClasses={'confirm-modal'}
            hideFooter={true}/>
    )
}

export default ShopOverlay;