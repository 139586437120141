import React, { Component } from 'react'

import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'


// Components.
import Header from './Header';
import BasketComponent from '../b2b/BasketComponent';

// Store.
import { RootState } from '../../store';
import { ICollectionState  } from '../../store/collections/reducers'
import { IProductState  } from '../../store/products/reducers'
import { getCollections  } from '../../store/collections/actions';
import { getOrderTypes } from '../../store/products/actions';
import { IUserSate } from '../../store/user/reducers';
import { IOrderState } from '../../store/order/orderStore';
import { resetFilter } from '../../store/session/actions'
import { Filter } from '../../models/Filter';

interface State {
    showSidebar: boolean,
    showOrderModal: boolean,
    collectionName: string,
    orderTypeName: string,
    editMode: boolean
}
  
interface OwnProps {
    match?: {params: {collection: string, orderType: string}},
    children?: any
    history?: any
    location?: any
    staticContext?: any
}
  
interface DispatchProps {
    getCollections: (orderType: string) => void
    getOrderTypes: (disallowedTypes?: string[]) => void
    resetFilter: (filter: Filter) => void
}
  
interface StateProps {
    productState: IProductState
    collectionState: ICollectionState
    userSate: IUserSate
    orderState: IOrderState
}
  
type Props = StateProps & OwnProps & DispatchProps;

class MainContainer extends Component<Props, State> {

    state = {
        showSidebar: false,
        showOrderModal: false,
        collectionName: '',
        orderTypeName: '',
        editMode: false
    }

    componentDidMount() {
        this.setMenuItems();

        const { order } = this.props.orderState;

        if (order) {

            if (this.state.editMode && !order) {
                this.setState({ editMode: false })
            }
        }

        if (!this.props.location.pathname.includes('/search')) {
            localStorage.setItem('search:prevPage', this.props.location.pathname);
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { productState, collectionState, location } = this.props;

        const { order } = this.props.orderState;

        if (order) {
            if (order.orderheader.navImportRef && order.orderheader.orderState === 0 && !this.state.editMode) {
                this.setState({ editMode: true }, () => {
                    if (!this.props.location.pathname.includes('/edit') && !this.props.location.pathname.includes('/search')) {
                        // this.props.history.push('/edit')
                    }
                })
            } else if (order.orderheader.navImportRef && order.orderheader.orderState === 0 && this.state.editMode) {
                if (!this.props.location.pathname.includes('/edit') && !this.props.location.pathname.includes('/search')) {
                    // this.props.history.push('/edit')
                }
            }
        }

        if (this.state.editMode && !order) {
            this.setState({ editMode: false })
        }

        if (location !== prevProps.location) {
            this.setMenuItems();
        }
        
        if ((!productState.orderTypes || !productState.orderTypes.length) && !productState.isFetchingOrderTypes) {
            const disallowedTypes = this.props.userSate.user && this.props.userSate.user.disallowedTypes ? this.props.userSate.user.disallowedTypes : []; 
            this.props.getOrderTypes(disallowedTypes);
        }

        if (
            (this.state.orderTypeName &&
                (this.state.orderTypeName !== prevState.orderTypeName && !collectionState.isFetchingCollectionMasters))
            ) {
                this.props.getCollections(this.state.orderTypeName);
        }

        if (prevProps.orderState.order && this.props.orderState.order &&
            prevProps.orderState.order.orderheader.appOrderId !== this.props.orderState.order.orderheader.appOrderId) {
                const order = this.props.orderState.order;

                if (order.orderheader.navImportRef && order.orderheader.orderState === 0 && !this.state.editMode) {
                    this.setState({ editMode: true }, () => {
                        if (!this.props.location.pathname.includes('/edit') && !this.props.location.pathname.includes('/search')) {
                            // this.props.history.push('/edit')
                        }
                    })
                } else {
                    this.setState({ editMode: false })

                    if (this.props.location.pathname.includes('/edit')) {
                        this.props.history.push('/')
                    }
                }
        }

        if (!this.props.location.pathname.includes('/search')) {
            localStorage.setItem('search:prevPage', this.props.location.pathname);
        }
    }

    setMenuItems = () => {
        let collectionName: any = null;
        let orderTypeName: any = null;

        if (this.props.match) {
            collectionName = this.props.match.params.collection;
            orderTypeName = this.props.match.params.orderType;

            this.setState({
                collectionName,
                orderTypeName,
            }, () => {
                //  this.props.getCollections(orderTypeName);
            });
        }
    }

    render() {
        const { children } = this.props;
        
        return (
            <>
                <Header
                    editMode={this.state.editMode}
                    {...this.props}
                />
                <div className="main-content">
                    { children }
                </div>
                <BasketComponent
                    showDropup={true}
                    showDashboardLink={true}
                    editMode={this.state.editMode}
                    {...this.props}
                />
            </>
        )
    }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
    return {
        productState: states.productStore.productStore,
        collectionState: states.collections.collections,
        userSate: states.user.user,
        orderState: states.order.order,
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
    return {
        getCollections: async (orderType: string) => {
            await dispatch(getCollections(orderType))
        },
        getOrderTypes: async (disallowedTypes?: string[]) => {
            await dispatch(getOrderTypes(disallowedTypes))
        },
        resetFilter: async (filter: Filter) => {
            await dispatch(resetFilter(filter))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MainContainer)
