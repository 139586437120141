import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import {RouteComponentProps, withRouter} from "react-router-dom";

// Store.
import { RootState } from '../../store';
import { IOrderState } from '../../store/order/orderStore';
import OrderApi from '../../store/order/OrderApi';
import { setOrder, getDashboardOrderState } from '../../store/order/actions'

// Components.
import Modal from '../layout/Modal/Modal';
import PropagateLoader from 'react-spinners/PropagateLoader'

interface State {
    showOverlay: boolean
    items: any[]
    isConfirming: boolean
    fetchingOrderId?: number
}
  
interface OwnProps {
}
  
interface DispatchProps {
    setOrder: (orderId: number) => void
    getDashboardOrderState: (orderId: number) => void
}
  
interface StateProps {
    orderState: IOrderState
}
  
type Props = StateProps & OwnProps & DispatchProps & RouteComponentProps;

export class StockHasChangedModal extends Component<Props, State> {

    state: State = { showOverlay: false, items: [], isConfirming: false }

    async componentDidMount() {
        const currentOrder = this.props.orderState.order

        if (currentOrder) {
            this.ensureStockHasChanged(currentOrder.orderheader.appOrderId);
        }
    }

    async componentDidUpdate(prevProps: Props) {
        const prevOrder = prevProps.orderState.order;
        const currentOrder = this.props.orderState.order
        let shouldCheck = false;
                
        if (prevOrder !== currentOrder && currentOrder) {
            if (prevOrder && prevOrder.orderheader.appOrderId !== currentOrder.orderheader.appOrderId) {
                shouldCheck = true;
                this.setState({ showOverlay: false})
            }

            if (!prevOrder && currentOrder) {
                shouldCheck = true;
            }
        }

        if (this.props.location !== prevProps.location) {
            if (this.props.location.pathname.includes('/checkout')
            || this.props.location.pathname.includes('/dashboard')) {
                shouldCheck = true;
            }
        }

        if (this.props.orderState.order && this.props.orderState.order.orderheader.orderIsFinal) {
            shouldCheck = false;
        }

        if (shouldCheck && currentOrder) {
            this.setState({ showOverlay: false}, () => {
                this.ensureStockHasChanged(currentOrder.orderheader.appOrderId);
            })
        }
    }

    ensureStockHasChanged = (orderId: number) => {
        this.setState({ fetchingOrderId: orderId }, async () => {
            const response = await OrderApi.ensureOrderStockChanges(orderId);

            if (response.data.length
                && (this.props.orderState.order && this.props.orderState.order.orderheader.appOrderId === this.state.fetchingOrderId)) {
                    this.setState({showOverlay: true, items: response.data})
            }
        })
    }

    confirmChanges = async () => {
        if (this.props.orderState.order) {
            this.setState({ isConfirming: true }, async () => {
                if (this.props.orderState.order) {
                    await OrderApi.ensureOrderStockChanges(this.props.orderState.order.orderheader.appOrderId, true);
                    this.props.getDashboardOrderState(this.props.orderState.order.orderheader.appOrderId)
                    this.props.setOrder(this.props.orderState.order.orderheader.appOrderId)
                    this.setState({showOverlay: false, items: [], isConfirming: false})
                } else {
                    this.setState({ isConfirming: false })    
                }
            })
        }
    }

    renderContent = () => {
        if (this.state.isConfirming) {
            return (
                <div className="loading-spinner-2" style={{paddingTop: '50px'}}>
                    <p>Confirming your changes</p>
                    <PropagateLoader />
                </div>
            )
        }

        const { order } = this.props.orderState;

        return (
            <div>
                <div className="text-center">
                    <h2 className="modal-title" id="modal-title">Stock has changed</h2>
                    {
                        order &&
                        <React.Fragment>
                            <p>
                                <strong>
                                    { order.orderheader.sellToCustomerName } B2B{order.orderheader.appOrderId} {order.orderheader.orderType} {order.orderheader.navImportRef} {order.orderheader.salespersonNote}
                                </strong>
                            </p>
                        </React.Fragment>
                    }
                    <p>
                        Following items has changed stock values.
                    </p>
                </div>
                <table className="table striped-table">
                    <thead>
                    <tr>
                        <th>Master Id</th>
                        <th className="co-review-table-details--adjust-center">Colorname</th>
                        <th className="co-review-table-details--adjust-center">Size</th>
                        <th className="co-review-table-details--adjust-center">Previous stock</th>
                        <th className="co-review-table-details--adjust-center">Current stock</th>        
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.items.map((item, key) => {
                            return (
                                <tr key={key}>
                                    <td>
                                        {item.masterid}
                                    </td>
                                <td>
                                    {item.colorname}
                                </td>
                                    <td>{item.sizename}</td>
                                    <td>{item.was}</td>
                                    <td>{item.isnow}</td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table> 
            </div>
        )
    }

    render() {
        if (!this.state.showOverlay) {
            return null;
        }
        return (
            <Modal
                children={this.renderContent()}
                size={'md'}
                hideClose={true}
                hide={() => console.log('hide stock has changed modal!')}
                footer={!this.state.isConfirming ?
                    <button
                        className="po__order-btn modal-footer__btn-center"
                        onClick={this.confirmChanges}>
                            OK
                    </button>
                    :
                    null                    
                }
                isShowing={this.state.showOverlay}
            />
        )
    }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
    return {
        orderState: states.order.order,
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
    return {
        setOrder: async (orderId: number) => {
            await dispatch(setOrder(orderId))
        },
        getDashboardOrderState: async (orderId: number) => {
            await dispatch(getDashboardOrderState(orderId))
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StockHasChangedModal))