import React from 'react';

import { FaChevronDown } from 'react-icons/fa';

interface Props {
    heading: any
    children: any
};

interface State {
    open: boolean,
    stateClass: string,
    accordionStyle: {
        height: number
    }
}

export class AccordionPanel extends React.Component<Props, State> {
    accordionPanelRef = React.createRef<HTMLDivElement>();

    state: State = {
        open: true,
        stateClass: '',
        accordionStyle: {
            height: 0
        }
    };

    componentDidUpdate(prevProps: Props) {
        this.updateHeight(prevProps.children);
    }

    updateHeight (children: any) {
        if (children !== this.props.children && this.state.open && this.accordionPanelRef.current) {
            this.setState({
                accordionStyle: {
                    height: this.accordionPanelRef.current.scrollHeight
                }
            });
        }
    }

    handleClick = () => {
        let height = 0;

        if (this.accordionPanelRef.current) {
            height = this.accordionPanelRef.current.scrollHeight;
            console.log(height, 'height :: handleClick :: accordionPanelRef')
        }
        //height = height > 280 ? 280 : height;

        if (this.state.open) {
            this.setState({
                open: false,
                stateClass: '',
                accordionStyle: {
                    height: 0
                }
            });
        } else {
            this.setState({
                open: true,
                stateClass: 'm-accordion-panel--open',
                accordionStyle: {
                    height
                }
            });
        }
    }

    render() {
        return (
            <div className={"m-accordion-panel" + (this.state.open ? " m-accordion-panel--open" : "")}>
                <div className="m-accordion-panel__header" onClick={this.handleClick}>
                    <div className="m-accordion-panel__heading">{this.props.heading}</div>
                    <div className="m-accordion-panel__state-indicator">
                        <FaChevronDown />
                    </div>
                </div>
                <div ref={this.accordionPanelRef} className="m-accordion-panel__content" style={this.state.accordionStyle}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default AccordionPanel;