import React from 'react';
import { FaTrash } from 'react-icons/fa';

import DeleteOrderModal from '../b2b/DeleteOrderModal';

interface IProps {
    order: any,
    onClickHandler: Function
    hide?: Function
}

const BasketDropUpItem: React.FC<IProps> = (props) => {
    
    const [showModal, setShowModal] = React.useState(false);
    const { onClickHandler, order, hide } = props;

    return (
        <div className="dropup-menu__item">
            <div className="content" onClick={() => {
                onClickHandler(order);
            }}>
                { order && order.appOrderId && 'B2B' + order.appOrderId}
                { order && order.sellToCustomerName && ' ' + order.sellToCustomerName}
                { order && order.orderType && ' ' + order.orderType}
                { order && order.navImportRef && ' ' +  order.navImportRef}
                { order && order.salespersonNote && ' ' + order.salespersonNote }
                
                {order.orderState === 0 && order.navImportRef
                && <span className="order-dropdown__label --completed" style={{marginLeft: '26px', top: '0'}}>Access to edit</span>}
                {order.orderState === 1 && order.navImportRef
                && <span className="order-dropdown__label --completed" style={{marginLeft: '26px', top: '0'}}>Approved order</span>}
            </div>
            <div className="actions" onClick={() => {
                hide && hide()
                setShowModal(true)
            }}>
                <span><FaTrash /></span>
            </div>
            <DeleteOrderModal
                show={showModal}
                shouldHide={() => {
                    setShowModal(false)
                }}
                orderId={order.appOrderId}
                order={order}
            />
        </div>
    )
}

export default BasketDropUpItem;