
import Style, { DeliveryWindow } from 'molo-shared/lib/models/Style';
import { MoloItem } from './MoloItem';
import { orderLine as OrderLine } from './Order';

type styleBeanTypes = 'style' | 'moloItem' | 'orderLine';

export class StyleBean {
    masterId: string = '';
    colorCode: string = '';
    description: string = '';
    colorName: string = '';
    seasonCode: string = '';
    colorBlocked: number = 0;
    currencyCode: string = 'EUR';
    deliveryWindow?: DeliveryWindow;
    discontinued:boolean = false;
    fabricComposition?: string;
    styleNote?: string;
    gots: string = '';
    oekotex: string = '';
    organic: boolean = false;
    ecovero:boolean = false;
    colorComment?: string;
    styleComment?: string;
    whp: number = 0;
    msrp: number = 0;
    totalDisp: number = 0;
    collectionName: string = '';
    navCollection: string = '';
    orderType: string = '';
    recycled: boolean = false;
    b2BColorComment: string = '';
    b2BMasterComment: string = '';
    priceType = 'NoDiff';
    ocsno: string = '';
    ocsblended:string ='';
    grsno:string = '';

    getWHPWithDiscount(discount?: number) {
        discount = discount || 0;
        return this.whp - (this.whp * (discount/100))
    }

    static build(data: any, type: styleBeanTypes) {
        let bean = new StyleBean();
        console.log('debug here');
        switch(type) {
            case 'moloItem':
                const moloItem: MoloItem = data;
                
                bean.masterId = moloItem.masterId;
                bean.colorCode = moloItem.colorCode;
                bean.description = moloItem.description;
                bean.colorName = moloItem.colorName;
                bean.seasonCode = moloItem.seasonCode;
                bean.colorBlocked = moloItem.colorBlocked;
                bean.gots = moloItem.gots || '';
                bean.oekotex = moloItem.oekoTex || '';
                bean.whp = moloItem.whp;
                bean.msrp = moloItem.msrp;
                bean.priceType = moloItem.priceType;
                bean.discontinued = moloItem.discontinued || false;
                bean.ocsblended = moloItem.ocsblended ||'';
                bean.grsno      = moloItem.grsno || '';
                
                // this.currencyCode = moloItem.cu;
                break;
            case 'style':
                // const randomNumber = Math.floor(Math.random() * 11);  
                const style: Style = data;

                if (!style) {
                    return bean;
                }

                bean.masterId = style.masterId;
                bean.colorCode = style.colorCode;
                bean.description = style.description;
                bean.colorName = style.colorName;
                bean.seasonCode = style.seasonCode;
                bean.colorBlocked = style.colorBlocked;
                bean.currencyCode = style.currencyCode;
                bean.deliveryWindow = style.deliveryWindow;
                bean.fabricComposition = style.fabricComposition;
                bean.styleNote = style.styleNote;
                bean.gots = style.gots || '';
                bean.ocsno = style.ocsno || '';
                bean.ocsblended = style.ocsblended ||'';
                bean.grsno = style.grsno || '';
                bean.discontinued = style.discontinued || false;
                bean.priceType = style.priceType
                bean.organic = style.organic;
                bean.ecovero = style.ecovero;
                bean.oekotex = style.oekotex || '';
                bean.styleComment = style.styleComment || '';
                bean.colorComment = style.colorComment || '';
                bean.whp = style.whp;
                bean.msrp = style.msrp;
                bean.totalDisp = style.totalDisp;
                bean.collectionName = style.collectionName;
                bean.navCollection = style.navCollection;
                bean.orderType = style.orderType;
                bean.recycled = style.recycled;
                bean.b2BColorComment = style.b2BColorComment;
                bean.b2BMasterComment = style.b2BMasterComment;
                break;
            case 'orderLine':
                
                const orderLine: OrderLine = data;
                bean.masterId = orderLine.masterid;
                bean.colorCode = orderLine.colorcode;
                bean.description = orderLine.description;
                bean.colorName = orderLine.colorname;
                bean.seasonCode = orderLine.seasoncode;
                bean.colorBlocked = orderLine.colorBlocked ? 1 : 0;
                bean.whp = orderLine.unitPrice;
                bean.ocsno = orderLine.ocsno;
                bean.grsno = orderLine.grsno;

                break;
            default:
                // code block
        }

        return bean;
    }
}