import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { appConfig } from '../../config';
import axios from '../globalAxios';
import { uniq } from 'lodash';

import { OrderType } from '../../models/OrderType';
import Style, { DeliveryWindow } from 'molo-shared/lib/models/Style';
import ProdctApi from './ProductApi';

const baseUrl = appConfig.api.baseUrl;

export interface IGetOrderType {
    type: 'FETCH_ORDER_TYPES_SUCCESS',
    orderTypes: OrderType[]
}

export interface IGetMoloItem {
    type: 'FETCH_MOLO_ITEM_SUCCESS',
    moloItems: Style[]
}

export interface ISetDeliveryWindows {
    type: 'SET_DELIVERY_WINDOWS_SUCCESS',
    deliveryWindows: DeliveryWindow[]
}

export interface ISetFetcingorderTypes {
    type: 'SET_FETCHING_ORDER_TYPES'
    isFetchingOrderTypes: boolean
}

export type ProductsActions = IGetOrderType | IGetMoloItem | ISetFetcingorderTypes | ISetDeliveryWindows

// Action Creators
export const getOrderTypesSuccess = (orderTypes: OrderType[]): IGetOrderType => {
    return { type: 'FETCH_ORDER_TYPES_SUCCESS', orderTypes }
}

export const getMoloItemSuccess = (moloItems: Style[]): IGetMoloItem => {
    return { type: 'FETCH_MOLO_ITEM_SUCCESS', moloItems }
}

export const setDeliveryWindowsSuccess = (deliveryWindows: DeliveryWindow[]): ISetDeliveryWindows => {
    return { type: 'SET_DELIVERY_WINDOWS_SUCCESS', deliveryWindows }
}

export const setFetchingOrderTypes = (isFetchingOrderTypes: boolean): ISetFetcingorderTypes => {
    return { type: 'SET_FETCHING_ORDER_TYPES', isFetchingOrderTypes }
}

/**
 * OrderTypes/GetOrderTypes
 */
export const getOrderTypes = (disallowedTypes?: string[]): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        try {
            dispatch(setFetchingOrderTypes(true))
            const response = await ProdctApi.getOrderTypes();

            let types = response.data;

            if (disallowedTypes) {
                types = types.filter((type: any) => disallowedTypes.indexOf(type.type.toLowerCase()) === -1)
            }

            dispatch(getOrderTypesSuccess(types))
        } catch (e) {

        } finally {
            dispatch(setFetchingOrderTypes(false))
        }
    }
}

 /**
  * /Items/GetMoloItem/{master}/{currencycode}
  * 
  * @param masterId 
  * @param currencyCode 
  */
export const getMoloItem = (masterId: string, currencyCode: string, pricegroup: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
            const url = baseUrl + 'Items/GetMoloItem/' + masterId + '/' + currencyCode + '/' + pricegroup;
            console.log('GetMoloITem...1 ');
        
        try {
            const response = await axios.post(url);
            dispatch(getMoloItemSuccess(response.data))
        } catch (e) {

        }
    }
}

export const setDeliveryWindows = (products: Style[]): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        const allDeliveryWindows = products.map(style => style.deliveryWindow);
        const deliveryWindows: DeliveryWindow[] = [];
        const uniqueWindows = uniq(allDeliveryWindows.map(window => window.window));

        for (const key of uniqueWindows) {
            const window = allDeliveryWindows.find(window => window.window === key);
            if (window) {
                deliveryWindows.push(window);
            }
        }
        
        dispatch(setDeliveryWindowsSuccess(deliveryWindows));
    }
}
