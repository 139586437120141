import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PropagateLoader from 'react-spinners/PropagateLoader'
import ClipLoader from 'react-spinners/ClipLoader';

// Store.
import { RootState } from '../../store';
import { getClosedOrders, getOpenOrders, getDashboardOrderState, addClosedOrderToRecent } from '../../store/order/actions';
import { IOrderState } from '../../store/order/orderStore';
import { updatePassword } from '../../store/user/actions';
import { IUserSate } from '../../store/user/reducers';
import OrderApi from '../../store/order/OrderApi';
import ProductApi from '../../store/products/ProductApi';

// Models.

// Components.

interface IErrors {
    oldPassword?: string | null,
    newPassword?: string | null,
    newPassword2?: string | null,
}

interface IFormData {
    oldPassword: string;
    newPassword: string;
    newPassword2: string;
}

interface State {
    formData: IFormData,
    errors: IErrors,
    showErrors: boolean,
    currentShop?: any,
    shops?: any[],
    shopNavOrders?: any[]
    editableNavOrders: any[],
    loadingNavOrder: boolean
    loadingShopOrders: boolean,
    tabIndex: number,
    orderTypes: string[]
}
  
interface OwnProps {
    shouldHide?: Function
    history: any
    isProfile?: boolean
}
  
interface DispatchProps {
    getClosedOrders: () => void
    getOpenOrders: () => void
    updatePassword: (email: string, oldPassword: string, newPassword: string) => void
    getDashboardOrderState: (orderId: number, should?: boolean) => void
    addClosedOrderToRecent: (order: any, should?: boolean) => any
}
  
interface StateProps {
    orderState: IOrderState
    userState: IUserSate
}
  

type Props = StateProps & OwnProps & DispatchProps;

export class MyMolo extends Component<Props, State> {

    state: State = {
        formData: {
            oldPassword: '',
            newPassword: '',
            newPassword2: '',
        },
        errors: {},
        showErrors: false,
        shopNavOrders: [],
        editableNavOrders: [],
        loadingNavOrder: false,
        tabIndex: 0,
        loadingShopOrders: false,
        orderTypes: []
    };

    async componentDidMount() {

        // const shopsResponse = await OrderApi.getShops()

        // let currentShop = shopsResponse.data[0];

        // if (!currentShop) {
        //     currentShop = {
        //         customerId: this.props.userState.user.customerData.CustomerId,
        //         name: this.props.userState.user.customerData.Name,
        //     }
        // }

        // const response = await ProductApi.getOrderTypes();
        // const orderTypes = response.data.map((type: any) => type.orderType)

        // this.setState({ shops: shopsResponse.data, currentShop, orderTypes }, () => {
        //     if (currentShop) {
        //         this.onChangeShop(currentShop);
        //     }
        // })

        // this.props.getClosedOrders();
        // this.props.getOpenOrders();

        // if (this.props.isProfile) {
        //     this.setState({ tabIndex : 2 })
        // }
    }

    handleSubmit = async (event: any) => {
        event.preventDefault()
        const formData = new FormData(event.target);
        const data: IFormData = {
            // @ts-ignore
            oldPassword: formData.get('oldPassword'),
            // @ts-ignore
            newPassword: formData.get('newPassword'),
            // @ts-ignore
            newPassword2: formData.get('newPassword2'),
        }
    
        if (this.checkErrors(data)) {
            const email = this.props.userState.user.email;
            // @ts-ignore
            this.props.updatePassword(email, data.oldPassword, data.newPassword)
        }
    }

    checkErrors = (data: IFormData) => {
        const errors:IErrors = {};

        if (!data.oldPassword || data.oldPassword === '') {
            errors.oldPassword = 'Required';
        }

        if (!data.newPassword || data.newPassword === '') {
            errors.newPassword = 'Required';
        }

        if (!data.newPassword2 || data.newPassword2 === '') {
            errors.newPassword2 = 'Required';
        }

        if (data.newPassword2 && data.newPassword2 !== '' &&
            data.newPassword && data.newPassword !== '' &&
            data.newPassword !== data.newPassword2) {
            errors.newPassword2 = 'Both passwords need to be the same';
        }

        this.setState({
            showErrors: !!Object.keys(errors).length,
            errors
        })

        return !Object.keys(errors).length;
    }

    handleOrderClick = async (orderId: number, isNavOrder: boolean = false) => {
        if (!isNavOrder) {
            this.props.history.push('/dashboard/' + orderId);
            this.props.getDashboardOrderState(orderId)
        } else {

            this.setState({loadingNavOrder: true})

            const navOrderResponse = await OrderApi.openNavOrder(orderId)
            const createdapporderid = navOrderResponse.data.createdapporderid

            if (navOrderResponse) {
                this.props.history.push('/dashboard/' + createdapporderid);
                this.setState({loadingNavOrder: false})
                this.props.getDashboardOrderState(createdapporderid, true)
            }
        }

        this.props.shouldHide && this.props.shouldHide()
    }

    onChangeShop = (shop: any) => {
        this.setState({ currentShop: shop, loadingShopOrders: true }, async () => {
            // Peak closed orders.
            const response = await OrderApi.peakNavOrders(shop.customerId)

            this.setState({
                shopNavOrders: response.data,
                loadingShopOrders: false,
                // editableNavOrders
            })
        })
    }

    isAlreadyOpen = (order: any) => {
        var existing = this.props.orderState.orders.find(function(item) {
            return item.navorderid === order.navorderid;
        });

        return existing;
    }

    renderNavOrderTable = (orders: any) => {
        return (
            <table className="striped-table table-fixed table-hover completed-orders-table">
                <thead>
                    <tr>
                        <th>Order number</th>
                        <th>Latest order number</th>
                        <th>Reference order number</th>
                        <th>Customer</th>
                        <th>Type</th>
                        <th>External Doc No</th>
                        <th>Order Comment</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        
                        orders.map((order: any, key: any) => {
                            return (
                                <tr key={key} onClick={() => {
                                    if (order.beingmodifiedby === '') {
                                        this.handleOrderClick(order.navorderid, true)
                                    }
                                }}>
                                    <td>{order.orderidcreated}</td>
                                    <td>{order.orderidmodified}</td>
                                    <td>{order.navorderid}</td>
                                    <td>
                                        <span>{order.selltocustomerno}</span><br/>
                                        <span>{order.shipttoname}</span>
                                        <span>{order.shiptoaddress}, {order.shiptocity}, {order.shiptocounty}</span>
                                    </td>
                                    <td>{order.ordertype}</td>
                                    <td>
                                        {
                                            order.inscanner && !this.isAlreadyOpen(order) &&
                                            <span
                                                className="item-list__item__blocked">
                                                In scanner
                                            </span>
                                        }
                                        {
                                            '' !== order.beingmodifiedby && !this.isAlreadyOpen(order) &&
                                            <span
                                            className="item-list__item__blocked">
                                                Being modified by : { order.beingmodifiedby }
                                            </span>
                                        }
                                        {
                                            this.isAlreadyOpen(order) &&
                                            <span
                                            className="item-list__item__blocked">
                                                Already open!
                                            </span>
                                        }
                                        {
                                            !order.orderisfinal
                                            && order.beingmodifiedby === ''
                                            && !order.inscanner
                                            && !this.isAlreadyOpen(order) &&
                                            <span
                                                className="item-list__item__blocked">
                                                Access to edit
                                            </span>
                                        }
                                        
                                        {order.externaldocumentno}
                                    </td>
                                    <td>{order.salespersonnote}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        )
    }

    render() {
        const { showErrors, errors, shops, loadingNavOrder } = this.state;

        

        // if (loadingNavOrder) {
        //     return (
        //         <div className="my-molo">
        //             <div className="loading-spinner-1">
        //                 <p>Loading Order</p>
        //                 <PropagateLoader />
        //             </div>
        //         </div>
        //     )
        // }

        return (
            <div className="my-molo">
                <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex })}>
                    <TabList>
                        <Tab>Profile</Tab>
                    </TabList>

                    {/* <TabPanel>
                        <h5>Orders ( { this.props.orderState.orders.length } ) 
                            <br/>
                            <span className="info-help">
                                Click on an order to open it.
                            </span>
                        </h5>
                        
                        <table className="striped-table table-hover">
                            <thead>
                                <tr>
                                    <th>Order#</th>
                                    <th>Customer</th>
                                    <th>Type</th>
                                    <th>Order Comment</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    
                                    this.props.orderState.orders.map((order, key) => {
                                        return (
                                            <tr key={key} onClick={() => this.handleOrderClick(order.appOrderId)}>
                                                <td>B2B{order.appOrderId}</td>
                                                <td>
                                                    <span>{order.sellToCustomerNo}</span><br/>
                                                    <span>{order.sellToCustomerName}</span>
                                                    <span>{order.sellToAddress}, {order.sellToCity}, {order.sellToCountry_RegionCode}</span>
                                                </td>
                                                <td>{order.orderType}</td>
                                                <td>{order.salespersonnote}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </TabPanel> */}

                    {/* <TabPanel>
                        {shops &&
                            <div>
                                {
                                    shops.length > 1 &&
                                    <>
                                        <h5>
                                            Change shop 
                                            <br/>
                                            <span className="info-help">
                                                Choose the shop you want to see orders for.
                                            </span>
                                        </h5>
                                        <div style={{ display: 'flex' , width: '100%'}}>
                                            <select
                                                style={{maxWidth: '500px'}}
                                                onChange={(event: any) => {
                                                    const selectedShop = shops[event.target.value];
                                                    this.onChangeShop(selectedShop)
                                                }}
                                                placeholder="select shop">
                                                {
                                                    shops.length &&
                                                    shops.map((shop, index) => {
                                                        return (
                                                            <option key={index} value={index}>
                                                                { shop.name }
                                                            </option>
                                                        )  
                                                    })
                                                }
                                            </select>
                                            {
                                                this.state.loadingShopOrders &&
                                                <div style={{ left: '16px', position: 'relative'}}>
                                                    <ClipLoader size={30}/>
                                                </div>
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        }
                        <h5>
                            Orders ( { this.state.shopNavOrders && this.state.shopNavOrders.length } ) 
                            <br/>
                            <span className="info-help">
                                Click on an order to preview it.
                            </span>
                        </h5>

                        {this.state.shopNavOrders && this.renderNavOrderTable(this.state.shopNavOrders)}
                    </TabPanel> */}
           
                    <TabPanel>
                        <form onSubmit={this.handleSubmit} noValidate={true}>
                            <label>Password</label>
                            <input type="password" placeholder="Enter Current Password" name="oldPassword" id="oldpassword" required/>
                            { showErrors && errors.oldPassword && <div className="form-error">{ errors.oldPassword }</div> }
                            <input type="password" placeholder="Enter New Password" name="newPassword" required/>
                            { showErrors && errors.newPassword && <div className="form-error">{ errors.newPassword }</div> }
                            <input type="password" placeholder="Re-type New Password" name="newPassword2" required/>
                            { showErrors && errors.newPassword2 && <div className="form-error">{ errors.newPassword2 }</div> }
                            <input type="submit" value="Change Password" />
                        </form>
                    </TabPanel>
                </Tabs>
            </div>
        )
    }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
    return {
        orderState: states.order.order,
        userState: states.user.user
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
    return {
        getClosedOrders: async () => {
            await dispatch(getClosedOrders())
        },
        getOpenOrders: async () => {
            await dispatch(getOpenOrders())
        },
        updatePassword: async (email: string, oldPassword: string, newPassword: string) => {
            await dispatch(updatePassword(email, oldPassword, newPassword))
        },
        getDashboardOrderState: async (orderId: number, should?: boolean) => {
            await dispatch(getDashboardOrderState(orderId, should))
        },
        addClosedOrderToRecent: async (order: any) => {
            await dispatch(addClosedOrderToRecent(order))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyMolo)