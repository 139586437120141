import React from 'react'
import { useSelector } from "react-redux";

import 'intersection-observer';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Store.
import { RootState } from '../../store/';

// Models.
import { StyleBean } from '../../models/Beans'

// utils.
import { roundAmount, getWHPWithDiscount } from '../../utils/priceHelper';
import { appConfig } from '../../config';
import shirtImg from '../../images/silhouette/shirt.jpg';

// Icons.
import { FaComment } from 'react-icons/fa';

const baseUrl = appConfig.media.baseUrl;

const ProductListItem: React.FC<
    {
        product:StyleBean,
        discount?: number,
        inBasket?: boolean,
        lazyLoadMoloMaster?: boolean
        quantityOveride?: number
        quantityLabel?: string
        scrollPosition?: any
        hidePrice?: boolean
    }> = (props) => {
    const { product, hidePrice } = props;

    const user = useSelector((state: RootState) => state.user.user);
    const { seasonCode, masterId, colorCode } = product;
    const imageUrl = baseUrl + 'media/productimage/' + seasonCode + '/' + masterId + '/' + colorCode + '/small?cache=?10052021';
    // const imageUrl = 'https://picsum.photos/200/300';
    // const [moloMaster, setMoloMaster] = React.useState<any>(null);
    // const quantityLabel = props.quantityLabel || 'Quantity';

    const getPriceString = () => {
        // const master = props.lazyLoadMoloMaster && moloMaster ? moloMaster : product;
        const master = product;
        const currencyCode = master.currencyCode || user.user.currencyCode;
        const discount = props.discount || 0;
        const whp = master.whp || 0;
        const msrp = master.msrp || 0;
        const prefix = master.priceType === 'Diff' ? 'From ' : '';

        return prefix + (roundAmount(getWHPWithDiscount(whp, discount)) + (msrp !== 0 ? '/' + roundAmount(msrp) + ' ' + currencyCode : ''))
    }

    // const getQuantity = () => {
    //     const master = props.lazyLoadMoloMaster && moloMaster ? moloMaster : product;
    //     const totalDisp = master.totalDisp || 0;

    //     if (props.quantityOveride) {
    //         return props.quantityOveride;
    //     }

    //     return totalDisp !== 9999 ? totalDisp : (<span className="infiniteAvailability"></span>)
    // }

    return (
        <div className={"pl-item" + (props.inBasket ? " in-basket" : "")}>
            {
                (product.b2BMasterComment || product.b2BColorComment) && <span className="pl-item__comment-icon"><FaComment /></span>
            }

            {/* <img src={imageUrl} alt={product.description}  className="pl-item__image fade-in-up" /> */}

            <LazyLoadImage
                alt={product.description}
                threshold={200}
                placeholder={<div className="pl-item__image fade-in-up"></div>}
                className="pl-item__image fade-in-up"
                src={imageUrl} // use normal <img> attributes as props
                visibleByDefault={true}
                scrollPosition={props.scrollPosition}
                placeholderSrc={shirtImg}
            />

            <p className="pl-item__text">
                <span className="--highlighted">{ product.description }</span> { product.colorName }
            </p>
            <p className="pl-item__text">
            { product.masterId } { product.colorCode }
            </p>
            {!!!hidePrice && <p className="pl-item__text --italic">
                { getPriceString() }
            </p>}
            {/* <p className="pl-item__text">
                {quantityLabel}: { getQuantity() }
            </p> */}
        </div>
    )
}
    

export default ProductListItem;