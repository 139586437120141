import React, { useEffect, useState } from 'react';
// fileapi 
import FileApi from '../../../store/files/FileApi';
// Utils.
import { totalDiscountAmount, roundAmount } from '../../../utils/priceHelper';

// Models
import Order from '../../../models/Order';

import { FaDownload } from 'react-icons/fa';

import {
    IGrouping,
} from './dashboard.d';

interface IProps {
    productSortedByCategory: IGrouping,
    order: Order,
    categoryChanged?: Function
    onClickFilterGroup?: Function
    hidePrices?: boolean
    showDownloadLink?:boolean
} 

const OrderSummary: React.FC<IProps> = (props) => {
    const [selectedCategory, setValue] = React.useState('categories');
    const { productSortedByCategory, order, categoryChanged, onClickFilterGroup, showDownloadLink } = props;
    const scrollableElement = React.createRef<HTMLDivElement>();
    const [hasOverflow, setHasOverflow] = useState<boolean>(false)

    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        };
    })

    const handleResize = () => {
        if (scrollableElement.current) {
          if (+scrollableElement.current.scrollHeight > +scrollableElement.current.clientHeight) {
            setHasOverflow(true)
          } else {
            if (+scrollableElement.current.scrollHeight === +scrollableElement.current.clientHeight) {
                setHasOverflow(false)
            }
          }
        }
    }

    const onChangeSelect = (event: any) => {
        setValue(event.currentTarget.value);

        if (categoryChanged) {
            categoryChanged(event.currentTarget.value);
        }
    }

    const onClickHandler = (selectedCategory: string, item: any) => {
        if (onClickFilterGroup) {
            onClickFilterGroup(selectedCategory, item);
        }
    } 
    const onDownloadProductImages = async () => {
        if(order.orderLines[0].bsl ){
            const response = await FileApi.createCustomerBSLItemsZip( order.orderLines[0].bsl );
            if (response.data && response.data.location) {
                window.location.href = response.data.location;
            }

        } else {
            const response = await FileApi.createCustomerUnshippedItemsZip( order.orderLines[0].navorderid, order.orderLines[0].deliveryCode );
            if (response.data && response.data.location) {
                window.location.href = response.data.location;
            }
        }
    }
    // const getFile = async () => {
    //     const response = await FileApi.createOrderImageZip(props.orderId)

    //     setDownloadURL(response.data.location)
    //     window.location.href = response.data.location;
    //     setDownloading(false)

    //     setTimeout(() => {
    //         props.shouldHide();
    //     }, 1000);
    // }


    const renderTableBody = () => {
        console.log("bo-larsen ... renderTableBody");
        return (
            Object.keys(productSortedByCategory[selectedCategory]).map((category, key) => {
                const item = productSortedByCategory[selectedCategory][category];
                
                return (
                    <tr key={key} onClick={()=> onClickHandler(selectedCategory, item)}>
                        <td>
                            <span style={{textTransform: 'capitalize'}}>
                                { item.title.replaceAll('_',' ')  }
                            </span>
                        </td>
                        <td>
                            { item.salesOptions }
                        </td>
                        <td>
                            { item.quantity }
                        </td>
                        {!props.hidePrices && <td>
                            { roundAmount(item.revenue) }
                        </td>}
                    </tr>
                )
            })
        )
    }

    const getSalesOptionsTotal = () => {
        let total = 0;
        for (const i in productSortedByCategory[selectedCategory]) {
            total = total + productSortedByCategory[selectedCategory][i].salesOptions;
        }
    
        return total;
    }

    return (
        <div className="order-summary">
            <select
                value={selectedCategory}
                onChange={onChangeSelect}
                style={{
                        textTransform: 'capitalize',
                        lineHeight: '1.5',
                        padding: '5px 10px',
                        minHeight: '36px'
                    }}
                >
                {
                    Object.keys(productSortedByCategory).length &&
                    Object.keys(productSortedByCategory).map((category, key) => {
                        return (
                            <option key={key} value={category}>
                                { category }
                            </option>
                        )  
                    })
                }
            </select>
            <div className={'os-table__fixed' + (hasOverflow ? ' has-overflow' : ' regular')}>
                <table className="os-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Options</th>
                            <th>Items</th>
                            {!props.hidePrices && <th>Total</th>}
                        </tr>
                    </thead>
                </table>
            </div>
            <div className="os-table__scroller" ref={scrollableElement}>
                <table className="os-table">
                    <tbody>{Object.keys(productSortedByCategory).length ? renderTableBody() : null}</tbody>
                </table>
            </div>
            <div className={'os-table__fixed' + (hasOverflow ? ' has-overflow' : ' regular')}>
                <table className="os-table">
                    <tfoot>
                        {
                            order && order.orderheader && order.orderheader.orderLinediscount ?
                            <tr>
                                <td>Discount ( { order.orderheader.currencyCode } { order.orderheader.orderLinediscount }% )</td>
                                <td></td>
                                <td></td>
                                <td>{ roundAmount(totalDiscountAmount(order.orderLines)) }</td>
                            </tr> : null
                        }
                        <tr>
                            <td className="bold">Grand total</td>
                            <td className="bold">{ getSalesOptionsTotal() }</td>
                            <td className="bold">{order && order.orderTotals.noOfItems}</td>
                            {!props.hidePrices && <td className="bold">{ order && order.orderheader.currencyCode } {order && roundAmount(order.orderTotals.amount)}</td>}
                        </tr>
                        <tr>
                            { console.dir(order)  }
                           { showDownloadLink
                             && (order && order.orderLines && order.orderLines[0] && order.orderLines[0].navorderid) || (order && order.orderLines && order.orderLines[0] && order.orderLines[0].bsl)?
                            <td colSpan={4} onClick={()=> onDownloadProductImages()}><div style={{float:'left', display:'flex', backgroundColor:'#e9eef7', width:'100%'}}><span style={{paddingTop:'3px'}}><FaDownload /></span><div style={{marginLeft:'20px'}}><a href="javascript:void(0)">Download images  </a></div></div></td>
                            :<td colSpan={4}/>
                            }
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
}

export default OrderSummary;