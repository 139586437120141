 import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import {RouteComponentProps, withRouter} from "react-router-dom";

// Store.
import { IUserSate } from '../../store/user/reducers';

// API.
import FileApi from '../../store/files/FileApi';
import OrderApi from '../../store/order/OrderApi';

// Utils.
import { FaDownload, FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import BounceLoader from 'react-spinners/BounceLoader'

// Components.
import { ReactComponent as DownloadIconLarge } from '../../images/svg/download_icon_large.svg';
import { ReactComponent as DownloadSuccessLarge } from '../../images/svg/download_success.svg';

// Store.
import { RootState } from '../../store';

interface IGroup {[id: string]: any[]}

interface State {
    step: string
    previousStep: string
    isGeneratingFile: boolean
    downloadSucceded: boolean
    dropStep: string
    downloadAction: 'downloadDrop' | 'downloadDropBySeason' | 'downloadPreviousDrops',
    selectedDrop: any
    currentShop?: any
    shops?: any[]
    loadingShopOrders: boolean
    shopDeliveryInfo?: any[]
    futureDrops: any[]
    previousDrops: any[]
    futureDropsGroupedBySeason: any
    currentDropsGroupedBySeason: any
    activeSection: string
    selectedSeason: string
}
  
interface OwnProps {
    match: {params: {collection: string, orderType: string}},
}
  
interface DispatchProps {
}
  
interface StateProps {
    userState: IUserSate
}
  
type Props = StateProps & OwnProps & DispatchProps & RouteComponentProps;

export class ProductDownloadImagesPage extends Component<Props, State> {

    state: State = {
        step: 'initial',
        previousStep: 'initial',
        isGeneratingFile: false,
        downloadSucceded: false,
        dropStep: 'initial',
        downloadAction: 'downloadDrop',
        selectedDrop: null,
        loadingShopOrders: false,
        shopDeliveryInfo: [],
        futureDrops: [],
        previousDrops: [],
        futureDropsGroupedBySeason: {},
        currentDropsGroupedBySeason: {},
        activeSection: 'previous-drops',
        selectedSeason: 'previous-drops',
    }

    componentDidMount = async() => {
        const shopsResponse = await OrderApi.getShops()

        let currentShop: any = null;

        this.setState({ shops: shopsResponse.data }, () => {
            if (!shopsResponse.data.length) {
                currentShop = {
                    customerId: this.props.userState.user.customerData.CustomerId,
                    name: this.props.userState.user.customerData.Name,
                }
                this.onChangeShop(currentShop);
            } else {
                this.setState({ step: 'chooseShop' });
            }
        })
    }

    groupBy = (key: any) => (array: any[]) =>
    array.reduce((objectsByKeyValue: { [x: string]: any; }, obj: { [x: string]: any; }) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
    }, {});

    onChangeShop = (shop: any) => {
        this.setState({ currentShop: shop, loadingShopOrders: true }, async () => {
            // Peak closed orders.
            const response = await OrderApi.getCustomerDeliveryDownloadInfo(shop.customerId)
            const groupBySeason = this.groupBy(['seasoncode']);
            let previousDrops = [];
            let futureDrops = [];
            let currentDrops = [];
            let futureDropsGroupedBySeason: IGroup = {};
            let currentDropsGroupedBySeason: IGroup = {};

            for (const res of response.data) {
                if (res.daystoseasonstartshipping < -150) {
                    previousDrops.push(res);
                }

                if (res.daystoseasonstartshipping > -150 && res.daystoseasonstartshipping <= 60 ) {
                    currentDrops.push(res);
                }

                if (res.daystoseasonstartshipping > 60 ) {
                    futureDrops.push(res);
                }
            }

            futureDropsGroupedBySeason = this.consolidateGroups(groupBySeason(futureDrops));
            currentDropsGroupedBySeason = this.consolidateGroups(groupBySeason(currentDrops));

            let activeSection = previousDrops.length ?
                'previous-drops' : (!previousDrops.length && futureDrops.length ? Object.keys(futureDropsGroupedBySeason)[0] : '');

            this.setState({
                shopDeliveryInfo: response.data,
                futureDrops,
                previousDrops,
                loadingShopOrders: false,
                futureDropsGroupedBySeason,
                currentDropsGroupedBySeason,
                activeSection,
            })
        })
    }

    consolidateGroups = (season: IGroup) => {
        for (const i in season) {
            const group: any = season[i];

            for (const drop of group) {

                if (drop.deliverycode.includes(' DL')) {
                    const dropKey = drop.deliverycode.split(' DL')[0]
                    let similar = group.filter((item: any) => item.deliverycode.includes(dropKey))

                    if (similar && similar.length < 2) {
                        continue;
                    }

                    let other = similar.filter((item: any) => !item.deliverycode.includes(' DL'))[0];

                    let pseudoGroup = {
                        customerno: other.customerno,
                        daysfromtoday: other.daysfromtoday,
                        daystoseasonstartshipping: other.daystoseasonstartshipping,
                        deliverycode: dropKey,
                        deliveryshipment: other.deliveryshipment,
                        seasoncode:  other.seasoncode,
                        dropsInluded: similar,
                    };

                    // Remove similar items.
                    season[i] = season[i].filter((item: any) => !item.deliverycode.includes(dropKey));

                    // Add new pseudogroup.
                    season[i].push(pseudoGroup)
                }
            }

            // Sort by date.
            season[i]
                .sort((a: any, b: any) => new Date(b.deliveryshipment).getTime() - new Date(a.deliveryshipment).getTime())
                .reverse()
        }

        return season;
    }

    onClickGetFile = () => {
        if (this.state.downloadAction === 'downloadDrop' && this.state.currentShop) {
            if (this.state.selectedDrop.dropsInluded && this.state.selectedDrop.dropsInluded.length) {
                this.setState({ isGeneratingFile: true, downloadSucceded: false, dropStep: 'download', }, async () => {
                    const dropsToDownload = this.state.selectedDrop.dropsInluded.map((drop: any) => {
                        return {
                            deliverycode: drop.deliverycode,
                            seasoncode: drop.seasoncode,
                    }});
    
                    const response = await FileApi.createCustomerMultipleDropZip(this.state.currentShop.customerId, dropsToDownload);
                    
                    if (response.data && response.data.location) {
                        window.location.href = response.data.location;
                    }
    
                    this.setState({
                        isGeneratingFile: false,
                        downloadSucceded: true,
                        previousStep: this.state.step
                    })
                })
            } else {
                this.setState({ isGeneratingFile: true, downloadSucceded: false, dropStep: 'download', }, async () => {
                    const response = await FileApi.createCustomerDropZip(this.state.currentShop.customerId, this.state.selectedDrop.deliverycode, this.state.selectedDrop.seasoncode);
                    
                    if (response.data && response.data.location) {
                        window.location.href = response.data.location;
                    }
    
                    this.setState({
                        isGeneratingFile: false,
                        downloadSucceded: true,
                        previousStep: this.state.step
                    })
                })
            }

            
        }

        if (this.state.downloadAction === 'downloadDropBySeason' && this.state.currentShop) {
            this.setState({ isGeneratingFile: true, downloadSucceded: false, dropStep: 'download', }, async () => {
                const dropsToDownload = this.state.currentDropsGroupedBySeason[this.state.selectedSeason].map((drop: any) => {
                    return {
                        deliverycode: drop.deliverycode,
                        seasoncode: drop.seasoncode,
                }});

                const response = await FileApi.createCustomerMultipleDropZip(this.state.currentShop.customerId, dropsToDownload);
                
                if (response.data && response.data.location) {
                    window.location.href = response.data.location;
                }

                this.setState({
                    isGeneratingFile: false,
                    downloadSucceded: true,
                    previousStep: this.state.step
                })
            })
        }

        if (this.state.downloadAction === 'downloadPreviousDrops' && this.state.currentShop) {
            this.setState({ isGeneratingFile: true, downloadSucceded: false, dropStep: 'download', }, async () => {
                const dropsToDownload = this.state.previousDrops.map((drop: any) => {
                    return {
                        deliverycode: drop.deliverycode,
                        seasoncode: drop.seasoncode,
                }});

                const response = await FileApi.createCustomerMultipleDropZip(this.state.currentShop.customerId, dropsToDownload);
                
                if (response.data && response.data.location) {
                    window.location.href = response.data.location;
                }

                this.setState({
                    isGeneratingFile: false,
                    downloadSucceded: true,
                    previousStep: this.state.step
                })
            })
        }
    }

    onClickShop = (shop: any) => {
        this.setState({ step: 'initial' }, () => {
            this.onChangeShop(shop);
        });        
    }

    onClickDropType = (dropType: string) => {
        this.setState({ step: dropType });
    }

    onClickSeason = (seasonName: string) => {
        this.setState({ step: 'chooseDrops', selectedSeason: seasonName })
    }

    renderLastStep = () => {
        return <React.Fragment>
            <div className="download__step__main">
                {
                    !this.state.isGeneratingFile && !this.state.downloadSucceded &&
                    <React.Fragment>
                        <DownloadIconLarge />
                        <p className="download__step__main__text">
                            {this.state.downloadAction === 'downloadPreviousDrops' && 'Generate zip file with images all your previous drops'}
                            {this.state.downloadAction === 'downloadDrop' && this.state.selectedDrop && 'Generate zip file with images from drop ' + this.state.selectedDrop.deliverycode}
                            {this.state.downloadAction === 'downloadDropBySeason' && 'Generate zip file with images from all drops on ' + this.state.selectedSeason}
                        </p>
                        <p>
                            
                        </p>
                    </React.Fragment>
                }
                {
                    !this.state.isGeneratingFile && this.state.downloadSucceded && 
                    <React.Fragment>
                        {/* <span className="download__step__title__icon" onClick={() => this.setState({ step: 'initial' })}><FaChevronLeft /></span> */}
                        <DownloadSuccessLarge />
                        <p className="download__step__main__text">
                            Your file has been generated
                        </p>
                    </React.Fragment>
                }
                {
                    this.state.isGeneratingFile &&
                    <div className="download__loader">
                        <BounceLoader />
                        <p className="download__step__main__text">
                            Downloading your file<br />
                            Might take a little while
                        </p>
                    </div>
                }
            </div>
        </React.Fragment>
    }

    renderSeason = () => {
        return <React.Fragment>
            {
                !this.state.isGeneratingFile && !this.state.downloadSucceded && this.state.dropStep === 'initial' &&
                <React.Fragment>
                    <div className="download__options">
                        {
                            this.state.currentDropsGroupedBySeason[this.state.selectedSeason]
                            .map((drop: any, key: number) => {
                                return (
                                    <div
                                        className="download__options__item --with-label"
                                        onClick={() => this.setState({
                                            dropStep: 'initial',
                                            downloadAction: 'downloadDrop',
                                            selectedDrop: drop,
                                            previousStep: this.state.step,
                                            step: 'download',
                                        })}
                                        key={key}
                                    >
                                        <span>{ drop.deliverycode }</span>
                                        <span className="download__options__item__date-label">{ new Date(drop.deliveryshipment).toLocaleDateString() }</span>
                                        <span className="download__options__item__icon"><FaChevronRight /></span>
                                    </div>
                                );
                            })
                        }
                        <div
                            className="download__options__item"
                            onClick={() => this.setState({
                                dropStep: 'initial',
                                downloadAction: 'downloadDropBySeason',
                                selectedDrop: null,
                                previousStep: this.state.step,
                                step: 'download',
                            })}
                        >
                            <span>Download all above</span>
                            <span className="download__options__item__icon"><FaChevronRight /></span>
                        </div>
                    </div>
                </React.Fragment>
            }
        </React.Fragment>
    }

    renderDropTypeChooser = () => {

        if (this.state.loadingShopOrders) {
            return <div className="download__loader">
                <BounceLoader />
                <p className="download__step__main__text">
                    Loading drops...
                </p>
            </div>
        }
        
        return (
            <div className="download__options">
                {this.state.previousDrops.length ? <div className="download__options__item" onClick={() => this.setState({ step: 'download', previousStep: this.state.step, downloadAction: 'downloadPreviousDrops' })}>
                    <span style={{textTransform: 'uppercase'}}>Previous drops</span>
                    <span className="download__options__item__icon"><FaChevronRight /></span>
                </div> : null}
                {
                    Object.keys(this.state.currentDropsGroupedBySeason).length ? 
                    Object.keys(this.state.currentDropsGroupedBySeason).map((seasonName) => {
                        return (
                            <div className="download__options__item" key={seasonName} onClick={() => this.onClickSeason(seasonName)}>
                                { seasonName }
                                <span className="download__options__item__icon"><FaChevronRight /></span>
                            </div>
                        )
                    })
                    :
                    null
                }
                {
                    Object.keys(this.state.futureDropsGroupedBySeason).length ?
                    Object.keys(this.state.futureDropsGroupedBySeason).map((seasonName) => {
                        return (
                            <div className="download__options__item" key={seasonName}>
                                <span>
                                    { seasonName } 
                                </span>
                                <span className="download__options__item__blocked">
                                    Not available yet
                                </span>
                            </div>
                        )
                    })
                    :
                    null
                }
            </div>
        )
    }

    renderShopChooser = () => {
        return (
            <div className="download__options">
                {this.state.shops && this.state.shops.map((shop, key) => {
                    return (
                        <div className="download__options__item" key={key} onClick={() => this.onClickShop(shop)}>
                            { shop.name }
                            <span className="download__options__item__icon"><FaChevronRight /></span>
                        </div>
                    )
                })}
            </div>
        )
    }

    render() {
        return (
            <div className="download-page">
                <div className="download-page__back-button clickable" onClick={() => this.props.history.goBack()}>
                    <span><FaChevronLeft /></span>
                    <span>Go Back</span>
                </div>
                <div className="download">
                    <h2 className="download__title">
                        {(this.state.step === 'initial' || this.state.step === 'chooseShop') && <span className="download__title__icon"><FaDownload /></span>}
                        {this.state.step !== 'initial' && this.state.step !== 'chooseShop' && <span className="download__step__title__icon" onClick={() => {
                            this.setState({ step: this.state.previousStep, previousStep: 'initial', downloadSucceded: false, dropStep: 'initial' })

                        }}><FaChevronLeft /></span>}
                        {(this.state.step === 'initial' || this.state.step === 'chooseShop') && <span>Download images</span>}
                        {this.state.step === 'previous-drops' && <span>Previous drops</span>}
                        {this.state.step === 'chooseDrops' && <span>Download drops</span>}
                        {this.state.step === 'download' && <span>Download</span>}

                        {
                            (this.state.step !== 'chooseShop' && this.state.shops && this.state.shops.length) ?
                            <p className="download__change-shop-link clickable" onClick={() => { this.setState({ step: 'chooseShop' })}}>Change shop</p>
                            :
                            null 
                        }
                        <span className="download__title__note">
                            Please note - It is only possbile to download product images that match your confirmed orders
                        </span>
                    </h2>
                    <div className="download__main">
                        <div className={'download__step' + (this.state.step === 'initial' ? ' active' : ' hidden')}>
                            { this.state.step === 'initial' && this.renderDropTypeChooser() }
                        </div>

                        <div className={'download__step' + (this.state.step === 'chooseShop' ? ' active' : ' hidden')}>
                            { this.state.step === 'chooseShop' && this.renderShopChooser() }
                        </div>

                        <div className={'download__step' + (this.state.step === 'chooseDrops' ? ' active' : ' hidden')}>
                            {this.state.step === 'chooseDrops' && this.renderSeason()}
                        </div>

                        <div className={'download__step' + (this.state.step === 'download' ? ' active' : ' hidden')}>
                            { this.state.step === 'download' && this.renderLastStep() }
                        </div>
                    </div>
                    {
                        this.state.step === 'download' && !this.state.isGeneratingFile && !this.state.downloadSucceded &&
                        <div className="transfer__footer">
                            <button
                                className="transfer__button"
                                disabled={this.state.isGeneratingFile}
                                onClick={this.onClickGetFile}>
                                    Download
                            </button>
                        </div>
                    }

                    {
                        this.state.step === 'download' && !this.state.isGeneratingFile && this.state.downloadSucceded &&
                        <div className="transfer__footer">
                            <button
                                className="transfer__button --gray"
                                disabled={this.state.isGeneratingFile}
                                onClick={() => this.setState({ step: 'initial', downloadSucceded: false, dropStep: 'initial', downloadAction: 'downloadDrop' })}>
                                    <span className="transfer__button__icon"><FaChevronLeft /></span>Go back
                            </button>
                        </div>
                    }
                </div>
                {/* <div className="dowload-page__text">
                    Please note - It is only possbile to download product images that match your confirmed orders
                </div> */}
            </div>
        )
    }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
    return {
        userState: states.user.user
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
    return {
      
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductDownloadImagesPage))