import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Button } from '../form/Button';

import { Redirect, RouteComponentProps } from 'react-router-dom'

import { ThunkDispatch } from 'redux-thunk'
import { RootState } from '../../store';
import { IUserSate } from '../../store/user/reducers';
import { isUserValid } from '../../store/user/actions';
import { UserApi } from '../../store/user/actions';

interface RouteProps extends RouteComponentProps {}

interface State {
    formValues: {
        email: string
    },
    redirectToReferrer: boolean,
    step: number
}
  
interface OwnProps {
}
  
interface DispatchProps {
    isUserValid: () => void;
}
  
interface StateProps {
    userState: IUserSate
}
  
type Props = StateProps & OwnProps & DispatchProps & RouteProps;

export class ForgotPassword extends Component<Props, State> {
    state: State = {
        formValues: {email: ''},
        redirectToReferrer: false,
        step: 0
    }

    componentDidMount() {
        if(this.props.userState.isValidated && this.props.userState.user) {
            this.setState({ redirectToReferrer: true });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.userState.user) {
            this.setState({ redirectToReferrer: true });
        }

        if (nextProps.userState.token && !nextProps.userState.user) {
            this.props.isUserValid();
        }
    }

    private handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { name, value } = e.currentTarget;

        if (name === 'email') {
            this.setState({formValues: {email: value}})
        }
    };

    async handleSubmit() {
        // this.props.loginUser(this.state.formValues.email, this.state.formValues.password)
        await UserApi.resetPassword(this.state.formValues.email);

        console.log(this.state.formValues.email, 'this.state.formValues.email')
        this.setState({step: 1})
        
    }

    render() {
        const referer = '/';

        if (this.state.redirectToReferrer) {
            return <Redirect to={referer} />;
        }

        if (this.state.step === 1) {
            return (
                <div className="login-page" style={{
                    display: 'flex',
                    height: '100vh',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage: 'url(https://static.molo.com/media/2073546/ow_frontbanner.jpg?width=2880&quality=70)',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '50% 50%',
                }}>
                <form
                    onSubmit={(e: any) => e.preventDefault()}
                    className="login-form login-form--forgot-password"
                    style={{
                        width: '100%',
                        maxWidth: '550px',
                        background: 'rgba(0,0,0,.3)',
                        padding: '30px',
                        borderRadius: '6px',
                    }}>
                    <h2>Check your email</h2>
                    <p>
                        We have send your an email with a new password
                    </p>
                    <Button handleCLick={() => this.setState({ redirectToReferrer: true })} title="Go to login" />
                </form>
            </div>
            )
        }

        return (
            <div className="login-page" style={{
                    display: 'flex',
                    height: '100vh',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage: 'url(https://static.molo.com/media/2073546/ow_frontbanner.jpg?width=2880&quality=70)',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '50% 50%',
                }}>
                <form
                    onSubmit={(e: any) => e.preventDefault()}
                    className="login-form login-form--forgot-password"
                    style={{
                        width: '100%',
                        maxWidth: '550px',
                        background: 'rgba(0,0,0,.3)',
                        padding: '30px',
                        borderRadius: '6px',
                    }}>
                    <h2>Reset Password</h2>
                    <p>
                        We’ll send you an email with your new password.
                    </p>
                    <input
                        name="email"
                        title="email"
                        type="email"
                        value={this.state.formValues.email}
                        onChange={this.handleChange}
                        placeholder="Your email"
                        required={true}
                    />
                    <Button handleCLick={this.handleSubmit.bind(this)} title="Reset Password" />
                </form>
            </div>
        )
    }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
    return {
      userState: states.user.user
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
    return {
        isUserValid: async () => {
            await dispatch(isUserValid())
            // console.log('isUserValid completed [UI]')
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)