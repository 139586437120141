import React, { Component } from 'react'

import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import {RouteComponentProps, withRouter} from "react-router-dom";

// Components.
import Header from './Header';
import BasketComponent from '../b2b/BasketComponent';

// Store.
import { RootState } from '../../store';
import { ICollectionState  } from '../../store/collections/reducers'
import { IProductState  } from '../../store/products/reducers'
import { getCollections  } from '../../store/collections/actions';
import { getOrderTypes } from '../../store/products/actions';
import { IUserSate } from '../../store/user/reducers';
import { IOrderState } from '../../store/order/orderStore';

interface State {
    showSidebar: boolean,
    showOrderModal: boolean,
    collectionName: string,
    orderTypeName: string,
    editMode: boolean
}
  
interface OwnProps {
    match?: {params: {collection: string, orderType: string}},
    children?: any
    history?: any
    location?: any
    staticContext?: any,
    hideBasket?: boolean
}
  
interface DispatchProps {
    getCollections: (orderType: string) => void
    getOrderTypes: (disallowedTypes?: string[]) => void
}
  
interface StateProps {
    productState: IProductState
    collectionState: ICollectionState
    userSate: IUserSate
    orderState: IOrderState
}
  
type Props = StateProps & OwnProps & DispatchProps & RouteComponentProps;

class MainContainer extends Component<Props, State> {

    state = {
        showSidebar: false,
        showOrderModal: false,
        collectionName: '',
        orderTypeName: '',
        editMode: false
    }

    componentDidMount() {
        const { order } = this.props.orderState;

        if (order) {
            if (order.orderheader.navImportRef && !order.orderheader.orderIsFinal && !this.state.editMode) {
                this.setState({ editMode: true }, () => {
                })
            }
        }

        if (!this.props.location.pathname.includes('/search')) {
            localStorage.setItem('search:prevPage', this.props.location.pathname);
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.orderState.order && this.props.orderState.order &&
            prevProps.orderState.order.orderheader.appOrderId !== this.props.orderState.order.orderheader.appOrderId) {
                const order = this.props.orderState.order;

                if (order.orderheader.navImportRef && order.orderheader.orderState === 0 && !this.state.editMode) {
                    this.setState({ editMode: true }, () => {
                       
                    })
                } else {
                    this.setState({ editMode: false })

               
                }
        }

        if (!this.props.location.pathname.includes('/search')) {
            localStorage.setItem('search:prevPage', this.props.location.pathname);
        }
    }

    render() {
        const { children } = this.props;
        
        return (
            <>
                <Header
                    editMode={this.state.editMode}
                    isDashboard={true}
                    {...this.props}
                />
                <div className="main-content">
                    { children }
                </div>
                {
                    !this.props.hideBasket &&
                    <BasketComponent
                        showContinueShopping={true}
                        showCheckoutLink={true}
                        showDropup={false}
                        editMode={this.state.editMode}
                        {...this.props}
                    />
                }
            </>
        )
    }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
    return {
        productState: states.productStore.productStore,
        collectionState: states.collections.collections,
        userSate: states.user.user,
        orderState: states.order.order,
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
    return {
        getCollections: async (orderType: string) => {
            await dispatch(getCollections(orderType))
            console.log('getCollections completed [UI]')
        },
        getOrderTypes: async (disallowedTypes?: string[]) => {
            await dispatch(getOrderTypes(disallowedTypes))
        }
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainContainer))
