import React, { Component } from 'react'
import { RootState } from '../../store';
import { connect } from 'react-redux'
import { getFilter, applyFilter } from '../../store/session/actions'
import { IFilterState } from '../../store/session/reducers'
import { ThunkDispatch } from 'redux-thunk'

import { FaChevronLeft, FaTimes } from 'react-icons/fa';

import { cloneDeep } from 'lodash';

import { orderLine } from '../../models/Order';
import { Filter } from '../../models/Filter';
import { DashboardFilterHelper  as FilterHelper } from '../../utils/DashboardFilterHelper';
import { CheckboxInput } from '../form/CheckboxInput';
import AccordionPanel from '../layout/AccordionPanel';

interface State {
  initialized?: boolean;
  styles?: orderLine[],
  searchTerm: string,
}

interface OwnProps {
  styles: orderLine[];
  showFilter: boolean;
  onClickGoBack?: Function;
  onClickCloseFilter?: Function;
  hideGoBack?: boolean;
}

interface DispatchProps {
  getFilter: () => void;
  applyFilter: (filter: Filter) => void;
}

interface StateProps {
  filterState: IFilterState
}

type Props = StateProps & OwnProps & DispatchProps

class DashboardFilterComponent extends Component<Props, State> {

  state = {initialized: false, searchTerm: '', styles: []}

  componentDidMount() {
    this.props.getFilter();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if ((!this.state.initialized && this.props.filterState.filter) || (prevProps.styles !== this.props.styles && this.props.filterState.filter)) {
      this.initFilters(this.props.filterState.filter, this.props.styles);
    }
  }

  componentWillUnmount() {
    this.setState({initialized: false})
  }

  public static getDerivedStateFromProps(props: Props, state: State) {
    if (props.styles !== state.styles) {
      return {
          styles: props.styles,
      }
    }
    
    return null;
  }

  initFilters (filter:Filter, styles: orderLine[]) {
    const newFilter = cloneDeep(filter);
    
    newFilter.productGroups = FilterHelper.hydrateProductGroups(styles);
    newFilter.deliveryWindows = FilterHelper.hydrateDeliveryWindows(styles);
    newFilter.productCategories = FilterHelper.hydrateProductCategories(styles);
    newFilter.collections = FilterHelper.hydrateCollections(styles);
    newFilter.sustainability = FilterHelper.hydrateSustainability(styles);
    this.props.applyFilter(newFilter);

    this.setState({
      initialized: true
    });
  }
  
  onFilterSelectionProductCategory (event: any) {        
    const selectedCategory = event.currentTarget.value;
    const selectedCategoryIsActive = event.currentTarget.checked;
    this.changeActiveFilters('productCategories', selectedCategory, selectedCategoryIsActive);
  }

  onFilterSelectionProductGroup (event: any) {
    const selectedProductGroup = event.currentTarget.value;
    const selectedProductGroupIsActive = event.currentTarget.checked;
    this.changeActiveFilters('productGroups', selectedProductGroup, selectedProductGroupIsActive);
  }

  onFilterSelectionDeliveryWindow (event: any) {
    const selectedDeliveryWindow = event.currentTarget.value;
    const selectedDeliveryWindowIsActive = event.currentTarget.checked;
    this.changeActiveFilters('deliveryWindows', selectedDeliveryWindow, selectedDeliveryWindowIsActive);
  }

  onFilterSelectionCollection (event: any) {
    const selectedCollection = event.currentTarget.value;
    const selectedCollectionIsActive = event.currentTarget.checked;
    this.changeActiveFilters('topCollections', selectedCollection, selectedCollectionIsActive);
  }

  onFilterSelectionSustainability (event: any) {
    const selected = event.currentTarget.value;
    const selectedIsActive = event.currentTarget.checked;
    this.changeActiveFilters('sustainability', selected, selectedIsActive);
  }

  changeActiveFilters =(filterType: any, filterValue: string, isActive: boolean) => {
    const newFilter = cloneDeep(this.props.filterState.filter);

    if (!newFilter) return

    const filter = FilterHelper.changeActiveFilters(newFilter, filterType, filterValue, isActive);

    this.props.applyFilter(filter);
  }

  onClickGoBack = () => {
    if (this.props.onClickGoBack) {
      this.props.onClickGoBack()
    }
  }

  render() {
    return (
      <div className={"filter-sidebar " + (this.props.showFilter ? "--is-active" : "--is-hidden")}>
        {!this.props.hideGoBack && <div className="filter-sidebar__go-back" onClick={this.onClickGoBack}>
          <span className="icon">
            <FaChevronLeft />
          </span>
          Go back
        </div>}
        <div className="close-filter mobile-only" onClick={() => {
          this.props.onClickCloseFilter && this.props.onClickCloseFilter();
        }}>
          <FaTimes />
        </div>
        <AccordionPanel heading="Collections">
          <div className="a-filter-facet-group">
            {(this.props.filterState.filter && this.props.filterState.filter.collections
              && this.props.filterState.filter.collections.map(collection => 
                this.props.filterState.filter && !!collection && (
                  <div className="a-filter-facet" key={collection}>
                      <CheckboxInput 
                          name={collection} 
                          title={collection} 
                          handleChange={this.onFilterSelectionCollection.bind(this)}
                          value={collection} 
                          checked={this.props.filterState.filter.activeFilters.collections.indexOf(collection) !== -1} />
                  </div>
              )
            ))}
          </div>
        </AccordionPanel>
        <AccordionPanel heading="Groups">
          <div className="a-filter-facet-group">
            {(this.props.filterState.filter && this.props.filterState.filter.productGroups
            && this.props.filterState.filter.productGroups.map(productGroupName => 
              this.props.filterState.filter && !!productGroupName && (
                  <div className="a-filter-facet" key={productGroupName}>
                      <CheckboxInput 
                          name={productGroupName} 
                          title={productGroupName} 
                          handleChange={this.onFilterSelectionProductGroup.bind(this)}
                          value={productGroupName} 
                          checked={this.props.filterState.filter.activeFilters.productGroups.indexOf(productGroupName) !== -1} />
                  </div>
              )
            ))}
          </div>
        </AccordionPanel>
        <AccordionPanel heading="Categories">
          <div className="a-filter-facet-group">
            {(this.props.filterState.filter && this.props.filterState.filter.productCategories.map(categoryName => 
                !!categoryName && this.props.filterState.filter && (
                    <div className="a-filter-facet" key={categoryName}>
                        <CheckboxInput 
                            name={categoryName} 
                            title={categoryName} 
                            handleChange={this.onFilterSelectionProductCategory.bind(this)}
                            value={categoryName} 
                            checked={this.props.filterState.filter.activeFilters.productCategories.indexOf(categoryName) !== -1} />
                    </div>
                )
            ))}
          </div>
        </AccordionPanel>
        <AccordionPanel heading="Delivery Windows">
          <div className="a-filter-facet-group">
            {(this.props.filterState.filter && this.props.filterState.filter.deliveryWindows
              && this.props.filterState.filter.deliveryWindows.map(deliveryWindowName => 
                this.props.filterState.filter && !!deliveryWindowName && (
                  <div className="a-filter-facet" key={deliveryWindowName}>
                      <CheckboxInput 
                          name={deliveryWindowName} 
                          title={deliveryWindowName} 
                          handleChange={this.onFilterSelectionDeliveryWindow.bind(this)}
                          value={deliveryWindowName} 
                          checked={this.props.filterState.filter.activeFilters.deliveryWindows.indexOf(deliveryWindowName) !== -1} />
                  </div>
              )
            ))}
          </div>
        </AccordionPanel>
        <AccordionPanel heading="Sustainability">
          <div className="a-filter-facet-group">
            {(this.props.filterState.filter && this.props.filterState.filter.sustainability
              && this.props.filterState.filter.sustainability.map(type => 
                this.props.filterState.filter && !!type && (
                  <div className="a-filter-facet" key={type}>
                      <CheckboxInput 
                          name={type} 
                          title={type.replaceAll('_',' ').toUpperCase()} 
                          handleChange={this.onFilterSelectionSustainability.bind(this)}
                          value={type} 
                          checked={!!this.props.filterState.filter.activeFilters.sustainability &&
                            this.props.filterState.filter.activeFilters.sustainability.indexOf(type) !== -1} />
                  </div>
              )
            ))}
          </div>
        </AccordionPanel>
      </div>
    )
  }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
  return {
    filterState: states.session.filter
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
  return {
    getFilter: async () => {
      await dispatch(getFilter())
      console.log('getFIlter completed [UI]')
    },
    applyFilter: async (filter: Filter) => {
      await dispatch(applyFilter(filter))
      console.log('apply filter completed [UI]')
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardFilterComponent)