import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { filterByValue } from 'molo-shared/lib/utils/UtilHelper';
import { Order, StepWizard } from 'molo-shared';
import OrderApi from '../../store/order/OrderApi';
import { RootState } from '../../store/';
import axios from 'axios';
import { ShippedCalendar } from '../b2b/ShippedCalendar';
import { cloneDeep, uniq } from 'lodash';
// import Order from '../../Models/Order'
import SidebarMenu from '../layout/SidebarMenu';
import { ShippedItemsOverview } from '../b2b/ShippedItemsOverview';
import Dropdown from '../layout/Dropdown';
import { FaTimes, FaChevronDown } from 'react-icons/fa';
import DashboardOverlay from '../b2b/DashboardOverlay';
import { Spinner } from "@blueprintjs/core";
import { getOpenOrders } from '../../store/order/actions'
import { request } from 'http';
import { Button } from '../form/Button';

interface Props {
    applyChanges: Function
    show: boolean
}

interface IOrderTableRowProps {
    order: any
    expanded: boolean
    onClickOrder?: Function
    onHandleClickBSL: Function
    onClickViewOrder?: Function
    onUpdateOrder?: Function
    onFetching?: Function
    isNavOrderTable?: boolean
    updateView?: Function
    openOrders: any
}

const OrderTableRow : React.FC<IOrderTableRowProps> = (props) => {
    const {
        order,
        expanded,
        onClickOrder,
        onHandleClickBSL,
        onClickViewOrder,
        onUpdateOrder,
        onFetching,
        isNavOrderTable,
        openOrders
    } = props

    const history = useHistory();
    const status = order.status;
    let statusClass = 'status-badge--not-shipped';
    const onClickOrderHandler = () => {
        onClickOrder && onClickOrder(order)
    }

    if (status !== 'Not Shipped') {
        statusClass = status === 'Fully Shipped' ? 'status-badge--fully' : 'status-badge--partial';
    }

    const onClickOption = async (option: string) => {
        if (option.toLowerCase() === 'view order') {
            if (!order.isImported) {
                onFetching && onFetching(true)
                const navOrderResponse = await OrderApi.openNavOrder(order.navorderid)
                // let createdapporderid = navOrderResponse.data.createdapporderid
                navOrderResponse.data.appOrderId = navOrderResponse.data.createdapporderid
                onClickViewOrder && onClickViewOrder(navOrderResponse.data)
                onFetching && onFetching(false)
                onUpdateOrder && onUpdateOrder()
            } else if (order.appOrderId) {
                onClickViewOrder && onClickViewOrder(order)
            }
        }

        if (option.toLowerCase() === 'edit order') { 
            if (!order.isImported) {
                onFetching && onFetching(true)
                await OrderApi.openNavOrder(order.navorderid)
                const navOrderResponse = await OrderApi.openNavOrder(order.navorderid)
                onFetching && onFetching(false)
                history.push('/dashboard/' + navOrderResponse.data.createdapporderid + '?step=details&origin=shop');
            } else { 
                history.push('/dashboard/' + order.appOrderId + '?step=details&origin=shop');
            }
        }
    }

    const isAlreadyOpen = (order: any) => {
        if (!openOrders || !openOrders.length) {
            return false
        }
        var existing = openOrders.find(function(item: any) {
            return item.navorderId && item.navorderId === order.navorderId;
        });
        return existing;
    }

    return (
        <>
            <tr
                onClick={() => {
                    onClickOrderHandler()
                }}
                className={`${expanded ? 'active' : ''}`}
            >
                <td style={{ paddingLeft: '20px' }}>
                    <div
                        className={`table-expander ${expanded ? 'active' : ''}`}>
                            <FaTimes/>
                    </div>
                    
                    {order.appOrderId && `B2B${order.appOrderId}`}
                </td>
                <td>
                    { isNavOrderTable ? `${order.navorderid || ''}` : `${order.navImportRef || ''}` }
                </td>
                <td>
                    {order.sellToCustomerName}
                </td>
                <td>{order.orderType}</td>
                <td>{order.salespersonnote}</td>
                <td>
                    <div className={`status-badge ${statusClass}`}>
                        { status }
                        {order.nooftrackingcodes && order.nooftrackingcodes > 0 ? <div className="has-tracking">tracking</div> : null}
                    </div>
                </td>
                <td>
                    {
                        order.inscanner && !isAlreadyOpen(order) && !order.isImported &&
                        <span
                            className="item-list__item__blocked">
                            In scanner
                        </span>
                    }
                    {
                        order.beingmodifiedby &&  '' !== order.beingmodifiedby && !isAlreadyOpen(order) && !order.isImported &&
                        <span
                        className="item-list__item__blocked">
                            Being modified by : { order.beingmodifiedby }
                        </span>
                    }
                    {
                        isAlreadyOpen(order) && !order.isImported &&
                        <span
                        className="item-list__item__blocked">
                            Already open!
                        </span>
                    }
                    <div className="option-wrapper"> 
                    <span className="to__buttonEdit" onClick={(e) => {
                            e.stopPropagation();
                            onClickOption('edit order') 
                        }}>
                           Edit
                        </span>
                        {/* <Dropdown
                            items={ ['Edit Order'] }
                            selected={true} 
                            value={undefined}
                            identifier={'$index'}
                            placeholder='Options'
                            onSelect={onClickOption}
                            allOption={false}
                        />  */}
                    </div>
                </td>
            </tr>
            {expanded && <tr className="hover-white">
                <td colSpan={6} className="no-padding expanded">
                    {/* <div>
                        <h2 className="text-xl p-2">Shipments</h2>
                    </div> */}
                    <ShippedCalendar
                        order={ order }
                        onHandleClickBSL={onHandleClickBSL}
                    />
                </td>
            </tr>}
        </>
    )
}

const OpenOrdersBody: React.FC<Props> = (props) => {
    let [selectedOrderType, setSelectedOrderType] = useState<null | string>(null)
    let [searchString, setSearchString] = React.useState<string>('');
    const [orders, setOrders] = useState<any[]>([])
    const [openOrders, setOpenOrders] = useState<any[]>([])
    const [closedOrders, setClosedOrders] = useState<any[]>([])
    const [activeOrder, setActiveOrder] = useState<any | undefined>(undefined)
    const [activeViewOrder, setActiveViewOrder] = useState<any | undefined>(undefined)
    const [selectedBSLItems, setSelectedBSLItems] = useState<any | undefined>(undefined)
    const [showSidebar, setShowSidebar] = useState(false);
    const [isFetching, setIsFetching] = useState(false)
    // const baseUrl = appConfig.media.baseUrl;
    const [initialized, setinitialized] = useState(false)
    const [allStatus, setAllStatus] = useState(['Fully Shipped', 'Partially Shipped', 'Not Shipped'])
    const [orderTypes, setOrderTypes] = useState<any[]>([])
    const [openFilteredItems, setOpenFilteredItems] = useState<any[]>([])
    const [closedFilteredItems, setClosedFilteredItems] = useState<any[]>([])
    const user = useSelector((state: RootState) => state.user.user);
    const dispatch = useDispatch()
    const [isFetchingItems, setIsFetchingItems] = useState(false)
    const [selectedStatus, setSelectedStatus] = useState<null | string>(null)
    const [activeOrderIds, setActiveOrderIds] = useState<any[]>([])
    const [showDashOverlay, setShowDashOverlay] = useState(false)
     
    const { show } = props;

    const onClickViewOrder = async (order: any) => {
        if (order.appOrderId) {
            // Get the order.
            let response = await OrderApi.getOrder(order.appOrderId)
            setActiveViewOrder(response.data)
            setShowDashOverlay(true)
        }
    }

    const handleOrderClick = (order: any) => {
        let orderId = null;
        if (order.navorderid) {
            orderId = order.navorderid;
        } else if (order.appOrderId) {
            orderId = order.appOrderId;
        }
        if (activeOrderIds.indexOf(orderId) === -1) {
            let orders = [ ...activeOrderIds ];
            orders.push(orderId)
            setActiveOrderIds(orders)
        } else {
            let orders = [ ...activeOrderIds ];
            let indexOf = orders.indexOf(+orderId);
            orders.splice(indexOf, 1);
            setActiveOrderIds(orders)
        }
    }

    useEffect( () => {
        if (show) {
            setinitialized(true)
            getInitialShop()
        }
    }, [show])

    useEffect( () => {
        filterItems()
    }, [selectedOrderType, selectedStatus])

    const getInitialShop = async () => {
        init(+user.user.customerData.CustomerId);
    }

    const filterItems = () => {
        let openItems = [ ...openOrders ];
        let closedItems = [ ...closedOrders ];
        if (selectedOrderType) {
            openItems = openItems.filter(order => order.orderType === selectedOrderType)
            closedItems = closedItems.filter(order => order.orderType === selectedOrderType)
        }
        if (selectedStatus) {
            if (selectedStatus === 'Fully Shipped') {
                openItems = openItems.filter(order => order.status === 'Fully Shipped')
                closedItems = closedItems.filter(order => order.status === 'Fully Shipped')
            } else if (selectedStatus === 'Partially Shipped') {
                openItems = openItems.filter(order => order.status === 'Partially Shipped')
                closedItems = closedItems.filter(order => order.status === 'Partially Shipped')
            } else if (selectedStatus === 'Not Shipped') {
                openItems = openItems.filter(order => order.status === 'Not Shipped')
                closedItems = closedItems.filter(order => order.status === 'Not Shipped')
            }
        }
        setOpenFilteredItems(openItems)
        setClosedFilteredItems(closedItems)
    }

    const filterByOrderType = (type: string) => {
        setSelectedOrderType(type === 'all' ? null : type)
    }

    const filterByOrderStatus = (status: string) => {
        setSelectedStatus(status === 'all' ? null : status)
    }

    const init = async (customerid: number, allShops: boolean = false) => {
        setActiveOrder(undefined)
        setIsFetching(true)
        setOrders([])
        const requests: any[] = [
            OrderApi.peakNavOrdersNew(+customerid, false, true),
            OrderApi.getOpenOrders()
        ]
        
        const responses = await axios.all(requests);
        console.log('allorders[1] her');
        console.dir(requests);
        const openOrdersResponse = responses[1]
        const navRefs = uniq(openOrdersResponse.data
            .filter((res: any) => res.navImportRef !== null))
            .map((ref: any) => ref.navImportRef)

        let res = responses[0].data.filter((res:any) => navRefs.indexOf(res.navorderid) === -1)
        
        const peakNavordersResponse = res.map((res: any) => {
            let sellToCustomerName = res.shipttoname || '';

            let order = {
                appOrderId: res.orderidcreated ? res.orderidcreated.toLowerCase().split('sb')[1] : undefined,
                sellToCustomerNo: res.selltocustomerno,
                sellToCustomerName: sellToCustomerName,
                sellToAddress: null,
                sellToCity: null,
                sellToCountry_RegionCode: null,
                shipttoname: '',
                orderType: res.ordertype,
                salespersonnote: res.salespersonnote,
                navorderid: res.navorderid,
                status: 'Partially Shipped',
                orderQuantity: res.orderQuantity,
                isImported: false
            }

            if (res.orderQuantity === res.outstandingQuantity) {
                order.status = 'Not Shipped'
            }

            if (res.outstandingQuantity > 0 && res.orderQuantity > res.outstandingQuantity) {
                order.status = 'Partially Shipped'
            }

            if (res.outstandingQuantity === 0) {
                order.status = 'Fully Shipped'
            }
            return order;
        })
        
        const openStuff = openOrdersResponse.data.map((item: any) => {
            item.isImported = true

            if (item.isImported) {
                item.status = 'Not Shipped'
            } else {
                const navRef = responses[0].data.find((item: any) => item.navImportRef === item.navorderid)

                if (navRef.orderQuantity === navRef.outstandingQuantity) {
                    item.status = 'Not Shipped'
                }
    
                if (navRef.outstandingQuantity > 0 && navRef.orderQuantity > navRef.outstandingQuantity) {
                    item.status = 'Partially Shipped'
                }
    
                if (navRef.outstandingQuantity === 0) {
                    item.status = 'Fully Shipped'
                }
            }
            return item
        })

        setIsFetching(false)
        const allOrders: any[] = [
            ...peakNavordersResponse ,
            ...openStuff,
        ];

        const ot: any[] = uniq(allOrders.map(item => item.orderType));

        setOrderTypes(ot);
        setOrders(allOrders)
        setClosedOrders([ ...peakNavordersResponse ])
        setOpenOrders([ ...openStuff ] )
        // setFilteredItems( [ ...allOrders ])
        setOpenFilteredItems( [ ...peakNavordersResponse ] )
        setClosedFilteredItems( [ ...openStuff ] )
    }

    const onCloseSidebar = () => {
        setShowSidebar(false)
        setSelectedBSLItems([])
    }

    const onHandleClickBSL = async (order: any, drop: any) => {
        setShowSidebar(true)

        if (drop.bsl) {
            const response = await OrderApi.peakNavShipmentItems(drop.bsl)
            setSelectedBSLItems(cloneDeep(response.data))
        } else {
            if (order.appOrderId) {
                const response = await OrderApi.getFilteredOrderLines(order.appOrderId, drop.deliveryCode)
                setSelectedBSLItems(cloneDeep(response.data))
            } else {
                const response = await OrderApi.peakNavOrderUnshippedItems(order.navorderid, drop.deliveryCode)
                setSelectedBSLItems(cloneDeep(response.data))
            }
        }
    }

    const renderSidebarMenuContent = () => {
        if (!selectedBSLItems) return null
        const total = selectedBSLItems.reduce((sum: number, li: any) => sum + li.quantity, 0)

        const orderlines = selectedBSLItems.map((item: any) => {
            item.quantity = item.qty || item.quantity
            item.deliveryCode = item.deliverycode
            return item
        })

        if (!isFetchingItems && !orderlines.length) {
            return (
                <div className="h-full flex justify-center pt-16">
                    <h1 className="text-2xl">No Items on this shipment!</h1>
                </div>
            )
        }

        return (
            <>
                <ShippedItemsOverview
                    orderLines={ selectedBSLItems }
                    order={ {
                        orderheader: activeOrder,
                        orderLines: orderlines,
                        orderTotals: {
                            // Should be modified.
                            noOfItems: +total,

                            // Should be modified / hide.
                            amount: 199,
                        }
                    } }
                />
            </>
        )
    }

    if (!props.show) return null

    return (
        <>
            {
                isFetching ?
                    <div className="big-spinner"><Spinner /></div>
                    : null
            }
            <div className="h-full overflow-auto">
                <div className="grid grid-cols-3 gap-10">
                    <div className="form-control">
                        <input
                            type="text"
                            name="search"
                            placeholder='Search'
                            onChange={(event: any) => {
                                const value = event.target.value

                                setSearchString(value || '')
                            }}
                        />
                    </div>
                    <div className="form-control">
                        {/* <Dropdown
                            items={ orderTypes }
                            value={selectedOrderType}
                            identifier={'$index'}
                            placeholder='Filter by type'
                            allLabel='Show all'
                            onSelect={filterByOrderType}
                            allOption={true}
                        /> */}
                    </div>
                    <div className="form-control">
                        {/* <Dropdown
                            items={ allStatus } 
                            value={selectedStatus}
                            identifier={'$index'}
                            placeholder='Filter by status'
                            allLabel='Show all'
                            onSelect={filterByOrderStatus}
                            allOption={true}
                        /> */}
                    </div>
                </div>
                
                {openOrders.length ?
                <>
                    <p className="text-xl">Not completed orders</p>
                    <table className="striped-table table-fixed table-hover completed-orders-table text-xs">
                        <thead>
                            <tr>
                                <th>Order ID</th>
                                <th>Molo ref. number</th>
                                <th>Shop</th>
                                <th>Type</th>
                                <th>Comment</th>
                                <th style={{ width: '150px' }}>Status</th>
                                <th style={{ width: '100px' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            filterByValue(closedFilteredItems, searchString, [
                                'orderType',
                                'salespersonnote',
                                'status',
                                'appOrderId',
                                'sellToCustomerName'
                            ])
                            .map((order: any, key: any) => {
                                if(order.orderQuantity === 0) {
                                    return null
                                }

                                let expanded = false

                                if (order.navorderid && order.navorderid !== null && activeOrderIds.indexOf(order.navorderid) !== -1 ) {
                                    expanded = true;
                                } else if (order.appOrderId && activeOrderIds.indexOf(order.appOrderId) !== -1) {
                                    expanded = true;
                                }

                                return <OrderTableRow
                                    key={key}
                                    order={ order }
                                    expanded={ expanded }
                                    onClickOrder={ handleOrderClick }
                                    onHandleClickBSL={onHandleClickBSL}
                                    onClickViewOrder={onClickViewOrder}
                                    onUpdateOrder={() => {
                                        dispatch(getOpenOrders()) 
                                        getInitialShop()
                                    }}
                                    openOrders={openOrders}
                                />
                            })
                        }
                        </tbody>
                    </table></>: null}

                    {closedOrders.length ?
                <>
                <p className="text-xl">Editable Completed Orders</p>
                <table className="striped-table table-fixed table-hover completed-orders-table text-xs mb-32">
                    <thead>
                        <tr>
                            <th>Order ID</th>
                            <th>Molo ref. number</th>
                            <th>Shop</th>
                            <th>Type</th>
                            <th>Comment</th>
                            <th style={{ width: '160px' }}>Status</th>
                            <th style={{ width: '100px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        filterByValue(openFilteredItems, searchString, [
                            'orderType',
                            'salespersonnote',
                            'status',
                            'appOrderId',
                            'sellToCustomerName'
                        ])
                        .map((order: any, key: any) => {
                            if(order.orderQuantity === 0) {
                                return null
                            }

                            let expanded = false

                            if (order.navorderid && order.navorderid !== null && activeOrderIds.indexOf(order.navorderid) !== -1 ) {
                                expanded = true;
                            } else if (order.appOrderId && activeOrderIds.indexOf(order.appOrderId) !== -1) {
                                expanded = true;
                            }
                            return <OrderTableRow
                                isNavOrderTable={true}
                                key={key}
                                order={ order }
                                expanded={ expanded }
                                onClickOrder={ handleOrderClick }
                                onHandleClickBSL={onHandleClickBSL}
                                onClickViewOrder={onClickViewOrder}
                                onUpdateOrder={() => {
                                    dispatch(getOpenOrders()) 
                                    getInitialShop()
                                }}
                                openOrders={openOrders}
                                onFetching={(fetching: boolean) => setIsFetching(fetching)}
                            />
                        })
                    }
                    </tbody>
                </table></> : null}
                
            </div>
            <SidebarMenu
                isShowing={ showSidebar }
                onClose={ onCloseSidebar }
            >
                { renderSidebarMenuContent() }
            </SidebarMenu>
            <DashboardOverlay
                isShowing={ showDashOverlay }
                onHide={() => setShowDashOverlay(false)}
                order={activeViewOrder}
            />
        </>
    )
}

export default OpenOrdersBody;