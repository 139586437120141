import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'

// Store.
import { RootState } from '../../store';
import { IProductState } from '../../store/products/reducers'
import ProductApi from '../../store/products/ProductApi';
import { createAndSetOrder, getShops } from '../../store/order/actions';
import { IOrderState } from '../../store/order/orderStore';
import OrderApi from '../../store/order/OrderApi';
import { IUserSate } from '../../store/user/reducers';

// Components.
import Modal from '../layout/Modal/Modal';
// import MoloRingloader from '../layout/MoloRingloader';
import CreateOrderBody from './CreateOrderBody';

// Models.
import { OrderType } from '../../models/OrderType';
import Order from '../../models/Order';

interface State {
    showOverlay: boolean
    initialized: boolean
    selected: OrderType
    currentStep: string
    selectedShop: any
    salespersonNote: string | null
    currentOrder?: Order
    forceReset: boolean,
    steps: string[],
    orderTypes: OrderType[],
    shops: any[]
}
  
interface OwnProps {
    hide?: Function
    isShowing: boolean
    orderTypeName?: string
    modal?: boolean
}
  
interface DispatchProps {
    // getOrderTypes: () => void
    createAndSetOrder: (orderType: string, customerId: number, salespersonNote?: string) => void
    getShops: () => void
}
  
interface StateProps {
    productStore: IProductState
    orderState: IOrderState
    userState: IUserSate
}
  
type Props = StateProps & OwnProps & DispatchProps;

interface stepLabel {
    [id: string]: string
}

export class CreateOrderModal extends Component<Props, State> {

    private initialState: State = {
        showOverlay: true,
        initialized: false,
        selected: {
            orderType: '',
            delivery: '',
            description: '',
            type: '',
        },
        selectedShop: null,
        currentStep: '',
        salespersonNote: null,
        forceReset: false,
        steps: [],
        shops: [],
        orderTypes: []
    }

    state: State = { ...this.initialState }

    async componentDidMount() {
        const orderTypeResponse = await ProductApi.getOrderTypes();
        const disallowedTypes = this.props.userState.user && this.props.userState.user.disallowedTypes ? this.props.userState.user.disallowedTypes : []; 
        let types = orderTypeResponse.data;

        if (disallowedTypes) {
            types = types.filter((type: any) => disallowedTypes.indexOf(type.type.toLowerCase()) === -1)
        }

        const shopsResponse = await OrderApi.getShops()

        this.setState({
            orderTypes: types,
            shops: shopsResponse.data,
            initialized: true,
        });
    }

    private handleCommentChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { value } = e.currentTarget;
        this.setState({ salespersonNote: value })
    };

    applyChanges = (orderType: string, customerId: number, salespersonNote: string | undefined) => {
        this.props.createAndSetOrder(orderType, customerId, salespersonNote || undefined);
        this.hide();
    }

    hide = () => {
        this.props.hide && this.props.hide();
    }

    render() {
        return (
            <Modal
                children={
                    <CreateOrderBody
                        applyChanges={this.applyChanges}
                        shops={this.state.shops}
                        orderTypes={this.state.orderTypes}
                    />
                }
                size={'md'}
                hide={this.hide}
                hideHeader={true}
                hideFooter={true}
                isShowing={this.props.isShowing}
            />
        )
    }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
    return {
        productStore: states.productStore.productStore,
        orderState: states.order.order,
        userState: states.user.user,
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
    return {
        createAndSetOrder: async (orderType: string, customerId: number, salespersonNote?: string) => {
            await dispatch(createAndSetOrder(orderType, customerId, salespersonNote))
            console.log('createOrder completed [UI]')
        },
        getShops: async () => {
            await dispatch(getShops())
            console.log('getShops completed [UI]')
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrderModal)