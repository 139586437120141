import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

// import axios from 'axios';
import axios from '../globalAxios';
import { uniq, sortBy } from 'lodash';
import { appConfig } from '../../config';

import Style, { DeliveryWindow, mapStyles } from 'molo-shared/lib/models/Style';
import { FaConnectdevelop } from 'react-icons/fa';

const baseUrl = appConfig.api.baseUrl;

export interface IGetCollections {
    type: 'FETCH_COLLECTIONS_SUCCESS',
    collections: any[]
}

export interface IGetCollectionMaster {
    type: 'FETCH_COLLECTION_MASTER_SUCCESS',
    styles: any[]
}

export interface SetFetcingCollectionMasters {
    type: 'SET_FETCHING_COLLECTION_MASTERS'
    isFetching: boolean
}

export interface ISetDeliveryWindows {
    type: 'SET_DELIVERY_WINDOWS_SUCCESS',
    deliveryWindows: DeliveryWindow[]
}

export type CollectionsActions = IGetCollections | IGetCollectionMaster | SetFetcingCollectionMasters | ISetDeliveryWindows

// Action Creators
export const getCollectionsSuccess = (collections: any[]): IGetCollections => {
    return { type: 'FETCH_COLLECTIONS_SUCCESS', collections }
}

export const getCollectionMasterSuccess = (styles: Style[]): IGetCollectionMaster => {
    return { type: 'FETCH_COLLECTION_MASTER_SUCCESS', styles }
}

export const setFetcingCollectionMasters = (isFetching: boolean) => {
    return { type: 'SET_FETCHING_COLLECTION_MASTERS', isFetching }
}

export const setDeliveryWindowsSuccess = (deliveryWindows: DeliveryWindow[]): ISetDeliveryWindows => {
    return { type: 'SET_DELIVERY_WINDOWS_SUCCESS', deliveryWindows }
}

export const getCollections = (orderType: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        const url = baseUrl + 'OrderTypes/GetOrderTypeCollections'
        dispatch(setFetcingCollectionMasters(true))
        
        try {
            const response = await axios.post(url, null, {params: { orderTypeName: orderType }});
            let collections = response.data;
            dispatch(setFetcingCollectionMasters(false))
            dispatch(getCollectionsSuccess(collections))
        } catch (e) {

        }
    }
}

export const getCollectionMasters = (orderType: string, collectionName: string, currencyCode: string, pricegroup: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        const url = baseUrl + 'Items/GetCollectionMasters'
console.log('GetCollectionMasters1');
        dispatch(setFetcingCollectionMasters(true));
        
        try {
            const response = await axios.post(url, null, {params: { orderType, collectionName, currencyCode, pricegroup }});
            let styles = mapStyles(response.data);

            // Hack - should be removed(TS)
            if (orderType === 'PRE-ORDER S22') {
                // styles = styles.filter(style => style.navCollection.includes('22'))
            }

            dispatch(getCollectionMasterSuccess(styles))
            dispatch(setDeliveryWindows(styles));
        } catch (e) {
            console.log(e, '**ERROR**');
        } finally {
            dispatch(setFetcingCollectionMasters(false));
        }
    }
}

export const setDeliveryWindows = (products: Style[]): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
        const allDeliveryWindows = products.map(style => style.deliveryWindow);
        const deliveryWindows: DeliveryWindow[] = [];
        const uniqueWindows = uniq(allDeliveryWindows.map(window => window.window));

        for (const key of uniqueWindows) {
            const window = allDeliveryWindows.find(window => window.window === key);
            if (window) {
                deliveryWindows.push(window);
            }
        }

        const windows = sortBy(deliveryWindows, 'sort')

        dispatch(setDeliveryWindowsSuccess(windows));
    }
}

export class CollectionApi {
    static getCollectionMasters = async (orderType: string, collectionName: string, currencyCode: string, pricegroup: string) => {
        const url = baseUrl + 'Items/GetCollectionMasters'
        console.log('getCollectionMasters');
        return await axios.post(url, null, {params: { orderType, collectionName, currencyCode, pricegroup }});
    }

    static getCollections = async (orderTypeName: string) => {
        const url = baseUrl + 'OrderTypes/GetOrderTypeCollections'
        return await axios.post(url, null, {params: { orderTypeName }});
    }
}
