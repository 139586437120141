import * as React from "react";

import BarLoader from 'react-spinners/BarLoader'

import { ReactComponent as Logo } from '../../images/svg/Molo_Logo_Black_WS.svg';

const MoloRingloader: React.FC = () => (
    <div style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        flexDirection: 'column',
    }}>
        <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center'}} className="logo-fade-in">
            <Logo style={{height: '100px'}} />
        </div>
        <div style={{marginBottom: '150px'}}>
            <BarLoader width={500} />
        </div>
    </div>
)

export default MoloRingloader;