import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from "react-router-dom";

import { Input } from '../form/Input';
import { Button } from '../form/Button';

import { Redirect, RouteComponentProps } from 'react-router-dom'
// import authenticateUser from '../../services/auth/authenticate';

import { ThunkDispatch } from 'redux-thunk'
import { RootState } from '../../store';
import { IUserSate } from '../../store/user/reducers';
import { loginUser, isUserValid } from '../../store/user/actions';

interface RouteProps extends RouteComponentProps {}

interface State {
    formValues: {
        email: string
        password: string,
    },
    redirectToReferrer: boolean
}
  
interface OwnProps {
    location: any
}
  
interface DispatchProps {
    loginUser: (email: string, password: string) => void;
    isUserValid: () => void;
}
  
interface StateProps {
    userState: IUserSate
}
  
type Props = StateProps & OwnProps & DispatchProps & RouteProps;

export class Login extends Component<Props, State> {
    state = {
        formValues: {email: '', password: ''},
        redirectToReferrer: false,
    }

    componentDidMount() {
        if(this.props.userState.isValidated && this.props.userState.user) {
            this.setState({ redirectToReferrer: true });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.userState.user) {
            this.setState({ redirectToReferrer: true });
        }

        if (nextProps.userState.token && !nextProps.userState.user) {
            this.props.isUserValid();
        }
    }

    private handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { name, value } = e.currentTarget;

        if (name === 'email') {
            this.setState({formValues: {email: value, password: this.state.formValues.password}})
        }

        if (name === 'password') {
            this.setState({formValues: {email: this.state.formValues.email, password: value}})
        }
    };

    handleSubmit() {
        this.props.loginUser(this.state.formValues.email, this.state.formValues.password)
    }

    render() {
        const referer = this.props.location.state.referer || '/';
        // console.log(this.props.location, 'this.props.location')
        let { redirectToReferrer } = this.state;

        if (redirectToReferrer) {
            return <Redirect to={referer} />;
        }

        return (
            <div className="login-page" style={{
                    display: 'flex',
                    height: '100vh',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '50% 50%',
                }}>
                <form
                    onSubmit={(e: any) => e.preventDefault()}
                    className="login-form"
                    style={{
                        width: '100%',
                        maxWidth: '550px',
                        background: 'rgba(0,0,0,.3)',
                        padding: '30px',
                        borderRadius: '6px',
                    }}>
                    <Input
                        name="email"
                        title="email"
                        inputType="email"
                        value={this.state.formValues.email}
                        handleChange={this.handleChange}
                        placeholder="Your email"
                        required={true}
                    />
                    <Input
                        name="password"
                        title="password"
                        inputType="password"
                        value={this.state.formValues.password}
                        handleChange={this.handleChange}
                        required={true}
                        placeholder="Your password" />
                    <Button handleCLick={this.handleSubmit.bind(this)} title="Login" />
                    <Link className="login-page__forgot-password" to={`/forgot-password`} >
                        Forgot password?
                    </Link>
                </form>
            </div>
        )
    }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
    return {
      userState: states.user.user
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
    return {
        loginUser: async (email: string, password: string) => {
            await dispatch(loginUser(email, password))
            // console.log('user login completed [UI]')
        },
        isUserValid: async () => {
            await dispatch(isUserValid())
            // console.log('isUserValid completed [UI]')
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)