import React from 'react';

interface IProps {
    title: string
    handleCLick: any,
}

export const Button: React.FC<IProps> = ({
    title,
    handleCLick,
}) => {
	return (  
        <div className="form-group">
            <button 
		        onClick= {handleCLick} > 
		        {title} 
	        </button>
        </div>
    )
}