import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PropagateLoader from 'react-spinners/PropagateLoader'
import ClipLoader from 'react-spinners/ClipLoader';

// Store.
import { RootState } from '../../store';
import { getClosedOrders, getOpenOrders, getDashboardOrderState, addClosedOrderToRecent } from '../../store/order/actions';
import { IOrderState } from '../../store/order/orderStore';
import { updatePassword } from '../../store/user/actions';
import { IUserSate } from '../../store/user/reducers';
import OrderApi from '../../store/order/OrderApi';
import ProductApi from '../../store/products/ProductApi';
import FileApi from '../../store/files/FileApi';

// Models.

// Components.

interface State {
    currentShop?: any,
    shops?: any[],
    shopNavOrders?: any[]
    shopDeliveryInfo?: any[],
    editableNavOrders: any[],
    loadingNavOrder: boolean
    loadingShopOrders: boolean,
    generatingFile: boolean,
    tabIndex: number,
    orderTypes: string[],
    futureDrops: any[],
    previousDrops: any[],
    futureDropsGroupedBySeason: any,
    activeSection: string
}
  
interface OwnProps {
    shouldHide?: Function
    history: any
    isProfile?: boolean
}
  
interface DispatchProps {
    getClosedOrders: () => void
    getOpenOrders: () => void
    updatePassword: (email: string, oldPassword: string, newPassword: string) => void
    getDashboardOrderState: (orderId: number, should?: boolean) => void
    addClosedOrderToRecent: (order: any, should?: boolean) => any
}
  
interface StateProps {
    orderState: IOrderState
    userState: IUserSate
}
  

type Props = StateProps & OwnProps & DispatchProps;

export class ProductDownloadModalContent extends Component<Props, State> {

    state: State = {
        generatingFile: false,
        shopNavOrders: [],
        shopDeliveryInfo: [],
        editableNavOrders: [],
        loadingNavOrder: false,
        tabIndex: 0,
        loadingShopOrders: false,
        orderTypes: [],
        futureDrops: [],
        previousDrops: [],
        futureDropsGroupedBySeason: {},
        activeSection: 'previous-drops'
    };

    async componentDidMount() {

        const shopsResponse = await OrderApi.getShops()

        console.log(shopsResponse, 'shopsResponse')

        let currentShop = shopsResponse.data[0];

        if (!currentShop) {
            currentShop = {
                customerId: this.props.userState.user.customerData.CustomerId,
                name: this.props.userState.user.customerData.Name,
            }
        }

        const response = await ProductApi.getOrderTypes();
        const orderTypes = response.data.map((type: any) => type.orderType)

        this.setState({ shops: shopsResponse.data, currentShop, orderTypes }, () => {
            if (currentShop) {
                this.onChangeShop(currentShop);
            }
        })

        this.props.getClosedOrders();
        this.props.getOpenOrders();

        if (this.props.isProfile) {
            this.setState({ tabIndex : 2 })
        }
    }

    groupBy = (key: any) => (array: any[]) =>
    array.reduce((objectsByKeyValue: { [x: string]: any; }, obj: { [x: string]: any; }) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
    }, {});

    handleOrderClick = async (orderId: number, isNavOrder: boolean = false) => {
        if (!isNavOrder) {
            this.props.history.push('/dashboard/' + orderId);
            this.props.getDashboardOrderState(orderId)
        } else {

            this.setState({loadingNavOrder: true})

            const navOrderResponse = await OrderApi.openNavOrder(orderId)
            const createdapporderid = navOrderResponse.data.createdapporderid

            if (navOrderResponse) {
                this.props.history.push('/dashboard/' + createdapporderid);
                this.setState({loadingNavOrder: false})
                this.props.getDashboardOrderState(createdapporderid, true)
            }
        }

        this.props.shouldHide && this.props.shouldHide()
    }

    onChangeShop = (shop: any) => {
        console.log(shop, 'changed shop')
        this.setState({ currentShop: shop, loadingShopOrders: true }, async () => {

            // const dummuyDataSeason = [
            //     {
            //         "customerno": "12025",
            //         "deliverycode": "SS TEST",
            //         "daysfromtoday": -31,
            //         "seasoncode": "S22TEST",
            //         "deliveryshipment": "2017-11-15T00:00:00Z",
            //         "daystoseasonstartshipping": 60
            //       },
            //       {
            //         "customerno": "12025",
            //         "deliverycode": "SS PRE",
            //         "daysfromtoday": -1015,
            //         "seasoncode": "S22TEST",
            //         "deliveryshipment": "2017-11-15T00:00:00Z",
            //         "daystoseasonstartshipping": 60
            //       },
            //       {
            //         "customerno": "12025",
            //         "deliverycode": "AW 01A",
            //         "daysfromtoday": -1169,
            //         "seasoncode": "S22TEST",
            //         "deliveryshipment": "2017-06-14T00:00:00Z",
            //         "daystoseasonstartshipping": 60
            //       },
            //       {
            //         "customerno": "12025",
            //         "deliverycode": "AW 01",
            //         "daysfromtoday": -97757,
            //         "seasoncode": "OW22TEST",
            //         "deliveryshipment": "1753-01-01T00:00:00Z",
            //         "daystoseasonstartshipping": 60
            //       },
            //       {
            //         "customerno": "12025",
            //         "deliverycode": "SS TEST",
            //         "daysfromtoday": -31,
            //         "seasoncode": "OW22TEST",
            //         "deliveryshipment": "2017-11-15T00:00:00Z",
            //         "daystoseasonstartshipping": 60
            //       },
            //       {
            //         "customerno": "12025",
            //         "deliverycode": "SS PRE",
            //         "daysfromtoday": -1015,
            //         "seasoncode": "OW22TEST",
            //         "deliveryshipment": "2017-11-15T00:00:00Z",
            //         "daystoseasonstartshipping": 60
            //       },
            //       {
            //         "customerno": "12025",
            //         "deliverycode": "AW 01A",
            //         "daysfromtoday": -1169,
            //         "seasoncode": "OW22TEST",
            //         "deliveryshipment": "2017-06-14T00:00:00Z",
            //         "daystoseasonstartshipping": 60
            //       },
            //       {
            //         "customerno": "12025",
            //         "deliverycode": "AW 01",
            //         "daysfromtoday": -97757,
            //         "seasoncode": "OW22TEST",
            //         "deliveryshipment": "1753-01-01T00:00:00Z",
            //         "daystoseasonstartshipping": 60
            //       },
            // ];

            // Peak closed orders.
            const response = await OrderApi.getCustomerDeliveryDownloadInfo(shop.customerId)

            console.log(response, 'response :: getCustomerDeliveryDownloadInfo ')

            let dummyResponse = response.data;
            // let dummyResponse = [...response.data, ...dummuyDataSeason];
            let previousDrops = [];
            let futureDrops = [];
            let futureDropsGroupedBySeason = {};

            for (const res of dummyResponse) {
                if (res.daystoseasonstartshipping <= 60) {
                    if (res.daystoseasonstartshipping > 0) {
                        futureDrops.push(res);
                    } else {
                        previousDrops.push(res);
                    }
                } else {
                    futureDrops.push(res);
                }
            }

            
            console.log(previousDrops, 'previousDrops')
            console.log(futureDrops, 'futureDrops')

            if (futureDrops.length) {
                const groupBySeason = this.groupBy(['seasoncode']);
                futureDropsGroupedBySeason = groupBySeason(futureDrops);
                console.log(futureDropsGroupedBySeason, 'futureDropsGroupedBySeason')
            }

            console.log(Object.keys(futureDropsGroupedBySeason), 'Object.keys(futureDropsGroupedBySeason)')

            let activeSection = previousDrops.length ?
                'previous-drops' : (!previousDrops.length && futureDrops.length ? Object.keys(futureDropsGroupedBySeason)[0] : '')

            console.log(activeSection, 'activeSectionactiveSectionactiveSection')

            this.setState({
                shopDeliveryInfo: response.data,
                futureDrops,
                previousDrops,
                loadingShopOrders: false,
                futureDropsGroupedBySeason,
                activeSection,
                // editableNavOrders
            })
        })
    }

    isAlreadyOpen = (order: any) => {
        var existing = this.props.orderState.orders.find(function(item) {
            return item.navorderid === order.navorderid;
        });

        return existing;
    }

    handleDropClick = (deliverycode: string, seasoncode: string) => {
        if (this.state.currentShop) {
            this.setState({ generatingFile: true }, async () => {
                const response = await FileApi.createCustomerDropZip(this.state.currentShop.customerId, deliverycode, seasoncode);

                this.setState({ generatingFile: false })

                window.location.href = response.data.location;

                console.log(response, 'RESPONS :: createCustomerDropZip')
            })
        }
    }

    onClickPreviousDrops = () => {
        this.setState({ generatingFile: true }, async () => {

            let data: any[] = [];

            for (const drop of this.state.previousDrops) {
                data.push({
                    deliverycode: drop.deliverycode,
                    seasoncode: drop.seasoncode,
                })
            }

            const response = await FileApi.createCustomerMultipleDropZip(this.state.currentShop.customerId, data);

            console.log(response, 'response :: createCustomerMultipleDropZip')

            this.setState({ generatingFile: false })

            window.location.href = response.data.location;
        })
    }

    renderDrops = () => {
        const {
            loadingShopOrders,
            generatingFile,
            previousDrops,
            futureDropsGroupedBySeason,
            activeSection,
            futureDrops,
            currentShop,
        } = this.state;

        if (generatingFile || loadingShopOrders) {
            return (
                <div className="my-molo">
                    <div className="loading-spinner-1">
                        <p>{generatingFile ? 'Generating file' : ''}</p>
                        <PropagateLoader />
                    </div>
                </div>
            )
        }

        if (!previousDrops.length && !futureDrops.length && currentShop) {
            return <h1>No Drops for { currentShop.name}</h1>
        }

        return (
            <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex })}>
                <TabList>
                    <Tab>Previous Drops</Tab>
                    <Tab>Current Drops</Tab>
                    <Tab>Future Drops</Tab>
                </TabList>
                <TabPanel>
                    <div className="product-download">
                        <div className="product-download__sidebar pds">
                            <ul className="pds__items">
                                {
                                    previousDrops.length ?
                                    <li
                                        className={"pds__item" + (activeSection === 'previous-drops' ? ' active' : '')}
                                        key="previous-drops"
                                        onClick={() => this.setState({ activeSection: 'previous-drops' })}>
                                        Previous drops
                                    </li> : null
                                }
                                {
                                    Object.keys(futureDropsGroupedBySeason).map((key) => {
                                        return (
                                            <li
                                                key={key}
                                                className={"pds__item" + (activeSection === key ? ' active' : '')}
                                                onClick={() => this.setState({ activeSection: key })}>
                                                {key}
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className="product-download__main">
                            {
                                this.state.activeSection === 'previous-drops' &&
                                // <button onClick={this.onClickPreviousDrops}>Download previous drops</button>
                                <button onClick={this.onClickPreviousDrops} className="details__remove">Download previous drops</button>
                            }
                            {
                                Object.keys(futureDropsGroupedBySeason).map((key) => {
                                    const drops = futureDropsGroupedBySeason[key];

                                    if (this.state.activeSection !== key) {
                                        return null
                                    }

                                    return (
                                        <table className="striped-table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Delivery code</th>
                                                    <th>Season code</th>
                                                    <th>Shipment date</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    
                                                    drops.map((info: any, key: number) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>{info.deliverycode}</td>
                                                                <td>{info.seasoncode}</td>
                                                                <td>{new Date(info.deliveryshipment).toLocaleDateString()}</td>
                                                                <td>
                                                                    {
                                                                        info.daystoseasonstartshipping <= 60 && <button
                                                                            onClick={() => this.handleDropClick(info.deliverycode, info.seasoncode)}
                                                                            className="details__remove">
                                                                                Download
                                                                        </button>
                                                                    }
                                                                    {
                                                                        info.daystoseasonstartshipping > 60 &&
                                                                        <span>Not available</span>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    )
                                })
                            }
                        </div>

                    </div>
                </TabPanel>
            </Tabs>
        )
    }

    render() {
        const { shops } = this.state;

        return (
            <div className="my-molo">
                <h5>Download product images</h5>
                {shops &&
                    <div>
                        {
                            shops.length > 1 &&
                            <>
                                <p>Choose the shop you want to see drops for.</p>
                                <div style={{ display: 'flex' , width: '100%'}}>
                                    <select
                                        style={{maxWidth: '500px'}}
                                        onChange={(event: any) => {
                                            const selectedShop = shops[event.target.value];
                                            this.onChangeShop(selectedShop)
                                        }}
                                        placeholder="select shop">
                                        {
                                            shops.length &&
                                            shops.map((shop, index) => {
                                                return (
                                                    <option key={index} value={index}>
                                                        { shop.name }
                                                    </option>
                                                )  
                                            })
                                        }
                                    </select>
                                    {
                                        this.state.loadingShopOrders &&
                                        <div style={{ left: '16px', position: 'relative'}}>
                                            <ClipLoader size={30}/>
                                        </div>
                                    }
                                </div>
                            </>
                        }
                    </div>
                }
                {this.renderDrops()}
            </div>
        )
    }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
    return {
        orderState: states.order.order,
        userState: states.user.user
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
    return {
        getClosedOrders: async () => {
            await dispatch(getClosedOrders())
        },
        getOpenOrders: async () => {
            await dispatch(getOpenOrders())
        },
        updatePassword: async (email: string, oldPassword: string, newPassword: string) => {
            await dispatch(updatePassword(email, oldPassword, newPassword))
        },
        getDashboardOrderState: async (orderId: number, should?: boolean) => {
            await dispatch(getDashboardOrderState(orderId, should))
        },
        addClosedOrderToRecent: async (order: any) => {
            await dispatch(addClosedOrderToRecent(order))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDownloadModalContent)