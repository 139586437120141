import React, { useEffect, useState } from 'react'
import {
    Overlay,
    getSizes,
    getRRPMap,
    getAllSizes,
    getGroupedMoloItems,
    ISizes,
    IRRPMap,
    StyleBean,
} from 'molo-shared'
import { groupSimilarColors } from '../shared/Dashboard/DashboardHelper'
import { useSelector } from "react-redux";
import { RootState } from '../../store';
import * as helper from '../shared/Dashboard/DashboardHelper';
import { DashboardFilterHelper } from '../../utils/DashboardFilterHelper';
import {
    IGrouping,
    DIVIDERS
} from '../shared/Dashboard/dashboard.d';
import { cloneDeep } from 'lodash'
import { Spinner } from "@blueprintjs/core";
import { orderLine } from '../../models/Order';

// import { Dashboard } from './dashboard/Dashboard';
import OrderPreview from './OrderPreview';
import OrderApi from '../../store/order/OrderApi';
import { appConfig } from '../../config';
import { uniq } from 'lodash';
import { FaCircle } from 'react-icons/fa';

interface IProps {
    isShowing: boolean
    onHide?: Function
    order?: any
}

interface IState {
    productSortedByCategory: IGrouping
    loadingSeasons: boolean
    loadingCollections: boolean
    slides: string[]
    deliveryWindows: any[]
    groupedMoloItems: {[id: string]: any[]}
    sizes: ISizes
    rrpMap: IRRPMap
    allSizes: ISizes
    groupedOrderlines: orderLine[]
    filteredOrderLines: orderLine[]
    filterInitialized: boolean
    showDeliveryModal: boolean
    currentDrop?: any,
    showProductOverlay: boolean
    selectedProduct?: StyleBean
    initialized: boolean
}

const baseUrl = appConfig.media.baseUrl;

const DashboardOverlay: React.FC<IProps> = (props) => {
    const { isShowing, onHide, order } = props
    const filter = useSelector((state: RootState) => state.session.filter.filter);
    const user = useSelector((state: RootState) => state.user.user.user);
    const [isFetching, setIsFetching] = useState(false)
    const [activeOrder, setActiveOrder] = useState<undefined | any>(undefined)
    const [state, setGlobalState] = useState<IState>(
        {
            productSortedByCategory:  {},
            loadingSeasons: false,
            loadingCollections: false,
            slides: [],
            deliveryWindows: [],
            groupedMoloItems: {},
            sizes: {},
            rrpMap: {},
            allSizes: {},
            groupedOrderlines: [],
            filteredOrderLines: [],
            filterInitialized: false,
            showDeliveryModal: false,
            showProductOverlay: false,
            initialized: false
        }
    )

    const setState = (changes: any) => {
        const newState: IState = { ...state, ...changes}

        setGlobalState(newState)
    }

    useEffect(() => {
        const init = async () => {
            if (isShowing && order) {
                setIsFetching(true)
                setActiveOrder(order)
                    const response = await OrderApi.GetMoloItemsOnOrder(order.orderheader.appOrderId)
                    const deliveryWindows = await OrderApi.getOrderDeliveries(order.orderheader.appOrderId)
                    const groupedMoloItems = getGroupedMoloItems(response.data)
                    const sizes = getSizes(order.orderLines)
                    const slides = uniq(order.orderLines.map(
                        (line: any) => baseUrl + 'media/productimage/' + line.seasoncode + '/' + line.masterid + '/' + line.colorcode + '/small')
                    );

                    setState({
                        sizes,
                        slides,
                        groupedMoloItems: groupedMoloItems,
                        rrpMap: getRRPMap(groupedMoloItems),
                        groupedOrderlines: groupSimilarColors(cloneDeep(order.orderLines)),
                        allSizes: getAllSizes(groupedMoloItems, sizes),
                        productSortedByCategory: helper.getProductCategories(cloneDeep(order.orderLines), DIVIDERS),
                        deliveryWindows: deliveryWindows.data,
                    })
                    setIsFetching(false)
            }
        }

        init()
    }, [isShowing, activeOrder])

    useEffect(() => {
        if (filter && order) {
            
            const filtered = DashboardFilterHelper.resultByFilter(
                filter,
                order.orderLines,
            );
            
            setState({
                groupedOrderlines: groupSimilarColors(cloneDeep(filtered)),
                filteredOrderLines: filtered,
            })
        }
    }, [filter])

    const renderPreview = (order: any, state: IState) => {
        return <OrderPreview
            order={ order }
            allSizes={ state.allSizes }
            deliveryWindows={ state.deliveryWindows }
            filteredOrderLines={ state.filteredOrderLines }
            groupedMoloItems={ state.groupedMoloItems }
            groupedOrderlines={ state.groupedOrderlines }
            productSortedByCategory={ state.productSortedByCategory }
            rrpMap={ state.rrpMap }
            sizes={ state.sizes }
            slides={ state.slides }
        />
    }

    const renderSpinner = () => {
        return <div className="big-spinner"><Spinner /></div>
    }

    const renderHeader = () => {
        return <div className="w-full flex">
            <div className="molo-logo">
                {
                    user &&
                    <span className="--user-name">{user.customerData.Name}</span>
                }
            </div>
            <div className="py-4 flex justify-center flex-grow">
                {order.orderheader.navImportRef && <span className="flex items-center pt-2">{ order.orderheader.navImportRef } <FaCircle className="order-dropdown__divider --take-over" /></span>}
                {order.orderheader.appOrderId && <span className="flex items-center pt-2">{`B2B${order.orderheader.appOrderId}`}</span>}
                <span className="flex items-center pt-2"><FaCircle className="order-dropdown__divider --take-over" /> {order.orderheader.orderType}</span>
                {order.orderheader.externalDocumentNo && <span className="flex items-center pt-2"><FaCircle className="order-dropdown__divider --take-over" />{order.orderheader.externalDocumentNo}</span>}
                {
                    order.orderheader.salespersonNote &&
                    <span className="flex items-center pt-2">
                        <FaCircle className="order-dropdown__divider --take-over" /> { order.orderheader.salespersonNote }
                    </span>
                }
            </div>
        </div>
    }

    return (
        <>
            <Overlay
                children={
                    <div>
                        <div>
                            { order && renderHeader()}
                        </div>
                        <div className="preview-overlay pt-4">
                            {order && !isFetching ? renderPreview(order, state) : renderSpinner()}
                        </div>
                    </div>
                }
                hide={() => {onHide && onHide()}}
                isShowing={ isShowing }
                level={2}
            />
        </>
    )
}

export default DashboardOverlay