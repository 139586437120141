import React, { Component } from 'react'
import { ThunkDispatch } from 'redux-thunk'
import { connect } from 'react-redux'

// Store.
import { ICollectionState } from '../../store/collections/reducers';
import { RootState } from '../../store';

// Components.
import CollectionChooser from '../../components/b2b/CollectionChooser';

interface State {
    collections: any[],
}
  
interface OwnProps {
    match: {params: {orderType: string}},
}
  
interface DispatchProps {}
  
interface StateProps {
    collectionStore: ICollectionState,
    collections?: any[],
}
  
type Props = StateProps & OwnProps & DispatchProps;

export class CollectionListContainer extends Component<Props, State> {
    state: State = {
        collections: []
    }

    componentDidUpdate = (prevProps: Props, prevState: State) => {
        if (prevProps.collections !== this.props.collections && this.props.collections) {
            this.setState(
                {
                    collections: this.props.collections.sort((a, b) => (a.sort > b.sort) ? 1 : -1)
                })
        }
    }

    render() {
        return (
            <div>
                <CollectionChooser
                    collections={this.state.collections}
                    orderType={this.props.match.params.orderType}
                    isFetching={this.props.collectionStore.isFetchingCollectionMasters}
                />
            </div>
        )
    }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
    return {
        collectionStore: states.collections.collections,
        collections: states.collections.collections.collections
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectionListContainer)