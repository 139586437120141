import React from 'react';
import ReactDOM from 'react-dom';

import { FaTimes } from 'react-icons/fa';

type ModalSizes = 'lg' | 'md' | 'sm' | 'special'

interface IProps {
    isShowing: boolean,
    hide: any,
    children?: any,
    header?: any,
    footer?: any,
    size?: ModalSizes
    hideFooter?: boolean
    hideHeader?: boolean
    hideClose?: boolean
    bodyClasses?: string | null
}

const Modal: React.FC<IProps> = (props) => {
    const { isShowing, hide, children, footer, size, hideFooter, hideHeader, bodyClasses, header, hideClose } = props;
    const modalSize: ModalSizes = size || 'lg';
    const [hideIsTriggered, setHideIsTriggered] = React.useState(false);
    const node = React.useRef<HTMLDivElement>(null);

    const onKeyPressed = (e: any) => {
        if (e.key === 'Escape' && props.hide && !hideIsTriggered) {
            e.preventDefault();
            setHideIsTriggered(true)
            document.addEventListener('keydown', onKeyPressed);
            props.hide();
        }
    }

    const handleClickOutside = (e: any) => {
        
        if(node && node.current && node.current.contains(e.target)) {
            return;
        }

        if(node && node.current && e.target.classList.contains('ignore-clickoutside')) {
            return;
        }
        
        // outside click 
        props.hide && props.hide();
    };

    React.useEffect(() => {
        // add when mounted
        document.addEventListener('keydown', onKeyPressed);
        document.addEventListener("mousedown", handleClickOutside);

        // return function to be called when unmounted
        return () => {
            document.removeEventListener('keydown', onKeyPressed);
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    if (isShowing) {
        return ReactDOM.createPortal(
            <React.Fragment>
                <div className={"modal-wrapper " + modalSize + (isShowing? ' showModal' : ' hideModal')}>
                    <div className={"modal " + (isShowing ? "showModal" : "hideModal")} ref={node}>
                        {
                            !hideHeader &&
                            <div className="modal-header">
                                {header}
                                {
                                    !hideClose &&
                                    <div className="modal-header__close" onClick={hide}>
                                        <FaTimes />
                                    </div>
                                }
                            </div>
                        }
                        {
                            hideHeader && !hideClose &&
                            <div className="modal-header__close" onClick={hide}>
                                <FaTimes />
                            </div>
                        }
                        <div className={"modal-body" + (bodyClasses ? ' ' + bodyClasses : '')}>
                            { children && children }
                        </div>
                        {
                            !hideFooter &&
                            <div className="modal-footer">
                                { footer && footer}
                            </div>
                        }
                    </div>
                </div>
            </React.Fragment>, document.getElementById('root') as HTMLElement
        )
    }
    
    return null;
}

export default Modal;