import { orderLine } from '../Models/Order';

interface IUnique {
    [id: string]: any
}

export function totalDiscountAmount(orderlines: any[], discount = false) {
    var totalDiscount = 0;

    for (const i in orderlines) {
        const orderline = orderlines[i];
        if (orderline.linediscountAmount) {
            totalDiscount = + totalDiscount + parseFloat(orderline.linediscountAmount);
        }
        
        if (orderline.linediscountAmount && discount === false) {
            discount = true;
        }
    }

    return totalDiscount;
}

export function humanReadableLineUnitPrices(masterId: string, colorCode: string, orderlines: orderLine[]) {

    var prices = [];

    var line = orderlines.filter(function(item) {
      return item.masterid === masterId && item.colorcode === colorCode;
    });

    for (var i in line) {
      prices.push(roundAmount(orderlines[i].unitPrice));
    }

    var unique: IUnique = {};

    prices.forEach(function(i) {
      if(!unique[i]) {
        unique[i] = true;
      }
    });

    return Object.keys(unique).join(" / ");
}

export function totalLineDiscountAmount(masterId: string, colorCode: string, orderlines: orderLine[]) {
    var totalDiscountAmount = 0;

    var line = orderlines.filter(function(item) {
      return item.masterid === masterId && item.colorcode === colorCode;
    });

    for (var i in line) {
      totalDiscountAmount = totalDiscountAmount + line[i].linediscountAmount;
    }

    return totalDiscountAmount;
}

export function totalLineAmount(masterId: string, colorCode: string, orderlines: orderLine[]) {
    var totalDiscountAmount = 0;

    var line = orderlines.filter(function(item) {
      return item.masterid === masterId && item.colorcode === colorCode;
    });

    for (var i in line) {
      totalDiscountAmount = totalDiscountAmount + line[i].amount;
    }

    return totalDiscountAmount;
}

export function getWHPWithDiscount(whp: number, discount?: number) {
  discount = discount || 0;
  return whp - (whp * (discount / 100))
}

export const roundAmount = (amount: number) => {
  return amount.toLocaleString(navigator.language, {minimumFractionDigits: 2})
};