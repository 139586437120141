import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { Link } from "react-router-dom";
import {RouteComponentProps, withRouter} from "react-router-dom";

import { groupBy } from '../../utils/UtilHelper';

import {
    FaChevronLeft,
    FaChevronRight,
    FaExternalLinkAlt,
    FaShoppingCart,
} from 'react-icons/fa';

import { RootState } from '../../store';
import { getOrder, getOpenOrders, setOrder, setUserHasConfirmed } from '../../store/order/actions';
import { IOrderState } from '../../store/order/orderStore';
import { IUserSate } from '../../store/user/reducers';
import { Basket } from '../../models/Basket';
import {
    roundAmount
} from '../../utils/priceHelper';
import OrderApi from '../../store/order/OrderApi';

import queryString from 'query-string'

// Components.
import BasketDropUp from './BasketDropUp';
import Order from '../../models/Order'
import CreateOrderModal from '../b2b/CreateOrderModal'
import ConfirmModal from '../layout/Modal/ConfirmModal';
import GatherDeliveryWindowsModal from './GatherDeliveryWindowsModal';

interface State {
    showOverlay: boolean,
    showGotItModal: boolean,
    order: Order | undefined | null
    showOrderModal: boolean,
    orderTypeName: string,
    previousNonDashboardUrl: string,
    isCheckout: boolean,
    isDetailsPage: boolean,
    showGatherModal: boolean
}
  
interface OwnProps {
    showDropup?: boolean
    showContinueShopping?: boolean
    showCheckoutLink?: boolean
    showDashboardLink?: boolean
    match?: {params: {appOrderId: number}},
    history: any
    editMode?: boolean
}
  
interface DispatchProps {
    getOrder: (orderId: number) => void
    getOpenOrders: () => void
    setOrder: (orderId: number) => void
    setUserHasConfirmed: (informed: boolean) => void
}
  
interface StateProps {
    orderState: IOrderState
    basket?: Basket
    userState: IUserSate
}
  
type Props = StateProps & OwnProps & DispatchProps & RouteComponentProps;

export class BasketComponent extends Component<Props, State> {
    state: State = {
        showOverlay: false,
        order: null,
        showOrderModal: false,
        orderTypeName: '',
        previousNonDashboardUrl: '/shop',
        isCheckout: false,
        isDetailsPage: false,
        showGotItModal: false,
        showGatherModal: false
    }

    public static getDerivedStateFromProps(props: Props, state: State) {
        if (props.orderState.order && props.orderState.order !== state.order) {
            return {
                order: props.orderState.order
            }
        }
        return null;
    }

    componentDidMount() {
        const orderId = localStorage.getItem('order')
        
        if (!this.props.location.pathname.includes('checkout') && !this.props.location.pathname.includes('dashboard') && !this.props.location.pathname.includes('previeworder') ) {
            localStorage.setItem('previousNonDashboardUrl', this.props.location.pathname + this.props.location.search)

            if (orderId) {
                this.props.setOrder(+orderId);
            }
         } else {
            if (!this.state.isCheckout) {
                this.setState({ isCheckout: true })
            }

            if (this.props.location.pathname.includes('dashboard') && this.props.location.search.includes('step=details')) {
                this.setState({ isDetailsPage: true })
            }
        }
        
        this.props.getOpenOrders();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            
            if (!this.props.location.pathname.includes('checkout') && !this.props.location.pathname.includes('dashboard') && !this.props.location.pathname.includes('previeworder') && !this.props.location.pathname.includes('search') ) {
                localStorage.setItem('previousNonDashboardUrl', this.props.location.pathname + this.props.location.search)
            } else {
                if (!this.state.isCheckout) {
                    this.setState({ isCheckout: true })
                }
            }
        }

        if (prevState.order !== this.state.order) {
            if (prevState.order && this.state.order &&
                prevState.order.orderheader.appOrderId !== this.state.order.orderheader.appOrderId && !this.props.showDashboardLink &&
                this.props.history) {
                // this.props.history.push('/shop/' + this.state.order.orderheader.orderType);
            }
        }

        const query = this.props.location && this.props.location.search ? queryString.parse(this.props.location.search) : null;
        let isDetailsPage = query && query.step === 'details' ? true : false;

        if (this.state.isDetailsPage !== isDetailsPage) {
            this.setState({isDetailsPage})
        }
    }

    setOrderHandler = (order: any) => {
        this.props.setOrder(order.appOrderId);
    }

    showCreateOrderModal = () => {
        this.setState({ showOrderModal: true })
    }

    render() {
        let { orders } = this.props.orderState;
        let recentClosedOrders = OrderApi.getRecentOrdersFromLocalStorage()

        orders = [...orders.filter(order => order.orderState === 0), ...recentClosedOrders]

        return (
            <div className={"basket-bottom" + (this.props.editMode ? ' --edit-mode' : '') + (this.state.isCheckout ? ' --checkout' : '') }>
                {
                    this.props.showDropup &&
                    <BasketDropUp
                        activeOrder={this.props.orderState.order || null}
                        orders={orders}
                        setOrderHandler={this.setOrderHandler}
                        onClickCreateModal={this.showCreateOrderModal}
                    />
                }

                {
                    this.props.showContinueShopping ?
                    (
                        <div style={{textAlign: 'left'}} className="continue-shopping">
                            <div className="basket-bottom__link clickable" onClick={() => {
                                const previousNonDashboardUrl = localStorage.getItem('previousNonDashboardUrl');

                                if (previousNonDashboardUrl) {
                                    this.props.history.push(previousNonDashboardUrl)
                                } else {
                                    this.props.history.push('/shop')
                                }
                            }}>
                                <span className="basket-bottom__icon-left"><FaChevronLeft /></span>
                                Continue shopping
                            </div>
                        </div>
                    ) : null
                }

                {
                    this.props.orderState.order &&
                    this.props.basket ?
                    <div
                        className={"shopping-cart" + (this.props.showDashboardLink && (this.props.basket && this.props.basket.numberOfItems > 0)? ' clickable' : '')}
                        style={{textAlign: 'center'}}
                        onClick={() => {
                            if (this.props.orderState.order) {
                                if (!this.props.showDashboardLink || (this.props.basket && this.props.basket.numberOfItems === 0) ) return;
                                this.props.history.push('/dashboard/' + this.props.orderState.order.orderheader.appOrderId + '?step=details&origin=shop')
                            }
                        }}>
                        <span className="shopping-cart__basket">
                            {/* <FaExternalLinkAlt /> */}
                            <FaShoppingCart />
                            <span className="shopping-cart__basket__items">{this.props.basket.numberOfItems }</span>
                        </span>
                        {
                            this.props.orderState.order &&
                            this.props.basket ? 
                            <span>
                                {/* {this.props.basket.numberOfItems } items, total:  */}
                                TOTAL: { this.props.basket && roundAmount(this.props.basket.total) + ' '} 
                                {this.props.orderState.order && this.props.orderState.order.orderheader.currencyCode}
                            </span>
                            :
                            null
                        }
                        {
                            this.props.showDashboardLink  && this.props.basket.numberOfItems > 0 ?
                            <span className="button shopping-cart__button">
                                <span className="shopping-cart__button__text">Go to items</span>
                                <FaExternalLinkAlt />
                            </span> : null

                        }
                    </div>
                    :
                    null
                }
                
                <div style={{textAlign: 'right'}} className="checkout-link">
                    {
                        this.props.orderState.order
                        && this.props.orderState.order.orderheader.orderState === 0
                        && this.props.orderState.order.orderLines.length
                        && !this.state.isDetailsPage
                        && this.props.showCheckoutLink ?
                        (
                            
                            <div className="basket-bottom__link clickable" onClick={() => {

                                const gotIt = this.props.orderState.userIsInformed;

                                const gatherDeliveryWindows = groupBy(['deliveryCode']);

                                const grupedDeliveryWindows = gatherDeliveryWindows(this.props.orderState.dashboardDeliveryWindows)
                                let forceAlert = false;

                                for (const i in grupedDeliveryWindows) {
                                    if (grupedDeliveryWindows[i].length > 1) {
                                        forceAlert = true;
                                    }
                                }
                                

                                if (forceAlert) {
                                    this.setState({ showGatherModal: true })
                                    this.props.setUserHasConfirmed(true)
                                } else {
                                    if (this.props.orderState.order && gotIt) {
                                        this.props.history.push(`/checkout/` + this.props.orderState.order.orderheader.appOrderId)
                                    } else {
                                        this.setState({ showGotItModal: true })
                                    }
                                }

                                if (this.props.orderState.order && gotIt) {
                                    this.props.history.push(`/checkout/` + this.props.orderState.order.orderheader.appOrderId)
                                } else {
                                    this.setState({ showGotItModal: true })
                                }
                            }} >
                                Checkout
                                <span className="basket-bottom__icon-right"><FaChevronRight /></span>
                            </div>
                        ) : null
                    }
                    {
                        this.props.orderState.dashboardOrder
                        && this.props.orderState.dashboardOrder.orderheader.orderState === 0
                        && this.props.orderState.dashboardOrder.orderLines.length
                        && this.state.isDetailsPage
                        && this.props.showCheckoutLink ?
                        (
                            
                            <Link
                                className="basket-bottom__link"
                                to={`/dashboard/` + this.props.orderState.dashboardOrder.orderheader.appOrderId + '?step=reset'}>
                                Checkout
                                <span className="basket-bottom__icon-right"><FaChevronRight /></span>
                            </Link>
                        ) : null
                    }
                    {
                        this.props.orderState.order && this.props.orderState.order.orderLines.length && this.props.showDashboardLink?
                        (
                            <Link className="basket-bottom__link" to={`/dashboard/` + this.props.orderState.order.orderheader.appOrderId} >
                                Checkout
                                <span className="basket-bottom__icon-right"><FaChevronRight /></span>
                            </Link>
                        ) : null
                    }

                </div>
                {
                    this.state.showOrderModal && <CreateOrderModal
                        isShowing={this.state.showOrderModal}
                        hide={() => this.setState({ showOrderModal: false })}
                        orderTypeName={this.state.orderTypeName}
                    />
                }
                {/* {
                    this.props.orderState.dashboardOrder &&
                    <GatherDeliveryWindowsModal
                        isShowing={ this.state.showGatherModal }
                        order={this.props.orderState.dashboardOrder}
                        hide={() => {
                            this.setState({ showGatherModal: false })
                            if (this.props.orderState.order) {
                                this.props.history.push(`/checkout/` + this.props.orderState.order.orderheader.appOrderId)
                            }
                        }}
                    />} */}
                <ConfirmModal
                    isShowing={this.state.showGotItModal}
                    hide={() => {
                        this.setState({showGotItModal: false})
                        this.props.setUserHasConfirmed(true)
                    }}
                    content={(
                        <div className="delete-order-modal">
                            <h2>You haven't changed your drops</h2>
                            <p style ={{
                                maxWidth: '400px',
                                margin: '0 auto 30px',
                            }}>
                                Are you sure you eant to checkout without changing your shipping dates?
                            </p>
                            <div className="delete-order-modal__footer">
                                <button
                                    className="btn"
                                    onClick={() => {
                                        this.setState({showGotItModal: false})
                                        this.props.setUserHasConfirmed(true)
                                    }}
                                >
                                    No, edit dates
                                </button>
                                <button
                                    className="btn"
                                    onClick={() => {
                                        this.setState({showGotItModal: false})
                                        this.props.setUserHasConfirmed(true)
                                        if (this.props.orderState.order) {
                                            this.props.history.push(`/checkout/` + this.props.orderState.order.orderheader.appOrderId)
                                        }
                                    }}
                                >
                                    Yes, go to checkout
                                </button>
                            </div>
                        </div>
                    )}
                />
            </div>
        )
    }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
    return {
        orderState: states.order.order,
        basket: states.order.order.basket,
        userState: states.user.user
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
    return {
        getOrder: async (orderId: number) => {
            await dispatch(getOrder(orderId))
            // console.log('getOrder completed [UI]')
        },
        setOrder: async (orderId: number) => {
            await dispatch(setOrder(orderId))
            // console.log('setOrder completed [UI]')
        },
        getOpenOrders: async () => {
            await dispatch(getOpenOrders())
            // console.log('getOpenOrders completed [UI]')
        },
        setUserHasConfirmed: async (informed: boolean) => {
            await dispatch(setUserHasConfirmed(informed))
            // console.log('getOpenOrders completed [UI]')
        },
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BasketComponent)) 