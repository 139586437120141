import React, { Component } from 'react'
import { ThunkDispatch } from 'redux-thunk'
import { RootState } from '../../store';
import { connect } from 'react-redux'
import {RouteComponentProps, withRouter} from "react-router-dom";

// Store.
import { IOrderState } from '../../store/order/orderStore';
import { setOrder, createAndSetOrder } from '../../store/order/actions';
import OrderApi from '../../store/order/OrderApi';
import ProductApi from '../../store/products/ProductApi';
import { IUserSate } from '../../store/user/reducers';

// Components.
import CreateOrderBody from '../../components/b2b/CreateOrderBody';

// Models.
import { OrderType } from '../../models/OrderType';

interface State {
    createNeworder: boolean
    orderTypes: OrderType[]
    shops: any[]
    orderType?: string | undefined
}
  
interface OwnProps {
    orderFlowDone: Function
    match?: {params: {collection: string, orderType: string}},
    orderType?: string
    multiOrderTypes?: string[]
}
  
interface DispatchProps {
    setOrder: (orderId: number) => void
    createAndSetOrder: (orderType: string, customerId: number, salespersonNote?: string) => void
}
  
interface StateProps {
    orderState: IOrderState
    userState: IUserSate
}
  
type Props = StateProps & OwnProps & DispatchProps;

type ComponentProps = Props & RouteComponentProps;

export class OrderFlow extends Component<ComponentProps, State> {

    state: State = {
        createNeworder: false,
        shops: [],
        orderTypes: []
    }

    async componentDidMount() {
        const orderTypeResponse = await ProductApi.getOrderTypes();
        const disallowedTypes = this.props.userState.user && this.props.userState.user.disallowedTypes ? this.props.userState.user.disallowedTypes : []; 
        let types = orderTypeResponse.data;

        if (disallowedTypes) {
            types = types.filter((type: any) => disallowedTypes.indexOf(type.type.toLowerCase()) === -1)
        }

        const shopsResponse = await OrderApi.getShops()
        const { orderType } = this.props.match.params;

        this.setState({
            orderTypes: types,
            shops: shopsResponse.data,
            orderType
        });
    }

    public static getDerivedStateFromProps(props: Props, state: State) {
        
        if (props.orderType && !state.orderType) {
          return {
            orderType: props.orderType
          }
        }
        return null;
    }

    componentDidUpdate() {
        const localStorageOrder = localStorage.getItem('order');
        if ((this.props.orderState.orders
            && this.props.orderState.orders.length
            && (this.state.orderType || this.props.orderType)
            && !this.state.createNeworder)
            || (!this.props.orderState.orders.length && !this.state.createNeworder)
            || (!localStorageOrder && !this.state.createNeworder)) {
                let ordersForCurrentOrderType = [];

                if (this.props.orderType) {
                    ordersForCurrentOrderType = this.props.orderState.orders.filter(o => o.orderType === this.props.orderType);
                } else {
                    ordersForCurrentOrderType = this.props.orderState.orders.filter(o => o.orderType === this.state.orderType);
                }


                if (!ordersForCurrentOrderType.length) {
                    this.setState({ createNeworder: true })
                }
            }
    }

    onClickSelectOrder = (order: any) => {
        this.props.setOrder(order.appOrderId)
        this.props.orderFlowDone()
    }

    applyChanges = (orderType: string, customerId: number, salespersonNote: string | undefined) => {
        this.props.createAndSetOrder(orderType, customerId, salespersonNote || undefined);
        this.props.orderFlowDone()
    }

    render() {
        if (this.state.createNeworder) {
            return (
                <div className="order-flow">
                    <h4
                        className="text-center"
                        style={{
                            maxWidth: '100%',
                            margin: '0 auto',
                            width: '600px',
                            marginBottom: '30px',
                        }}
                    >
                        {
                            this.props.orderState.order && this.state.orderType
                            && this.props.orderState.order.orderheader.orderType !== this.state.orderType &&
                            <span>
                                {
                                    this.state.orderType && this.state.orderType.toLowerCase() === 'reorder' ?
                                    <span>Please create a new REORDER as it is not possible to add a reorder items to your current PRE-ORDER.</span>
                                    :
                                    <span>Please create a new PRE-ORDER as it is not possible to add pre-order items to your current REORDER.</span>
                                }
                            </span>
                        }
                        
                    </h4>
                    <CreateOrderBody
                        orderTypes={this.state.orderTypes}
                        shops={this.state.shops}
                        applyChanges={this.applyChanges}
                        orderType={this.state.orderType}
                    />
                </div>
            )
        }

        const onClickOrderType = (type: any) => {
                this.setState({ orderType: type.orderType }, () => {
                    this.setState({ createNeworder: true })
                })
        }

        return (
            <React.Fragment>
                <h4
                    className="text-center"
                    style={{
                        maxWidth: '100%',
                        margin: '0 auto',
                        width: '600px',
                        marginBottom: '30px',
                    }}
                >
                    {
                        this.props.orderState.order && this.state.orderType
                        && this.props.orderState.order.orderheader.orderType !== this.state.orderType ?
                        <span>
                            {
                                this.state.orderType && this.state.orderType.toLowerCase() === 'reorder' ?
                                <span>Please create a new REORDER as it is not possible to add a reorder items to your current PRE-ORDER.</span>
                                :
                                <span>Please create a new PRE-ORDER as it is not possible to add pre-order items to your current REORDER.</span>
                            }
                        </span>
                        :
                        <span>
                            {
                                this.props.orderState.orders && this.state.orderType && this.props.multiOrderTypes
                                && this.props.orderState.orders.filter(o => o.orderType === this.state.orderType ).length &&
                                <span>Choose an existing order or create a new.</span>
                                
                            }
                        </span>
                    }
                    
                </h4>
                {
                    this.props.orderState.orders && this.state.orderType &&
                    <div className="order-flow order-flow--intro">
                        {
                            this.props.orderState.orders &&
                            this.props.orderState.orders.filter(order => {
                                if (!this.props.multiOrderTypes || (this.props.multiOrderTypes && this.props.multiOrderTypes.length <= 1)) {
                                    return order.orderType === this.state.orderType
                                }
                                return true
                            }).length ?
                            <div className="left">
                                <p>Existing orders:</p>
                                <div className="options">
                                    {
                                        this.props.orderState.orders &&
                                        this.props.orderState.orders
                                        .filter(order => {
                                            if (!this.props.multiOrderTypes || (this.props.multiOrderTypes && this.props.multiOrderTypes.length <= 1)) {
                                                return order.orderType === this.state.orderType
                                            }
                                            return true
                                        })
                                        .map((order, key) => {
                                            return (
                                                <div key={key} onClick={() => this.onClickSelectOrder(order)} className="options__item">
                                                    <span>
                                                        { order.appOrderId } : { order.orderType }
                                                    </span>
                                                    <span className="options__item__explainer">
                                                        { order.sellToCustomerName }<br/>{ order.salespersonNote }
                                                    </span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div> : null
                        }

                        <div className="right">
                            {
                                (!this.props.multiOrderTypes || (this.props.multiOrderTypes && this.props.multiOrderTypes.length <= 1)) &&
                                <button onClick={() => this.setState({ createNeworder: true })}>Create new {this.state.orderType}</button>
                            }
                            {
                                (this.props.multiOrderTypes && this.props.multiOrderTypes && this.props.multiOrderTypes.length > 1) &&
                                <>
                                    {
                                        this.props.multiOrderTypes.map((type: any, key: number) => {
                                            return (
                                                <button
                                                    onClick={() => onClickOrderType(type)}>
                                                        Create new { type.orderType }
                                                </button>
                                            )
                                        })
                                    }
                                </>
                            }
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
    return {
        orderState: states.order.order,       
        userState: states.user.user,
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
    return {
        setOrder: async (orderId: number) => {
            await dispatch(setOrder(orderId))
        },
        createAndSetOrder: async (orderType: string, customerId: number, salespersonNote?: string) => {
            await dispatch(createAndSetOrder(orderType, customerId, salespersonNote))
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderFlow));
