import { cloneDeep, sortBy, uniq } from 'lodash';
import { Filter } from '../models/Filter';
import Style, { IStyle } from 'molo-shared/lib/models/Style';
import { productListCategorySort } from '../constants/productSort';

interface sustainabilityItems {
    gots:        Style[]
    oekoTex:     Style[]
    recycled:    Style[]
    organic:     Style[]
    ecovero:     Style[]
    ocs_100:     Style[]
    ocs_blended: Style []
    grs:         Style[]
}

const productGroupSortArray = [
    'Boy',
    'Girl',
    'Baby Boy',
    'Baby Girl',
    'Outerwear',
    'Unisex',
    'Accessories',
    'Swimwear',
    'Marketing',
]

export const FilterHelper = {
    isInitialFilterReady,
    filterIsEmpty,
    resultByFilter,
    hydrateSeasons,
    hydrateProductGroups,
    hydrateDeliveryWindows,
    hydrateProductCategories,
    hydrateSustainability,
    hydrateProductCategoriesByCollectionSort,
};

function hydrateSeasons (styles: any[]) {      
    return uniq(styles.map(style => style.seasonCode));
}

function hydrateProductGroups (styles: Style[]) {
    let productGroups = uniq(styles.map(style => style.productGroup))

    return sortBy(productGroups, function(item){
        return productGroupSortArray.indexOf(item)
    });
}

function hydrateDeliveryWindows (styles: Style[]) {        
    return uniq(styles.map(style => style.deliveryWindow.label));
}

function hydrateProductCategories (styles: Style[]) {
    return uniq(styles.map(style => style.productCategory)).sort();
}

function hydrateProductCategoriesByCollectionSort (styles: IStyle[], collectionName: string) {
    collectionName = collectionName.toLowerCase()
    const categories = uniq(styles.map(style => style.productCategory));
  
    if (!productListCategorySort[collectionName]) {
      return categories
    }
  
    let sorted: string[] = [];
    const keysNotInSort = categories.filter(cat => productListCategorySort[collectionName].indexOf(cat) === -1 && cat.toLowerCase() !== 'marketing');
    let keysInSort = categories.filter(cat => productListCategorySort[collectionName].indexOf(cat) !== -1 && cat.toLowerCase() !== 'marketing');
  
    keysInSort = sortBy(keysInSort, (item) => {
      return productListCategorySort[collectionName].indexOf(item)
    });
  
    sorted = [ ...keysInSort, ...keysNotInSort];
  
    if (categories.indexOf('Marketing') !== 1) {
      sorted.push('Marketing')
    }
  
    return sorted;
  }

function hydrateSustainability (styles: Style[]) {
    let sustainabilityFilters = [];
  
    if (styles.filter(style => style.gots !== '').length) {
        sustainabilityFilters.push('gots');
    }

    if (styles.filter(style => style.ocsno !== '').length) {
        sustainabilityFilters.push('ocs_100');
    }

    if (styles.filter(style => style.oekotex !== '').length) {
        sustainabilityFilters.push('oekoTex');
    }

    if (styles.filter(style => !!style.recycled).length) {
        sustainabilityFilters.push('recycled');
    }

    if (styles.filter(style => !!style.organic).length) {
        sustainabilityFilters.push('organic');
    }
    if (styles.filter(style => !!style.ecovero).length) {
        sustainabilityFilters.push('ecovero');
    }
    if(styles.filter(style => style.ocsblended !== '' ).length){
        sustainabilityFilters.push('ocs_blended');
    }
    if(styles.filter(style => style.grsno !== '' ).length){
        sustainabilityFilters.push('grs');
    }
    return sustainabilityFilters;
  }

function isInitialFilterReady(filter:Filter) {    
    return filter && filter.activeFilters && (
        filter.activeFilters.seasons.length || 
        filter.activeFilters.collections.length || 
        filter.activeFilters.productGroups.length || 
        filter.activeFilters.deliveryWindows.length ||
        filter.activeFilters.productCategories.length ||
        (filter.activeFilters.sustainability && filter.activeFilters.sustainability.length)
    );
}

function filterIsEmpty(filter:Filter) {
    return filter.activeFilters.collections.length === 0 &&
        filter.activeFilters.deliveryWindows.length === 0 &&
        filter.activeFilters.productGroups.length === 0 &&
        filter.activeFilters.productCategories.length === 0 &&
        filter.activeFilters.seasons.length === 0;
}

function resultByFilter(filter:Filter, styles:Style[]) {
    let newStyles = cloneDeep(styles);

    // if everything is empty, the initial filter isn't ready yet
    if (!isInitialFilterReady(filter)) {
        return styles;
    }

    // seasons
    if (filter.activeFilters.seasons.length) {
        newStyles = newStyles.filter(style => filter.activeFilters.seasons.indexOf(style.seasonCode) !== -1);
    }

    // collections 
    if (filter.activeFilters.collections.length) {
        newStyles = newStyles.filter(style => filter.activeFilters.collections.indexOf(style.collectionName) !== -1);
    }

    // deliveryWindows 
    if (filter.activeFilters.deliveryWindows.length) {
        newStyles = newStyles.filter(style => filter.activeFilters.deliveryWindows.indexOf(style.deliveryWindow.label) !== -1);
    }

    // productGroups
    if (filter.activeFilters.productGroups.length) {
        let chunks: Style[] = [];

        for (const group of filter.activeFilters.productGroups) {
            const chunk = newStyles.filter(
                style => style.subCategories
                    .map(name => name.toLowerCase())
                    .indexOf(group.toLowerCase()) !== -1)
            chunks = [ ...chunks, ...chunk]
        }

        const original = newStyles.filter(
            style => filter.activeFilters.productGroups.indexOf(style.productGroup) !== -1);

        newStyles = uniq([ ...original, ...chunks]);
    }

    // productCategories
    if (filter.activeFilters.productCategories.length) {
        newStyles = newStyles.filter(style => filter.activeFilters.productCategories.indexOf(style.productCategory) !== -1);
    }

    // Sustainabilty.
    if (filter.activeFilters.sustainability && filter.activeFilters.sustainability.length) {
        const cloned = [ ...newStyles ];

        let sustainabilityItems:sustainabilityItems = {
            gots: [],
            oekoTex: [],
            recycled: [],
            organic: [],
            ecovero: [],
            ocs_100: [],
            ocs_blended:[],
            grs:[]
        }

        for (const item of filter.activeFilters.sustainability) {
            switch(item) {
                case 'gots':
                    const gots = cloned.filter(style => style.gots !== '');
                    sustainabilityItems.gots = gots;
                    break;
                case 'oekoTex':
                    const oekoTex = cloned.filter(style => style.oekotex !== '');
                    sustainabilityItems.oekoTex = oekoTex;
                    break;
                case 'recycled':
                    const recycled = cloned.filter(style => !!style.recycled);
                    sustainabilityItems.recycled = recycled;
                    break;
                case 'organic':
                    const organic = cloned.filter(style => !!style.organic);
                    sustainabilityItems.organic = organic;
                    break;
                case 'ecovero':
                    const ecovero = cloned.filter(style => !!style.ecovero);
                    sustainabilityItems.ecovero = ecovero;
                    break;
                case 'ocs_100':
                    const ocs_100  = cloned.filter(style => style.ocsno !== '');
                    sustainabilityItems.ocs_100 = ocs_100;
                    break;
                case 'ocs_blended':
                    const ocs_blended = cloned.filter(style => style.ocsblended !== '');
                    sustainabilityItems.ocs_blended= ocs_blended;
                    break;
                case 'grs':
                    const grs  = cloned.filter(style => style.grsno  !== '');
                    sustainabilityItems.grs = grs;
                    break;
            }

            newStyles = [
                ...sustainabilityItems.gots,
                ...sustainabilityItems.oekoTex,
                ...sustainabilityItems.recycled,
                ...sustainabilityItems.organic,
                ...sustainabilityItems.ecovero,
                ...sustainabilityItems.ocs_100,
                ...sustainabilityItems.ocs_blended,
                ...sustainabilityItems.grs
            ]
        }
    }
    
    return newStyles;
}
