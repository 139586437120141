import React, { Component } from 'react'
import { ThunkDispatch } from 'redux-thunk'
import { RootState } from '../../store';
import { connect } from 'react-redux'

// Api.
import ProductApi from '../../store/products/ProductApi';
import ProductList from '../../components/shared/ProductList';
// import { ProductList } from 'molo-shared';
import { CollectionApi } from '../../store/collections/actions';
import BounceLoader from 'react-spinners/BounceLoader';

// Models.
import Style from 'molo-shared/lib/models/Style';

// Store.
import  { mapStyles } from 'molo-shared/lib/models/Style';
import { IUserSate } from '../../store/user/reducers';
import { IOrderState } from '../../store/order/orderStore';

// Utils.
import MoloRingloader from '../../components/layout/MoloRingloader';
import queryString from 'query-string'
import { uniqBy } from 'lodash'
import { OrderType } from '../../models/OrderType';
import {
    FaChevronLeft,
} from 'react-icons/fa';

interface State {
    styles: Style[]
    suggestions: Style[]
    loaded: boolean
    noResult: boolean
    lineDiscount: number
    orderTypes: OrderType[]
    prevPage: string
}
  
interface OwnProps {
    location?: {search: string}
    match?: {params: {query: string}},
    history?: any
}
  
interface DispatchProps {
}
  
interface StateProps {
    userState: IUserSate,
    orderState: IOrderState
}
  
type Props = StateProps & OwnProps & DispatchProps;

export class SearchPage extends Component<Props, State> {

    ref = React.createRef<HTMLDivElement>();

    state: State = {
        styles: [],
        suggestions: [],
        loaded: false,
        noResult: false,
        lineDiscount: 0,
        orderTypes: [],
        prevPage: '',
    }

    async componentDidMount() {
        const response = await ProductApi.getOrderTypes()

        if (response) {
            this.setState({ orderTypes: response.data }, () => {
                this.doSearch();
            })
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { location } = this.props;
        
        if (location !== prevProps.location) {
            this.setState({ loaded: false, styles: [], suggestions: [] }, () => {
                this.doSearch();
            })
        }
    }

    doSearch = async () => {
        let result: Style[] = [];
        let suggestions: Style[] = [];
        let suggestion = '';
        let query = '';
        const currencyCode = this.props.userState.user ? this.props.userState.user.currencyCode : 'EUR';
        const pricegroup   = this.props.userState.user ? this.props.userState.user.customerData.pricegroup   : 'ALL';
        const lineDiscount = this.props.userState.user ? this.props.userState.user.customerData.linediscount : 0;
        const searchQuery = this.props.location && this.props.location.search ? queryString.parse(this.props.location.search) : '';
        // const userAllowOrderTypes = this.props.userState.user && this.props.userState.user.allowOrderTypes ? this.props.userState.user.allowOrderTypes : [];
        const disallowedTypes = this.props.userState.user && this.props.userState.user.disallowedTypes ? this.props.userState.user.disallowedTypes : []; 
        // const allowOrderTypes: string[] = userAllowOrderTypes.map((type: any) => type.OrderType)
        const allowedTypes = this.state.orderTypes
                                .filter(type => disallowedTypes.indexOf(type.type.toLowerCase()) === -1)
                                .map(type => type.orderType)

        if (searchQuery && searchQuery.collection && searchQuery.color) {
            const collection = searchQuery.collection && typeof searchQuery.collection === 'string' ? searchQuery.collection : '';
            const color = searchQuery.color && typeof searchQuery.color === 'string' ? searchQuery.color : '';
            const orderType = searchQuery.orderType && typeof searchQuery.orderType === 'string' ? searchQuery.orderType : '';
            const response = await CollectionApi.getCollectionMasters(orderType, collection, currencyCode, pricegroup);
            
            result = mapStyles(response.data);

            if (color !== '') {
                result = result.filter(item => +item.colorCode === +color);
            }

            result = result.filter(style => allowedTypes.indexOf(style.orderType) !== -1)

            this.setState({styles: result, loaded: true, noResult: !result.length, lineDiscount})
        } else if (this.props.match && this.props.match.params.query) {
            
            query = this.props.match.params.query;

            const searchQuery = this.props.location && this.props.location.search ? queryString.parse(this.props.location.search) : '';

            if (searchQuery && searchQuery.suggestion) {
                suggestion = searchQuery.suggestion && typeof searchQuery.suggestion === 'string' ? searchQuery.suggestion : '';
                const splitted = suggestion.split(' - ');

                

                

                if (splitted.length > 1) {
                    query = splitted[0].toLowerCase().includes(query) ?
                    splitted[0].split(' ')[0].trim().toLowerCase() : splitted[1].split(' ')[0].trim().toLowerCase();
                }

                ProductApi.search(suggestion, currencyCode).then(response => {
                    if (response) {
                        suggestions = uniqBy(mapStyles(response), 'masterColor');
                        result = uniqBy(mapStyles(response), 'masterColor');
                        result = result.filter(style => allowedTypes.indexOf(style.orderType) !== -1)
                        
                        // Sort by relevance.
                        const sorted: Style[] = suggestions.sort((a: Style, b: Style) => {
                            // Sort results by matching name with keyword position in name
                            const aCompare = a.description + ' ' + a.colorName;
                            const bCompare = b.description + ' ' + b.colorName;

                            if(aCompare.toLowerCase().indexOf(suggestion.toLowerCase()) > bCompare.toLowerCase().indexOf(suggestion.toLowerCase())) {
                                return 1;
                            } else if (aCompare.toLowerCase().indexOf(suggestion.toLowerCase()) < bCompare.toLowerCase().indexOf(suggestion.toLowerCase())) {
                                return -1;
                            } else {
                                if(aCompare > bCompare)
                                    return 1;
                                else
                                    return -1;
                            }
                        });

                        this.setState({ suggestions: sorted, noResult: !result.length },() => {
                            setTimeout( async () => {
                                this.formatAllStringsWithRelevance(suggestion);

                                this.setState({styles: result, loaded: true, lineDiscount}, () => {
                                    setTimeout(() => {
                                        this.formatAllStringsWithRelevance(query);
                                    })
                                })
                            })
                        })
                    }
                })
            } else {
                this.setState({ noResult: false },async () => {
                    const search = await ProductApi.search(query, currencyCode);

                    if (search) {
                        result = uniqBy(mapStyles(search), 'masterColor');
                        result = result.filter(style => allowedTypes.indexOf(style.orderType) !== -1)

                        

                        result = result.filter((style: Style) => {
                            return !suggestions.find((suggestion: Style) => suggestion.colorCode === style.colorCode && suggestion.masterId === style.masterId)
                        })
                    }

                    this.setState({styles: result, loaded: true, noResult: !result.length, lineDiscount}, () => {
                        setTimeout(() => {
                            this.formatAllStringsWithRelevance(query);
                        })
                    })
                })
            }
        }
    }

    doQuery = async (query: string) => {
        const currencyCode = this.props.userState.user ? this.props.userState.user.currencyCode : 'EUR';
        return await ProductApi.search(query, currencyCode);
    }

    isInBasket = (product: Style) => {
        return this.props.orderState.order && !!this.props.orderState.order.orderLines.find((line) => {
            return line.masterid === product.masterId && line.colorcode === product.colorCode;
        })
    }

    formatAllStringsWithRelevance = (query: string) => {
        if (this.ref.current !== null) {
            const items = this.ref.current.querySelectorAll('.pl-item__text');
            const that = this;

            items.forEach(function(val) {
                val.innerHTML = that.formatStringByRelevance(val.innerHTML, query);
            })
        }
    }

    formatStringByRelevance = (name: string, query: string) => {
        return name.replace(new RegExp(query, "gi"), (match) => `<span class="query-highlighted">${match}</span>`);
    }

    goBack = () => {
        const path = localStorage.getItem('search:prevPage') || '/shop';

        this.props.history.push(path);
    }

    render() {
        const { styles, suggestions } = this.state;
        
        return (
            <div className="search-page" ref={this.ref}>
                <div className="product-list__header" onClick={this.goBack}>
                    <span className="product-list__header__go-back"><FaChevronLeft /> Go back</span>
                </div>
                {/* {
                    suggestions.length ?
                    <div>
                        <ProductList
                            products={styles}
                            lineDiscount={this.props.userState.user.customerData.linediscount}
                            useInfiniteScroll={true}
                            simple={true}
                            useProductOrderType={true}
                            lazyLoadMoloMaster={false}
                            showGoBack={true}
                            ignoreFilter={true}
                        />
                    </div>
                    : null
                } */}

                {
                    !this.state.loaded && !suggestions.length ?
                    <div style={{
                        height: '100vh',
                        width: '100vw',
                        paddingBottom: '150px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <BounceLoader />
                    </div> : null
                }

                {
                    this.state.loaded && this.state.noResult ?
                    <div className="loading-spinner-1">
                        <h2>Your search gave no results.</h2>
                        <p style={{marginBottom: '70px'}}>Please try another.</p>
                    </div> : null
                }

                {/* <ProductList
                    products={styles}    
                    lineDiscount={this.state.lineDiscount}
                    ignoreFilter={true}
                    showGoBack={true}
                    history={this.props.history}
                    lazyLoadMoloMaster={false}
                    useProductOrderType={true}
                    simple={true}
                    collectionName={''}
                /> */}
                <ProductList
                    products={styles}
                    lineDiscount={this.props.userState.user.customerData.linediscount}
                    useInfiniteScroll={true}
                    simple={true}
                    useProductOrderType={true}
                    lazyLoadMoloMaster={false}
                    showGoBack={true}
                    ignoreFilter={true}
                    hidePrice={true}
                    checkOrderType={true}
                />
            </div>
        )
    }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
    return {
        userState: states.user.user,
        orderState: states.order.order,
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage)
