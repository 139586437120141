import React, { useState, useEffect, createRef } from 'react';

import { FaChevronLeft } from 'react-icons/fa';

import { throttle } from 'lodash';

interface IProps {
    showGoBack?: boolean
    goBack?: Function
    title: string
    onSticky?: Function
    onUnSticky?: Function
}

const StickyHeader: React.FC<IProps> = (props) => {
    const { showGoBack, goBack, title, onSticky, onUnSticky } = props;
    let ref = createRef<HTMLDivElement>();
    const [isSticky, setSticky] = useState(false);
    const top = window.innerWidth > 1000 ? 78 : 110;

    useEffect(() => {
        const container = document.getElementById('sticky-container');
        // Add scroll event when the component is loaded
        container && container.addEventListener('scroll', throttle(handleScroll, 100));

        return () => {
        // Remove scroll event after the component is unmount,
        // like componentWillUnmount()
        container && container.removeEventListener('scroll', () => handleScroll);
        };
        // Here, we set useEffect() arguments as empty array.
        // This means useEffect() will only run once after component loaded
    });

    const handleScroll = () => {
        // Make sure ref has current attibute and getBoundingClientRect function
        // otherwise, it could cause getBoundingClientRect undefined error.
        if (ref && ref.current && ref.current.getBoundingClientRect()) {
            // Then, we compare the distance of the ref component to the top
            // with top value we set. If less than, we set isStick ture.
            if (onSticky && ref && ref.current && ref.current.getBoundingClientRect().top <= top) {
                if (!ref.current.classList.contains('is-sticky')) {
                    setSticky(true);
                    onSticky(title)
                }
            } else {
                if (ref.current.classList.contains('is-sticky') && onUnSticky) {
                    setSticky(false);
                    onUnSticky(title)
                }
            }
        }
    };

    return (
        <h2 className={`category-name sticky__wrapper ${isSticky && 'is-sticky'}`} ref={ref}>
            { showGoBack &&  <span className="category-name__go-back" onClick={() => goBack}><FaChevronLeft /></span>}
            <span className="category-name__text">
                { title }
            </span>
        </h2>
    )
}

export default StickyHeader;