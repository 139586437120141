import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { createBrowserHistory } from 'history';
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { FaTimes } from 'react-icons/fa';
import './App.scss';

// Store.
import { RootState } from './store/';
import { isUserValid } from './store/user/actions';

// Components.
import OrderTypeChooser from './components/b2b/OrderTypeChooser';
import PreOrderChooser from './components/b2b/PreOrderChooser';
import ProductListContainer from './components/b2b/ProductListContainer';
import EditNavOrderContainer from './components/b2b/EditNavOrderContainer';
import CollectionListContainer from './components/b2b/CollectionListContainer';
import MainContainer from './components/layout/MainContainer';
import DashboardContainer from './components/layout/DashboardContainer';
import CheckoutContainer from './components/b2b/CheckoutContainer';
import Dashboard from './components/shared/Dashboard/Dashboard';
import SearchPage from './components/shared/SearchPage';
import Login from './components/auth/Login';
import Logout from './components/auth/Logout';
import ForgotPassword from './components/auth/ForgotPassword';
import MyMolo from './components/b2b/MyMolo';
import StockHasChangedModal from './components/b2b/StockHasChangedOverlay';
import LookUpNavOrder from './components/b2b/LookUpNavOrder';
import ProductDownloadImagesPage from './components/b2b/ProductDownloadImagesPage';
import EditOrderHandlerOverlay from './components/b2b/EditOrderHandlerOverlay';
import ConfirmModal from './components/layout/Modal/ConfirmModal';

export const isAuthenticated = (user: any) => {
  // If there already is a user.
  if (user && user.user) {
    return true;
  }

  // Fallback if all tries fails.
  return false;
}

const PrivateRoute = ({ containerType, routeComponent, isAuthenticated, desktopOnly, ...rest }: any) => {
  const RouteComponent = routeComponent;

  const renderShopContainer = (props: any) => (
    <MainContainer {...props}>
      <RouteComponent {...props} />
    </MainContainer>
  )

  if (!isAuthenticated) {
    return <Redirect to={{ pathname: '/login', state: { referer: rest.location } }} />
  }

  if (containerType === 'dashboard') {
    return (
      <Route
        {...rest}
        render={props => {
          return (
            <DashboardContainer key={props.match.params.orderId || 'empty'}>
              <RouteComponent {...props} />
            </DashboardContainer>
          )
        }}
      />
    )
  }

  if (containerType === 'checkout') {
    return (
      <Route
        {...rest}
        render={props => {
          return (
            <DashboardContainer hideBasket={true}>
              <RouteComponent {...props} />
            </DashboardContainer>
          )
        }}
      />
    )
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (desktopOnly) {
          return (
            <React.Fragment>
              <div className="desktop-only">
                {renderShopContainer(props)}
              </div>
              <div className="mobile-only">
                  <div className="loading-spinner-1">
                    <h2>Desktop only!</h2>
                    <p style={{marginBottom: '70px', textAlign: 'center'}}>A mobile friendly version will be available soon :-)</p>
                  </div>
              </div>
            </React.Fragment>
          )
        }

        return (
          renderShopContainer(props)
        )
      }}
    />)
};

const App: React.FC = () => {

  const user = useSelector((state: RootState) => state.user.user);
  const alerts = useSelector((state: RootState) => state.alertStore.alertState);
  const dispatch = useDispatch();
  const appUserHasBeenValidated = useSelector((state: RootState) => state.user.user.isValidated);
  const [showCriticalModal, setShowCriticalModal] = React.useState(false);
  const [criticalMessage, setCriticalMessage] = React.useState('');
  // Create the shared history
  const history = createBrowserHistory();

  history.listen((location, action) => {
    // clear alert on location change
    toast.dismiss()
    // dispatch(clearActions());
  });

  React.useEffect(() => {
    let timer: any;
    let timerForBuild: any;
    // add when mounted
    checkVersion();

    timer = setInterval(()=> dispatch(isUserValid()), 15000);
    timerForBuild = setInterval(()=> checkVersion(), 200000);
    // return function to be called when unmounted
    return () => {
      clearInterval(timer)
      clearInterval(timerForBuild)
    };
  }, [dispatch]);

  const checkVersion =  async () => {
    // if (process.env.NODE_ENV === 'production') {
      const buildResponse = await axios.get('/build.json?' + (new Date().getTime()/1000))
      const localStorageBuild = localStorage.getItem('build');
      const buildVersion = buildResponse.data.buildVersion;

      if ((localStorageBuild && localStorageBuild !== buildVersion) || !localStorageBuild) {
        console.log('Refresh application...')
        window.location.reload();
      }

      localStorage.setItem('build', buildVersion);
    // }
  }

  const CriticalModal = () => {
    return <ConfirmModal
      isShowing={showCriticalModal}
      hide={() => { setShowCriticalModal(false) }}
      content={(
        <div className="delete-order-modal">
            <h2>Something when wrong!</h2>
            <p>{ criticalMessage }</p>
            <div className="delete-order-modal__footer">
                <button className="btn" onClick={() => { setShowCriticalModal(false) }}>Close</button>
            </div>
        </div>
      )}
    />
  }

  if (alerts.length) {
    switch(alerts[0].type) {
      case 'success':
        toast.error(alerts[0].message);  
        break;
      case 'error':
        toast.success(alerts[0].message);
        break;
      case 'critical':
        
        setTimeout(() => {
          setCriticalMessage(alerts[0].message)
          setShowCriticalModal(true)
        });
        
        break;
    }
  }
  
  if (!appUserHasBeenValidated) {
    dispatch(isUserValid());
    return <div style={{ height: '100vh', width: '100vw' }}></div>
  }

  return (
    <div className="App">
      {/* <div className="desktop-only"> */}
        <ToastContainer
          position={toast.POSITION.BOTTOM_LEFT}
          autoClose={2000}
          closeButton={<FaTimes />}
        />
        
        <Router>
          <Route exact path="/login" component={Login} />
          {/* <Route exact path="/logout" component={Logout} /> */}
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <StockHasChangedModal />
          <EditOrderHandlerOverlay />

          <Switch>
            <PrivateRoute exact path="/logout" isAuthenticated={isAuthenticated(user)} routeComponent={Logout} />
            <PrivateRoute exact path="/previeworder/:appOrderId" isAuthenticated={isAuthenticated(user)} routeComponent={LookUpNavOrder} desktopOnly={false} />
            <PrivateRoute exact path="/search/:query?" isAuthenticated={isAuthenticated(user)} routeComponent={SearchPage}  desktopOnly={true} />
            <PrivateRoute exact path="/dashboard" isAuthenticated={isAuthenticated(user)} routeComponent={Dashboard} containerType={'dashboard'}  desktopOnly={false} />  
            <PrivateRoute exact path="/dashboard/:orderId" isAuthenticated={isAuthenticated(user)}  containerType={'dashboard'} routeComponent={Dashboard} desktopOnly={false} />
            <PrivateRoute exact path="/checkout/:orderId" isAuthenticated={isAuthenticated(user)} routeComponent={CheckoutContainer} containerType='checkout' desktopOnly={true}/>
            <PrivateRoute exact path="/" isAuthenticated={isAuthenticated(user)} routeComponent={OrderTypeChooser} desktopOnly={true} />
            <PrivateRoute exact path="/shop" isAuthenticated={isAuthenticated(user)} routeComponent={OrderTypeChooser} desktopOnly={true} />
            <PrivateRoute exact path="/shop/:orderType" isAuthenticated={isAuthenticated(user)} routeComponent={CollectionListContainer} desktopOnly={true} />
            <PrivateRoute exact path="/preOrderChooser" isAuthenticated={isAuthenticated(user)} routeComponent={PreOrderChooser} desktopOnly={true} />
            <PrivateRoute exact path="/shop/:orderType/:collection" isAuthenticated={isAuthenticated(user)} routeComponent={ProductListContainer} desktopOnly={true} />
            <PrivateRoute exact path="/edit/:collection?" isAuthenticated={isAuthenticated(user)} routeComponent={EditNavOrderContainer} desktopOnly={true} />
            <PrivateRoute exact path="/my-molo" isAuthenticated={isAuthenticated(user)} routeComponent={MyMolo} desktopOnly={true} />
            <PrivateRoute exact path="/download" isAuthenticated={isAuthenticated(user)} routeComponent={ProductDownloadImagesPage} desktopOnly={true} />
          </Switch>
        </Router>
        <CriticalModal />
      {/* </div> */}
      {/* <div className="mobile-only">
        
          <div className="loading-spinner-1">
            <h2>Desktop only!</h2>
            <p style={{marginBottom: '70px'}}>A mobile friendly version will be available soon :-)</p>
          </div>
      </div> */}
    </div>
  );
}

export default App;
