import { orderLine as OrderLine} from '../../../models/Order';

export interface IKeyValue { [id: string]: OrderLine[]; }
export type tvalueKey = 'collection' | 'productcategory' | 'deliveryCode' | 'productgroup'
export type tcategories = 'collection' | 'categories' | 'deliverywindows' | 'groups'

export interface IDivider {
    id: string,
    title: string,
    singularTitle: string,
    valueKey: tvalueKey,
    labelKey: tvalueKey,
    isCalendarView: boolean,
}

export interface ISubGrouping {
    [id: string]: {
        orderLines: OrderLine[],
        title: string,
        quantity: number,
        revenue: number,
        value: string,
        colorBlocked: boolean,
        salesOptions: number,
    }
}

export interface IGrouping {
    [id: string]: ISubGrouping
}

export const DIVIDERS: IDivider[] = [
  // {
  //   id: 'collection',
  //   title: 'Collection',
  //   singularTitle: 'Collection',
  //   valueKey: 'topCollection',
  //   labelKey: 'topCollection',
  //   isCalendarView: false,
  // },
  {
    id: 'groups',
    title: 'Groups',
    singularTitle: 'Group',
    valueKey: 'productgroup',
    labelKey: 'productgroup',
    isCalendarView: false,
  },
  {
    id: 'categories',
    title: 'Categories',
    singularTitle: 'Category',
    valueKey: 'productcategory',
    labelKey: 'productcategory',
    isCalendarView: false,
  },
  {
    id: 'deliverywindows',
    title: 'Delivery Windows',
    singularTitle: 'Delivery Window',
    valueKey: 'deliveryCode',
    labelKey: 'deliveryCode',
    isCalendarView: false, // set to true when calendar view is ready
  },
  {
    id: 'sustainability',
    title: 'Sustainability',
    singularTitle: 'Sustainability',
    valueKey: 'sustainability',
    labelKey: 'sustainability',
    isCalendarView: false, // set to true when calendar view is ready
  },
];