export interface Filter {
    // term: string,
    seasons: string[],
    productGroups: string[],
    productCategories: string[],
    deliveryWindows: string[],
    sustainability: string[],
    collections?: string[],
    activeFilters: {
        seasons: string[],
        collections: string[],
        productGroups: string[],
        productCategories: string[],
        deliveryWindows: string[],
        sustainability?: string[],
    }
}

export const initialFilter: Filter = {
    // term: '',
    seasons: [],
    productGroups: [],
    productCategories: [],
    deliveryWindows: [],
    collections: [],
    sustainability: [
        // 'gots',
        // 'oekoTex',
        // 'recycled',
        // 'organic',
    ],
    activeFilters: {
        seasons: [],
        collections: [],
        productGroups: [],
        productCategories: [],
        deliveryWindows: [],
        sustainability: []
    }
};



// use typescript enum rather than action constants
export enum FilterActionTypes {
    GET_FILTER = "GET_FILTER",
    APPLY_FILTER = "APPLY_FILTER"
}