import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'

// Store.
import { RootState } from '../../../store';
import { IFilterState } from '../../../store/session/reducers';
import { IUserSate } from '../../../store/user/reducers'
import { applyChanges, applyChangesFromDashboard } from '../../../store/order/actions';

// Components.
import ConfirmModal from '../../layout/Modal/ConfirmModal';
import ProductOverlay from '../ProductOverlay';
import ProductListItem from '../../shared/ProductListItem';

import ReactToPrint from 'react-to-print';

// API.
import ProductApi from '../../../store/products/ProductApi';

// Models,
import Order, { orderLine } from '../../../models/Order';
import { basketLine } from '../../../models/Basket';
import Style, { createStyle } from 'molo-shared/lib/models/Style';

// Utils.
import { cloneDeep, spread, union } from 'lodash';
import {
    humanReadableLineUnitPrices,
    totalLineDiscountAmount,
    totalLineAmount,
    roundAmount
} from '../../../utils/priceHelper';
import { mapMoloItems } from '../../../models/MoloItem';
import { appConfig } from '../../../config';
import { FaList, FaTh, FaPrint, FaCircle } from 'react-icons/fa';
import { filterByValue } from 'molo-shared'
import {
    IGrouping,
} from './dashboard.d';
import { StyleBean } from '../../../models/Beans';

interface ISize {
    sizename: string,
    quantity: number
}

interface masterColorKey {
    [id: string]: any,
}

interface ISizes {
    [id: string]: ISize[],
}

interface IGroupedOrderLine {
    [id: string]: any,
}

interface IGroupedDeliveryWindows {
    [id: string]: {
        label: string,

        orderLines: orderLine[],
    }
}

interface IRRPMap {
    [id: string]: number[]
}

interface ISustainabilityMap {
    [id: string]: string | null | undefined
}

interface State {
    showDeleteModal: boolean,
    modal: any,
    groupedOrderlines: orderLine[],
    productOverlayComponent: JSX.Element | null,
    showOverlay: boolean,
    changes: basketLine,
    selectedProduct?: StyleBean,
    isListView: boolean,
    showSizes: boolean,
    sizes: ISizes,
    rrpMap: IRRPMap
    allSizes: ISizes
    searchTerm : '',
}
  
interface OwnProps {
    productSortedByCategory: IGrouping,
    selectedCategory: string,
    orderLines: orderLine[],
    onClickDelete: Function,
    order: Order,
    styles: Style[]
    groupedOrderlines: orderLine[]
    sizes: ISizes,
    masters: any
    groupedMoloItems: {[id: string]: any[]}
    rrpMap: IRRPMap,
    allSizes: ISizes,
    disableActions?: boolean
}
  
interface DispatchProps {
    applyChanges: (orderId: number, changes: basketLine) => void
    applyChangesFromDashboard: (orderId: number, changes: basketLine) => void
}
  
interface StateProps {
    filterState: IFilterState,
    userState: IUserSate,
}
  
type Props = StateProps & OwnProps & DispatchProps;

const baseUrl = appConfig.media.baseUrl;

export class Details extends Component<Props, State> {
    state: State = {
        showDeleteModal: false,
        modal: null,
        groupedOrderlines: [],
        productOverlayComponent: null,
        showOverlay: false,
        changes: {},
        isListView: true,
        showSizes: false,
        sizes: {},
        rrpMap: {},
        allSizes: {},
        searchTerm: '',
    }

    componentRef: any = null;

    groupSimilarColors(orderLines: orderLine[]) {
        const grouped: IGroupedOrderLine = {}
        const sizes: ISizes = {};
  
        for (const i in orderLines) {
            const orderline = orderLines[i];
            const masterid = orderline.masterid;
            const colorcode = orderline.colorcode;

            if (grouped[masterid + colorcode]) {
                const q = +grouped[masterid + colorcode].quantity + +orderline.quantity;
                grouped[masterid + colorcode].quantity = q;
                grouped[masterid + colorcode]['orderLines'].push({...orderline})
            } else {
                grouped[masterid + colorcode] = orderline
                grouped[masterid + colorcode]['orderLines'] = [{...orderline}]
            }
    
            if (!sizes[masterid + colorcode]) {
                sizes[masterid + colorcode] = [];
            }
    
            sizes[masterid + colorcode].push({ sizename: orderline.sizename, quantity: orderline.quantity });
        }

        // this.setState({ sizes })

        let g: orderLine[] = []

        for (const key in grouped) {
            let line = grouped[key];
            let q = 0;

            for (const delta in line['orderLines']) {
                q = q + line['orderLines'][delta].quantity;
            }

            line.quantity = q;

            g.push(line);
        }

        const sorted = g.sort((a, b) => (a.masterid > b.masterid) ? 1 : -1)

        return sorted;
    }

    openDeleteModal = (line: orderLine) => {
        this.setState({
            showDeleteModal: true
        }, () => {
            const modal = (
                <ConfirmModal
                        isShowing={this.state.showDeleteModal}
                        hide={() => this.onHide()}
                        content={(
                            <div className="delete-order-modal">
                                <h2>Remove line</h2>
                                <p>Are you sure you want to remove this order item?</p>
                                <div className="delete-order-modal__footer">
                                    <button className="btn ignore-clickoutside" onClick={() => this.onClickDelete(line)}>Yes!!</button>
                                    <button className="btn ignore-clickoutside" onClick={() => this.onHide()}>Cancel</button>
                                </div>
                            </div>
                        )}
                />
            )

            this.setState({showDeleteModal: true, modal})
        });
    }

    onHide = () => {
        this.setState({ showDeleteModal: false, modal: null })
    }

    onHideProductOverlay = () => {
        this.setState({ showOverlay: false })
    }

    applyBasketChanges = (changes: basketLine) => {
        const newChanges = { ...this.state.changes, ...changes }
        this.setState({changes: newChanges})
    }

    applyChanges = () => {
        if (this.props.order) {
            this.props.applyChangesFromDashboard(this.props.order.orderheader.appOrderId, this.state.changes);
            this.setState({changes: {}}, () => {
                this.onHideProductOverlay();
            })
        }
    }

    handleOnChangeProduct = async (item: any) => {
        const currencyCode = this.props.userState.user.currencyCode || 'EUR';
        const response = await ProductApi.getMaster(item.masterId, item.colorCode, currencyCode)
        const product = response.data;
        const style = createStyle(product);        
        const bean = StyleBean.build(style, 'style')

        this.handleOnProductClick(bean)
    }

    onClickDelete = (line: orderLine) => {
        this.props.onClickDelete(line);
        this.onHide()
    }

    handleOnProductClick = (product: StyleBean) => {
        if (window.innerWidth < 901 || this.props.disableActions) {
            return;
        }

        if (this.props.order && this.props.order.orderheader.navImportRef
            && this.props.order.orderheader.orderState !== 0) {
            return;
        }

        setTimeout(() => {
            this.setState({
                selectedProduct: product,
                showOverlay: true,
            });
        }, 200)
        
    }

    renderTableHeader = () => {
        return (
            <tr>
                <td></td>
                <td>Sustainability</td>
                <td>Quantity</td>
                <td>Unit price</td>
                <td>RRP</td>
                {
                    this.props.order && this.props.order.orderheader.orderLinediscount ?
                    <td>Discount</td> : null
                }
                <td>Total</td>
                {
                    this.props.order.orderheader.orderState === 0 &&
                    <td className="hide-on-mobile"></td>
                }
            </tr>
        )
    }

    renderTableHeaderPrint = () => {
        return (
            <tr>
                <td></td>
                <td>Sustainability</td>
                <td>Quantity</td>
                <td>Unit price</td>
                <td>RRP</td>
                {
                    this.props.order && this.props.order.orderheader.orderLinediscount ?
                    <td>Discount</td> : null
                }
                <td>Total</td>
                {
                    this.props.order.orderheader.orderState === 0 && !this.props.disableActions &&
                    <td className="hide-on-mobile"></td>
                }
            </tr>
        )
    }

    getSustainability = (item: any) => {
        
        console.log('Sustainability check');
        console.dir(item);

        if (item.gots !== '') return 'GOTS';
        if (item.oekoTex !== '') return 'OEKOTEX';
        if (item.ocsno != '') return 'OCS 100';
        if(item.ocsblended != '') return 'OCS BLENDED';
        if (item.recycled) return 'RECYCLED';
        if (item.organic) return 'ORGANIC';
        if(item.ecovero) return 'ECOVERO';
        if (item.grsno) return 'GRS';
        

        return '';
    }

    renderTableBody = (orderLines: orderLine[], showSizes: boolean, withSubtotal?: boolean) => {

        return (
            <React.Fragment>
                {
                    filterByValue(orderLines, this.state.searchTerm).map((line, key) => {
                        // const imageUrl = 'https://picsum.photos/200/300';
                        const imageUrl = baseUrl + 'media/productimage/' + line.seasoncode + '/' + line.masterid + '/' + line.colorcode + '/small';
                        
                        return (
                            <React.Fragment key={key}>
                                <tr>
                                    <td onClick={() => this.handleOnProductClick(StyleBean.build(line, 'orderLine'))}>
                                        <div className="details__info">
                                            <img src={imageUrl} alt={line.description} className="details__image" />
                                            <div className="details__info__data">
                                                <span className="item-list__item__name__id">{line.masterid} {line.description}</span>
                                                <span className="item-list__item__color">{line.colorcode} {line.colorname}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td onClick={() => this.handleOnProductClick(StyleBean.build(line, 'orderLine'))}>
                                        { this.getSustainability(line)}
                                    </td>
                                    <td onClick={() => this.handleOnProductClick(StyleBean.build(line, 'orderLine'))}>
                                        {
                                            !line.colorBlocked && line.quantityShipped ?
                                            <span className="item-blocked">
                                                Quantity shipped
                                            </span> : null
                                        }
                                        {
                                            line.colorBlocked ?
                                            <span className="item-blocked">
                                                Blocked
                                            </span> : null
                                        }
                                        {line.quantity}
                                    </td>
                                    <td onClick={() => this.handleOnProductClick(StyleBean.build(line, 'orderLine'))}>
                                        { humanReadableLineUnitPrices(line.masterid, line.colorcode, line.orderLines) }
                                    </td>
                                    <td onClick={() => this.handleOnProductClick(StyleBean.build(line, 'orderLine'))}>
                                        <div>
                                            {
                                                this.props.rrpMap[line.masterid + line.colorcode] ?
                                                this.props.rrpMap[line.masterid + line.colorcode].map((item: number, key) => {
                                                    return (
                                                        <React.Fragment key={key    }>
                                                            {roundAmount(item)}
                                                            {
                                                                key + 1 !== this.props.rrpMap[line.masterid + line.colorcode].length && <span> / </span>
                                                            }
                                                        </React.Fragment>
                                                    )
                                                })
                                                : <span style={{visibility: 'hidden'}}>...</span>
                                            }
                                        </div>
                                    </td>
                                    {
                                        this.props.order && this.props.order.orderheader.orderLinediscount ?
                                        <td onClick={() => this.handleOnProductClick(StyleBean.build(line, 'orderLine'))}>{ roundAmount(totalLineDiscountAmount(line.masterid, line.colorcode, line.orderLines)) } ({ line.lineDiscountPct }%)</td> : null
                                    }
                                    <td>{ roundAmount(totalLineAmount(line.masterid, line.colorcode, line.orderLines)) } </td>
                                    {
                                        this.props.order.orderheader.orderState === 0 && !this.props.disableActions &&
                                        <td className="hide-on-mobile"><button onClick={() => this.openDeleteModal(line)} className="details__remove">Remove</button></td>
                                    }
                                </tr>
                                {
                                    showSizes &&
                                    <React.Fragment key={line.seasoncode + '/' + line.masterid + '/' + line.colorcode}>
                                        <tr>
                                            <td colSpan={7}>
                                                <div className="details__sizes">
                                                    {
                                                        this.props.allSizes[line.masterid + line.colorcode] &&
                                                        this.props.allSizes[line.masterid + line.colorcode].map((size, index) => {
                                                            return (
                                                                <div className="details__size" key={'allSizes' + line.masterid + line.colorcode + index}>
                                                                    <div className="details__size__name">
                                                                        {size.sizename}
                                                                    </div>
                                                                    <div className="details__size__quantity">
                                                                        {size.quantity}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        )
                    })
                }
                { this.state.showDeleteModal && this.state.modal }
            </React.Fragment>
        )
    }

    renderOverlay = () => {

        if (!this.state.selectedProduct) {
            return null;
        }

        return (
            <ProductOverlay
                product={this.state.selectedProduct}
                hide={this.onHideProductOverlay}
                order={this.props.order}
                applyBasketChanges={this.applyBasketChanges}
                applyChanges={this.applyChanges}
                changeProduct={this.handleOnChangeProduct}
                showModal={true}
                lazyLoadMaster={true}
                orderType={this.props.order.orderheader.orderType}
                checkOrderType={true}
            />
        )
    }

    onChangeHandler = () => {
        this.setState({ isListView: !this.state.isListView })
    }

    getStyleBean = (line: any) => {
        // if (this.props.order.orderheader.orderIsFinal) {
        //     let bean = StyleBean.build(line, 'orderLine');
        //     bean.currencyCode = this.props.order.orderheader.currencyCode;
        //     return bean;
        // }

        // Dirty but useful fallback.
        if (!this.props.groupedMoloItems[line.masterid + line.colorcode]) {
            return StyleBean.build({}, 'orderLine')
        }

        let bean = StyleBean.build(mapMoloItems(this.props.groupedMoloItems[line.masterid + line.colorcode])[0], 'moloItem');
        bean.currencyCode = this.props.order.orderheader.currencyCode;
        return bean;
    }

    renderGrid = () => {
        return (
            <ul className="pl">
                {
                    this.props.groupedOrderlines.map((line, key)  =>
                        {
                            return (
                                <React.Fragment key={key}>
                                    {
                                        <li onClick={ () => this.handleOnProductClick(this.getStyleBean(line)) } className="pl-item__wrapper">
                                        <ProductListItem
                                            product={this.getStyleBean(line)}
                                            lazyLoadMoloMaster={true}
                                            quantityOveride={line.quantity}
                                        />
                                        {
                                            this.state.showSizes &&
                                            <div className="details__sizes__overlay">
                                                <div className="details__sizes">
                                                    {
                                                        this.props.sizes[line.masterid + line.colorcode].map((size, index) => {
                                                            return (
                                                                <div className="details__size" key={index}>
                                                                    <div className="details__size__name">
                                                                        {size.sizename}
                                                                    </div>
                                                                    <div className="details__size__quantity">
                                                                        {size.quantity}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </li>
                                    }
                                </React.Fragment>
                            )
                        }
                    )
                }
            </ul>
        )
    }

    groupBy = (key: any) => (array: any[]) =>
    array.reduce((objectsByKeyValue: { [x: string]: any; }, obj: { [x: string]: any; }) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
    }, {});

    renderPrint = () => {
        const order = this.props.order.orderheader;
        // Group every thing by delivery windows.
        const groupByDeliveryCode = this.groupBy(['deliveryCode']);
        const groupedByDeliveryCode = groupByDeliveryCode(this.props.orderLines);
        let sortedWithGroup: IGroupedOrderLine = {};
        // And then inside every delivery window, group by product group.
        for (const key in groupedByDeliveryCode) {
            const groupByProductGroup = this.groupBy(['productgroup']);
            const grouped = groupByProductGroup(groupedByDeliveryCode[key]);
            sortedWithGroup[key] = grouped;
        }

        return (
            <React.Fragment>
                <div ref={el => (this.componentRef = el)} className="print-view">
                    <div className="print-header">
                        <span>{ order.sellToCustomerName }</span>
                        <span> <FaCircle className="order-dropdown__divider" /> b2b{order.appOrderId}</span>
                        <span> <FaCircle className="order-dropdown__divider" /> {order.orderType}</span>
                        {
                            order.navImportRef &&
                            <span>
                                <FaCircle className="order-dropdown__divider" /> {order.navImportRef}
                            </span>
                        }
                        {
                            order.salespersonNote &&
                            <span>
                                <FaCircle className="order-dropdown__divider" /> { order.salespersonNote }
                            </span>
                        }
                    </div>
                    {Object.keys(sortedWithGroup).map((key: string) => {
                        const deliveryGroup = sortedWithGroup[key];
                        const values = Object.values(deliveryGroup);
                        const isLast = Object.keys(sortedWithGroup)[Object.keys(sortedWithGroup).length - 1] === key;
                        const allTheValues = spread(union)(values);
                        let totalQuantity = allTheValues.map((value: any) => value.quantity).reduce((a, b) => a + b);
                        let total = allTheValues.map((value: any) => value.amount).reduce((a, b) => a + b);
                        const shipmentDate = deliveryGroup[Object.keys(deliveryGroup)[0]][0].deliveryshiptmentdate;
                        
                        return (
                            <div key={key} className="print-fragment">
                                <div className="print-fragment__header">
                                    <span>{ key === 'specdate' ? 'Shipping date' : key }</span>
                                    <span className="--date">{ new Date(shipmentDate).toLocaleDateString() }</span>
                                </div>
                                {
                                    Object.keys(deliveryGroup).map((key) => {
                                        const items = this.groupSimilarColors(cloneDeep(deliveryGroup[key]));

                                        return (
                                            <React.Fragment key={key}>
                                                <h2 className='category-name sticky__wrapper'>
                                                    <span className="category-name__text">
                                                        { key }
                                                    </span>
                                                </h2>
                                                {
                                                    <table className="details striped-table">
                                                        <thead>{ this.renderTableHeader() }</thead>
                                                        <tbody>{ this.renderTableBody(items, true, true) }</tbody>
                                                    </table>
                                                }
                                            </React.Fragment>
                                        )
                                    })
                                }
                                <div className='print-fragment__subtotal'>
                                    <div className="--total">Subtotal</div>
                                    <div className="--right">Quantity: { totalQuantity }</div>
                                    <div className="--right">Total: { roundAmount(total) }</div>
                                </div>
                                {
                                    isLast &&
                                    <React.Fragment>
                                        <div className="grand-total">
                                            <h2 style={{textAlign: 'right', marginRight: '25px'}}>Grand Total</h2>
                                            <div className='print-fragment__subtotal'>
                                                <div className="--total --right"></div>
                                                <div className="--right">Quantity: { this.props.order.orderTotals.noOfItems }</div>
                                                <div className="--right">Total: { roundAmount(this.props.order.orderTotals.amount) }</div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                        )
                    })}
                </div>
            </React.Fragment>
        );
    }

    render() {
        return (
            <React.Fragment>
                <div className="dashboard-details">
                    <div className="dashboard-details__header">
                        <div
                            onClick={() => this.setState({ isListView: true })}
                            className={"dashboard-details__header__toggler desktop-only" +  (this.state.isListView ? ' active' : '')}>
                            <FaList />
                        </div>
                        <div
                            onClick={() => this.setState({ isListView: false })}
                            className={"dashboard-details__header__toggler desktop-only" +  (!this.state.isListView ? ' active' : '')}>
                            <FaTh />
                        </div>
                        <div className="dashboard-details__header__item dashboard-details__header__show-sizes">
                            <div className="pretty p-default form-group">
                                <input
                                    className="a-form-control a-form-control--checkbox"
                                    checked={this.state.showSizes}
                                    onChange={() => {this.setState({ showSizes: !this.state.showSizes })}}
                                    type="checkbox"/>
                                <div className="state">
                                    <label>Show sizes</label>
                                </div>
                            </div>
                        </div>
                        {(Object.keys(this.props.rrpMap).length ||
                        (this.props.order && this.props.order.orderheader.navImportRef && this.props.order.orderheader.orderState === 1)) ? 
                        <div className="dashboard-details__header__item --print clickable">
                            <ReactToPrint
                                trigger={() => <div className="dashboard-details__header__item --print"><FaPrint/><span>Print basket</span></div>  }
                                content={() => this.componentRef}
                            />
                        </div> : null}
                        <div className="dashboard-details__header__item dashboard-search-wrapper">
                            <input
                                type="text" 
                                className="--dashboard-search"
                                placeholder="Search in basket"
                                onChange={(event: any) => {
                                    const value = event.target.value;
                                    this.setState({ searchTerm: value })
                                }}
                            />
                        </div>
                    </div>
                    {
                        this.state.isListView &&
                        <table className="details striped-table">
                            <thead>{ this.renderTableHeader() }</thead>
                            <tbody>{ this.renderTableBody(this.props.groupedOrderlines, this.state.showSizes) }</tbody>
                        </table>
                    }
                    {
                        !this.state.isListView &&
                        this.renderGrid()
                    }
                    { this.renderPrint() }
                </div>
                { this.state.showOverlay && this.renderOverlay() }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
    return {
        filterState: states.session.filter,
        userState: states.user.user,
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
    return {
        applyChanges: async (orderId: number, changes: basketLine) => {
            await dispatch(applyChanges(orderId, changes))
            // console.log('applyChanges completed [UI]')
        },
        applyChangesFromDashboard: async (orderId: number, changes: basketLine) => {
            await dispatch(applyChangesFromDashboard(orderId, changes))
            // console.log('applyChanges completed [UI]')
        },
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Details)