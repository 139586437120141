import { cloneDeep, uniq } from 'lodash';
import { Filter } from '../models/Filter';
import { orderLine } from '../models/Order';

interface sustainabilityItems {
  gots:       orderLine[]
  oekoTex:    orderLine[]
  recycled:   orderLine[]
  organic:    orderLine[]
  ecovero:    orderLine[]
  ocs_100:    orderLine[]
  ocs_blended:orderLine[]
  grs:        orderLine[]
}

interface ISustainabilityMap {
  [id: string]: string | null | undefined
}

export const DashboardFilterHelper = {
    isInitialFilterReady,
    filterIsEmpty,
    resultByFilter,
    hydrateSeasons,
    hydrateProductGroups,
    hydrateDeliveryWindows,
    hydrateProductCategories,
    hydrateCollections,
    hydrateSustainability,
    changeActiveFilters
};

function hydrateCollections (styles: orderLine[]) {      
  return uniq(styles.map(style => style.topCollection ));
}

function hydrateSeasons (styles: orderLine[]) {      
    return uniq(styles.map(style => style.seasoncode ));
}

function hydrateProductGroups (styles: orderLine[]) {        
    return uniq(styles.map(style => style.productgroup));
}

function hydrateDeliveryWindows (styles: orderLine[]) {        
    return uniq(styles.map(style => style.deliveryCode));
}

function hydrateProductCategories (styles: orderLine[]) {        
    return uniq(styles.map(style => style.productcategory));
}

function hydrateSustainability (styles: orderLine[]) {
  let sustainabilityFilters = [];
  console.log('hyradte heloper bo larsen');
  if (styles.filter(style => style.gots !== '').length) {
    sustainabilityFilters.push('gots');
  }
  if (styles.filter(style => style.oekoTex !== '').length) {
    sustainabilityFilters.push('oekoTex');
  }
  if (styles.filter(style => !!style.recycled).length) {
    sustainabilityFilters.push('recycled');
  }
  if (styles.filter(style => !!style.organic).length) {
    sustainabilityFilters.push('organic');
  }
  if (styles.filter(style => !!style.ecovero).length) {
    sustainabilityFilters.push('ecovero');
  }
  if (styles.filter(style => style.ocsno !== '').length) {
    sustainabilityFilters.push('ocs_100');
  }
  if (styles.filter(style => style.ocsblended !== '').length) {
    sustainabilityFilters.push('ocs_blended');
  }
  if (styles.filter(style => style.grsno  !== '').length) {
    sustainabilityFilters.push('grs');
  }
  if (styles.filter(style => style.ecovero  !== false).length) {
    sustainabilityFilters.push('ecovero');
  }
  return sustainabilityFilters;
}

function isInitialFilterReady(filter:Filter) {
    return filter && filter.activeFilters && (
        filter.activeFilters.seasons.length || 
        filter.activeFilters.collections.length || 
        filter.activeFilters.productGroups.length || 
        filter.activeFilters.deliveryWindows.length ||
        filter.activeFilters.productCategories.length ||
        (filter.activeFilters.sustainability && filter.activeFilters.sustainability.length)
    );
}

function filterIsEmpty(filter:Filter) {
    return filter.activeFilters.collections.length === 0 &&
        filter.activeFilters.deliveryWindows.length === 0 &&
        filter.activeFilters.collections.length &&
        filter.activeFilters.productGroups.length === 0 &&
        filter.activeFilters.productCategories.length === 0 &&
        filter.activeFilters.seasons.length === 0;
}

function resultByFilter(filter:Filter, styles: orderLine[]) {
    let newStyles = cloneDeep(styles);

    // if everything is empty, the initial filter isn't ready yet
    if (!isInitialFilterReady(filter)) {
        return styles;
    }

    // deliveryWindows 
    if (filter.activeFilters.collections.length) {
      newStyles = newStyles.filter(style => filter.activeFilters.collections.indexOf(style.topCollection) !== -1);
  }

    // deliveryWindows 
    if (filter.activeFilters.deliveryWindows.length) {
        newStyles = newStyles.filter(style => filter.activeFilters.deliveryWindows.indexOf(style.deliveryCode) !== -1);
    }

    // productGroups
    if (filter.activeFilters.productGroups.length) {
        newStyles = newStyles.filter(style => filter.activeFilters.productGroups.indexOf(style.productgroup) !== -1);
    }

    // productCategories
    if (filter.activeFilters.productCategories.length) {
        newStyles = newStyles.filter(style => filter.activeFilters.productCategories.indexOf(style.productcategory) !== -1);
    }

    // Sustainabilty.
    if (filter.activeFilters.sustainability && filter.activeFilters.sustainability.length) {
      const cloned = [ ...newStyles ];

      let sustainabilityItems:sustainabilityItems = {
          gots: [],
          oekoTex: [],
          recycled: [],
          organic: [],
          ecovero: [],
          ocs_100: [],
          ocs_blended:[],
          grs:[]
      }

      for (const item of filter.activeFilters.sustainability) {
          switch(item) {
            case 'gots':
                const gots = cloned.filter(style => style.gots !== '');
                sustainabilityItems.gots = gots;
                break;
            case 'oekoTex':
                const oekoTex = cloned.filter(style => style.oekoTex !== '');
                sustainabilityItems.oekoTex = oekoTex;
                break;
            case 'recycled':
                const recycled = cloned.filter(style => !!style.recycled);
                sustainabilityItems.recycled = recycled;
                break;
            case 'organic':
                const organic = cloned.filter(style => !!style.organic);
                sustainabilityItems.recycled = organic;
                break;
            case 'ecovero':
              const ecovery = cloned.filter(style => !!style.ecovero);
              sustainabilityItems.recycled = ecovery;
              break;
            case 'ocs_100':
              const ocs_100 = cloned.filter(style => style.ocsno !== '');
              sustainabilityItems.ocs_100 = ocs_100;
              break;
            case 'ocs_blended':
                const ocs_blended = cloned.filter(style => style.ocsblended !== '');
                sustainabilityItems.ocs_blended = ocs_blended;
              break;
          case 'grs':
                const grs = cloned.filter(style => style.grsno !== '');
                sustainabilityItems.grs = grs;
                break;
        }

          newStyles = [
              ...sustainabilityItems.gots,
              ...sustainabilityItems.oekoTex,
              ...sustainabilityItems.recycled,
              ...sustainabilityItems.organic,
              ...sustainabilityItems.ecovero,
              ...sustainabilityItems.ocs_100,
              ...sustainabilityItems.ocs_blended,
              ...sustainabilityItems.grs
          ]
      }
  }
    return newStyles;
}

function changeActiveFilters (filter: Filter, filterType: any, filterValue: string, isActive?: boolean) {
    const newFilter = cloneDeep(filter);

    if (!newFilter) return newFilter;

    switch (filterType) {
      case 'deliveryWindows':
        if (isActive) {
          newFilter.activeFilters.deliveryWindows.push(filterValue);
        } else {
          const filterTypeIndex = newFilter.activeFilters.deliveryWindows.indexOf(filterValue);
          newFilter.activeFilters.deliveryWindows.splice(filterTypeIndex, 1);
        }
        break;
      case 'productGroups':
        if (isActive) {
          newFilter.activeFilters.productGroups.push(filterValue);
        } else {
          const filterTypeIndex = newFilter.activeFilters.productGroups.indexOf(filterValue);
          newFilter.activeFilters.productGroups.splice(filterTypeIndex, 1);
        }
        break;
      case 'productCategories':
          if (isActive) {
            newFilter.activeFilters.productCategories.push(filterValue);
          } else {
            const filterTypeIndex = newFilter.activeFilters.productCategories.indexOf(filterValue);
            newFilter.activeFilters.productCategories.splice(filterTypeIndex, 1);
          }
          break;
        case 'topCollections':
          if (isActive) {
            newFilter.activeFilters.collections.push(filterValue);
          } else {
            const filterTypeIndex = newFilter.activeFilters.collections.indexOf(filterValue);
            newFilter.activeFilters.collections.splice(filterTypeIndex, 1);
          }
          break;
        case 'sustainability':
          if (isActive && newFilter.activeFilters.sustainability) {
            newFilter.activeFilters.sustainability.push(filterValue);
          } else {
            if (newFilter.activeFilters.sustainability) {
              const filterTypeIndex = newFilter.activeFilters.sustainability.indexOf(filterValue);
              newFilter.activeFilters.sustainability.splice(filterTypeIndex, 1);  
            }
          }
          break;
    }

    return newFilter;
}
