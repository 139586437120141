import React, { Component } from 'react'
import { ThunkDispatch } from 'redux-thunk'
import { RootState } from '../../store';
import { connect } from 'react-redux'
import {RouteComponentProps, withRouter} from "react-router-dom";

// Store.
import OrderApi from '../../store/order/OrderApi';
import { getDashboardOrderState } from '../../store/order/actions';

// Utils.
import PropagateLoader from 'react-spinners/PropagateLoader'

interface State {
    loadingNavOrder: boolean
    orderNotfound: boolean
}
  
interface OwnProps {
    match?: {params: {appOrderId: number}},
    history: any
}
  
interface DispatchProps {
    getDashboardOrderState: (orderId: number) => void
}
  
interface StateProps {
}
  
type Props = StateProps & OwnProps & DispatchProps & RouteComponentProps;

export class LookUpNavOrder extends Component<Props, State> {
    state: State = {
        loadingNavOrder: true,
        orderNotfound: false,
    }

    async componentDidMount () {
        const appOrderId = this.props.match.params.appOrderId;

        if (appOrderId) {
            const response = await OrderApi.lookupOrderInNav(appOrderId);

            if (response && response.data.sonumber) {
                const navOrderResponse = await OrderApi.openNavOrder(response.data.sonumber)
                const createdapporderid = navOrderResponse.data.createdapporderid

                if (navOrderResponse) {
                    this.props.history.push('/dashboard/' + createdapporderid);
                    this.setState({loadingNavOrder: false})
                } else {
                    this.setState({loadingNavOrder: false, orderNotfound: true})
                }
            } else {
                this.setState({loadingNavOrder: false, orderNotfound: true})
            }
        }
    }

    render() {
        if (this.state.loadingNavOrder) {
            return (
                <div className="loading-spinner-1">
                    <h2>Loading your order</h2>
                    <p style={{marginBottom: '70px'}}>Please wait - it won't be long :-)</p>
                    <PropagateLoader />
                </div>
            )
        }

        if (this.state.orderNotfound) {
            return (
                <div className="loading-spinner-1">
                    <h2>Your order is being processed!</h2>
                    <p style={{marginBottom: '70px'}}>Please try again later.</p>
                </div>
            )
        }

        return null
    }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
    return {
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
    return {
        getDashboardOrderState: async (orderId: number) => {
            await dispatch(getDashboardOrderState(orderId))
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LookUpNavOrder)) 
