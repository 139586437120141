import { Component } from 'react'
import { connect } from 'react-redux'

// import { RouteComponentProps } from 'react-router-dom'
import {RouteComponentProps, withRouter} from "react-router-dom";
// import authenticateUser from '../../services/auth/authenticate';

import { ThunkDispatch } from 'redux-thunk'
import { RootState } from '../../store';
import { IUserSate } from '../../store/user/reducers';
import { logoutUser } from '../../store/user/actions';

interface RouteProps extends RouteComponentProps {}

interface State {
    userIsInvalid: boolean
}
  
interface OwnProps {
    history: any
}
  
interface DispatchProps {
    logoutUser: () => void;
}
  
interface StateProps {
    userState: IUserSate
}
  
type Props = StateProps & OwnProps & DispatchProps & RouteProps;

export class Logout extends Component<Props, State> {

    state = { userIsInvalid: false }

    componentDidMount() {
        if (this.props.userState.user) {
            
            let state = { ...this.props.history.location.state };
            delete state.referer;
            console.log(state)
            this.props.history.replace({ ...this.props.history.location.state, state });

            this.props.logoutUser();
        }

    }

    componentDidUpdate(prevProps: Props) {
        // console.log(this.props.userState.user, 'this.props.userState.user')
        if (!this.props.userState.user) {
            let state = { ...this.props.history.location.state };
            delete state.referer;
            console.log(state)
            this.props.history.replace({ ...this.props.history.location.state, state });
            this.setState({userIsInvalid: true})
        }
    }

    render() {
        if (this.state.userIsInvalid) {
            console.log('redirect')
        }
        return null
    }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => {
    return {
      userState: states.user.user
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
    return {
        logoutUser: async () => {
            await dispatch(logoutUser())
            // console.log('user login completed [UI]')
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout))