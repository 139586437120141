import React from 'react';
import Modal from './Modal';

interface IProps {
    isShowing: boolean,
    hide: any,
    content: any,
    footer?: any
}

const ConfirmModal: React.FC<IProps> = (props) => {
    const { isShowing, hide, content } = props;

    if (isShowing) {
        return (
            <Modal
                children={content}
                size={'sm'}
                hide={hide}
                isShowing={isShowing}
                bodyClasses={'confirm-modal'}
                hideFooter={true}/>
        )
    }
    
    return null;
}

export default ConfirmModal;