import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { Filter } from '../../models/Filter';

// Action Definition
export interface GetFilterAction {
  type: 'GET_FILTER'
}
export interface SetFetcing {
  type: 'SET_FETCHING'
  isFetching: boolean
}

export interface ApplyFilterAction {
  type: 'APPLY_FILTER'
  filter: Filter
}

export interface ResetFilterSelectedAction {
  type: 'RESET_FILTER_SELECTED'
  filter: Filter
}

// Union Action Types
export type Action = GetFilterAction | SetFetcing | ApplyFilterAction | ResetFilterSelectedAction

// Action Creators
export const get = (): GetFilterAction => {
  return { type: 'GET_FILTER' }
}
export const isFetching = (isFetching: boolean): SetFetcing => {
  return { type: 'SET_FETCHING', isFetching }
}
export const apply = (filter: Filter): ApplyFilterAction => {
  return { type: 'APPLY_FILTER', filter }
}

export const reset = (filter: Filter): ResetFilterSelectedAction => {
  return { type: 'RESET_FILTER_SELECTED', filter }
}

// thunk action
export const getFilter = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(get())
  }
}

export const applyFilter = (filter: Filter): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(apply(filter))
  }
}

export const resetFilter = (filter: Filter): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  // Invoke API
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    console.log('filter action triggred')
    dispatch(reset(filter))
  }
}
