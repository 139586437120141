import * as React from "react";

import {isFunction, isString} from 'lodash';

import { FaCheck, FaCaretDown } from 'react-icons/fa';

interface Props {
    items: any[],
    identifier: string,
    multi?: boolean,
    value: any,
    selected?: any
    onSelect?: Function
    allOption?: boolean
    allLabel?: string
    extraLabel?: string | Function | boolean
    placeholder?: string
    trigger?: Function
    valueRender?: Function
}

const Dropdown: React.FC<Props> = (props) => {
    const { items, identifier, value, onSelect, multi, allOption, allLabel, placeholder, extraLabel } = props;
    const [open, setValue] = React.useState(false);
    const node = React.useRef(null);
    const DEFAULT_PLACEHOLDER_STRING = placeholder || 'Select...';

    const handleToggle = (event: any) => {
        event.stopPropagation();
        setValue(!open)
    }

    const onClickSelected = (selected: any) => {
        if (onSelect) {
            onSelect(selected);

            if (!multi) {
                setValue(false)
            }
        }
    }

    const handleClickOutside = (e: any) => {

        // @ts-ignore
        if(node.current.contains(e.target)) {
            return;
        }
        
        // outside click 
        setValue(false)
    };

    const isItemSelected = (item: any) => { 
        if (multi) {
            if (identifier === '$index') {
                return Array.isArray(value) ? value.indexOf(item) !== -1 : item === value;
            } else {
                if (Array.isArray(value)) {
                    return value.map(val => val[identifier]).indexOf(item[identifier]) !== -1;
                }
            }
        }

        return identifier !== '$index' && value? item[identifier] === value[identifier] : item === value;
    }

    const renderExtraLabel = (item?: any) => {
        if (isFunction(extraLabel)) {
            return extraLabel(item)
        }

        if (isString(extraLabel)) {
            return <sub className="extra-label"> - upcoming</sub>;
        }

        return null
    }

    React.useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClickOutside);
        // return function to be called when unmounted
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    let printValue = identifier !== '$index' && value && value[identifier] ? (value[identifier] || DEFAULT_PLACEHOLDER_STRING) : (value  || DEFAULT_PLACEHOLDER_STRING);

    if (multi) {
        if (identifier !== '$index') {
            printValue = "";
        } else {
            if (Array.isArray(value)) {
                if (value.length) {
                    printValue = value.map(v => `${v}`).join(' | ');   
                } else {
                    printValue = DEFAULT_PLACEHOLDER_STRING;
                }
            }
        }
    }

    return (
        <div className={`dropdown ${open ? 'active' : ''}`} ref={node}>
            <div className="dropdown__trigger" onClick={handleToggle}>
                <span>
                    { !props.trigger && printValue.replaceAll('_',' ') }
                    {
                        props.trigger && props.trigger()
                    }
                </span>
                <span className={"dropdown__trigger__icon" + (open ? " open" : "")}>
                    <FaCaretDown />
                </span>
            </div>
            <div className={"dropdown__menu " + (open ? "--is-active" : "--is-hidden")}>
                {
                    allOption &&
                    <div
                        className={"dropdown__menu__item"}
                        onClick={(event: any) => {
                            event.stopPropagation();
                            onClickSelected('all')
                            setValue(false);
                        }}>
                            { allLabel || 'All'}
                    </div>
                }
                {
                    items.map((item, key) => {
                        return (
                            <div
                                className={"dropdown__menu__item " + (isItemSelected(item) ? "--is-selected" : "")}
                                key={key}
                                onClick={(event: any) => {
                                    event.stopPropagation();
                                    onClickSelected(item)}
                                }
                            >
                                    { !props.valueRender && (identifier !== '$index' ? item[identifier].replaceAll('_',' ') : item.replaceAll('_',' ')) }
                                    { props.valueRender ? props.valueRender(item) : null }
                                    {extraLabel && renderExtraLabel(item)}
                                    {
                                        isItemSelected(item) &&
                                        <span className="dropdown__menu__item__selected-icon"><FaCheck /></span>
                                    }
                            </div>
                        )
                    })
                }
            </div>
        </div>

    )
}

export default Dropdown;