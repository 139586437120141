// Our data store
import reduxStore from '../store';
import axios from 'axios';
// import reduxStore from '../store';
import { setAlert } from './alert/actions';

const {dispatch} = reduxStore;

const UNAUTHORIZED = 401;
// const {dispatch} = reduxStore; // direct access to redux store.


// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.status === UNAUTHORIZED) {
        return Promise.reject(response);
    }

    if (response.status > 399) {
        if (response.status > 499) {
            const message = response.data.error || '';
            dispatch(setAlert(message, 'critical'))
        }
        return Promise.reject(response);
    }

    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});

axios.interceptors.request.use(config => {
    config['validateStatus'] = function (status) {
        return status >= 200 && status < 550; // default
    };
    return config;
})

export default axios;   