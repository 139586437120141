import Order from '../models/Order';
// import { initialFilter } from './Filter';

import {cloneDeep} from 'lodash';

export interface basketLine {[id: string]: number}

export interface Basket {
    total: number
    currency: string
    items: any[]
    numberOfItems: number,
    basketLines: basketLine,
    changes: basketLine,
}

export const initialBasket = {
    total: 0,
    currency: '',
    items: [],
    numberOfItems: 0,
    basketLines: {},
    changes: {},
}

export const compileBasket = (order: Order): Basket => {
    let basketLines: basketLine = {};
    let changes: basketLine = {};
    const orderLines = cloneDeep(order.orderLines);

    for (const key in orderLines) {
        const o = orderLines[key];
        basketLines[o.itemId] = o.quantity;
    }

    return {
        total: order.orderTotals.amount,
        numberOfItems: order.orderTotals.noOfItems,
        currency: order.orderheader.currencyCode,
        items: order.orderLines,
        basketLines,
        changes,
    }
};